import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import {
  Checkbox,
  CheckboxChangeEvent,
  CheckboxModule,
} from "primeng/checkbox";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { EventEmitterValue } from "shared-types";

@Component({
  selector: "db-checkbox",
  standalone: true,
  imports: [CheckboxModule, FormsModule, ReactiveFormsModule],
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @ViewChild(Checkbox, { read: Checkbox, static: true })
  primeCheckbox!: Checkbox;

  @Input() label: string = "";
  @Input() inputId: string = "";
  @Input() name: string = "";
  @Input() value: string = "";
  @Input() binary: boolean = true;
  @Input() isDisabled = false;

  @Input() dataTestId: string = "";

  @Output() onChange = new EventEmitter<
    EventEmitterValue<Checkbox["onChange"]>
  >();

  isChecked: boolean = false;

  private changeFn!: (value: boolean) => {};
  private onTouch!: () => {};

  ngOnInit() {
    this.primeCheckbox.onChange.subscribe(this.onChange);
  }

  registerOnChange(fn: any): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.isChecked = value;
  }

  valueChangedHandler(event: CheckboxChangeEvent): void {
    this.value = event.checked;
    if (this.changeFn) {
      this.changeFn(event.checked);
    }

    if (this.onTouch) {
      this.onTouch();
    }
  }
}
