import { Moment } from "moment";
import { DayShortAbbreviation, IOpeningHours } from "types";

export function getShortDayAbbreviation(
  dateTime: Moment,
): DayShortAbbreviation {
  return dateTime
    .clone()
    .locale("en")
    .format("ddd")
    .toLowerCase() as keyof IOpeningHours;
}
