import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { ExecPipe } from "shared-pipes";
import { DropdownModule } from "primeng/dropdown";
import { PrimeTemplate } from "primeng/api";
import { DropdownComponent } from "../dropdown";
import { ChipComponent } from "../chip";

/** @deprecated - use multiselect instead */
@Component({
  selector: "db-multi-select-chip-dropdown",
  standalone: true,
  imports: [
    CommonModule,
    DropdownComponent,
    FormsModule,
    ChipComponent,
    ExecPipe,
    DropdownModule,
  ],
  templateUrl: "./multi-select-chip-dropdown.component.html",
  styleUrls: ["./multi-select-chip-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MultiSelectChipDropdown,
      multi: true,
    },
  ],
  exportAs: "dbMultiSelectChipDropdown",
})
export class MultiSelectChipDropdown<T>
  implements OnChanges, ControlValueAccessor
{
  @ViewChild(DropdownComponent, { read: DropdownComponent, static: true })
  dropdown!: DropdownComponent;

  @ContentChildren(PrimeTemplate) templates!: QueryList<PrimeTemplate>;

  @ContentChild("multiSelectChipDropdownChipContent", {
    read: TemplateRef,
    descendants: true,
  })
  chipItemTemplateRef?: TemplateRef<{
    item: T;
  }>;
  @ContentChild("multiSelectChipDropdownOptionContent", {
    read: TemplateRef,
    descendants: true,
  })
  optionItemTemplateRef?: TemplateRef<{
    item: T;
  }>;
  @ContentChild("multiSelectChipDropdownChip", {
    read: TemplateRef,
    descendants: true,
  })
  chipTemplateRef?: TemplateRef<{
    $implicit: T;
    index: number;
    isNew: boolean;
    isRemoved: boolean;
    removedItemHandler: (item: T) => void;
    undoRemovedItemHandler: (item: T) => void;
  }>;

  @Output("onChange") onChangeEmitter = new EventEmitter<T[keyof T][]>();
  @Output("itemSelected") itemSelectEmitter = new EventEmitter<T>();
  @Output("itemRemoved") itemRemoveEmitter = new EventEmitter<T>();

  @Input() dropdownOptions: T[] = [];
  @Input() optionLabel!: keyof T;
  @Input() optionValue!: keyof T;
  @Input() optionDisabled!: string;
  @Input() filterBy = "";
  @Input() placeholder = "";
  @Input() chipImageField: keyof T | null = null;
  @Input() inputWidth?: number | string;
  disabled = false;
  @Input() autoDisplayFirst = false;
  @Input() itemDataSelectorFn: (item: T) => any = (item: T) =>
    `${item[this.optionValue]}`;
  @Input() itemsCompareFn: (a: T, b: T) => boolean = (a: T, b: T) =>
    this.itemDataSelectorFn(a) === b;
  @Input() dropdownWidth = "100%";
  @Input() dropdownDataTestId?: string;

  readonly newBadgeLabel = $localize`:@@common|new:new`.toLowerCase();
  changeDetectorRef = inject(ChangeDetectorRef);

  dropdownValue: T | null = null;

  private _writeValueSelectedItemValues: any[] = [];
  private _selectedItems: T[] = [];
  private _removedItems: T[] = [];

  notSelectedDropdownOptions: T[] = [];

  set selectedItems(selectedItems: T[]) {
    this._selectedItems = selectedItems;
    this.notSelectedDropdownOptions = this.dropdownOptions?.filter(
      (o) =>
        !this.selectedItems.find(
          (s) => this.itemDataSelectorFn(o) === this.itemDataSelectorFn(s),
        ),
    );
  }

  get selectedItems() {
    return this._selectedItems;
  }

  get removedItems() {
    return this._removedItems;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["dropdownOptions"]) {
      if (!!changes["dropdownOptions"]?.isFirstChange) {
        this.selectedItems = []; // initial setup
      }

      this.setupSelectedItems();
    }
  }

  onChange = (items: any[]) => {
    this.onChangeEmitter.emit(items);
  };

  setDisabledState = (isDisabled: boolean) => {
    this.disabled = isDisabled;
  };

  setupSelectedItems() {
    this.selectedItems = this._writeValueSelectedItemValues
      .map(
        (v) =>
          this.dropdownOptions.find((o) => this.itemsCompareFn(o, v)) || null,
      )
      .filter((val) => !!val) as T[];
  }

  selectedItemsChange() {
    this.onChange(
      this.selectedItems.map((item) => this.itemDataSelectorFn(item)),
    );
  }

  selectedItemHandler = ({
    value,
    originalEvent,
  }: {
    value: T;
    originalEvent: Event;
  }): void => {
    if (originalEvent instanceof KeyboardEvent) {
      return void (this.dropdownValue = value);
    }
    this.selectedItems = this.selectedItems.concat(value);
    this._removedItems = this._removedItems.filter(
      (i) => this.itemDataSelectorFn(i) !== this.itemDataSelectorFn(value),
    );
    this.dropdownValue = null;

    this.itemSelectEmitter.emit(value);
    this.selectedItemsChange();
  };

  onDropdownHideHandler = (): void => {
    if (
      !this.dropdownValue ||
      this.selectedItems.includes(this.dropdownValue)
    ) {
      return;
    }
    this.selectedItems = this.selectedItems.concat(this.dropdownValue);
    this.dropdownValue = null;
    this.selectedItemsChange();
  };

  removedItemHandler = (item: T): void => {
    this.selectedItems = this.selectedItems.filter((itm) => item !== itm);
    if (!this.isNewItem(item)) {
      this._removedItems = this._removedItems.concat(item);
    }

    this.itemRemoveEmitter.emit(item);
    this.selectedItemsChange();
  };

  undoRemovedItemHandler = (item: T): void => {
    this.selectedItems = this.selectedItems.concat(item);
    this._removedItems = this._removedItems.filter((i) => i !== item);
    this.selectedItemsChange();
  };

  isNewItem = (item: T) => {
    return !this._writeValueSelectedItemValues.find((v) =>
      this.itemsCompareFn(v, item),
    );
  };

  getImageSrc = (item: T): string | undefined => {
    if (!this.chipImageField) {
      return undefined;
    }
    return (item[this.chipImageField] as string) || undefined;
  };

  registerOnChange(fn: (items: T[keyof T][]) => {}): void {
    const defaultOnChange = this.onChange;
    this.onChange = (items: T[keyof T][]) => {
      defaultOnChange.call(this, items);
      fn.call(this, items);
    };
  }

  registerOnTouched(fn: () => {}): void {
    const dropdownOnTouchHandler = this.dropdown.primeDropdown.onModelTouched;
    this.dropdown.primeDropdown.onModelTouched = () => {
      dropdownOnTouchHandler.call(this.dropdown);
      fn();
    };
  }

  writeValue(selected: any): void {
    if (!Array.isArray(selected)) {
      return;
    }
    this._writeValueSelectedItemValues = selected;
    this._removedItems = [];
    this.setupSelectedItems();
    this.changeDetectorRef.markForCheck();
  }
}
