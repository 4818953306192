<db-card>
  <div class="wrapper">
    @if (emailVerified) {
      <h1 i18n="@@auth-module|verify-email|title">
        Your email has been verified!
      </h1>
      @if (showLottie$ | async) {
        <db-lottie-player
          width="250px"
          height="300"
          margin="auto"
          [lottie]="Lottie.VERIFIED_EMAIL"
          background="transparent"
          [speed]="1"
          [autoplay]="true"
        >
        </db-lottie-player>
      }
      <db-button
        width="full-width"
        (click)="goToDeskbirdWebApp()"
        i18n="@@auth-module|verify-email|go-to-webapp"
      >
        Go to deskbird web app
      </db-button>
      <a
        (click)="goBackToLogin()"
        queryParamsHandling="preserve"
        class="big-link"
        i18n="@@auth-module|go-back"
        >Go back</a
      >
    } @else {
      <h1 i18n="@@auth-module|verify-email|check-email">
        Please check your inbox
      </h1>
      @if (showLottie$ | async) {
        <db-lottie-player
          style="width: 250px; height: 300px; margin: auto"
          [lottie]="Lottie.VERIFY_EMAIL"
          background="transparent"
          [speed]="1"
          [autoplay]="true"
        >
        </db-lottie-player>
      }
      <p i18n="@@auth-module|verify-email|body">
        We have sent you a verification email.
        <br />
        Please click on the verification link to confirm that you are the owner
        of the email address.
        <br /><br />
        Did not receive any email? Please also check your spam folder or
        <br />
        <a class="resend" (click)="resendEmail()">
          resend verification email.</a
        >
      </p>
    }
    @if (!emailVerified) {
      <a class="big-link" (click)="goBackToLogin()" i18n="@@auth-module|go-back"
        >Go back</a
      >
    }
  </div>
</db-card>
