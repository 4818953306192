import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";

@Directive({
  selector: "[dbDelayVisibility]",
})
export class DelayVisibilityDirective implements OnInit {
  @Input() dbDelayVisibility = 0;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      "visibility",
      "hidden",
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        "visibility",
        "visible",
      );
    }, this.dbDelayVisibility);
  }
}
