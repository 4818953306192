import { Component, inject } from "@angular/core";
import { interval } from "rxjs";
import { map, startWith, take } from "rxjs/operators";

import { WINDOW } from "common-module";
import { Lottie } from "lottie-module";

@Component({
  selector: "db-not-active-company",
  templateUrl: "./not-active-company.component.html",
  styleUrls: ["./not-active-company.component.scss"],
})
export class NotActiveCompanyComponent {
  private readonly window = inject<Window>(WINDOW);

  readonly Lottie = Lottie;
  showLottie$ = interval(0).pipe(
    take(1),
    map(() => true),
    startWith(false),
  );

  requestDemoClickHandler(): void {
    this.window
      .open("https://www.deskbird.com/request-demo", "_blank")
      ?.focus();
  }
}
