import { workingDaysToNumberMap, IOpeningHours } from "types";

export function officeNonWorkingDays(
  openingHours: IOpeningHours | null | undefined,
): number[] {
  if (!openingHours) {
    return [];
  }

  const nonWorkingDays = Object.keys(openingHours).reduce(
    (nonWorkingDaysCurrentValue, weekDayAbbreviation) => {
      const dayOpeningHours =
        openingHours[weekDayAbbreviation as keyof IOpeningHours];
      if (!dayOpeningHours.isOpen) {
        const dayNumber =
          workingDaysToNumberMap[
            weekDayAbbreviation as keyof typeof workingDaysToNumberMap
          ];
        nonWorkingDaysCurrentValue.push(dayNumber);
      }

      return nonWorkingDaysCurrentValue;
    },
    new Array<number>(),
  );

  return nonWorkingDays;
}
