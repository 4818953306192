import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  OnInit,
  inject,
} from "@angular/core";
import {
  Subject,
  takeUntil,
  BehaviorSubject,
  combineLatest,
  switchMap,
  of,
  tap,
} from "rxjs";
import { FeatureAccessService } from "../services/feature-access.service";
import { FeatureAccessType } from "types";

/**
 * Structural directive for conditional rendering based on feature access.
 *
 * @example
 * // Single feature check
 * <div *dbFeatureAccess="'FEATURE_NAME'">Content</div>
 *
 * // Multiple features with condition
 * <div *dbFeatureAccess="['FEATURE_1', 'FEATURE_2']; condition: 'every'">
 *   Content shown when user has ALL features
 * </div>
 *
 * // With else template
 * <div *dbFeatureAccess="'FEATURE_NAME'; else noAccess">
 *   Content when feature is available
 * </div>
 * <ng-template #noAccess>
 *   Content when feature is not available
 * </ng-template>
 */
@Directive({
  selector: "[dbFeatureAccess]",
  standalone: true,
})
export class FeatureAccessDirective implements OnDestroy, OnInit {
  @Input() set dbFeatureAccess(value: FeatureAccessType | FeatureAccessType[]) {
    this.feature$$.next(value);
  }
  @Input() set dbFeatureAccessCondition(condition: "some" | "every") {
    this.condition$$.next(condition);
  }
  @Input() set dbFeatureAccessElse(templateRef: TemplateRef<unknown> | null) {
    this.elseTemplate$$.next(templateRef);
  }

  private readonly destroy$$ = new Subject<void>();
  private readonly feature$$ = new BehaviorSubject<
    FeatureAccessType | FeatureAccessType[] | null
  >(null);
  private readonly condition$$ = new BehaviorSubject<"some" | "every">("some");
  private readonly elseTemplate$$ =
    new BehaviorSubject<TemplateRef<unknown> | null>(null);

  private readonly featureAccessService = inject(FeatureAccessService);
  private readonly templateRef = inject(TemplateRef<unknown>);
  private readonly viewContainer = inject(ViewContainerRef);

  private hasView = false;
  private hasElseView = false;

  ngOnInit(): void {
    this.setupSubscription();
  }

  private setupSubscription(): void {
    combineLatest([this.feature$$, this.condition$$, this.elseTemplate$$])
      .pipe(
        switchMap(([feature, condition, elseTemplate]) => {
          if (!feature) {
            return of(false);
          }
          return this.featureAccessService
            .hasFeatureAccess(feature, condition)
            .pipe(
              tap((hasAccess) => {
                // features are not loaded yet
                if (hasAccess === null) {
                  return;
                }

                if (
                  (!hasAccess && this.hasView) ||
                  (hasAccess && this.hasElseView)
                ) {
                  this.viewContainer.clear();
                  this.hasView = false;
                  this.hasElseView = false;
                }

                if (hasAccess && !this.hasView) {
                  this.viewContainer.createEmbeddedView(this.templateRef);
                  this.hasView = true;
                } else if (!hasAccess && elseTemplate && !this.hasElseView) {
                  this.viewContainer.createEmbeddedView(elseTemplate);
                  this.hasElseView = true;
                }
              }),
            );
        }),
        takeUntil(this.destroy$$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }
}
