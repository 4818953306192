<p-selectButton
  [options]="options"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [multiple]="multiple"
>
  <ng-template let-item pTemplate>
    <ng-container *ngIf="template; else defaultTpl">
      <ng-container
        *ngTemplateOutlet="template; context: { $implicit: item }"
      ></ng-container>
    </ng-container>
    <ng-template #defaultTpl>
      {{ item.label }}
    </ng-template>
  </ng-template>
</p-selectButton>
