<db-card>
  <div class="wrapper">
    @if (emailCheckResult$ | async) {
      <h3>
        @if (!(emailCheckResult$ | async)?.userExists) {
          <ng-container i18n="@@auth-module|welcome">
            Welcome to {{ (emailCheckResult$ | async)?.companyName }}
          </ng-container>
        } @else {
          <ng-container i18n="@@auth-module|welcome-back"
            >Welcome back to {{ (emailCheckResult$ | async)?.companyName }}
          </ng-container>
        }
      </h3>
    }

    <div
      class="company-logo"
      [ngStyle]="{
        'background-image':
          'url(' + (emailCheckResult$ | async)?.companyLogoUrl + ')',
        'background-position': 'center',
      }"
    ></div>
    <div class="email">
      <img src="/assets/icons/purple-check-mark.svg" alt="Check" />
      <p class="email">{{ (emailCheckResult$ | async)?.email }}</p>
    </div>
    @if (emailCheckResult$ | async; as emailCheckResult) {
      <div id="social-sign-in" class="social-sign-in-buttons">
        @if (emailCheckResult.userExists) {
          @if (emailCheckResult.extraInformation) {
            <div class="info">
              <img src="/assets/icons/info.svg" alt="I" />
              <p>{{ emailCheckResult.extraInformation }}</p>
            </div>
          }
          @if (emailCheckResult.loginMethods?.google?.enabled) {
            <db-button
              [buttonType]="'outlined'"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
              [width]="'full-width'"
              id="google"
              (click)="login(LoginType.Google)"
            >
              <img src="/assets/icons/google-logo.svg" alt="Google Logo" />
              <db-loading-tense
                infinitiveText="Sign in with Google"
                presentText="Signing in"
                [state]="
                  isSigningInWithGoogle
                    ? LoadingTenseState.Present
                    : LoadingTenseState.Infinitive
                "
                i18n-infinitiveText="
                  @@auth-module|sign-in|google-loader-infinitive-text"
                i18n-presentText="@@auth-module|sign-in-loader-present-text"
              >
              </db-loading-tense>
            </db-button>
          }
          @if (emailCheckResult.loginMethods?.microsoft?.enabled) {
            <db-button
              id="microsoft"
              (click)="login(LoginType.Microsoft)"
              [buttonType]="'outlined'"
              [width]="'full-width'"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
            >
              <img
                src="/assets/icons/microsoft-logo-small.svg"
                alt="Microsoft Logo"
              />
              <db-loading-tense
                infinitiveText="Sign in with Microsoft"
                presentText="Signing in"
                [state]="
                  isSigningInWithMicrosoft
                    ? LoadingTenseState.Present
                    : LoadingTenseState.Infinitive
                "
                i18n-infinitiveText="
                  @@auth-module|sign-in|microsoft-loader-infinitive-text"
                i18n-presentText="@@auth-module|sign-in-loader-present-text"
              >
              </db-loading-tense>
            </db-button>
          }
          @if (emailCheckResult.loginMethods?.saml?.enabled) {
            <db-button
              id="saml"
              (click)="login(LoginType.Saml)"
              [buttonType]="'outlined'"
              [width]="'full-width'"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
            >
              <img src="/assets/icons/sso.svg" alt="SSO" />
              <db-loading-tense
                infinitiveText="Sign in with company account (SSO)"
                presentText="Signing in"
                [state]="
                  isSigningInWithSAML
                    ? LoadingTenseState.Present
                    : LoadingTenseState.Infinitive
                "
                i18n-infinitiveText="
                  @@auth-module|sign-in|saml-loader-infinitive-text"
                i18n-presentText="@@auth-module|sign-in-loader-present-text"
              >
              </db-loading-tense>
            </db-button>
          }
          @if (emailCheckResult.loginMethods?.email?.enabled) {
            <db-button
              id="email"
              [buttonType]="'outlined'"
              [width]="'full-width'"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
              (click)="onEmailSignIn()"
            >
              <img src="/assets/icons/envelope.svg" alt="Envelope" />
              <span i18n="@@auth-module|sign-in|sign-in-email"
                >Sign in with email</span
              >
            </db-button>
          }
        } @else {
          @if (emailCheckResult.extraInformation) {
            <div class="info">
              <img src="/assets/icons/info.svg" alt="I" />
              <p>{{ emailCheckResult.extraInformation }}</p>
            </div>
          }
          @if (emailCheckResult.loginMethods?.google?.enabled) {
            <db-button
              id="google"
              [buttonType]="'outlined'"
              [width]="'full-width'"
              (click)="login(LoginType.Google)"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
            >
              <img src="/assets/icons/google-logo.svg" alt="Google Logo" />
              <db-loading-tense
                infinitiveText="Sign up with Google"
                presentText="Signing up"
                [state]="
                  isSigningInWithGoogle
                    ? LoadingTenseState.Present
                    : LoadingTenseState.Infinitive
                "
                i18n-infinitiveText="
                  @@auth-module|sign-up|google-loader-infinitive-text"
                i18n-presentText="@@auth-module|sign-up-loader-present-text"
              >
              </db-loading-tense>
            </db-button>
          }
          @if (emailCheckResult.loginMethods?.microsoft?.enabled) {
            <db-button
              id="microsoft"
              [buttonType]="'outlined'"
              [width]="'full-width'"
              (click)="login(LoginType.Microsoft)"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
            >
              <img
                src="/assets/icons/microsoft-logo-small.svg"
                alt="Microsoft Logo"
              />
              <db-loading-tense
                infinitiveText="Sign up with Microsoft"
                presentText="Signing up"
                [state]="
                  isSigningInWithMicrosoft
                    ? LoadingTenseState.Present
                    : LoadingTenseState.Infinitive
                "
                i18n-infinitiveText="
                  @@auth-module|sign-up|microsoft-loader-infinitive-text"
                i18n-presentText="@@auth-module|sign-up-loader-present-text"
              >
              </db-loading-tense>
            </db-button>
          }
          @if (emailCheckResult.loginMethods?.saml?.enabled) {
            <db-button
              id="saml"
              [buttonType]="'outlined'"
              [width]="'full-width'"
              (click)="login(LoginType.Saml)"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
            >
              <img src="/assets/icons/sso.svg" alt="SSO" />
              <db-loading-tense
                infinitiveText="Sign up with company account (SSO)"
                presentText="Signing up"
                [state]="
                  isSigningInWithSAML
                    ? LoadingTenseState.Present
                    : LoadingTenseState.Infinitive
                "
                i18n-infinitiveText="
                  @@auth-module|sign-up|saml-loader-infinitive-text"
                i18n-presentText="@@auth-module|sign-up-loader-present-text"
              >
              </db-loading-tense>
            </db-button>
          }
          @if (emailCheckResult.loginMethods?.email?.enabled) {
            <db-button
              id="email"
              [buttonType]="'outlined'"
              [width]="'full-width'"
              (click)="onEmailSignUp()"
              [disabled]="
                isSigningInWithGoogle ||
                isSigningInWithMicrosoft ||
                isSigningInWithSAML
              "
            >
              <img src="/assets/icons/envelope.svg" alt="Envelope" />
              <span i18n="@@auth-module|sign-up-email">Sign up with email</span>
            </db-button>
          }
        }
        <a
          class="big-link"
          [routerLink]="['/login']"
          queryParamsHandling="preserve"
          i18n="@@auth-module|go-back"
          >Go back</a
        >
      </div>
    }
  </div>
</db-card>
