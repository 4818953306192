import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FeatureAccessType, IBookingCheckinInfo, IUser } from "types";
import { BookingCheckInStatusUtil } from "./booking-check-in-status-util";
import { IBookingCheckInStatusDto } from "./types";
import { AsyncPipe, NgClass } from "@angular/common";
import { TooltipModule } from "primeng/tooltip";
import { FeatureAccessService } from "auth-module/services/feature-access.service";

@Component({
  selector: "db-booking-status",
  templateUrl: "./booking-status.component.html",
  styleUrls: ["./booking-status.component.scss"],
  standalone: true,
  imports: [NgClass, TooltipModule, AsyncPipe],
})
export class BookingStatusComponent implements OnInit, OnChanges {
  private readonly bookingCheckInStatusUtil = inject(BookingCheckInStatusUtil);
  @Output() bookingCheckInConfirmation = new EventEmitter<void>();
  @Input() booking!: IBookingCheckinInfo;
  @Input() authUser!: Pick<IUser, "hourFormat">;
  @Input() showOnlyStatusIcon = false;
  @Input() onlyColor = false;
  @Input() alignContentEnd = true;
  @Input() dataTestIdForCheckInCta?: string;

  bookingStatusDto!: IBookingCheckInStatusDto;
  protected readonly featureAccessService: FeatureAccessService =
    inject(FeatureAccessService);

  protected readonly canSeeStatus$ = this.featureAccessService.hasFeatureAccess(
    FeatureAccessType.RESOURCE_BOOKING,
  );

  ngOnInit() {
    this.bookingStatusDto =
      this.bookingCheckInStatusUtil.buildStatusIconAndText(
        this.booking,
        this.onlyColor,
        this.authUser,
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["booking"] && !changes["booking"].firstChange) {
      this.bookingStatusDto =
        this.bookingCheckInStatusUtil.buildStatusIconAndText(
          this.booking,
          this.onlyColor,
          this.authUser,
        );
    }
  }

  checkInHandler(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.bookingCheckInConfirmation.emit();
  }
}
