import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ExportRangeType } from "common-module";
import { dateRangeValidator } from "../../validators";

const maxAllowedPeriodInMonthsDefault = 36;

export const exportDialogForm = (maxAllowedPeriodInMonths?: number) =>
  new FormGroup(
    {
      exportType: new FormControl<ExportRangeType>(ExportRangeType.ALL, [
        Validators.required,
      ]),
      startDate: new FormControl<string>(
        {
          value: "",
          disabled: true,
        },
        [Validators.required],
      ),
      endDate: new FormControl<string>(
        {
          value: "",
          disabled: true,
        },
        [Validators.required],
      ),
    },
    {
      validators: [
        dateRangeValidator(
          "startDate",
          "endDate",
          maxAllowedPeriodInMonths || maxAllowedPeriodInMonthsDefault,
        ),
      ],
    },
  );
