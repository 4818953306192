export const ALL_OFFICES = "all";

export const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"] as const;

export const TWENTY_FOUR_HOURS = "24 hours";
export const uploadImageUrl = "/api/uploadMedia?internal=true&workspaceId=";
export const googleApiKey = "AIzaSyCDgzkO_CGqVvvc0z1uojddXJ3HE5i6bC4";

export const meetingRoomCateringAndServicesDataExportDateFormat = "DD-MM-YYYY";

export const OFFICE_STORE_NAME = "office";

export const countriesInfo = [
  {
    code: "AF",
    name: "Afghanistan",
    currencyCode: "AFN",
    continentName: "Asia",
  },
  {
    code: "AL",
    name: "Albania",
    currencyCode: "ALL",
    continentName: "Europe",
  },
  {
    code: "DZ",
    name: "Algeria",
    currencyCode: "DZD",
    continentName: "Africa",
  },
  {
    code: "AS",
    name: "American Samoa",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "AD",
    name: "Andorra",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "AO",
    name: "Angola",
    currencyCode: "AOA",
    continentName: "Africa",
  },
  {
    code: "AI",
    name: "Anguilla",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "AQ",
    name: "Antarctica",
    currencyCode: "",
    continentName: "Antarctica",
  },
  {
    code: "AG",
    name: "Antigua and Barbuda",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "AR",
    name: "Argentina",
    currencyCode: "ARS",
    continentName: "South America",
  },
  {
    code: "AM",
    name: "Armenia",
    currencyCode: "AMD",
    continentName: "Asia",
  },
  {
    code: "AW",
    name: "Aruba",
    currencyCode: "AWG",
    continentName: "North America",
  },
  {
    code: "AU",
    name: "Australia",
    currencyCode: "AUD",
    continentName: "Oceania",
  },
  {
    code: "AT",
    name: "Austria",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "AZ",
    name: "Azerbaijan",
    currencyCode: "AZN",
    continentName: "Asia",
  },
  {
    code: "BS",
    name: "Bahamas",
    currencyCode: "BSD",
    continentName: "North America",
  },
  {
    code: "BH",
    name: "Bahrain",
    currencyCode: "BHD",
    continentName: "Asia",
  },
  {
    code: "BD",
    name: "Bangladesh",
    currencyCode: "BDT",
    continentName: "Asia",
  },
  {
    code: "BB",
    name: "Barbados",
    currencyCode: "BBD",
    continentName: "North America",
  },
  {
    code: "BY",
    name: "Belarus",
    currencyCode: "BYR",
    continentName: "Europe",
  },
  {
    code: "BE",
    name: "Belgium",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "BZ",
    name: "Belize",
    currencyCode: "BZD",
    continentName: "North America",
  },
  {
    code: "BJ",
    name: "Benin",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "BM",
    name: "Bermuda",
    currencyCode: "BMD",
    continentName: "North America",
  },
  {
    code: "BT",
    name: "Bhutan",
    currencyCode: "BTN",
    continentName: "Asia",
  },
  {
    code: "BO",
    name: "Bolivia",
    currencyCode: "BOB",
    continentName: "South America",
  },
  {
    code: "BQ",
    name: "Bonaire",
    currencyCode: "USD",
    continentName: "North America",
  },
  {
    code: "BA",
    name: "Bosnia and Herzegovina",
    currencyCode: "BAM",
    continentName: "Europe",
  },
  {
    code: "BW",
    name: "Botswana",
    currencyCode: "BWP",
    continentName: "Africa",
  },
  {
    code: "BV",
    name: "Bouvet Island",
    currencyCode: "NOK",
    continentName: "Antarctica",
  },
  {
    code: "BR",
    name: "Brazil",
    currencyCode: "BRL",
    continentName: "South America",
  },
  {
    code: "IO",
    name: "British Indian Ocean Territory",
    currencyCode: "USD",
    continentName: "Asia",
  },
  {
    code: "VG",
    name: "British Virgin Islands",
    currencyCode: "USD",
    continentName: "North America",
  },
  {
    code: "BN",
    name: "Brunei",
    currencyCode: "BND",
    continentName: "Asia",
  },
  {
    code: "BG",
    name: "Bulgaria",
    currencyCode: "BGN",
    continentName: "Europe",
  },
  {
    code: "BF",
    name: "Burkina Faso",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "BI",
    name: "Burundi",
    currencyCode: "BIF",
    continentName: "Africa",
  },
  {
    code: "KH",
    name: "Cambodia",
    currencyCode: "KHR",
    continentName: "Asia",
  },
  {
    code: "CM",
    name: "Cameroon",
    currencyCode: "XAF",
    continentName: "Africa",
  },
  {
    code: "CA",
    name: "Canada",
    currencyCode: "CAD",
    continentName: "North America",
  },
  {
    code: "CV",
    name: "Cape Verde",
    currencyCode: "CVE",
    continentName: "Africa",
  },
  {
    code: "KY",
    name: "Cayman Islands",
    currencyCode: "KYD",
    continentName: "North America",
  },
  {
    code: "CF",
    name: "Central African Republic",
    currencyCode: "XAF",
    continentName: "Africa",
  },
  {
    code: "TD",
    name: "Chad",
    currencyCode: "XAF",
    continentName: "Africa",
  },
  {
    code: "CL",
    name: "Chile",
    currencyCode: "CLP",
    continentName: "South America",
  },
  {
    code: "CN",
    name: "China",
    currencyCode: "CNY",
    continentName: "Asia",
  },
  {
    code: "CX",
    name: "Christmas Island",
    currencyCode: "AUD",
    continentName: "Asia",
  },
  {
    code: "CC",
    name: "Cocos [Keeling] Islands",
    currencyCode: "AUD",
    continentName: "Asia",
  },
  {
    code: "CO",
    name: "Colombia",
    currencyCode: "COP",
    continentName: "South America",
  },
  {
    code: "KM",
    name: "Comoros",
    currencyCode: "KMF",
    continentName: "Africa",
  },
  {
    code: "CK",
    name: "Cook Islands",
    currencyCode: "NZD",
    continentName: "Oceania",
  },
  {
    code: "CR",
    name: "Costa Rica",
    currencyCode: "CRC",
    continentName: "North America",
  },
  {
    code: "HR",
    name: "Croatia",
    currencyCode: "HRK",
    continentName: "Europe",
  },
  {
    code: "CU",
    name: "Cuba",
    currencyCode: "CUP",
    continentName: "North America",
  },
  {
    code: "CW",
    name: "Curacao",
    currencyCode: "ANG",
    continentName: "North America",
  },
  {
    code: "CY",
    name: "Cyprus",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "CZ",
    name: "Czechia",
    currencyCode: "CZK",
    continentName: "Europe",
  },
  {
    code: "CD",
    name: "Democratic Republic of the Congo",
    currencyCode: "CDF",
    continentName: "Africa",
  },
  {
    code: "DK",
    name: "Denmark",
    currencyCode: "DKK",
    continentName: "Europe",
  },
  {
    code: "DJ",
    name: "Djibouti",
    currencyCode: "DJF",
    continentName: "Africa",
  },
  {
    code: "DM",
    name: "Dominica",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "DO",
    name: "Dominican Republic",
    currencyCode: "DOP",
    continentName: "North America",
  },
  {
    code: "TL",
    name: "East Timor",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "EC",
    name: "Ecuador",
    currencyCode: "USD",
    continentName: "South America",
  },
  {
    code: "EG",
    name: "Egypt",
    currencyCode: "EGP",
    continentName: "Africa",
  },
  {
    code: "SV",
    name: "El Salvador",
    currencyCode: "USD",
    continentName: "North America",
  },
  {
    code: "GQ",
    name: "Equatorial Guinea",
    currencyCode: "XAF",
    continentName: "Africa",
  },
  {
    code: "ER",
    name: "Eritrea",
    currencyCode: "ERN",
    continentName: "Africa",
  },
  {
    code: "EE",
    name: "Estonia",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "ET",
    name: "Ethiopia",
    currencyCode: "ETB",
    continentName: "Africa",
  },
  {
    code: "FK",
    name: "Falkland Islands",
    currencyCode: "FKP",
    continentName: "South America",
  },
  {
    code: "FO",
    name: "Faroe Islands",
    currencyCode: "DKK",
    continentName: "Europe",
  },
  {
    code: "FJ",
    name: "Fiji",
    currencyCode: "FJD",
    continentName: "Oceania",
  },
  {
    code: "FI",
    name: "Finland",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "FR",
    name: "France",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "GF",
    name: "French Guiana",
    currencyCode: "EUR",
    continentName: "South America",
  },
  {
    code: "PF",
    name: "French Polynesia",
    currencyCode: "XPF",
    continentName: "Oceania",
  },
  {
    code: "TF",
    name: "French Southern Territories",
    currencyCode: "EUR",
    continentName: "Antarctica",
  },
  {
    code: "GA",
    name: "Gabon",
    currencyCode: "XAF",
    continentName: "Africa",
  },
  {
    code: "GM",
    name: "Gambia",
    currencyCode: "GMD",
    continentName: "Africa",
  },
  {
    code: "GE",
    name: "Georgia",
    currencyCode: "GEL",
    continentName: "Asia",
  },
  {
    code: "DE",
    name: "Germany",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "GH",
    name: "Ghana",
    currencyCode: "GHS",
    continentName: "Africa",
  },
  {
    code: "GI",
    name: "Gibraltar",
    currencyCode: "GIP",
    continentName: "Europe",
  },
  {
    code: "GR",
    name: "Greece",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "GL",
    name: "Greenland",
    currencyCode: "DKK",
    continentName: "North America",
  },
  {
    code: "GD",
    name: "Grenada",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "GP",
    name: "Guadeloupe",
    currencyCode: "EUR",
    continentName: "North America",
  },
  {
    code: "GU",
    name: "Guam",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "GT",
    name: "Guatemala",
    currencyCode: "GTQ",
    continentName: "North America",
  },
  {
    code: "GG",
    name: "Guernsey",
    currencyCode: "GBP",
    continentName: "Europe",
  },
  {
    code: "GN",
    name: "Guinea",
    currencyCode: "GNF",
    continentName: "Africa",
  },
  {
    code: "GW",
    name: "Guinea-Bissau",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "GY",
    name: "Guyana",
    currencyCode: "GYD",
    continentName: "South America",
  },
  {
    code: "HT",
    name: "Haiti",
    currencyCode: "HTG",
    continentName: "North America",
  },
  {
    code: "HM",
    name: "Heard Island and McDonald Islands",
    currencyCode: "AUD",
    continentName: "Antarctica",
  },
  {
    code: "HN",
    name: "Honduras",
    currencyCode: "HNL",
    continentName: "North America",
  },
  {
    code: "HK",
    name: "Hong Kong",
    currencyCode: "HKD",
    continentName: "Asia",
  },
  {
    code: "HU",
    name: "Hungary",
    currencyCode: "HUF",
    continentName: "Europe",
  },
  {
    code: "IS",
    name: "Iceland",
    currencyCode: "ISK",
    continentName: "Europe",
  },
  {
    code: "IN",
    name: "India",
    currencyCode: "INR",
    continentName: "Asia",
  },
  {
    code: "ID",
    name: "Indonesia",
    currencyCode: "IDR",
    continentName: "Asia",
  },
  {
    code: "IR",
    name: "Iran",
    currencyCode: "IRR",
    continentName: "Asia",
  },
  {
    code: "IQ",
    name: "Iraq",
    currencyCode: "IQD",
    continentName: "Asia",
  },
  {
    code: "IE",
    name: "Ireland",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "IM",
    name: "Isle of Man",
    currencyCode: "GBP",
    continentName: "Europe",
  },
  {
    code: "IL",
    name: "Israel",
    currencyCode: "ILS",
    continentName: "Asia",
  },
  {
    code: "IT",
    name: "Italy",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "CI",
    name: "Ivory Coast",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "JM",
    name: "Jamaica",
    currencyCode: "JMD",
    continentName: "North America",
  },
  {
    code: "JP",
    name: "Japan",
    currencyCode: "JPY",
    continentName: "Asia",
  },
  {
    code: "JE",
    name: "Jersey",
    currencyCode: "GBP",
    continentName: "Europe",
  },
  {
    code: "JO",
    name: "Jordan",
    currencyCode: "JOD",
    continentName: "Asia",
  },
  {
    code: "KZ",
    name: "Kazakhstan",
    currencyCode: "KZT",
    continentName: "Asia",
  },
  {
    code: "KE",
    name: "Kenya",
    currencyCode: "KES",
    continentName: "Africa",
  },
  {
    code: "KI",
    name: "Kiribati",
    currencyCode: "AUD",
    continentName: "Oceania",
  },
  {
    code: "XK",
    name: "Kosovo",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "KW",
    name: "Kuwait",
    currencyCode: "KWD",
    continentName: "Asia",
  },
  {
    code: "KG",
    name: "Kyrgyzstan",
    currencyCode: "KGS",
    continentName: "Asia",
  },
  {
    code: "LA",
    name: "Laos",
    currencyCode: "LAK",
    continentName: "Asia",
  },
  {
    code: "LV",
    name: "Latvia",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "LB",
    name: "Lebanon",
    currencyCode: "LBP",
    continentName: "Asia",
  },
  {
    code: "LS",
    name: "Lesotho",
    currencyCode: "LSL",
    continentName: "Africa",
  },
  {
    code: "LR",
    name: "Liberia",
    currencyCode: "LRD",
    continentName: "Africa",
  },
  {
    code: "LY",
    name: "Libya",
    currencyCode: "LYD",
    continentName: "Africa",
  },
  {
    code: "LI",
    name: "Liechtenstein",
    currencyCode: "CHF",
    continentName: "Europe",
  },
  {
    code: "LT",
    name: "Lithuania",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "LU",
    name: "Luxembourg",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "MO",
    name: "Macao",
    currencyCode: "MOP",
    continentName: "Asia",
  },
  {
    code: "MK",
    name: "Macedonia",
    currencyCode: "MKD",
    continentName: "Europe",
  },
  {
    code: "MG",
    name: "Madagascar",
    currencyCode: "MGA",
    continentName: "Africa",
  },
  {
    code: "MW",
    name: "Malawi",
    currencyCode: "MWK",
    continentName: "Africa",
  },
  {
    code: "MY",
    name: "Malaysia",
    currencyCode: "MYR",
    continentName: "Asia",
  },
  {
    code: "MV",
    name: "Maldives",
    currencyCode: "MVR",
    continentName: "Asia",
  },
  {
    code: "ML",
    name: "Mali",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "MT",
    name: "Malta",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "MH",
    name: "Marshall Islands",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "MQ",
    name: "Martinique",
    currencyCode: "EUR",
    continentName: "North America",
  },
  {
    code: "MR",
    name: "Mauritania",
    currencyCode: "MRO",
    continentName: "Africa",
  },
  {
    code: "MU",
    name: "Mauritius",
    currencyCode: "MUR",
    continentName: "Africa",
  },
  {
    code: "YT",
    name: "Mayotte",
    currencyCode: "EUR",
    continentName: "Africa",
  },
  {
    code: "MX",
    name: "Mexico",
    currencyCode: "MXN",
    continentName: "North America",
  },
  {
    code: "FM",
    name: "Micronesia",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "MD",
    name: "Moldova",
    currencyCode: "MDL",
    continentName: "Europe",
  },
  {
    code: "MC",
    name: "Monaco",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "MN",
    name: "Mongolia",
    currencyCode: "MNT",
    continentName: "Asia",
  },
  {
    code: "ME",
    name: "Montenegro",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "MS",
    name: "Montserrat",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "MA",
    name: "Morocco",
    currencyCode: "MAD",
    continentName: "Africa",
  },
  {
    code: "MZ",
    name: "Mozambique",
    currencyCode: "MZN",
    continentName: "Africa",
  },
  {
    code: "MM",
    name: "Myanmar [Burma]",
    currencyCode: "MMK",
    continentName: "Asia",
  },
  {
    code: "NA",
    name: "Namibia",
    currencyCode: "NAD",
    continentName: "Africa",
  },
  {
    code: "NR",
    name: "Nauru",
    currencyCode: "AUD",
    continentName: "Oceania",
  },
  {
    code: "NP",
    name: "Nepal",
    currencyCode: "NPR",
    continentName: "Asia",
  },
  {
    code: "NL",
    name: "Netherlands",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "NC",
    name: "New Caledonia",
    currencyCode: "XPF",
    continentName: "Oceania",
  },
  {
    code: "NZ",
    name: "New Zealand",
    currencyCode: "NZD",
    continentName: "Oceania",
  },
  {
    code: "NI",
    name: "Nicaragua",
    currencyCode: "NIO",
    continentName: "North America",
  },
  {
    code: "NE",
    name: "Niger",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "NG",
    name: "Nigeria",
    currencyCode: "NGN",
    continentName: "Africa",
  },
  {
    code: "NU",
    name: "Niue",
    currencyCode: "NZD",
    continentName: "Oceania",
  },
  {
    code: "NF",
    name: "Norfolk Island",
    currencyCode: "AUD",
    continentName: "Oceania",
  },
  {
    code: "KP",
    name: "North Korea",
    currencyCode: "KPW",
    continentName: "Asia",
  },
  {
    code: "MP",
    name: "Northern Mariana Islands",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "NO",
    name: "Norway",
    currencyCode: "NOK",
    continentName: "Europe",
  },
  {
    code: "OM",
    name: "Oman",
    currencyCode: "OMR",
    continentName: "Asia",
  },
  {
    code: "PK",
    name: "Pakistan",
    currencyCode: "PKR",
    continentName: "Asia",
  },
  {
    code: "PW",
    name: "Palau",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "PS",
    name: "Palestine",
    currencyCode: "ILS",
    continentName: "Asia",
  },
  {
    code: "PA",
    name: "Panama",
    currencyCode: "PAB",
    continentName: "North America",
  },
  {
    code: "PG",
    name: "Papua New Guinea",
    currencyCode: "PGK",
    continentName: "Oceania",
  },
  {
    code: "PY",
    name: "Paraguay",
    currencyCode: "PYG",
    continentName: "South America",
  },
  {
    code: "PE",
    name: "Peru",
    currencyCode: "PEN",
    continentName: "South America",
  },
  {
    code: "PH",
    name: "Philippines",
    currencyCode: "PHP",
    continentName: "Asia",
  },
  {
    code: "PN",
    name: "Pitcairn Islands",
    currencyCode: "NZD",
    continentName: "Oceania",
  },
  {
    code: "PL",
    name: "Poland",
    currencyCode: "PLN",
    continentName: "Europe",
  },
  {
    code: "PT",
    name: "Portugal",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "PR",
    name: "Puerto Rico",
    currencyCode: "USD",
    continentName: "North America",
  },
  {
    code: "QA",
    name: "Qatar",
    currencyCode: "QAR",
    continentName: "Asia",
  },
  {
    code: "CG",
    name: "Republic of the Congo",
    currencyCode: "XAF",
    continentName: "Africa",
  },
  {
    code: "RO",
    name: "Romania",
    currencyCode: "RON",
    continentName: "Europe",
  },
  {
    code: "RU",
    name: "Russia",
    currencyCode: "RUB",
    continentName: "Europe",
  },
  {
    code: "RW",
    name: "Rwanda",
    currencyCode: "RWF",
    continentName: "Africa",
  },
  {
    code: "RE",
    name: "Réunion",
    currencyCode: "EUR",
    continentName: "Africa",
  },
  {
    code: "BL",
    name: "Saint Barthélemy",
    currencyCode: "EUR",
    continentName: "North America",
  },
  {
    code: "SH",
    name: "Saint Helena",
    currencyCode: "SHP",
    continentName: "Africa",
  },
  {
    code: "KN",
    name: "Saint Kitts and Nevis",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "LC",
    name: "Saint Lucia",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "MF",
    name: "Saint Martin",
    currencyCode: "EUR",
    continentName: "North America",
  },
  {
    code: "PM",
    name: "Saint Pierre and Miquelon",
    currencyCode: "EUR",
    continentName: "North America",
  },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    currencyCode: "XCD",
    continentName: "North America",
  },
  {
    code: "WS",
    name: "Samoa",
    currencyCode: "WST",
    continentName: "Oceania",
  },
  {
    code: "SM",
    name: "San Marino",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "SA",
    name: "Saudi Arabia",
    currencyCode: "SAR",
    continentName: "Asia",
  },
  {
    code: "SN",
    name: "Senegal",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "RS",
    name: "Serbia",
    currencyCode: "RSD",
    continentName: "Europe",
  },
  {
    code: "SC",
    name: "Seychelles",
    currencyCode: "SCR",
    continentName: "Africa",
  },
  {
    code: "SL",
    name: "Sierra Leone",
    currencyCode: "SLL",
    continentName: "Africa",
  },
  {
    code: "SG",
    name: "Singapore",
    currencyCode: "SGD",
    continentName: "Asia",
  },
  {
    code: "SX",
    name: "Sint Maarten",
    currencyCode: "ANG",
    continentName: "North America",
  },
  {
    code: "SK",
    name: "Slovakia",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "SI",
    name: "Slovenia",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "SB",
    name: "Solomon Islands",
    currencyCode: "SBD",
    continentName: "Oceania",
  },
  {
    code: "SO",
    name: "Somalia",
    currencyCode: "SOS",
    continentName: "Africa",
  },
  {
    code: "ZA",
    name: "South Africa",
    currencyCode: "ZAR",
    continentName: "Africa",
  },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    currencyCode: "GBP",
    continentName: "Antarctica",
  },
  {
    code: "KR",
    name: "South Korea",
    currencyCode: "KRW",
    continentName: "Asia",
  },
  {
    code: "SS",
    name: "South Sudan",
    currencyCode: "SSP",
    continentName: "Africa",
  },
  {
    code: "ES",
    name: "Spain",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "LK",
    name: "Sri Lanka",
    currencyCode: "LKR",
    continentName: "Asia",
  },
  {
    code: "SD",
    name: "Sudan",
    currencyCode: "SDG",
    continentName: "Africa",
  },
  {
    code: "SR",
    name: "Suriname",
    currencyCode: "SRD",
    continentName: "South America",
  },
  {
    code: "SJ",
    name: "Svalbard and Jan Mayen",
    currencyCode: "NOK",
    continentName: "Europe",
  },
  {
    code: "SZ",
    name: "Swaziland",
    currencyCode: "SZL",
    continentName: "Africa",
  },
  {
    code: "SE",
    name: "Sweden",
    currencyCode: "SEK",
    continentName: "Europe",
  },
  {
    code: "CH",
    name: "Switzerland",
    currencyCode: "CHF",
    continentName: "Europe",
  },
  {
    code: "SY",
    name: "Syria",
    currencyCode: "SYP",
    continentName: "Asia",
  },
  {
    code: "ST",
    name: "São Tomé and Príncipe",
    currencyCode: "STD",
    continentName: "Africa",
  },
  {
    code: "TW",
    name: "Taiwan",
    currencyCode: "TWD",
    continentName: "Asia",
  },
  {
    code: "TJ",
    name: "Tajikistan",
    currencyCode: "TJS",
    continentName: "Asia",
  },
  {
    code: "TZ",
    name: "Tanzania",
    currencyCode: "TZS",
    continentName: "Africa",
  },
  {
    code: "TH",
    name: "Thailand",
    currencyCode: "THB",
    continentName: "Asia",
  },
  {
    code: "TG",
    name: "Togo",
    currencyCode: "XOF",
    continentName: "Africa",
  },
  {
    code: "TK",
    name: "Tokelau",
    currencyCode: "NZD",
    continentName: "Oceania",
  },
  {
    code: "TO",
    name: "Tonga",
    currencyCode: "TOP",
    continentName: "Oceania",
  },
  {
    code: "TT",
    name: "Trinidad and Tobago",
    currencyCode: "TTD",
    continentName: "North America",
  },
  {
    code: "TN",
    name: "Tunisia",
    currencyCode: "TND",
    continentName: "Africa",
  },
  {
    code: "TR",
    name: "Turkey",
    currencyCode: "TRY",
    continentName: "Asia",
  },
  {
    code: "TM",
    name: "Turkmenistan",
    currencyCode: "TMT",
    continentName: "Asia",
  },
  {
    code: "TC",
    name: "Turks and Caicos Islands",
    currencyCode: "USD",
    continentName: "North America",
  },
  {
    code: "TV",
    name: "Tuvalu",
    currencyCode: "AUD",
    continentName: "Oceania",
  },
  {
    code: "UM",
    name: "U.S. Minor Outlying Islands",
    currencyCode: "USD",
    continentName: "Oceania",
  },
  {
    code: "VI",
    name: "U.S. Virgin Islands",
    currencyCode: "USD",
    continentName: "North America",
  },
  {
    code: "UG",
    name: "Uganda",
    currencyCode: "UGX",
    continentName: "Africa",
  },
  {
    code: "UA",
    name: "Ukraine",
    currencyCode: "UAH",
    continentName: "Europe",
  },
  {
    code: "AE",
    name: "United Arab Emirates",
    currencyCode: "AED",
    continentName: "Asia",
  },
  {
    code: "GB",
    name: "United Kingdom",
    currencyCode: "GBP",
    continentName: "Europe",
  },
  {
    code: "US",
    name: "United States",
    currencyCode: "USD",
    continentName: "North America",
  },
  {
    code: "UY",
    name: "Uruguay",
    currencyCode: "UYU",
    continentName: "South America",
  },
  {
    code: "UZ",
    name: "Uzbekistan",
    currencyCode: "UZS",
    continentName: "Asia",
  },
  {
    code: "VU",
    name: "Vanuatu",
    currencyCode: "VUV",
    continentName: "Oceania",
  },
  {
    code: "VA",
    name: "Vatican City",
    currencyCode: "EUR",
    continentName: "Europe",
  },
  {
    code: "VE",
    name: "Venezuela",
    currencyCode: "VEF",
    continentName: "South America",
  },
  {
    code: "VN",
    name: "Vietnam",
    currencyCode: "VND",
    continentName: "Asia",
  },
  {
    code: "WF",
    name: "Wallis and Futuna",
    currencyCode: "XPF",
    continentName: "Oceania",
  },
  {
    code: "EH",
    name: "Western Sahara",
    currencyCode: "MAD",
    continentName: "Africa",
  },
  {
    code: "YE",
    name: "Yemen",
    currencyCode: "YER",
    continentName: "Asia",
  },
  {
    code: "ZM",
    name: "Zambia",
    currencyCode: "ZMW",
    continentName: "Africa",
  },
  {
    code: "ZW",
    name: "Zimbabwe",
    currencyCode: "ZWL",
    continentName: "Africa",
  },
  {
    code: "AX",
    name: "Åland",
    currencyCode: "EUR",
    continentName: "Europe",
  },
];
