<button
  class="button-icon"
  [type]="type"
  [attr.data-testId]="dataTestId"
  [ngStyle]="style"
  [ngClass]="{
    'is-loading': isLoading,
    'severity-secondary': severity === 'secondary',
    'severity-danger': severity === 'danger',
    disabled: disabled,
    small: size === 'small',
    large: size === 'large',
    'x-large': size === 'x-large',
    'xx-large': size === 'xx-large',
    'with-border': withBorder,
    'dynamic-size': dynamicSize,
  }"
  [pTooltip]="tooltip || ''"
  [tooltipPosition]="tooltipPosition"
>
  <i class="pi" [ngClass]="icon"></i>
</button>
