import { Directive, ElementRef, Input, Output, inject } from "@angular/core";
import { fromEvent } from "rxjs";
import { tap, throttleTime } from "rxjs/operators";

@Directive({
  selector: "[dbDebounceClick]",
  standalone: true,
})
export class DebounceClickDirective {
  @Input() timePeriod = 1000;
  @Output() dbDebounceClick = fromEvent(
    (inject(ElementRef<HTMLElement>) as ElementRef<HTMLElement>).nativeElement,
    "click",
  ).pipe(
    tap((e) => {
      e.stopImmediatePropagation();
    }),
    throttleTime(1000),
  );
}
