export interface IRecord {
  id: string;
  userId: string;
  type: string;
  recordTypeId: string;
  companyId: string;
  expiresAt: number;
  createdAt: number;
  firstName: string;
  lastName: string;
  email: string;
  verifier: {
    id: string;
    name: string;
    email: string;
    profileImage: string;
  };
  profileImage: string;
}
