<p-progressBar
  [mode]="mode"
  [value]="value"
  [showValue]="showValue"
  [ngClass]="{
    rounded: borderStyle === 'rounded',
    squared: borderStyle === 'squared',
  }"
>
</p-progressBar>
