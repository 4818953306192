import { inject, Injectable, LOCALE_ID } from "@angular/core";
import moment from "moment";
import "moment-timezone";
import { TimeFormat } from "types";

@Injectable({ providedIn: "root" })
export class DateTimeFormatter {
  private readonly defaultFormat = "MMM D, y";
  private readonly intlLocale = inject(LOCALE_ID);

  format(
    value: string | number,
    formatString?: string | undefined,
    timeZone?: string | undefined,
  ): string {
    if (!value) {
      return "";
    }
    const val = moment(value).locale(this.intlLocale);
    return this._format(timeZone, val, formatString);
  }

  formatTimestamp(
    value: number,
    formatString?: string | undefined,
    timeZone?: string | undefined,
  ): string {
    if (!value) {
      return "";
    }
    const val = moment(value).locale(this.intlLocale);
    return this._format(timeZone, val, formatString);
  }

  /**
   * @deprecated - use timeFormatToMomentTimeFormat instead
   * */
  getTimeFormatString(timeFormat: TimeFormat): string {
    const is12Hour = timeFormat === TimeFormat.TWELVE_HOUR;
    return `${is12Hour ? "hh" : "HH"}:mm${is12Hour ? " A" : ""}`;
  }

  formatDateForDailyFeed(
    date: string,
    index: number | undefined,
    dateOnly?: boolean,
  ): string {
    const today = moment();
    const todayWeekday = today.day();
    const currentWeekday = moment(date).day();
    const formattedDate = this.format(date, "ddd D MMM");

    if (dateOnly === true) {
      return formattedDate;
    }

    const dayForwardOffset =
      index ?? moment(date).startOf("day").diff(today.startOf("day"), "days");

    if (dayForwardOffset === 0) {
      if (todayWeekday === currentWeekday) {
        return `${$localize`:@@booking-module|daily-view|today:Today`}, ${formattedDate}`;
      }
      const todayIsSunday = todayWeekday === 0;
      if (todayIsSunday) {
        return `${$localize`:@@booking-module|daily-view|tomorrow:Tomorrow`}, ${formattedDate}`;
      }
    }
    if (dayForwardOffset === 1 && todayWeekday + 1 === currentWeekday) {
      return `${$localize`:@@booking-module|daily-view|tomorrow:Tomorrow`}, ${formattedDate}`;
    }

    return formattedDate;
  }

  private _format(
    timeZone: string | undefined,
    val: moment.Moment,
    formatString: string | undefined,
  ) {
    if (timeZone) {
      val.tz(timeZone);
    } else {
      val.local();
    }
    return val.format(formatString || this.defaultFormat);
  }
}
