import { Component, OnDestroy } from "@angular/core";
import { RouterModel } from "router-module";
import {
  Subject,
  delay,
  filter,
  interval,
  map,
  race,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs";
import { AuthModel } from "../+store/model";
import { Lottie } from "lottie-module";
import { GlobalLoaderModel } from "../../../../loader-module/src/public-api";
import { user } from "@angular/fire/auth";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnDestroy {
  Lottie = Lottie;
  destroy$$ = new Subject<void>();

  showLottie$ = interval(0).pipe(
    take(1),
    map(() => true),
    startWith(false),
  );
  emailVerified = false;

  constructor(
    private authModel: AuthModel,
    private routerModel: RouterModel,
    private globalLoaderModel: GlobalLoaderModel,
  ) {
    interval(5000)
      .pipe(
        switchMap(() => this.authModel.firebaseUser$.pipe(take(1))),
        tap((user) => {
          user?.reload();
        }),
        filter((user) => !!user?.emailVerified),
        tap((user) => {
          user?.getIdToken(true);
          this.emailVerified = true;
        }),
        delay(1000),
        take(1),
      )
      .subscribe(() => {});
  }

  resendEmail(): void {
    this.globalLoaderModel.actions.dispatch.showLoader({ visibility: true });
    this.authModel.actions.dispatch.resendVerificationEmail();
    race(
      this.authModel.actions.listen.resendVerificationEmailSuccess$,
      this.authModel.actions.listen.resendVerificationEmailFailure$,
    )
      .pipe(take(1))
      .subscribe(() => {
        this.globalLoaderModel.actions.dispatch.showLoader({
          visibility: false,
        });
      });
  }

  goToDeskbirdWebApp(): void {
    this.routerModel.actions.dispatch.navigate({
      commands: ["/default"],
      extras: { queryParamsHandling: "preserve" },
    });
  }

  goBackToLogin(): void {
    this.authModel.actions.dispatch.logout({});
    this.routerModel.actions.dispatch.navigate({
      commands: ["/login"],
      extras: { queryParamsHandling: "preserve" },
    });
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
  }
}
