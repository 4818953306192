<div class="d-flex justify-content-between align-items-center gap-16">
  @for (item of items; track item.id) {
    <div
      class="CardSelector-item"
      [ngClass]="{
        'disabled': isDisabled || item.disabled,
        'selected': value?.id === item.id
      }"
      (click)="onSelectedItem(item)"
    >
      <div
        class="CardSelector-item-image"
        [ngStyle]="{
          'background-image': 'url(' + item.image + ')',
        }"
      ></div>
      <div class="d-flex-column gap-2">
        <div class="CardSelector-item-title small-text-bold">
          {{ item.title }}
        </div>
        <div class="CardSelector-item-description very-small-text">
          {{ item.description }}
        </div>
      </div>
    </div>
  }
</div>
