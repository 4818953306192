export function encodeString(str: string): string {
  return btoa(str);
}

export function decodeString(str: string): string {
  try {
    const decoded = atob(str);
    return decoded;
  } catch (error) {
    return "";
  }
}
