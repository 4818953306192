import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { ButtonComponent } from "db-ui";
import { GlobalViewComponent } from "../global-view";

@Component({
  selector: "db-error",
  templateUrl: "./error.component.html",
  imports: [ButtonComponent, GlobalViewComponent],
  standalone: true,
})
export class ErrorComponent {
  @Input() iconPath!: string;

  // NOTE: prevent default behavior for html title attribute
  @HostBinding("attr.title") get getTitle(): null {
    return null;
  }

  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() text!: string;
  @Input() buttonText!: string;

  @Output() buttonClick = new EventEmitter<any>();

  onButtonClick() {
    this.buttonClick.emit();
  }
}
