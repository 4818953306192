<db-card>
  <div class="wrapper">
    <h1 i18n="@@auth-module|not-active-user|message-welcome">
      Welcome to {{ (emailCheckResult$ | async)?.companyName }}
    </h1>
    <div
      class="company-logo"
      [ngStyle]="{
        'background-image':
          'url(' + (emailCheckResult$ | async)?.companyLogoUrl + ')',
        'background-position': 'center',
      }"
    ></div>
    <h3 i18n="@@auth-module|not-active-user|message-contact-admin">
      Please contact your administrator
    </h3>
    <p i18n="@@auth-module|not-active-user|message-contact-admin-details">
      You need to be invited to get access to this workspace. Your workspace
      administrator will be able to give you more information.
    </p>
    <div class="buttons-container">
      <db-button
        (click)="visitDeskbirdWebsiteClickHandler()"
        i18n="@@auth-module|not-active-user|visit-website"
      >
        Visit deskbird website
      </db-button>
      <a
        [routerLink]="['/login']"
        queryParamsHandling="preserve"
        class="big-link"
        i18n="@@auth-module|go-back"
        >Go back
      </a>
    </div>
  </div>
</db-card>
