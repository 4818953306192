import { IUserInfo, OfficeEventAttendanceResponseType } from "types";

export type OfficeEventAttendanceResponse = IUserInfo & {
  response?: OfficeEventAttendanceResponseType;
};

export interface IOfficeEventAttendanceStats {
  accepted: number;
  declined: number;
  tentative: number;
  total: number;
}

export interface IOfficeEventAttendance {
  stats: IOfficeEventAttendanceStats;
  attendees: Array<OfficeEventAttendanceResponse>;
  me?: {
    response: OfficeEventAttendanceResponseType;
    isInvited: boolean;
  };
}
