import { VisibilityRestrictionType } from "../enums";
import { ProfileType } from "types";

export interface ICompanyPrivacySettings {
  allowsPrivateProfilesToggle: boolean;
  defaultNewUserProfileType: ProfileType;
  allowsFollowRequests: boolean;
  userVisibilityRestriction: VisibilityRestrictionType;
  userGroupVisibilityRestriction: VisibilityRestrictionType;
}
