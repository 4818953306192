import { areaTypeSuffixMap } from "../constants";
import { DeskAreaType } from "types";

export function getMappedAreaSuffixValue(
  deskAreaType: DeskAreaType | undefined,
) {
  if (!deskAreaType) {
    return null;
  }

  return areaTypeSuffixMap[deskAreaType];
}
