<p-panel
  class="clear-panel"
  [toggleable]="true"
  [collapsed]="true"
  (collapsedChange)="collapsedChange.emit($event)"
>
  @for (template of templates; track template) {
    <ng-template let-item [pTemplate]="template.name" [type]="template.type">
      <ng-container
        *ngTemplateOutlet="template.template; context: { $implicit: item }"
      ></ng-container>
    </ng-template>
  }

  <ng-content></ng-content>
</p-panel>
