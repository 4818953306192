export enum GoogleTagManagerDimensions {
  ALLOWS_RESOURCE_BOOKING = "allows_resource_booking", // = book_module_enabled
  ALLOWS_OFFICE_PLANNING = "allows_office_planning", // = scheduling_module_enabled
  MEETING_ROOM_SYNC_ENABLED = "meeting_room_sync_enabled",

  COMPANY_NAME = "company_name",
  COMPANY_UUID = "company_uuid",
  COMPANY_USER_VISIBILITY_RESTRICTION = "cmp_usr_visibility",
  COMPANY_USE_PRIVATE_PROFILE = "prv_prf_toggle",
  COMPANY_HAS_HRIS_ACTIVATED = "hris_activated",

  USER_UUID = "user_uuid",
  USER_ID = "user_id",
  USER_MAIN_OFFICE_ID = "main_office_id",
  USER_OFFICE_ID = "office_id",
  USER_ROLE = "role",
  USER_AVATAR_ENABLED = "avatar_enabled",
  USER_LANGUAGE = "language",
}
