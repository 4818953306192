import { Directive, Input } from "@angular/core";
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from "@angular/forms";
import { delay, Observable, of, switchMap } from "rxjs";

@Directive({
  selector: "[dbAsyncValueValidator]",
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      multi: true,
      useExisting: AsyncValueValidatorDirective,
    },
  ],
  standalone: true,
})
export class AsyncValueValidatorDirective implements AsyncValidator {
  @Input() debounceTime = 500;
  @Input() checkValue!: (value: any) => Observable<ValidationErrors | null>;
  @Input() ignoreEmptyString = true;

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    return of(control.value).pipe(
      delay(this.debounceTime),
      switchMap((value) => {
        if (this.ignoreEmptyString === false || value !== "") {
          return this.checkValue(value);
        }
        return [null];
      }),
    );
  }
}
