import { Injectable, inject } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  UrlTree,
} from "@angular/router";
import { FeatureAccessService } from "../services/feature-access.service";
import { FeatureAccessType } from "types";
import { filter, map, take, switchMap } from "rxjs/operators";
import { RouterModel } from "router-module";
import { Observable, of } from "rxjs";

interface FeatureGuardData {
  featureAccess: FeatureAccessType | FeatureAccessType[];
  condition?: "some" | "every";
}

@Injectable({
  providedIn: "root",
})
export class FeatureAccessGuard implements CanActivate {
  private routerModel = inject(RouterModel);
  private router = inject(Router);
  private featureAccessService = inject(FeatureAccessService);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const { featureAccess, condition = "some" } =
      route.data as FeatureGuardData;

    if (!featureAccess) {
      return this.getDefaultRedirectUrl();
    }

    return this.featureAccessService
      .hasFeatureAccess(featureAccess, condition)
      .pipe(
        filter((value) => value !== null),
        take(1),
        switchMap((hasAccess) =>
          hasAccess ? of(true) : this.getDefaultRedirectUrl(),
        ),
      );
  }

  private getDefaultRedirectUrl(): Observable<UrlTree> {
    return this.routerModel.isAdminAppEnv$.pipe(
      take(1),
      map((isAdminAppEnv) =>
        this.router.parseUrl(
          `${
            isAdminAppEnv
              ? this.routerModel.adminPartPrefix
              : this.routerModel.clientPartPrefix
          }default`,
        ),
      ),
    );
  }
}
