import { IBookingFloorPlanInfo } from "./booking-floor-plan-info";

export interface IGuestUser {
  firstName: string;
  lastName: string;
  email: string;
}

export interface IGuestUserInfo extends IGuestUser {
  /** Additional information shown under the user name on the user-info.component */
  extra?: string;
}

export interface IGuestUserInOfficeDto extends IGuestUserInfo {
  isGuest: true;
  officeOptionColor: string;
  /** Booking information used for floor plan navigation. Only available in daily office view */
  bookingInformation?: IBookingFloorPlanInfo;
}
