import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import {
  APP_URL,
  samlIsNewUserParameterName,
  samlMobileTokenParameterName,
  samlTokenParameterName,
  WINDOW,
  iosSamlSuccessPath,
  samlSuccessRoute,
} from "common-module";
import { GlobalLoaderModel } from "loader-module";
import { NotificationModel } from "notification-module";
import { RouterModel } from "router-module";
import {
  Subscription,
  Subject,
  interval,
  race,
  filter,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  delay,
  tap,
} from "rxjs";
import { combineLatestForFrame } from "shared";
import { AuthModel } from "../+store/model";
import { isUserFirstAndLastNameInputRequired } from "shared-utils";

const COUNTDOWN = 6;

@Component({
  selector: "db-saml",
  templateUrl: "./saml.component.html",
  styleUrls: ["./saml.component.scss"],
})
export class SamlComponent implements OnDestroy, OnInit {
  router = inject(Router);
  authModel = inject(AuthModel);
  routerModel = inject(RouterModel);
  globalLoader = inject(GlobalLoaderModel);
  notificationModel = inject(NotificationModel);
  window = inject<Window>(WINDOW);
  appURL = inject<string>(APP_URL);

  isLoggedIn$ = this.authModel.isLoggedIn$;

  subscription: Subscription = new Subscription();
  errorUpdatingUser = false;

  queryParamsNavigationCleanUp = {
    [samlIsNewUserParameterName]: undefined,
    [samlMobileTokenParameterName]: undefined,
    [samlTokenParameterName]: undefined,
  };

  userNames$ = new Subject<{ firstName: string; lastName: string }>();
  isUserInputRequired$ = this.authModel.user$.pipe(
    map(isUserFirstAndLastNameInputRequired),
  );
  url$ = this.routerModel.selectors.url$;

  count = COUNTDOWN;

  private generateProvider(providerName: string): string {
    return providerName.includes("saml.")
      ? providerName
      : `saml.${providerName}`;
  }

  ngOnInit(): void {
    const samlLoginPlatform = (localStorage.getItem("saml") as string) || null;
    const isSamlRedirectBack = !!samlLoginPlatform;
    // handle saml authentication initialization (MANAGEMENT OF localStorage saml value is here - keep it in one place!)
    // URL navigated by mobile apps is http://<env>.deskbird.com/saml?providerId=saml.<provider>&platform=ios
    this.subscription.add(
      combineLatestForFrame([
        this.routerModel.selectors.queryParams$.pipe(distinctUntilChanged()),
        this.routerModel.allRouteParams$.pipe(distinctUntilChanged()),
        this.isLoggedIn$.pipe(distinctUntilChanged()),
      ])
        .pipe(
          filter(([queryParams]) => {
            const isMobileEndScreen = !!(
              (queryParams?.[samlTokenParameterName] as string) || null
            );
            // clean up the value if we are coming back from redirect
            return isSamlRedirectBack === false && isMobileEndScreen === false;
          }),
          take(1),
          switchMap(([queryParams, allRouteParams, isLoggedIn]) => {
            let provider =
              (queryParams["providerId"] as string) ||
              (queryParams["providerName"] as string) ||
              (allRouteParams["provider"] as string);
            if (!provider)
              return [{ provider: null, platform: null, isLoggedIn }];
            provider = this.generateProvider(provider);
            const platform = queryParams["platform"] as string;
            return [{ provider, platform, isLoggedIn }];
          }),
        )
        .subscribe(({ provider, platform, isLoggedIn }): void => {
          if (provider === null) {
            return void this.routerModel.actions.dispatch.navigate({
              commands: [isLoggedIn ? "/default" : "/login"],
              extras: {
                queryParamsHandling: "merge",
                queryParams: this.queryParamsNavigationCleanUp,
              },
            });
          }
          // we use local storage to determine if we are back from saml auth redirect or not
          localStorage.setItem("saml", platform || "default");
          this.authModel.actions.dispatch.samlSignIn({ provider });
        }),
    );

    // handle saml authentication return
    // After successful SSO authentication, firebase returns token and auth effects redirect here again via
    // http://<env>.deskbird.com/saml?token=<token>&userId=<firebaseId>
    // here we need to check if the user need to input any data and if yes we need to show him the screen for it
    // if we are all good and we have all the information we need to fix the url for the mobile devices
    // the url that they expect is
    // http://<env>.deskbird.com/saml?$samlToken=${samlToken}&isNewUser=false
    this.subscription.add(
      combineLatestForFrame([
        this.authModel.user$.pipe(distinctUntilChanged()),
        this.routerModel.selectors.queryParams$.pipe(distinctUntilChanged()),
        this.isLoggedIn$.pipe(distinctUntilChanged()),
      ])
        .pipe(
          delay(0), // delay this stream with one tick so the previous one can complete
          filter(([user]) => {
            return isUserFirstAndLastNameInputRequired(user) === false;
          }),
          tap(([user]) => {
            if (
              user?.providerIds &&
              user?.providerIds.length &&
              !user.providerIds.filter((p) => p.includes("saml.")).length
            ) {
              this.authModel.actions.dispatch.logout({
                skipNavigationToLoginScreen: false,
              });
              return;
            }
          }),
          map(([user, queryParams, isLoggedIn]) => ({
            user,
            token: (queryParams[samlTokenParameterName] as string) || null,
            isLoggedIn,
          })),
          filter(({ user, isLoggedIn, token }) => {
            return (isLoggedIn && !!token) || !isLoggedIn;
          }),
        )
        .subscribe(({ user, token, isLoggedIn }): void => {
          if (!isLoggedIn) {
            this.routerModel.actions.dispatch.navigate({
              commands: [
                samlLoginPlatform === "default" ? "/login" : "/default",
              ], // do not display the web login page for mobile
              extras: {
                queryParamsHandling: "merge",
                queryParams: this.queryParamsNavigationCleanUp,
              },
            });
            return;
          }

          const isIOS = samlLoginPlatform === "ios";
          const path = isIOS
            ? iosSamlSuccessPath
            : this.appURL.concat(samlSuccessRoute);
          const pathParams = `?${samlMobileTokenParameterName}=${token}&${samlIsNewUserParameterName}=false`;

          this.window.location.href = `${path}${pathParams}`;
          localStorage.removeItem("saml");
        }),
    );

    // Whenever we are on the mobile end screen we want to display a countdown clock just in
    // case if someone somehow ended on this screen via the browser so they can get redirected back
    // to the application after the countdown finishes, on the mobile apps this will not happen because
    // they will close the webview after we end up on http://<env>.deskbird.com/saml?$samlToken=${samlToken}&isNewUser=false
    this.subscription.add(
      this.routerModel.selectors.queryParams$
        .pipe(
          map(
            (queryParams) =>
              (queryParams?.[samlTokenParameterName] as string) ||
              (queryParams?.[samlMobileTokenParameterName] as string) ||
              null,
          ),
          filter((val) => !!val),
          take(1),
          switchMap(() =>
            this.isUserInputRequired$.pipe(
              filter((val) => !val),
              take(1),
            ),
          ),
          switchMap(() =>
            interval(1000).pipe(
              take(6),
              map(() => this.count - 1),
            ),
          ),
        )
        .subscribe({
          next: (count) => {
            this.count = count;
          },
          complete: () => {
            localStorage.removeItem("saml");
            this.routerModel.actions.dispatch.navigate({
              commands: ["/default"],
              extras: {
                queryParamsHandling: "merge",
                queryParams: this.queryParamsNavigationCleanUp,
              },
            });
          },
        }),
    );
  }

  setNamesHandler({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) {
    if (!firstName || !lastName) return;

    this.globalLoader.actions.dispatch.showLoader({ visibility: true });
    this.authModel.actions.dispatch.patchUser({
      updates: { firstName, lastName },
    });
    this.errorUpdatingUser = false;

    race(
      this.authModel.actions.listen.updateUserSuccess$.pipe(
        map(() => true),
        take(1),
      ),
      this.authModel.actions.listen.updateUserFailure$.pipe(
        map(() => false),
        take(1),
      ),
    ).subscribe((success) => {
      this.globalLoader.actions.dispatch.showLoader({ visibility: false });
      // we just return here because the stream above will redirect us
      // when the actual user is updated in the store
      if (success) return;
      this.errorUpdatingUser = true;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
