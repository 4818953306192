import { Injectable } from "@angular/core";
import { createEffect } from "@ngrx/effects";
import { catchError, map, merge, mergeMap, switchMap, takeUntil } from "rxjs";

import { IOfficeEvent } from "common-module";
import { NotificationModel, NotificationType } from "notification-module";

import { OfficeEventsModel } from "./model";
import { OfficeEventService } from "../../services/office-event.service";
import { IApiErrorResponse, ApiErrorResponse } from "types";
import { customErrorMessage } from "./effects-custom-error-message";

@Injectable({ providedIn: "root" })
export class OfficeEventsEffects {
  loadOfficeEvents = createEffect(() =>
    this.officeEventsModel.actions.listen.loadOfficeEvents$.pipe(
      switchMap((payload) =>
        this.officeEventsService.loadOfficeEvents(payload).pipe(
          takeUntil(
            this.officeEventsModel.actions.listen.loadOfficeEventsCancel$,
          ),
          map((response) => {
            const errorResponse = response as IApiErrorResponse;
            if (errorResponse.errorCode) {
              throw new ApiErrorResponse(
                errorResponse.statusCode,
                errorResponse.errorCode,
                errorResponse.message,
              );
            }

            const events = (response as Array<IOfficeEvent>) || [];
            return this.officeEventsModel.actions.create.loadOfficeEventsSuccess(
              {
                events,
                gridDatePeriodType: payload.gridDatePeriodType,
              },
            );
          }),
          catchError((error) => {
            return [
              this.officeEventsModel.actions.create.loadOfficeEventsFailure({
                error,
              }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-event-module|error-get-office-events:Error loading office event(s)`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  loadInvitableUserGroups$ = createEffect(() =>
    this.officeEventsModel.actions.listen.loadInvitableUserGroups$.pipe(
      switchMap(({ companyId }) => {
        return this.officeEventsService.loadUserGroups(companyId).pipe(
          takeUntil(
            this.officeEventsModel.actions.listen
              .loadInvitableUserGroupsCancel$,
          ),
          mergeMap((response) => [
            this.officeEventsModel.actions.create.loadInvitableUserGroupsSuccess(
              { userGroups: response.userGroups },
            ),
          ]),
        );
      }),
      catchError((error) => {
        return [
          this.officeEventsModel.actions.create.loadInvitableUserGroupsFailure({
            error,
          }),
          this.notificationModel.actions.create.showNotification({
            data: $localize`:@@office-events-module|user-groups-list|error:Error while getting the list of user groups`,
            notificationType: NotificationType.ERROR,
          }),
        ];
      }),
    ),
  );

  loadInvitableUsers$ = createEffect(() =>
    this.officeEventsModel.actions.listen.loadInvitableUsers$.pipe(
      switchMap(({ companyId }) => {
        return this.officeEventsService.loadUsers(companyId).pipe(
          takeUntil(
            this.officeEventsModel.actions.listen.loadInvitableUsersCancel$,
          ),
          mergeMap((users) => [
            this.officeEventsModel.actions.create.loadInvitableUsersSuccess({
              users: users,
              totalUsers: users.length,
            }),
          ]),
        );
      }),
      catchError((error) => {
        return [
          this.officeEventsModel.actions.create.loadInvitableUsersFailure({
            error,
          }),
          this.notificationModel.actions.create.showNotification({
            data: $localize`:@@office-events-module|users-list|error:Error while getting the list of users`,
            notificationType: NotificationType.ERROR,
          }),
        ];
      }),
    ),
  );

  selectOfficeEvent = createEffect(() =>
    merge(
      this.officeEventsModel.actions.listen.selectOfficeEvent$.pipe(
        map(({ officeEventId }) => officeEventId),
      ),
      this.officeEventsModel.actions.listen.attendOfficeEventSuccess$.pipe(
        map(({ event }) => event.id),
      ), // event re-load is required because attendance stats is not returned as part of attendOfficeEvent response
    ).pipe(
      switchMap((eventId) =>
        this.officeEventsService.getOneOfficeEvent(eventId).pipe(
          takeUntil(
            this.officeEventsModel.actions.listen.selectOfficeEventCancel$,
          ),
          map((response) => {
            const errorResponse = response as ApiErrorResponse;
            if (errorResponse.errorCode) {
              throw new ApiErrorResponse(
                errorResponse.statusCode,
                errorResponse.errorCode,
                errorResponse.message,
              );
            }

            const event = response as IOfficeEvent;
            return this.officeEventsModel.actions.create.selectOfficeEventSuccess(
              { event },
            );
          }),
          catchError((error) => {
            let responseError: ApiErrorResponse;
            if ("status" in error) {
              responseError = error.error as ApiErrorResponse;
            } else {
              responseError = error;
            }
            if (responseError.statusCode === 404) {
              return [
                this.officeEventsModel.actions.create.selectOfficeEventFailure({
                  error: responseError,
                }),
              ];
            }
            return [
              this.officeEventsModel.actions.create.selectOfficeEventFailure({
                error: responseError,
              }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-event-module|error-get-office-events:Error loading office event(s)`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  saveOfficeEvent = createEffect(() =>
    this.officeEventsModel.actions.listen.saveOfficeEvent$.pipe(
      switchMap((payload) =>
        (!payload.event.id
          ? this.officeEventsService.createOfficeEvent(payload.event)
          : this.officeEventsService.updateOfficeEvent(payload.event)
        ).pipe(
          takeUntil(
            this.officeEventsModel.actions.listen.saveOfficeEventCancel$,
          ),
          switchMap((response) => {
            const event: IOfficeEvent = { ...payload.event, ...response };
            const message = payload.event.id
              ? $localize`:@@office-event-module|success-office-event-updated:Event updated successfully`
              : $localize`:@@office-event-module|success-office-event-created:Event created successfully`;
            return [
              this.officeEventsModel.actions.create.saveOfficeEventSuccess({
                event,
              }),
              this.notificationModel.actions.create.showNotification({
                data: message,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
          catchError((error) => {
            return [
              this.officeEventsModel.actions.create.saveOfficeEventFailure({
                error,
              }),
              this.notificationModel.actions.create.showNotification({
                data: customErrorMessage(
                  error?.error?.errorCode,
                  $localize`:@@office-event-module|error-save-office-events:Error saving office event`,
                ),
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  deleteOfficeEvent = createEffect(() =>
    this.officeEventsModel.actions.listen.deleteOfficeEvent$.pipe(
      switchMap((payload) =>
        this.officeEventsService.deleteOfficeEvent(payload.event).pipe(
          takeUntil(
            this.officeEventsModel.actions.listen.deleteOfficeEventCancel$,
          ),

          switchMap(() => {
            const event: IOfficeEvent = payload.event;
            return [
              this.officeEventsModel.actions.create.deleteOfficeEventSuccess({
                event,
              }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-event-module|success-office-event-deleted:Event deleted successfully`,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
          catchError((error) => {
            return [
              this.officeEventsModel.actions.create.deleteOfficeEventFailure({
                error,
              }),
              this.notificationModel.actions.create.showNotification({
                data: customErrorMessage(
                  error?.error?.errorCode,
                  $localize`:@@office-event-module|error-delete-office-event:Error deleting office event`,
                ),
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  attendOfficeEvent = createEffect(() =>
    this.officeEventsModel.actions.listen.attendOfficeEvent$.pipe(
      switchMap((payload) =>
        this.officeEventsService.attendOfficeEvent(payload).pipe(
          takeUntil(
            this.officeEventsModel.actions.listen.attendOfficeEventCancel$,
          ),

          switchMap(() => {
            const event: IOfficeEvent = payload.event;
            return [
              this.officeEventsModel.actions.create.attendOfficeEventSuccess({
                event: {
                  ...event,
                  attendance: {
                    me: {
                      response: payload.response,
                      isInvited: true,
                    },
                    stats: event.attendance!.stats,
                    attendees: event.attendance!.attendees,
                  },
                },
              }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-event-module|success-office-event-attended:Event response saved successfully`,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
          catchError((error) => {
            return [
              this.officeEventsModel.actions.create.attendOfficeEventFailure({
                error,
              }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-event-module|error-attending-office-event:Error saving event response`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  constructor(
    private officeEventsService: OfficeEventService,
    private officeEventsModel: OfficeEventsModel,
    private notificationModel: NotificationModel,
  ) {}
}
