import { IOfficeEvent } from "./office-event";
import { IOffice } from "../office";
import { IUserGroup } from "common-module";

export type IOfficeEventWithInvitedOfficesAndGroupsProps = IOfficeEvent & {
  invitedOffices: Array<Partial<Pick<IOffice, "address" | "name">>>;
  invitedUserGroups: Array<
    Partial<Pick<IUserGroup, "id" | "name" | "userIds">>
  >;
};
