import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "db-statistic-info-box",
  templateUrl: "./statistic-info-box.component.html",
  styleUrls: ["./statistic-info-box.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class StatisticInfoBoxComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) id!: string;
  @Input({ required: true }) iconPath!: string;
  @Input() size: "small" | "medium" = "small";
  @Input() isClickable: boolean = false;
  @Input() isSelected: boolean = false;

  @Output() infoBoxClick = new EventEmitter<string>();
}
