import { Provider } from "@angular/core";
import { CONFIRMATION_DIALOG_MAP } from "./constants";
import { IConfirmationDialogComponentMap } from "./interfaces";

export function confirmationDialogMapProvider(
  map: IConfirmationDialogComponentMap,
): Provider {
  return {
    provide: CONFIRMATION_DIALOG_MAP,
    useValue: map,
    multi: true,
  };
}
