import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "db-tag-list",
  standalone: true,
  imports: [],
  templateUrl: "./tag-list.component.html",
  styleUrls: ["./tag-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagListComponent {
  @Input({ required: true }) items: string[] = [];
}
