import { Route, RouterModule, Routes } from "@angular/router";
import { CheckInComponent } from "./check-in/check-in.component";
import { HasEmailSetGuard } from "./guards/has-email.activate";
import { NotActiveCompanyComponent } from "./not-active-company/not-active-company.component";
import { NotActiveUserComponent } from "./not-active-user/not-active-user.component";
import { SignInEmailComponent } from "./sign-in-email/sign-in-email.component";
import { SignInWizardComponent } from "./sign-in-wizard/sign-in-wizard.component";
import { SignInComponent } from "./sign-in/sign-in.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from "@angular/router";
import { NoAccessComponent } from "./no-access/no-access.component";
import { NoAccessActivate } from "./guards/no-access.activate";
import { AuthenticateActivate } from "./guards/authenticate.activate";
import { LoginDeactivate } from "./guards/login.deactivate";
import { SamlComponent } from "./saml/saml.component";
import { redirectRouteFactory } from "common-module";
import { SlackComponent } from "./slack/slack.component";

@Injectable()
export class ComponentReuseStrategy extends RouteReuseStrategy {
  public retrieve(): DetachedRouteHandle | null {
    return null;
  }
  public store(): void {}
  public shouldAttach(): boolean {
    return false;
  }
  public shouldDetach(): boolean {
    return false;
  }
  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot,
  ): boolean {
    return (
      future.routeConfig?.component === current.routeConfig?.component &&
      !future.routeConfig?.data?.["redirectTo"]
    );
  }
}

const loginRoute: Route = {
  path: "login",
  canDeactivate: [LoginDeactivate],
  children: [
    redirectRouteFactory("", "check-in", {
      navigationExtras: { queryParamsHandling: "preserve" },
      canActivate: [AuthenticateActivate],
      publicOnly: true,
    }),
    {
      path: "check-in",
      component: CheckInComponent,
      canActivate: [AuthenticateActivate],
      data: {
        publicOnly: true,
      },
    },
    {
      path: "not-active-user",
      component: NotActiveUserComponent,
      canActivate: [AuthenticateActivate, HasEmailSetGuard],
      data: {
        publicOnly: true,
      },
    },
    {
      path: "not-active-company",
      component: NotActiveCompanyComponent,
      canActivate: [AuthenticateActivate, HasEmailSetGuard],
      data: {
        publicOnly: true,
      },
    },
    {
      path: "sign-in",
      component: SignInComponent,
      canActivate: [AuthenticateActivate, HasEmailSetGuard],
      data: {
        publicOnly: true,
      },
    },
    {
      path: "sign-in-email",
      component: SignInEmailComponent,
      canActivate: [AuthenticateActivate, HasEmailSetGuard],
      data: {
        publicOnly: true,
      },
    },
    {
      path: "sign-up-wizard",
      component: SignInWizardComponent,
      canActivate: [HasEmailSetGuard],
    },
    {
      path: "verify-email",
      component: VerifyEmailComponent,
      canActivate: [HasEmailSetGuard],
      data: {
        publicOnly: true,
      },
    },
  ],
};

const logoutRoute: Route = {
  path: "logout",
  outlet: "logout",
  canActivate: [AuthenticateActivate],
  children: [],
};

const noAccessRoute: Route = {
  path: "no-access",
  canActivate: [NoAccessActivate],
  component: NoAccessComponent,
};

const routes: Routes = [
  loginRoute,
  logoutRoute,
  noAccessRoute,
  { path: "slack", component: SlackComponent },
  { path: "saml", pathMatch: "full", component: SamlComponent },
  { path: "saml/:provider", pathMatch: "full", component: SamlComponent },
];

export const AuthRoutingModule = RouterModule.forChild(routes);
