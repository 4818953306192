import { NgModule, Optional, SkipSelf } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { GlobalEffects } from "./+store/global/effects";
import { globalReducer } from "./+store/global/reducer";
import {
  GLOBAL_STORE_NAME,
  OFFICE_EQUIPMENT_STORE_NAME,
  OFFICE_EVENTS_STORE_NAME,
  OFFICE_ROLES_STORE_NAME,
  QR_GENERATOR_STORE_NAME,
} from "./constants";
import { CompanySwitcherComponent } from "./company-switcher/company-switcher.component";
import { CommonModule } from "@angular/common";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ResolveModule } from "resolve-module";
import { OfflineComponent } from "./offline/offline.component";
import { ErrorComponent } from "./error";
import { ErrorNotFoundComponent } from "./error-not-found/error-not-found.component";
import { ErrorUnexpectedComponent } from "./error-unexpected/error-unexpected.component";
import { NoticeMessageComponent } from "./notice-message/notice-message.component";
import { officeEventsReducer } from "./+store/office-events/reducer";
import { OfficeEventsEffects } from "./+store/office-events/effects";
import { QrGenerationEffects } from "./+store/qr-generation/effects";
import { qrGeneratorReducer } from "./+store/qr-generation/reducer";
import { OfficeRolesEffects } from "./+store/office-roles/effects";
import { officeRolesReducer } from "./+store/office-roles/reducer";
import { officeEquipmentReducer } from "./+store/office-equipment/reducer";
import { OfficeEquipmentEffects } from "./+store/office-equipment/effects";
import { DropdownWithSearchComponent } from "db-ui";

@NgModule({
  declarations: [
    CompanySwitcherComponent,
    OfflineComponent,
    ErrorNotFoundComponent,
    ErrorUnexpectedComponent,
    NoticeMessageComponent,
  ],
  imports: [
    CommonModule,
    ResolveModule,
    NgxSkeletonLoaderModule,
    ErrorComponent,
    DropdownWithSearchComponent,
  ],
  exports: [
    CompanySwitcherComponent,
    OfflineComponent,
    ErrorNotFoundComponent,
    ErrorUnexpectedComponent,
    NoticeMessageComponent,
  ],
})
export class GlobalModule {}

@NgModule({
  imports: [
    GlobalModule,
    StoreModule.forFeature(GLOBAL_STORE_NAME, globalReducer),
    EffectsModule.forFeature([GlobalEffects]),
    StoreModule.forFeature(OFFICE_EVENTS_STORE_NAME, officeEventsReducer),
    EffectsModule.forFeature([OfficeEventsEffects]),
    StoreModule.forFeature(OFFICE_ROLES_STORE_NAME, officeRolesReducer),
    EffectsModule.forFeature([OfficeRolesEffects]),
    StoreModule.forFeature(OFFICE_EQUIPMENT_STORE_NAME, officeEquipmentReducer),
    EffectsModule.forFeature([OfficeEquipmentEffects]),
    StoreModule.forFeature(QR_GENERATOR_STORE_NAME, qrGeneratorReducer),
    EffectsModule.forFeature([QrGenerationEffects]),
  ],
  exports: [GlobalModule],
})
export class GlobalModuleWithProviders {
  constructor(@Optional() @SkipSelf() globalModule: GlobalModuleWithProviders) {
    if (globalModule) {
      throw new Error("GlobalModule should only be imported once!");
    }
  }
}
