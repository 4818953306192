import { Environment } from "../enums";

export function isProductionEnvironment(env: Environment) {
  return env === Environment.PRODUCTION;
}

export function isStagingEnvironment(env: Environment) {
  return env === Environment.STAGING;
}

export function isDevelopmentEnvironment(env: Environment) {
  return env === Environment.DEVELOPMENT;
}

export function isDebugEnvironment(env: Environment) {
  return (
    env === Environment.DEVELOPMENT ||
    env === Environment.DEBUG_PRODUCTION ||
    env === Environment.DEBUG_STAGING
  );
}

export function isLocalEnvironment(appUrl: string) {
  return appUrl.includes("localhost");
}
