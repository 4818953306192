import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormatDateTimePipe, TimeZoneAbbreviationPipe } from "../../pipes";
import { TimeFormatByUserPipe } from "../../pipes/time-format-by-user.pipe";
import { DEFAULT_TIMEZONE, TIME_FORMAT_H24 } from "../../constants";
import { IUser } from "types";

@Component({
  selector: "db-start-end-time",
  templateUrl: "./start-end-time.component.html",
  styleUrls: ["./start-end-time.component.scss"],
  standalone: true,
  imports: [TimeFormatByUserPipe, FormatDateTimePipe, TimeZoneAbbreviationPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartEndTimeComponent {
  readonly timeFormat = TIME_FORMAT_H24;

  @Input() startTime!: number | string;
  @Input() endTime!: number | string;
  @Input() timeZone: string | undefined;
  @Input() authUser!: IUser;

  get finalTimeZone(): string {
    return this.timeZone || DEFAULT_TIMEZONE;
  }
}
