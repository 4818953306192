export interface IBaseResponse<T> {
  success: boolean;
  data: T;
  message?: string;
  statusCode?: string;
  errorCode?: string;
}

export type IBaseResponseError = Pick<
  IBaseResponse<null>,
  "errorCode" | "message" | "statusCode"
>;

export type IApiResponse<T> = Omit<IBaseResponse<T>, "success">;
