import { Injectable, inject } from "@angular/core";
import { createEffect } from "@ngrx/effects";
import { catchError, map, switchMap, takeUntil } from "rxjs";

import { NotificationModel, NotificationType } from "notification-module";

import { OfficeRolesModel } from "./model";
import { IApiErrorResponse, ApiErrorResponse, IUserOfficeRole } from "types";
import { OfficeRolesService } from "../../services/office-roles.service";

@Injectable({ providedIn: "root" })
export class OfficeRolesEffects {
  private officeRolesService = inject(OfficeRolesService);
  private officeRolesModel = inject(OfficeRolesModel);
  private notificationModel = inject(NotificationModel);

  loadOfficeRolesForOffice = createEffect(() =>
    this.officeRolesModel.actions.listen.loadOfficeRolesForOffice$.pipe(
      switchMap((payload) =>
        this.officeRolesService.getForOffice(payload.officeId).pipe(
          takeUntil(
            this.officeRolesModel.actions.listen
              .loadOfficeRolesForOfficeCancel$,
          ),
          map((response) => {
            const errorResponse = response as IApiErrorResponse;
            if (errorResponse.errorCode) {
              throw new ApiErrorResponse(
                errorResponse.statusCode,
                errorResponse.errorCode,
                errorResponse.message,
              );
            }

            const entities = (response as Array<IUserOfficeRole>) || [];
            return this.officeRolesModel.actions.create.loadOfficeRolesForOfficeSuccess(
              {
                officeRoles: entities,
              },
            );
          }),
          catchError((error) => {
            return [
              this.officeRolesModel.actions.create.loadOfficeRolesForOfficeFailure(
                { error },
              ),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-roles-module|error-get-office-roles:Error loading office role(s)`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  loadOfficeRolesForUsers = createEffect(() =>
    this.officeRolesModel.actions.listen.loadOfficeRolesForUsers$.pipe(
      switchMap((payload) =>
        this.officeRolesService.getForUsers(payload.userIds).pipe(
          takeUntil(
            this.officeRolesModel.actions.listen.loadOfficeRolesForUsersCancel$,
          ),
          map((response) => {
            const errorResponse = response as IApiErrorResponse;
            if (errorResponse.errorCode) {
              throw new ApiErrorResponse(
                errorResponse.statusCode,
                errorResponse.errorCode,
                errorResponse.message,
              );
            }

            const entities = (response as Array<IUserOfficeRole>) || [];
            return this.officeRolesModel.actions.create.loadOfficeRolesForUsersSuccess(
              {
                officeRoles: entities,
              },
            );
          }),
          catchError((error) => {
            return [
              this.officeRolesModel.actions.create.loadOfficeRolesForUsersFailure(
                { error },
              ),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-roles-module|error-get-office-roles:Error loading office role(s)`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );
}
