import { CommonModule } from "@angular/common";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { FavoriteSpacesEffects } from "./+store/effects";
import { favoriteSpacesReducer } from "./+store/reducer";
import { FAVOURITE_SPACES_STORE_NAME } from "./constants";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule,

    StoreModule.forFeature(FAVOURITE_SPACES_STORE_NAME, favoriteSpacesReducer),
    EffectsModule.forFeature([FavoriteSpacesEffects]),
  ],
  exports: [],
})
export class FavoriteSpacesModule {
  constructor(@SkipSelf() @Optional() module: FavoriteSpacesModule) {
    if (module) {
      throw new Error("FavoriteSpacesModule should be imported only once!");
    }
  }
}
