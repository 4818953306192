import {
  ActivatedRouteSnapshot,
  Params,
  RouterStateSnapshot,
} from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";

const auxiliaryRegex = /\([^\)]*\)/g;
const auxiliaryNameAndPath = /\(([^:]*):([^\)]*)\)/;

export interface RouterState {
  url: string;
  path: string;
  outlets: {
    primary: string[];
    [outletName: string]: string[];
  } | null;
  queryParams: Params;
  hierarchyRouteParams: { [key: string]: Params[] };
  hierarchyData: { [key: string]: any[] };
  pathWithoutAuxiliaries: string;
}

export interface PreviousRouterState {
  previousUrl: string | null;
  previousPath: string | null;
  previousPathWithoutAuxiliaries: string;
  previousQueryParams: Params | null;
  previousHierarchyRouteParams: { [key: string]: Params[] } | null;
  previousHierarchyData: { [key: string]: any[] } | null;
  previousOutlets: {
    primary: string[];
    [outletName: string]: string[];
  } | null;
}

export type FullRouterState = RouterState & PreviousRouterState;

export class AppRouterStateSerializer
  implements RouterStateSerializer<FullRouterState>
{
  previousState: RouterState = {
    url: null,
    path: null,
    queryParams: null,
    hierarchyData: null,
    hierarchyRouteParams: null,
    outlets: null,
  } as any;

  serialize(routerState: RouterStateSnapshot): FullRouterState {
    const { url } = routerState;
    const path = url.split("?")[0];
    const pathWithoutAuxiliaries = path.replace(/\([^:]+:[^\)]+\)/g, "");

    const outlets: {
      primary: string[];
      [outletName: string]: string[];
    } = { primary: pathWithoutAuxiliaries.slice(1).split("/") };

    const outletMatches = url.match(auxiliaryRegex) || [];
    for (const match of outletMatches) {
      const [, outletName, outletPath] =
        match.match(auxiliaryNameAndPath) || [];
      if (!outletName || !outletPath) {
        continue;
      }
      outlets[outletName] = outletPath.split("/");
    }

    const queryParams = routerState.root.queryParams;
    let hierarchyData: { [name: string]: any[] } = {};
    let hierarchyRouteParams: { [name: string]: any[] } = {};
    let route = routerState.root;
    let children = route.children;
    let length = children.length;
    let unvisitedChildrenArrays = [] as ActivatedRouteSnapshot[][];

    for (let i = 0; i < length; i++) {
      const child = children[i];
      hierarchyData[child.outlet] = [
        child.data,
        ...(hierarchyData[child.outlet] || []),
      ];
      hierarchyRouteParams[child.outlet] = [
        child.params,
        ...(hierarchyRouteParams[child.outlet] || []),
      ];
      if (Array.isArray(child.children) && child.children.length > 0) {
        const unvisited = children.slice(1);
        if (unvisited.length > 0) {
          unvisitedChildrenArrays.push(unvisited);
        }
        children = child.children;
        length = children.length;
        i = -1;
      } else {
        children = unvisitedChildrenArrays.pop() || [];
        length = children.length;
        i = -1;
      }
    }

    const newState = {
      url,
      path,
      queryParams,
      hierarchyData,
      hierarchyRouteParams,
      outlets,
      pathWithoutAuxiliaries,
    };

    const previousState = this.previousState;
    this.previousState = newState;

    return {
      ...newState,
      previousUrl: previousState.url,
      previousPath: previousState.path,
      previousPathWithoutAuxiliaries: previousState.pathWithoutAuxiliaries,
      previousQueryParams: previousState.queryParams,
      previousHierarchyData: previousState.hierarchyData,
      previousHierarchyRouteParams: previousState.hierarchyRouteParams,
      previousOutlets: previousState.outlets,
    };
  }
}
