import {
  IOfficePlanningStatusOption,
  OfficePlanningStatusOptionType,
} from "types";
import { defaultStatusTypeNameMap } from "./status-type-map";

export const translateAndSortSchedulingOptions: (
  statusOptions: IOfficePlanningStatusOption[],
) => IOfficePlanningStatusOption[] = (statusOptions) => {
  return statusOptions
    .map((status) => {
      let name: string = "";
      if (status.type !== OfficePlanningStatusOptionType.Custom) {
        name = status.name || defaultStatusTypeNameMap(status.type);
      } else if (
        status.type === OfficePlanningStatusOptionType.Custom &&
        status.name
      ) {
        name = status.name;
      }
      return { ...status, name, isNameUserDefined: !!status.name };
    })
    .filter(({ name }) => !!name)
    .sort((x, y) => (x.order > y.order ? 1 : -1));
};
