<div id="primeng-chart-with-tooltip">
  <div
    #tooltipElement
    id="tooltip"
    [ngClass]="{ 'white-theme': useWhiteTheme }"
    [dbHidden]="!x || !y || !marginLeft || !marginTop"
    [delayVisible]="5"
    [style.marginLeft.px]="marginLeft"
    [style.marginTop.px]="marginTop"
    [style.left.px]="x"
    [style.top.px]="y"
  >
    <div id="primeng-chart-with-tooltip-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
