import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";

import { SidebarModule } from "primeng/sidebar";

@Component({
  selector: "db-ui-slide-over",
  standalone: true,
  imports: [SidebarModule],
  templateUrl: "./ui-slide-over.component.html",
  styleUrls: ["./ui-slide-over.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSlideOverComponent {
  @Input() visible = false;
  @Input() size: "large" | "medium" | "small" = "large";
  @Output() slideOverHide = new EventEmitter<void>();

  slideOverHideHandler() {
    this.slideOverHide.emit();
  }

  get slideOverClass() {
    return this.size === "large"
      ? "db-ui-slide-over p-sidebar-lg"
      : this.size === "medium"
        ? "db-ui-slide-over p-sidebar-md"
        : "db-ui-slide-over p-sidebar-sm";
  }
}
