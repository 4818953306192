<p-sidebar
  appendTo="body"
  [styleClass]="slideOverClass"
  [(visible)]="visible"
  [position]="'right'"
  [showCloseIcon]="false"
  [baseZIndex]="950"
  (onHide)="slideOverHideHandler()"
>
  <ng-content></ng-content>
</p-sidebar>
