import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable({ providedIn: "root" })
export class MatPaginatorIntlTranslated extends MatPaginatorIntl {
  override itemsPerPageLabel = $localize`:@@common|paginator|items-per-page:Items per page`;
  override nextPageLabel = $localize`:@@common|paginator|next-page:Next page`;
  override previousPageLabel = $localize`:@@common|paginator|previous-page:Previous page`;
  override firstPageLabel = $localize`:@@common|paginator|first-page:First page`;
  override lastPageLabel = $localize`:@@common|paginator|last-page:Last page`;

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return $localize`:@@common|paginator|range-label-zero:0 from 0`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return $localize`:@@common|paginator|range-label:${
      startIndex + 1
    } - ${endIndex} from ${length}`;
  };
}
