<p-chip
  [label]="label!"
  [removable]="removable!"
  [removeIcon]="removeIcon!"
  [style]="style!"
  [styleClass]="styleClass!"
  [icon]="icon!"
  [image]="image!"
  [ngClass]="['custom-chip', 'custom-chip-' + size]"
>
  <div id="chip-content">
    <ng-content></ng-content>
    @if (showBadge) {
      <p-badge class="chip-badge" [value]="badgeLabel || 'No label'"></p-badge>
    }
  </div>
</p-chip>
