import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IOfficeEvent, IUserGroup } from "common-module";
import { Observable } from "rxjs";
import {
  IApiErrorResponse,
  IUserInfo,
  OfficeEventAttendanceResponseType,
} from "types";

@Injectable({
  providedIn: "root",
})
export class OfficeEventService {
  constructor(private http: HttpClient) {}

  loadOfficeEvents(payload: {
    companyId: string;
    fromDate?: string;
    untilDate?: string;
    isAdmin?: boolean;
  }): Observable<Array<IOfficeEvent> | IApiErrorResponse> {
    const { companyId, fromDate, untilDate, isAdmin } = payload;
    let url = `/api/events?companyId=${companyId}`;

    if (fromDate) {
      url += `&from=${fromDate}`;
    }
    if (untilDate) {
      url += `&until=${untilDate}`;
    }
    if (isAdmin) {
      url += `&admin`;
    }

    return this.http.get<Array<IOfficeEvent> | IApiErrorResponse>(url);
  }

  loadUserGroups(companyId: string): Observable<{ userGroups: IUserGroup[] }> {
    return this.http.get<{ userGroups: IUserGroup[] }>(
      `/api/businesscompany/${companyId}/userGroups`,
    );
  }

  loadUsers(companyId: string): Observable<IUserInfo[]> {
    return this.http.get<IUserInfo[]>(`/api/users?companyId=${companyId}`);
  }

  getOneOfficeEvent(
    eventId: string,
  ): Observable<IOfficeEvent | IApiErrorResponse> {
    return this.http.get<IOfficeEvent | IApiErrorResponse>(
      `/api/events/${eventId}`,
    );
  }

  createOfficeEvent(data: Partial<IOfficeEvent>): Observable<IOfficeEvent> {
    return this.http.post<IOfficeEvent>(`/api/events/`, data);
  }

  updateOfficeEvent(data: Partial<IOfficeEvent>): Observable<IOfficeEvent> {
    return this.http.put<IOfficeEvent>(`/api/events/${data.id}`, data);
  }

  deleteOfficeEvent(data: IOfficeEvent): Observable<void> {
    return this.http.delete<void>(`/api/events/${data.id}`);
  }

  attendOfficeEvent(data: {
    eventId: string;
    response: OfficeEventAttendanceResponseType;
  }): Observable<void> {
    return this.http.put<void>(`/api/events/${data.eventId}/attendance/me`, {
      response: data.response,
    });
  }
}
