import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IQRGeneratorState } from "./reducer";
import { QR_GENERATOR_STORE_NAME } from "../../constants";

const getState = createFeatureSelector<IQRGeneratorState>(
  QR_GENERATOR_STORE_NAME,
);
const getQrGeneratorCurrentView = createSelector(
  getState,
  (state: IQRGeneratorState) => state.currentView,
);
const getQrTemplate = createSelector(getState, (state: IQRGeneratorState) => ({
  fontColor: state.fontColor,
  backgroundColor: state.backgroundColor,
  qrCodesForResourceCount: state.qrCodesForResourceCount,
  totalResources: state.totalResources,
  transparentBackground: state.transparentBackground,
  name: state.name,
  withTemplate: state.withTemplate,
  companyImage: state.companyImage,
  qrGenerationLevel: state.qrGenerationLevel,
}));
const getTotalResources = createSelector(
  getState,
  (state: IQRGeneratorState) => state.totalResources,
);
const getWithTemplate = createSelector(
  getState,
  (state: IQRGeneratorState) => state.withTemplate,
);
const getLoading = createSelector(
  getState,
  (state: IQRGeneratorState) => state.loading,
);
const getGeneratingCodes = createSelector(
  getState,
  (state: IQRGeneratorState) => state.generatingCodes,
);
const getErrors = createSelector(
  getState,
  (state: IQRGeneratorState) => state.errors,
);
const getDownloadParameters = createSelector(
  getState,
  (state: IQRGeneratorState) => {
    return {
      floorId: state.floorId,
      areaId: state.areaId,
      resourceId: state.resourceId,
    };
  },
);

export const qrGeneratorSelectors = {
  qrTemplate: getQrTemplate,
  totalResources: getTotalResources,
  withTemplate: getWithTemplate,
  loading: getLoading,
  generatingCodes: getGeneratingCodes,
  errors: getErrors,
  downloadParameters: getDownloadParameters,
  currentView: getQrGeneratorCurrentView,
};
