@for (
  item of processedConfig;
  track trackByPath(index, item);
  let index = $index
) {
  @if (item.isVisible) {
    <div
      class="slide-item slide-in"
      [class.is-last-slide-item]="index === visibleCount - 1"
      [class.slide-out]="item.isAboutToDisappear"
      [class]="item.size"
    >
      <ng-template slideItemComponentPlaceholderDirective></ng-template>
      @if (index !== visibleCount - 1) {
        <div class="overlay"></div>
      }
    </div>
  }
}
