import { Provider } from "@angular/core";
import { CLIENT_PART_PREFIX } from "../constants";

export function clientPartPrefixProviderFactory(prefix: string): Provider {
  prefix = prefix.trim();
  if (!prefix.startsWith("/")) {
    prefix = `/${prefix}`;
  }
  if (prefix.length > 1 && prefix.endsWith("/")) {
    prefix = prefix.slice(0, -1);
  }
  return {
    provide: CLIENT_PART_PREFIX,
    useValue: prefix,
  };
}
