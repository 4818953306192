import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ENVIRONMENT, Environment } from "common-module";
import { RouterModel } from "router-module";

@Component({
  selector: "app-route-not-found",
  templateUrl: "./route-not-found.component.html",
  styleUrls: ["./route-not-found.component.scss"],
})
export class RouteNotFoundComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private routerModel: RouterModel,
    private router: Router,
    @Inject(ENVIRONMENT) private env: Environment,
  ) {
    if (env === Environment.DEVELOPMENT) {
      console.log(this.router.config);
    }
  }

  ngOnInit(): void {
    const currentPath = this.activatedRoute.snapshot.url.map((s) => s.path);
    const upperPath = currentPath.slice(0, -1);
    this.routerModel.actions.dispatch.navigate({
      commands: upperPath.length ? upperPath : ["/"],
    });
  }
}
