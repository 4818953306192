<db-error
  iconPath="/assets/images/errors/error-offline.svg"
  title="Whoops."
  subTitle="It looks like you are offline."
  text="Make sure your device has a stable internet connection and is not in airplane mode. If all else fails, grab a cup of coffee and try again."
  buttonText="Refresh Page"
  i18n-title="@@global-module|error-offline|title"
  i18n-subTitle="@@global-module|error-offline|subTitle"
  i18n-text="@@global-module|error-offline|text"
  i18n-buttonText="@@global-module|error-offline|buttonText"
  (buttonClick)="refresh()"
>
</db-error>
