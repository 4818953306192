import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, scan, shareReplay, Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DebugService implements OnDestroy {
  sub = new Subscription();
  private queue$$ = new BehaviorSubject<string | null>(null);
  log$ = this.queue$$.pipe(
    shareReplay(),
    scan(
      (acc, curr) => (curr === null ? [] : acc.concat([curr])),
      [] as string[],
    ),
  );

  constructor() {
    this.sub.add(this.log$.subscribe());
  }

  send(message: string) {
    this.queue$$.next(message);
  }

  claer() {
    this.queue$$.next(null);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
