import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IOfficeEquipmentState } from "./reducer";
import { OFFICE_EQUIPMENT_STORE_NAME } from "../../constants";

const getState = createFeatureSelector<IOfficeEquipmentState>(
  OFFICE_EQUIPMENT_STORE_NAME,
);

export const officeSelectors = {
  officeEquipment: createSelector(getState, (state) => state.officeEquipment),
};
