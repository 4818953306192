import moment from "moment";
import { TIME_FORMAT_H12, TIME_FORMAT_H24 } from "shared-constants";

export const hourToTimeAndValue = (
  hour: number,
  timeFormat: 12 | 24 = 24,
  minute: number = 0,
  disabledTimeValues: string[] = [],
) => {
  const value = moment({ hour, minute }).format(TIME_FORMAT_H24);
  const disabled = disabledTimeValues.includes(value);
  return {
    value,
    title: moment({ hour, minute }).format(
      timeFormat === 12 ? TIME_FORMAT_H12 : TIME_FORMAT_H24,
    ),
    disabled,
  };
};

export const getTimesList = (
  timeFormat?: 12 | 24,
  minutes?: 15 | 30,
  disabledTimeValues: string[] = [],
  includeOptionFor2359?: boolean,
) => {
  const items: { value: string; title: string; disabled: boolean }[] = [];
  for (let i = 0; i < 24; i++) {
    items.push(hourToTimeAndValue(i, timeFormat, 0, disabledTimeValues));
    if (minutes === 15) {
      items.push(hourToTimeAndValue(i, timeFormat, 15, disabledTimeValues));
      items.push(hourToTimeAndValue(i, timeFormat, 30, disabledTimeValues));
      items.push(hourToTimeAndValue(i, timeFormat, 45, disabledTimeValues));
    }
    if (minutes === 30) {
      items.push(hourToTimeAndValue(i, timeFormat, 30, disabledTimeValues));
    }
  }

  if (includeOptionFor2359) {
    items.push(hourToTimeAndValue(23, timeFormat, 59, disabledTimeValues));
  }
  return items;
};
