import { createReducer, on } from "@ngrx/store";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import {
  loadOfficeRolesForOfficeBundle,
  loadOfficeRolesForUsersBundle,
} from "./bundles";
import { IUserOfficeRole } from "types";

const sortComparer = (a: IUserOfficeRole, b: IUserOfficeRole) =>
  `${a.userId}-${a.officeRoleId}-${a.officeId}`.localeCompare(
    `${b.userId}-${b.officeRoleId}-${b.officeId}`,
  );

const adapter = createEntityAdapter<IUserOfficeRole>({
  selectId: (entity: IUserOfficeRole) =>
    `${entity.userId}-${entity.officeRoleId}-${entity.officeId}`,
  sortComparer,
});

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export interface IOfficeRolesState extends EntityState<IUserOfficeRole> {
  isLoading: boolean;
}

export const initialState: IOfficeRolesState = {
  ...adapter.getInitialState({}),
  isLoading: false,
};

export const officeRolesReducer = createReducer(
  initialState,
  on(loadOfficeRolesForOfficeBundle.loadOfficeRolesForOffice, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(
    loadOfficeRolesForOfficeBundle.loadOfficeRolesForOfficeSuccess,
    loadOfficeRolesForUsersBundle.loadOfficeRolesForUsersSuccess,
    (state, { officeRoles }) => {
      const cleanState = {
        ...state,
        ...adapter.removeAll(state),
      };

      return {
        ...cleanState,
        ...adapter.addMany(officeRoles, cleanState),
        isLoading: false,
      };
    },
  ),
  on(
    loadOfficeRolesForOfficeBundle.loadOfficeRolesForOfficeFailure,
    loadOfficeRolesForUsersBundle.loadOfficeRolesForUsersFailure,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
);

export const selectOfficeRoleIds = selectIds;
export const selectOfficeRoleEntities = selectEntities;
export const selectAllOfficeRoles = selectAll;
export const selectOfficeRolesTotalCount = selectTotal;
