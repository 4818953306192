import { Injectable, TemplateRef } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NoticeMessageService {
  _noticeMessageTemplateRef: TemplateRef<any> | null = null;
  _noticeMessageStyle: { [klass: string]: any } | null = null;

  setNoticeMessageTemplateRef(
    template: TemplateRef<any>,
    style: { [klass: string]: any } | null = null,
  ): void {
    this._noticeMessageTemplateRef = template;
    this._noticeMessageStyle = style;
  }

  clearNoticeMessageTemplateRef(): void {
    this._noticeMessageTemplateRef = null;
  }
}
