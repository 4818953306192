import { ConferenceLinkType } from "types/enums";
import { CateringCategory } from "./catering-category";
import { IUserViewInfo } from "../user-info";
import { IOfficeFloorBasicInfo } from "../offices/office-floor";
import { IRequestedCategory } from "../service-request";

export interface MeetingRoomCalendarDataAccessRules {
  isHidden: boolean;
  isRestricted: boolean;
  userHasAccess: boolean;
  restrictedTime: number;
}

export interface MeetingRoom {
  id: string;
  name: string;
  capacity: number;
  description: string;
  amenities: string[];
  floorId: string;
  accessRules: MeetingRoomCalendarDataAccessRules;
  images: string[];
  order: number;
  equipment: IEquipment[];

  conferenceProviders: ConferenceLinkType[];
  syncProvider: string | null;

  serviceRequestCategories?: CateringCategory[];
}

export interface MeetingRoomWithFloor extends MeetingRoom {
  floor: IOfficeFloorBasicInfo;
  events?: MeetingRoomCalendarData[];
}

export interface MeetingRoomCalendarData {
  id: string;
  events: MeetingRoomCalendarEventData[];
}

export interface MeetingRoomWithCalendarData extends MeetingRoom {
  events: MeetingRoomCalendarData["events"];
}

export interface MeetingRoomCalendarEventData {
  id?: string;
  bookingId?: string;

  subject: string;
  startTime: string;
  endTime: string;

  type: "occurrence" | "legacy" | "single";
  roomId: string;
  shouldMasquerade: boolean;
  hasServiceRequest?: boolean;

  user: {
    id: string;
    avatarUrl: string;
    firstName: string;
    lastName: string;
    avatarColor: string;
    profileImage?: string;
  };

  virtualId: string;
  seriesId: string;

  isEditable: boolean;
  isPrivate: boolean;

  bufferBeforeMinutes: number;
  bufferAfterMinutes: number;
}

export interface IMeetingRoomKioskEventData {
  id: string;
  subject?: string;
  startTime: string;
  endTime: string;

  hasServiceRequest?: boolean;
  checkIn: {
    checkedIn: boolean;
    autoCancellationAt: string;
  };

  owner?: IUserViewInfo;

  // Not returned by API but possibly needed for UI
  bookingId?: string;
  type: "occurrence" | "legacy" | "single";
  roomId: string;
  virtualId: string;
  seriesId: string;
}

export interface IMeetingRoomKioskEventDetailsData
  extends IMeetingRoomKioskEventData {
  description?: string;
  serviceRequest?: {
    requestedCategories: IRequestedCategory[];
  };
  attendees?: {
    id: string;
    firstName?: string;
    lastName?: string;
    avatarColor?: string;
    profileImage?: string;
    email?: string;
    type: string;
  }[];
}

export interface IEquipment {
  id: string;
  title: string;
}

export interface IEquipmentDto {
  id: string;
  title?: string;
}

export interface CurrentEventDateAndTime {
  start: string;
  end: string;
  dateTimestamp: number;
}

export interface AdjacentMeetingRoomEvents {
  previousEvent?: MeetingRoomCalendarEventData;
  nextEvent?: MeetingRoomCalendarEventData;
}
