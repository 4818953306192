export enum AvailableLocales {
  English = "en",
  German = "de",
  French = "fr",
  Spanish = "es",
  Italian = "it",
  Ukrainian = "uk",
  Dutch = "nl",
  Hungarian = "hu",
  Czech = "cs",
}
