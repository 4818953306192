import { forNamespace } from "ngrx-action-bundles";
import { IGenericConfirmation, ILocalConfirmationConfig } from "../interfaces";
import { props } from "@ngrx/store";

const factory = forNamespace("CONFIRMATION");

export const openConfirmationComponentBundle = factory.singleAction(
  "openConfirmationComponent",
  props<{ name: string }>(),
  true,
);
export const openGenericConfirmationBundle = factory.singleAction(
  "openGenericConfirmation",
  props<{ config: IGenericConfirmation }>(),
  true,
);
export const addOpenConfirmationBundle = factory.singleAction(
  "__addOpenConfirmation",
  props<{ config: ILocalConfirmationConfig }>(),
  true,
);
export const confirmationResultBundle = factory.singleAction(
  "confirmationResult",
  props<{
    name: string;
    data?: any;
    result: boolean;
    formValue: any;
    timestamp: number;
  }>(),
);

export const confirmationBundles = [
  addOpenConfirmationBundle,
  openConfirmationComponentBundle,
  confirmationResultBundle,
  openGenericConfirmationBundle,
] as const;
