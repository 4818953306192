import { HttpErrorResponse } from "@angular/common/http";
import { props } from "@ngrx/store";
import { forNamespace } from "ngrx-action-bundles";
import { IUserFavoriteResource, IUserFavoriteResourceEssentials } from "types";

const factory = forNamespace("FAVOURITE-SPACES");

export const addFavoriteSpaceBundle = factory.asyncAction(
  "addFavoriteSpace",
  props<{ resource: IUserFavoriteResourceEssentials }>(),
  props<{ resource: IUserFavoriteResource }>(),
  props<{
    error: HttpErrorResponse;
    resource: IUserFavoriteResourceEssentials;
  }>(),
);

export const removeFavoriteSpaceBundle = factory.asyncAction(
  "removeFavoriteSpace",
  props<{ resource: IUserFavoriteResourceEssentials }>(),
  props<{ resource: IUserFavoriteResourceEssentials }>(),
  props<{
    error: HttpErrorResponse;
    resource: IUserFavoriteResourceEssentials;
  }>(),
);

export const favoriteSpacesBundles = [
  addFavoriteSpaceBundle,
  removeFavoriteSpaceBundle,
];
