import { AccessEntityType } from "../enums";
import { IAccessEntity } from "../interfaces";
import { UserStatus, IUser } from "types";

export function generateUserAccessEntities(users?: IUser[]): IAccessEntity[] {
  return (
    users
      ?.sort((a, b) => {
        if (a.status === b.status) {
          return a.firstName < b.firstName ? -1 : 1;
        }
        if (a.status === UserStatus.Active) {
          return -1;
        }
        if (b.status === UserStatus.Active) {
          return 1;
        }
        return a.firstName < b.firstName ? -1 : 1;
      })
      .map((user: IUser) => ({
        id: AccessEntityType.EMPLOYEE + "-" + user.id.toString(),
        _id: user.id.toString(),
        title: `${user.firstName} ${user.lastName}`,
        isInactive: user.status === UserStatus.Inactive,
        color: user.avatarColor || "#ff1658",
        type: AccessEntityType.EMPLOYEE,
        isUserGroup: false,
        extraInfo: user.email,
        imgSrc: user.profileImage,
      })) || []
  );
}
