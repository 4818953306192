<header>
  <db-button-icon
    class="close-button"
    icon="pi pi-times"
    size="x-large"
    (click)="slideOverClosedHandler()"
  >
  </db-button-icon>
  <h3 class="text-black">
    <ng-content select="[dbUiSlideOverContentHeader]"></ng-content>
  </h3>
</header>
<ng-content></ng-content>
