export enum ClickFavoriteSpaceEntryPoint {
  schedule_personal_feed = "schedule_personal_feed",

  book_overview = "book_overview", // your spaces section
  book_floorplan = "book_floorplan", // floor plan preview
  book_area_list_view = "book_area_list_view",
  book_area_map_view = "book_area_map_view",
  book_booking_widget = "book_booking_widget",

  upcoming_bookings = "upcoming_bookings",
  completed_bookings = "completed_bookings",
  admin_upcoming_bookings = "admin_upcoming_bookings",
  admin_completed_bookings = "admin_completed_bookings",

  colleagues_profile = "colleagues_profile",
  profile_your_spaces = "profile_your_spaces",
}

export enum InitiateEntityBookingEntryPoint {
  schedule_personal_feed = "schedule_personal_feed",
  schedule_colleagues_view = "schedule_colleagues_view",

  book_overview = "book_overview", // your spaces section
  book_area_list_view = "book_area_list_view",
  book_area_map_view = "book_area_map_view",
}

export enum SubmittedEntityBookingEntryPoint {
  schedule_personal_feed = "schedule_personal_feed",
  schedule_colleagues_view = "schedule_colleagues_view",

  book_overview = "book_overview", // your spaces section
  book_area_list_view = "book_area_list_view",
  book_area_map_view = "book_area_map_view",
}

export enum ViewOfficeEventEntryPoint {
  admin_past_events = "admin_past_events",
  admin_upcoming_events = "admin_upcoming_events",

  schedule_personal_feed = "schedule_personal_feed",
  all_events = "all_events", // all events slide over opening from personal feed
}

export enum OfficeFunctionsEntryPoint {
  schedule_daily_office_view = "schedule_daily_office_view",
  book_floorplan = "book_floorplan", // floor plan preview
}

export enum OfficeEquipmentEntryPoint {
  book_floorplan = "book_floorplan", // floor plan preview
  book_dashboard = "book_dashboard",
}

export enum SelectFilterEntryPoint {
  book_floorplan = "book_floorplan", // floor plan preview
  book_dashboard = "book_dashboard",
}

export enum ClearFilterEntryPoint {
  schedule_daily_office_view = "schedule_daily_office_view",
  book_floorplan = "book_floorplan", // floor plan preview
  book_dashboard = "book_dashboard",
}

export enum FloorPlanNavigationEntryPoint {
  schedule_daily_office_view = "schedule_daily_office_view",
}
