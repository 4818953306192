import { CommonModule } from "@angular/common";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { NotificationEffects } from "./+store/effects";
import { NotificationComponent } from "./notification/notification.component";
import { ExecPipe } from "shared";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [NotificationComponent],
  imports: [
    ToastModule,
    CommonModule,
    EffectsModule.forFeature([NotificationEffects]),
    ExecPipe,
    RouterModule,
  ],
  exports: [NotificationComponent],
  providers: [MessageService],
})
export class NotificationModule {
  constructor(@SkipSelf() @Optional() module: NotificationModule) {
    if (module) {
      throw new Error("Notification should be imported only once!");
    }
  }
}
