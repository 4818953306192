<ng-template #pastTextTemplate>
  {{ pastText }}
</ng-template>
<ng-template #presentTextTemplate>
  <db-dot-loader>{{ presentText }}</db-dot-loader>
</ng-template>
<ng-template #infinitiveTextTemplate>
  {{ infinitiveText }}
</ng-template>
<ng-template
  #invalidStateTemplate
  i18n="@@loading-tense-module|loading-tense|invalid-state"
>
  [[INVALID STATE]]
</ng-template>

<ng-container
  *ngTemplateOutlet="
    state === LoadingTenseState.Infinitive
      ? infinitiveTextTemplate
      : state === LoadingTenseState.Past
        ? pastTextTemplate
        : state === LoadingTenseState.Present
          ? presentTextTemplate
          : invalidStateTemplate
  "
>
</ng-container>
