import { Moment } from "moment";

export const dateNoTimeInfo = (
  inputDate: string | Date | null,
): Date | null => {
  const date = inputDate ? new Date(inputDate) : null;
  return date
    ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    : null;
};

export const momentToDateNoTimeInfo = (inputDate: Moment): Date | null => {
  const date = inputDate
    ? new Date(inputDate.year(), inputDate.month(), inputDate.date(), 0, 0, 0)
    : null;
  return date;
};
