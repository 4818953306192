import {
  CalendarIntegrationFeatureType,
  IntegrationType,
} from "../types/integration";

export interface IBaseIntegration {
  isConnected: boolean;
  isEnabled: boolean;
  provider?: string;
  syncStatus?: string;
}

export interface ICalendarIntegrationFeature {
  type: CalendarIntegrationFeatureType;
  isConnected: boolean;
  isEnabled: boolean;
}

export interface IHrisIntegrationFeature {
  type: IntegrationType;
  isConnected: boolean;
  isEnabled: boolean;
}

export interface ICalendarIntegration extends IBaseIntegration {
  type: IntegrationType;
  features: ICalendarIntegrationFeature[];
}

export interface IHrisIntegration extends IBaseIntegration {
  type: IntegrationType;
  features: IHrisIntegrationFeature[];
}

export type IIntegration = IHrisIntegration | ICalendarIntegration;
