import { Injectable } from "@angular/core";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";
import { officeBundles } from "./bundles";
import { officeSelectors } from "./selectors";

@Injectable({ providedIn: "root" })
export class OfficeEquipmentModel {
  selectors = connectSelectors(officeSelectors);
  actions = connectBundles(officeBundles);
}
