import { Injectable } from "@angular/core";
import { IUserViewAvatarProfileOpeningProps } from "types";
import { RouterModel } from "router-module";

@Injectable({
  providedIn: "root",
})
export class UserProfileNavigationService {
  constructor(private routerModel: RouterModel) {}

  openProfileHandler(
    event: Event,
    userInfo: IUserViewAvatarProfileOpeningProps,
  ) {
    event.preventDefault();
    event.stopPropagation();

    if (!userInfo) {
      return;
    }

    const userId = userInfo.userId || userInfo.id;
    this.routerModel.navigationOutlet("user", ["profile", userId], {
      queryParamsHandling: "preserve",
    });
  }
}
