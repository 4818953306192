import { Inject, Injectable } from "@angular/core";
import { SentryErrorHandler } from "@sentry/angular";
import * as Sentry from "@sentry/angular";
import { ENVIRONMENT } from "./constants";
import { Environment } from "./enums";
import { IUser } from "types";
import { isDebugEnvironment } from "./utils";

@Injectable({
  providedIn: "root",
})
export class SentryService {
  sentry: SentryErrorHandler;

  updateUser: (user: IUser | Partial<IUser> | null) => void;

  constructor(@Inject(ENVIRONMENT) environment: Environment) {
    if (isDebugEnvironment(environment)) {
      this.sentry = {
        handleError: console.error,
      } as unknown as SentryErrorHandler;
      this.updateUser = (user: Partial<IUser> | null): void => {
        console.log("set sentry user as:", user?.uuid);
      };
    } else {
      this.sentry = Sentry.createErrorHandler({ showDialog: false });
      this.updateUser = (user: Partial<IUser> | null): void => {
        this.setSentryUserUuid(user);
      };
    }
  }

  private setSentryUserUuid(user: Partial<IUser> | null): void {
    Sentry.getCurrentScope().setUser(user ? { id: user.uuid } : null);
  }

  handleError(error: unknown): void {
    this.sentry.handleError(error);
  }

  captureMessage(message: string, customData?: Record<string, any>): void {
    if (customData) {
      Sentry.withScope((scope) => {
        if (customData) {
          scope.setContext("custom", customData);
        }

        Sentry.captureMessage(message, "info");
      });
    } else {
      Sentry.captureMessage(message, "info");
    }
  }
}
