<div class="list-item-col-content">
  <div class="list-item-col-content-row">
    <div class="list-item-title small-text-bold flex-center text-gray">
      @if (itemTitle) {
        {{ itemTitle }}
      }
      <ng-content select="[entryTitle]"></ng-content>
    </div>
    @if (itemSubTitle) {
      <div class="vertical-dash"></div>
      <div class="list-item-subtitle small-text">
        {{ itemSubTitle }}
      </div>
    }
  </div>
  @if (startTime && endTime) {
    <div class="list-item-col-content-row very-small-text text-dark-gray">
      @if (!isFullDay) {
        <db-start-end-time
          [authUser]="authUser"
          [timeZone]="timezone"
          [startTime]="startTime"
          [endTime]="endTime"
        ></db-start-end-time>
      } @else {
        <ng-container i18n="@@common|full-day">Full day</ng-container>
      }
    </div>
  }
</div>
<div class="list-item-col-quick-action">
  <ng-content select="[itemQuickAction]"></ng-content>
</div>
