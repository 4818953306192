<db-dropdown
  #dropdown
  [dataTestId]="dataTestId"
  [options]="options"
  [disabled]="isDisabled"
  [ngModel]="selectedOptionValue"
  [ngModelOptions]="{ standalone: true }"
  [optionLabel]="optionLabel"
  [optionValue]="optionValue"
  [optionDisabled]="optionDisabled"
  [filter]="!!filterBy"
  [filterBy]="filterBy"
  [virtualScroll]="options.length > 5"
  [virtualScrollItemSize]="virtualScrollItemSize"
  [autoDisplayFirst]="false"
  appendTo="body"
  [readonly]="isReadonly"
  [placeholder]="placeholder"
  [templatesAsInput]="
    templates && templates.length > 0 ? templates.toArray() : null
  "
  [emptyFilterMessage]="emptyFilterMessage"
  [styleClass]="styleClass"
  [panelStyleClass]="panelStyleClass"
  [showClear]="showClear"
  (onChange)="selectedItemHandler($event)"
  (onHide)="onDropdownHideHandler()"
  (onFilter)="onFilter(dropdown)"
>
</db-dropdown>
