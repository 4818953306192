export enum AreaTranslationType {
  AREA_NAME = "area_name",
  AREA_RESOURCE_SING = "area_resource_singular",
  AREA_RESOURCE_PL = "area_resource_plural",
  AREA_RESOURCE_SIZE = "area_resource_size",
  AREA_RESOURCE_PLACED_UNIVERSAL = "area_resource_placed_universal",
  AREA_RESOURCE_UNIVERSAL = "area_resource_universal",
  ADD_AREA = "add_area",
  ADD_RESOURCES = "add_resources",
}

// Use only for translations requiring usage of i18nPluralPipe
export enum AreaResourcesTranslationTypePlural {
  RESOURCE = "resource",
  AVAILABLE = "available",
  ACTIVE = "active",
}
