<cdk-virtual-scroll-viewport
  dbDynamicSizeVirtualScroll
  appendOnly
  [scrollableItems]="scrollableItems$ | async"
  #viScroll
  (scrolledIndexChange)="scrolledIndexChangeHandler($event)"
>
  <div
    *cdkVirtualFor="
      let day of scrollableItems$ | async;
      let i = index;
      trackBy: trackByFn;
      templateCacheSize: 0
    "
    [attr.vc-data-index]="i"
    [style.height.px]="day.height"
  >
    <ng-container
      [ngTemplateOutlet]="scrollableItemTemplate"
      [ngTemplateOutletContext]="{ day: day, index: i }"
    >
    </ng-container>
  </div>
</cdk-virtual-scroll-viewport>
