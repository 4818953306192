import { createReducer, on } from "@ngrx/store";
import { uploadBundle, uploadProgressBundle } from "./bundles";
import { Range } from "shared";

export interface IUploadState {
  readonly progress: { [name: string]: Range<0, 101> };
  readonly fileNames: { [name: string]: string[] };
  readonly successfulUploadIds: string[];
  readonly failedUploadIds: string[];
}

const initialState: IUploadState = {
  progress: {},
  fileNames: {},
  successfulUploadIds: [],
  failedUploadIds: [],
};

export const uploadReducer = createReducer(
  initialState,
  on(uploadBundle.upload, (state, { uuid: key, files }) => {
    return {
      ...state,
      progress: { ...state.progress, [key]: 0 as const },
      fileNames: {
        ...state.fileNames,
        [key]: files.map((f) => {
          return f.name;
        }),
      },
    };
  }),
  on(uploadProgressBundle.uploadProgress, (state, { uuid, progress }) => {
    return {
      ...state,
      progress: { ...state.progress, [uuid]: progress as Range<0, 101> },
    };
  }),
  on(uploadBundle.uploadSuccess, (state, { uuid }) => {
    const { [uuid]: currentProgress, ...otherProgress } = state.progress;
    const { [uuid]: currentNames, ...otherNames } = state.fileNames;
    return {
      ...state,
      progress: { ...otherProgress },
      fileNames: { ...otherNames },
      successfulUploadIds: [...state.successfulUploadIds, uuid],
    };
  }),
  on(uploadBundle.uploadFailure, (state, { uuid }) => {
    const { [uuid]: currentProgress, ...otherProgress } = state.progress;
    const { [uuid]: currentNames, ...otherNames } = state.fileNames;

    return {
      ...state,
      progress: { ...otherProgress },
      fileNames: { ...otherNames },
      failedUploadIds: [...state.failedUploadIds, uuid],
    };
  }),
  on(uploadBundle.uploadCleanup, (state, { uuid }) => {
    const { [uuid]: currentProgress, ...otherProgress } = state.progress;
    const { [uuid]: currentNames, ...otherNames } = state.fileNames;

    const failedUploads = state.failedUploadIds.filter((id) => id !== uuid);
    const successfulUploads = state.successfulUploadIds.filter(
      (id) => id !== uuid,
    );

    return {
      ...state,
      progress: otherProgress,
      fileNames: otherNames,
      failedUploads,
      successfulUploads,
    };
  }),
  on(uploadBundle.uploadCancel, (state, { uuid }) => {
    const { [uuid]: currentProgress, ...otherProgress } = state.progress;
    const { [uuid]: currentNames, ...otherNames } = state.fileNames;

    const failedUploads = state.failedUploadIds.filter((id) => id !== uuid);
    const successfulUploads = state.successfulUploadIds.filter(
      (id) => id !== uuid,
    );

    return {
      ...state,
      progress: otherProgress,
      fileNames: otherNames,
      failedUploads,
      successfulUploads,
    };
  }),
);
