@if (showOfficeSwitcher$ | async) {
  <ng-container [dbResolve]="officeResolveBundle">
    @if (offices$ | async) {
      <db-dropdown-with-search
        class="no-border office-selector"
        [options]="(officeOptions$ | async)!"
        [selectedOption]="(selectedOfficeOption$ | async)!"
        [optionLabel]="'title'"
        [optionValue]="'value'"
        [filterBy]="'title'"
        [isDisabled]="!(offices$ | async)?.length"
        (selectedValueChanged)="handleOfficeSelectionChange($event)"
      >
      </db-dropdown-with-search>
    } @else {
      <div id="company-select">
        <ngx-skeleton-loader
          [theme]="{
            margin: 0,
            width: '230px',
            height: '40px',
            'border-radius': '44px',
          }"
        >
        </ngx-skeleton-loader>
      </div>
    }
  </ng-container>
}
