import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { Chip, ChipModule } from "primeng/chip";
import { BadgeModule } from "primeng/badge";
import { ChipSize } from "shared-types";

@Component({
  selector: "db-chip",
  standalone: true,
  imports: [CommonModule, ChipModule, BadgeModule],
  templateUrl: "./chip.component.html",
  styleUrls: ["./chip.component.scss"],
})
export class ChipComponent implements OnInit {
  @ViewChild(Chip, { read: Chip, static: true }) chip!: Chip;
  // Used primeng inputs for chip:
  @Input() label: string | undefined;
  @Input() removable: boolean | undefined = false;
  @Input() removeIcon = "pi pi-times-circle";
  @Input() style: { [klass: string]: any } | null | undefined;
  @Input() styleClass: string | undefined;
  @Input() icon: string | undefined;
  @Input() image: string | undefined;
  @Input() @HostBinding("class.with-badge") showBadge = false;
  @Input() badgeLabel: string | undefined;
  @Input() size: ChipSize = "large";

  @Output() onRemove: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() onImageError: EventEmitter<Event> = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {
    this.chip.onRemove.subscribe(this.onRemove);
    this.chip.onImageError.subscribe(this.onImageError);
  }
}
