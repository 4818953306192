import { Component, Input } from "@angular/core";

import { ProgressSpinnerComponent } from "../progress-spinner";

@Component({
  selector: "db-global-progress",
  standalone: true,
  imports: [ProgressSpinnerComponent],
  templateUrl: "./global-progress.component.html",
  styleUrls: ["./global-progress.component.scss"],
})
export class GlobalProgressComponent {
  @Input() inProgress = false;
}
