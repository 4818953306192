import { IBookingCheckInStatusDto } from "./types";
import { inject, Injectable } from "@angular/core";
import moment from "moment";
import {
  DAY_AND_MONTH_FORMAT,
  DAY_OF_WEEK_FORMAT,
  DEFAULT_TIMEZONE,
} from "shared-constants";
import { DateTimeFormatter } from "shared-utils";
import {
  BookingStatus,
  CheckInStatus,
  IBookingCheckinInfo,
  IUser,
} from "types";

const readyForCheckInStatuses = [
  CheckInStatus.ReadyForNonQRCheckIn,
  CheckInStatus.ReadyForCheckIn,
];
const checkInStatuses = [
  ...readyForCheckInStatuses,
  CheckInStatus.CheckInNotAvailable,
  CheckInStatus.NotCheckedIn,
];

type StatusIcon =
  | "svg-not-available"
  | "svg-booking-red"
  | "svg-booking-green"
  | "svg-booking-green-checked"
  | "svg-booking-purple-checked"
  | "";

const iconSrcPaths: Record<StatusIcon, string> = {
  "svg-not-available": "/assets/icons/not-available.svg",
  "svg-booking-red": "/assets/icons/bookings/booking-red.svg",
  "svg-booking-green": "/assets/icons/bookings/booking-green.svg",
  "svg-booking-green-checked":
    "/assets/icons/bookings/booking-green-checked.svg",
  "svg-booking-purple-checked":
    "/assets/icons/bookings/booking-purple-checked.svg",
  "": "",
};

@Injectable({ providedIn: "root" })
export class BookingCheckInStatusUtil {
  private readonly dateTimeFormatter = inject(DateTimeFormatter);

  buildStatusIconAndText(
    booking: IBookingCheckinInfo,
    onlyColor: boolean,
    authUser: Pick<IUser, "hourFormat">,
  ): IBookingCheckInStatusDto {
    let iconName: StatusIcon = "";
    let labelText = "";
    let additionalIconName: "svg-qr-code" | "" = "";
    let additionalInfoText = "";
    let tooltipText = "";
    const {
      checkInStatus,
      bookingStartTime,
      bookingStatus,
      bookingAutoCancellationTime,
      checkInAvailableFrom,
    } = booking;
    const isBookingForToday = isBookingToday(bookingStartTime);
    const officeTimeZone =
      booking.workspace.address?.timeZone || DEFAULT_TIMEZONE;
    const bookingCancellationTimePassed = isBookingCancellationTimePassed(
      booking,
      officeTimeZone,
    );
    const isPastBooking = [
      BookingStatus.COMPLETED,
      BookingStatus.CANCELED,
    ].includes(bookingStatus);

    if (
      isPastBooking &&
      (checkInStatuses.includes(checkInStatus) || bookingCancellationTimePassed)
    ) {
      iconName = "svg-not-available";
    } else if (
      readyForCheckInStatuses.includes(checkInStatus) &&
      isBookingForToday
    ) {
      iconName = "svg-booking-red";
      labelText = $localize`:@@booking-module|booking-status|check-in:Check in`;
      if (checkInStatus === CheckInStatus.ReadyForCheckIn) {
        additionalIconName = "svg-qr-code";
        tooltipText = $localize`:@@booking-module|booking-status|qr-info-tooltip:Scan the QR code with your phone to check in`;
      }
      if (bookingAutoCancellationTime) {
        additionalInfoText = this.getAutoCancellationText(
          authUser,
          bookingAutoCancellationTime,
          officeTimeZone,
        );
      }
    } else if (checkInStatus === CheckInStatus.CheckedIn) {
      iconName = onlyColor ? "svg-booking-green" : "svg-booking-green-checked";
      labelText = $localize`: @@booking-module|booking-status|checked-in: Checked in `;
    } else {
      iconName = "svg-booking-purple-checked";
      labelText = $localize`: @@booking-module|booking-status|booked: Booked`;

      if (checkInAvailableFrom) {
        additionalInfoText = this.getCheckInAvailableFromText(
          checkInAvailableFrom,
          officeTimeZone,
        );
      }
    }

    return {
      iconSrc: iconSrcPaths[iconName],
      labelText,
      additionalIconName,
      additionalInfoText,
      tooltipText,
    };
  }

  private getAutoCancellationText(
    authUser: Pick<IUser, "hourFormat">,
    bookingAutoCancellationTime: number,
    officeTimeZone: string,
  ): string {
    const timeFormat = this.dateTimeFormatter.getTimeFormatString(
      authUser.hourFormat!,
    );
    const checkInUntil = this.dateTimeFormatter.formatTimestamp(
      bookingAutoCancellationTime,
      timeFormat,
      officeTimeZone,
    );
    return $localize`:@@booking-module|booking-status|check-in-until:util ${checkInUntil}`;
  }

  private getCheckInAvailableFromText(
    checkInAvailableFrom: number,
    officeTimeZone: string,
  ): string {
    const isThisWeek = moment(checkInAvailableFrom).isSame(new Date(), "week");
    const dateFormat = isThisWeek ? DAY_OF_WEEK_FORMAT : DAY_AND_MONTH_FORMAT;
    const checkInOn = this.dateTimeFormatter.formatTimestamp(
      checkInAvailableFrom,
      dateFormat,
      officeTimeZone,
    );
    return $localize`:@@booking-module|booking-status|check-in-on:Check-in on ${checkInOn}`;
  }
}

function isBookingToday(startingTimestamp: number): boolean {
  const dateNow = moment().startOf("day");
  const bookingStartDate = moment(startingTimestamp).startOf("day");

  return dateNow.valueOf() === bookingStartDate.valueOf();
}

function isBookingCancellationTimePassed(
  booking: { bookingAutoCancellationTime?: number },
  timezone: string,
): boolean {
  if (!booking.bookingAutoCancellationTime) {
    return false;
  }
  const bookingTzTime = moment(booking.bookingAutoCancellationTime).tz(
    timezone,
    true,
  );
  const nowTzTime = moment().tz(timezone, true);
  return bookingTzTime.isBefore(nowTzTime);
}
