import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {
  IApiResponse,
  IBaseResponseError,
  ICheckInBookingDto,
  ICheckInBookingResponse,
  ICreateBookingForEmployee,
  ICreateBookingForGuestDto,
  IMultiDayBookingsCreationResult,
  IUpdateBookingDto,
  IUpdateBookingResponse,
} from "types";
import { catchError, Observable, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
/** Booking V2 endpoints - https://app-staging.deskbird.com/api/docs/#/Booking%20V2 */
export class BookingV2Service {
  private readonly baseUrl = "/api/bookings";
  private readonly http = inject(HttpClient);

  createBookings(
    bookings: (ICreateBookingForGuestDto | ICreateBookingForEmployee)[],
  ): Observable<IMultiDayBookingsCreationResult> {
    return this.http.post<IMultiDayBookingsCreationResult>(this.baseUrl, {
      bookings,
    });
  }

  editBooking(
    request: IUpdateBookingDto,
  ): Observable<IApiResponse<IUpdateBookingResponse>> {
    const url = `${this.baseUrl}/${request.bookingId}`;
    return this.http
      .patch<IApiResponse<IUpdateBookingResponse>>(url, request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(() => error.error as IBaseResponseError),
        ),
      );
  }

  checkInBooking(
    bookingId: string,
    request: ICheckInBookingDto,
  ): Observable<ICheckInBookingResponse | IBaseResponseError> {
    return this.http
      .patch<
        IApiResponse<ICheckInBookingResponse>
      >(`${this.baseUrl}/${bookingId}/check-in`, request)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(() => error.error as IBaseResponseError),
        ),
      );
  }

  cancelBooking(
    bookingId: string,
  ): Observable<IApiResponse<void> | IBaseResponseError> {
    return this.http
      .patch<IApiResponse<void>>(`${this.baseUrl}/${bookingId}/cancel`, {})
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(() => error.error as IBaseResponseError),
        ),
      );
  }

  finishBooking(
    bookingId: string,
  ): Observable<IApiResponse<void> | IBaseResponseError> {
    return this.http
      .patch<
        IApiResponse<void>
      >(`${this.baseUrl}/${bookingId}/early-release`, {})
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(() => error.error as IBaseResponseError),
        ),
      );
  }
}
