import {
  Component,
  Injector,
  OnDestroy,
  ViewContainerRef,
} from "@angular/core";
import { BehaviorSubject, Subscription, take } from "rxjs";
import { ConfirmationModel } from "../+store/model";
import { CONFIRMATION_DIALOG_MAP } from "../constants";
import {
  IConfirmationConfig,
  IConfirmationDialogComponentMap,
  IConfirmationDialogMap,
  ILocalConfirmationConfig,
} from "../interfaces";

@Component({
  selector: "db-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"],
})
export class ConfirmationComponent implements OnDestroy {
  confirmationLabelDefault = $localize`:@@common|confirm:Confirm`;
  cancelLabelDefault = $localize`:@@common|cancel:Cancel`;

  disableCancel$$ = new BehaviorSubject<boolean>(false);
  disableConfirm$$ = new BehaviorSubject<boolean>(false);
  formValue$$ = new BehaviorSubject<any>(null);

  subscription = new Subscription();
  confirmationConfigs$ = this.confirmationModel.selectors.confirmationConfigs$;
  allDialogMap: IConfirmationDialogComponentMap = {};
  activeDialogs: IConfirmationDialogMap = {};
  private _lastDialogLength = 0;
  private timestamp: number = 0;

  constructor(
    private confirmationModel: ConfirmationModel,
    private viewContainerRef: ViewContainerRef,
    private injector: Injector,
  ) {
    this.subscription.add(
      confirmationModel.actions.listen.openGenericConfirmation$.subscribe(
        ({ timestamp }) => {
          this.timestamp = timestamp;
        },
      ),
    );

    this.subscription.add(
      confirmationModel.actions.listen.openConfirmationComponent$.subscribe(
        ({ name, timestamp }) => {
          this.timestamp = timestamp;
          this.__openConfirmationComponent(name);
        },
      ),
    );

    this.subscription.add(
      confirmationModel.actions.listen.confirmationResult$.subscribe(
        ({ name }) => {
          this.__closeConfirmationComponent(name);
        },
      ),
    );
  }

  __getConfirmationInstanceByName = (
    map: IConfirmationDialogMap,
    name: string,
  ) => {
    return map[name].instance;
  };

  __createComponent = (name: string) => {
    const ctor = this.allDialogMap[name];
    const injector = Injector.create({
      providers: [{ provide: ConfirmationComponent, useValue: this }],
      parent: this.injector,
    });
    return this.viewContainerRef.createComponent(ctor, { injector });
  };

  __closeConfirmationComponent = (name: string) => {
    const component = this.activeDialogs[name];
    if (!component) {
      return;
    }
    component.destroy();
  };

  __openConfirmationComponent = (name: string) => {
    const dialogMapArray = this.injector.get(
      CONFIRMATION_DIALOG_MAP,
    ) as IConfirmationDialogComponentMap[];
    if (this._lastDialogLength !== dialogMapArray.length) {
      this._lastDialogLength = dialogMapArray.length;
      this.allDialogMap = dialogMapArray.reduce((accMap, currMap) => {
        return Object.entries(currMap).reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, accMap);
      }, {} as IConfirmationDialogComponentMap);
    }
    this.activeDialogs[name] = this.__createComponent(name);

    const confirmation = this.__getConfirmationInstanceByName(
      this.activeDialogs,
      name,
    );
    if (!confirmation) {
      return;
    }

    const config: IConfirmationConfig = confirmation.config || {};
    const localConfig: ILocalConfirmationConfig = { ...config, name };
    this.confirmationModel.actions.dispatch.__addOpenConfirmation({
      config: localConfig,
    });
  };

  confirmClickHandler(
    confirmationConfig: ILocalConfirmationConfig,
    formValue: any = null,
  ): void {
    if (formValue) {
      return void this.confirmationModel.actions.dispatch.confirmationResult({
        name: confirmationConfig.name,
        result: true,
        data: confirmationConfig.data,
        formValue,
        timestamp: this.timestamp,
      });
    }

    this.formValue$$.pipe(take(1)).subscribe((formValue) => {
      this.confirmationModel.actions.dispatch.confirmationResult({
        name: confirmationConfig.name,
        result: true,
        data: confirmationConfig.data,
        formValue,
        timestamp: this.timestamp,
      });
    });
  }

  cancelClickHandler(
    confirmationConfig: ILocalConfirmationConfig,
    formValue: any = null,
  ): void {
    if (formValue) {
      return void this.confirmationModel.actions.dispatch.confirmationResult({
        name: confirmationConfig.name,
        result: false,
        data: confirmationConfig.data,
        formValue,
        timestamp: this.timestamp,
      });
    }

    this.formValue$$.pipe(take(1)).subscribe((formValue) => {
      this.confirmationModel.actions.dispatch.confirmationResult({
        name: confirmationConfig.name,
        result: false,
        data: confirmationConfig.data,
        formValue,
        timestamp: this.timestamp,
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
