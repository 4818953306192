import { Inject, Injectable } from "@angular/core";
import { createEffect } from "@ngrx/effects";
import { LocalStorageService } from "common-module";
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  of,
  tap,
} from "rxjs";
import { InternalizationModel } from "./model";
import { DEFAULT_LOCALE, PRIME_NG_TRANSLATION } from "../constants";
import { i18nService } from "../i18n.service";
import { DateAdapter } from "@angular/material/core";
import { AuthModel } from "auth-module";
import { AvailableLocales, LOCALE } from "shared";
import { identifyBrowserLanguage } from "./utils/identify-browser-language";
import { WindowService } from "global-module";
import { PrimeNgTranslation } from "../prime-ng-translation-utils";
import { PrimeNgTranslationService } from "../prime-ng-translation.service";
import { IUser } from "types";

@Injectable({
  providedIn: "root",
})
export class InternalizationEffects {
  loadLocale$ = createEffect(() => {
    const locale = this.localStorageService.getItem(LOCALE) || DEFAULT_LOCALE;
    this.materialDateAdapter.setLocale(locale);
    this.primeNgTranslationService.initTranslations(this.primeNgTranslation);
    return of(
      this.internalizationModel.actions.create.loadLocale({ locale: locale }),
    );
  });

  checkLanguage$ = createEffect(
    () =>
      combineLatest([
        this.internalizationModel.selectors.locale$.pipe(
          distinctUntilChanged(),
        ),
        this.authModel.user$.pipe(
          distinctUntilChanged(
            (a, b) =>
              a?.id === b?.id &&
              a?.initialDeviceLanguage === b?.initialDeviceLanguage &&
              a?.language === b?.language &&
              a?.isUsingSystemLanguage === b?.isUsingSystemLanguage,
          ),
        ),
      ]).pipe(
        debounceTime(0),
        filter((data): data is [string, IUser] => !!data[1]),
        tap(([locale, user]) => {
          const availableLanguagesArray = Object.values(
            AvailableLocales,
          ).filter((value) => typeof value === "string") as AvailableLocales[];
          const browserLanguage = identifyBrowserLanguage(
            this.windowService.getWindow().navigator,
          );
          const deviceLanguage = availableLanguagesArray.includes(
            browserLanguage as AvailableLocales,
          )
            ? browserLanguage
            : AvailableLocales.English;

          const noInitialDeviceLanguageOnFirstLogin =
            !user.initialDeviceLanguage && deviceLanguage;
          if (noInitialDeviceLanguageOnFirstLogin) {
            this.authModel.actions.dispatch.updateUser({
              userId: user.id,
              updates: {
                initialDeviceLanguage: deviceLanguage,
                avatarColor: user.avatarColor,
              }, // empty avatarColor changes the color with no need
              noNotification: true,
            });
            return;
          }

          const userLanguage = user.isUsingSystemLanguage
            ? deviceLanguage
            : availableLanguagesArray.includes(
                  user.language as AvailableLocales,
                )
              ? user.language
              : deviceLanguage;

          if (userLanguage && userLanguage != locale) {
            this.materialDateAdapter.setLocale(userLanguage);
            this.primeNgTranslationService.initTranslations(
              this.primeNgTranslation,
            );
            this.i18nService.changeLanguage(userLanguage);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    @Inject(PRIME_NG_TRANSLATION)
    private primeNgTranslation: PrimeNgTranslation,
    private primeNgTranslationService: PrimeNgTranslationService,
    private localStorageService: LocalStorageService,
    private i18nService: i18nService,
    private internalizationModel: InternalizationModel,
    private authModel: AuthModel,
    private materialDateAdapter: DateAdapter<any>,
    private windowService: WindowService,
  ) {}
}
