import { AvailableLocales } from "../enums/available-locales.enum";

export const availableLanguages = [
  {
    title: "Deutsch (Deutschland)",
    value: AvailableLocales.German,
  },
  {
    title: "English (US)",
    value: AvailableLocales.English,
  },
  {
    title: "Español (España)",
    value: AvailableLocales.Spanish,
  },
  {
    title: "Français (France)",
    value: AvailableLocales.French,
  },
  {
    title: "Italiano (Italia)",
    value: AvailableLocales.Italian,
  },
  {
    title: "Nederlands (Nederland)",
    value: AvailableLocales.Dutch,
  },
  {
    title: "Українська (Україна)",
    value: AvailableLocales.Ukrainian,
  },
  {
    title: "Magyar (Magyarország)",
    value: AvailableLocales.Hungarian,
  },
  {
    title: "Čeština (Česká republika)",
    value: AvailableLocales.Czech,
  },
] as const;
