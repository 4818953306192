import { IBookingCheckinInfo } from "types";
import { IBookingTimeParams, IBookingViewProps } from "./bookings/booking-view";
import { DeskAreaType } from "../enums";

export type IUserSpaceViewBooking = Pick<
  IBookingViewProps,
  "zoneItemId" | "id" | "workspaceId" | "zoneId"
> &
  IBookingCheckinInfo &
  IBookingTimeParams;

export interface IUserSpaceView {
  isOccupied: boolean;
  isDedicatedResource: boolean;
  isFavoriteResource: boolean;
  resourceType: DeskAreaType;
  zoneId: string;
  zoneItemId: number;
  zoneName: string;
  zoneItemName: string;
  floorId: string;
  officeId: string;
  bookings: IUserSpaceViewBooking[];
  guestBookings: IBookingViewProps[];
}
