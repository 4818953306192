<p-calendar
  class="date-range-picker full-width-inputtext"
  [class.no-border]="noBorder"
  [class.icon-in-container]="iconInContainer"
  [showIcon]="true"
  [ngModel]="dateRange"
  selectionMode="range"
  [readonlyInput]="true"
  icon="d-calendar-icon"
  (ngModelChange)="dateRangeChangedHandler($event)"
  [placeholder]="placeholder"
>
</p-calendar>
