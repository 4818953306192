import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";

export enum NoticeType {
  Info,
  Error,
}

@Component({
  selector: "db-notice",
  templateUrl: "./notice.component.html",
  styleUrls: ["./notice.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class NoticeComponent {
  NoticeType = NoticeType;

  @Input() type = NoticeType.Info;
  @Input() noMargin = false;
  @Input() noPadding = false;
  @Input() padding: 16 | 8 = 16;
  @Input() showInfoIcon = false;
  @Input() dataTestId = "";
  @Input() alignCenter = false;
  @Input() textLeft = false;
}
