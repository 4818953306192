import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  IOfficeEventsState,
  selectAllOfficeEvents,
  selectOfficeEventsTotalCount,
} from "./reducer";
import { OFFICE_EVENTS_STORE_NAME } from "../../constants";
import { GridDatePeriodType } from "types";
import { sortByDateTime } from "shared";

const getState = createFeatureSelector<IOfficeEventsState>(
  OFFICE_EVENTS_STORE_NAME,
);

const getOfficeEvents = createSelector(
  getState,
  (state: IOfficeEventsState) => {
    const events = selectAllOfficeEvents(state);
    const sortedEvent = events
      .slice()
      .sort((a, b) =>
        sortByDateTime(
          a.startTime,
          b.startTime,
          state.listDatePeriodType === GridDatePeriodType.PAST ? "desc" : "asc",
        ),
      );
    return sortedEvent;
  },
);
const getOfficeEventsTotalCount = createSelector(
  getState,
  (state: IOfficeEventsState) => selectOfficeEventsTotalCount(state),
);
const getOnlyInvitedOfficeEvents = createSelector(
  getState,
  (state: IOfficeEventsState) =>
    selectAllOfficeEvents(state).filter(
      (event) => event.attendance?.me?.isInvited,
    ),
);
const getIsListLoading = createSelector(
  getState,
  (state: IOfficeEventsState) => state.isListLoading,
);

const getSelectedOfficeEvent = createSelector(
  getState,
  (state: IOfficeEventsState) => state.selectedEvent,
);
const getIsSelectedEventLoading = createSelector(
  getState,
  (state: IOfficeEventsState) => state.isSelectedEventLoading,
);
const getInvitableUserGroups = createSelector(
  getState,
  (state: IOfficeEventsState) => state.invitableUserGroups,
);
const getInvitableUsers = createSelector(
  getState,
  (state: IOfficeEventsState) => state.invitableUsers,
);
const getTotalInvitableUsers = createSelector(
  getState,
  (state: IOfficeEventsState) => state.totalInvitableUsers,
);

export const officeEventsSelectors = {
  officeEvents: getOfficeEvents,
  officeEventsTotalCount: getOfficeEventsTotalCount,
  officeEvent: getSelectedOfficeEvent,
  isListLoading: getIsListLoading,
  isSelectedEventLoading: getIsSelectedEventLoading,
  onlyInvitedOfficeEvents: getOnlyInvitedOfficeEvents,
  invitableUserGroups: getInvitableUserGroups,
  invitableUsers: getInvitableUsers,
  totalInvitableUsers: getTotalInvitableUsers,
};
