import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  inject,
} from "@angular/core";
import { fromEvent, debounceTime, Subscription } from "rxjs";
import { safeRequestAnimationFrame } from "shared";

@Directive({
  selector: "[dbFullVh]",
  standalone: true,
})
export class FullVhDirective implements OnDestroy {
  sub = new Subscription();

  @HostBinding("style.height") height = "100vh";
  @Input() subtractPx: number | string = 0;
  @Output() resized = new EventEmitter<number>();

  changeDetectorRef = inject(ChangeDetectorRef);
  nativeElement: HTMLElement;
  updateScheduled = false;

  constructor(private elementRef: ElementRef) {
    this.nativeElement = this.elementRef.nativeElement;

    this.sub.add(
      fromEvent(window, "resize")
        .pipe(debounceTime(500))
        .subscribe(() => this.setHeight()),
    );

    this.setHeight();
  }

  private setHeight() {
    if (!this.nativeElement || this.updateScheduled) {
      return;
    }
    this.updateScheduled = true;
    safeRequestAnimationFrame(() => {
      this.updateScheduled = false;
      const boundingClientRect = this.nativeElement.getBoundingClientRect();
      const bodyClientHeight = window.innerHeight;
      const newHeight =
        bodyClientHeight - 1 - (boundingClientRect.top + +this.subtractPx);
      this.height = `${newHeight}px`;
      this.resized.emit(newHeight);
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
