import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FeatureAccessType, IUserInfo } from "types";
import { hasAccessToPageFactory } from "shared";
import { MenuItem } from "primeng/api";
import { ExecPipe } from "shared-pipes";
import { MenuComponent, UserInfoComponent } from "db-ui";
import { GlobalModule } from "global-module";
import { MenuItemWithChildrenComponent } from "../menu-item-with-children/menu-item-with-children.component";
import { MenuItemComponent } from "../menu-item/menu-item.component";
import {
  menuItemLabels,
  publicApiKeyRestrictedMessage,
} from "./admin-navigation-translations";
import { FeatureAccessDirective } from "auth-module";

@Component({
  selector: "db-admin-navigation-mpps",
  templateUrl: "./admin-navigation-mpps.component.html",
  styleUrl: "./admin-navigation-mpps.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MenuItemComponent,
    MenuItemWithChildrenComponent,
    GlobalModule,
    UserInfoComponent,
    MenuComponent,
    ExecPipe,
    FeatureAccessDirective,
  ],
})
export class AdminNavigationMppsComponent implements OnChanges {
  @Input() isExpanded = false;
  @Input() officeId!: string | null;
  @Input() companyId!: string;

  @Input() hasPlanningAccess = false;
  @Input() hasBookingAccess = false;
  @Input() hasIntegrations = false;
  @Input() publicApiKeysPageAllowed = false;

  @Input() isUserAdmin = false;
  @Input() isUserManager = false;
  @Input() isUserGroupManager = false;
  @Input() isUserGuest = false;
  @Input() isUserOfficeAdmin = false;
  @Input() isUserRegularUser = false;
  @Input() isUserDeskbirdAdmin = false;
  @Input() userRoleCompanyPageRestrictions: string[] | null = null;
  @Input() cateringAndServicesEnabled!: boolean;
  @Input() kioskModeEnabled!: boolean;
  @Input() officeEquipmentEnabled!: boolean;
  @Input() hybridWorkPoliciesEnabled!: boolean;

  @Input() userProfileImageUrl: string | null | undefined;
  @Input() userInfo!: IUserInfo;

  @Input() adminPartPrefix!: string;
  @Input() clientPartPrefix!: string;
  @Input() hideIcons = false;

  @Output() navigateToProfile = new EventEmitter<void>();
  @Output() signOut = new EventEmitter<void>();

  publicApiKeysTooltipLabel(): string {
    return this.publicApiKeysPageAllowed ? "" : publicApiKeyRestrictedMessage;
  }

  menuItemLabels = menuItemLabels;

  userMenuItems: MenuItem[] = [
    {
      icon: "user-icon",
      label: $localize`:@@navigation-module|client-navigation|profile:Profile settings`,
      command: () => this.navigateToProfile.emit(),
    },
    {
      icon: "sign-out-icon",
      label: $localize`:@@navigation-module|client-navigation|sign out:Sign out`,
      command: () => this.signOut.emit(),
    },
  ];

  hasAccessToPage: (args: string[] | null) => boolean = () => true;

  readonly FeatureAccessType = FeatureAccessType;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      typeof changes["userProfileImageUrl"]?.currentValue === "string" &&
      this.userInfo
    ) {
      this.userInfo = {
        ...this.userInfo,
        profileImage: this.userProfileImageUrl as string,
      };
    }

    if (changes["userRoleCompanyPageRestrictions"]) {
      this.hasAccessToPage = hasAccessToPageFactory(
        this.userRoleCompanyPageRestrictions,
      );
    }
  }
}
