export const agentHas = (keyword: string): boolean => {
  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
};

export const isSafari = (): boolean => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (!!(window as any).ApplePaySetupFeature || !!(window as any).safari) &&
    agentHas("Safari") &&
    !agentHas("Chrome") &&
    !agentHas("CriOS")
  );
};

export const isFirefox = (): boolean => {
  return agentHas("Firefox") || agentHas("FxiOS") || agentHas("Focus");
};
