export const countryPhoneCodes = [
  { countryCode: "DZ", value: "213" },
  { countryCode: "AD", value: "376" },
  { countryCode: "AO", value: "244" },
  { countryCode: "AI", value: "1264" },
  { countryCode: "AG", value: "1268" },
  { countryCode: "AR", value: "54" },
  { countryCode: "AM", value: "374" },
  { countryCode: "AW", value: "297" },
  { countryCode: "AU", value: "61" },
  { countryCode: "AT", value: "43" },
  { countryCode: "AZ", value: "994" },
  { countryCode: "BS", value: "1242" },
  { countryCode: "BH", value: "973" },
  { countryCode: "BD", value: "880" },
  { countryCode: "BB", value: "1246" },
  { countryCode: "BY", value: "375" },
  { countryCode: "BE", value: "32" },
  { countryCode: "BZ", value: "501" },
  { countryCode: "BJ", value: "229" },
  { countryCode: "BM", value: "1441" },
  { countryCode: "BT", value: "975" },
  { countryCode: "BO", value: "591" },
  { countryCode: "BA", value: "387" },
  { countryCode: "BW", value: "267" },
  { countryCode: "BR", value: "55" },
  { countryCode: "BN", value: "673" },
  { countryCode: "BG", value: "359" },
  { countryCode: "BF", value: "226" },
  { countryCode: "BI", value: "257" },
  { countryCode: "KH", value: "855" },
  { countryCode: "CM", value: "237" },
  { countryCode: "CA", value: "1" },
  { countryCode: "CV", value: "238" },
  { countryCode: "KY", value: "1345" },
  { countryCode: "CF", value: "236" },
  { countryCode: "CL", value: "56" },
  { countryCode: "CN", value: "86" },
  { countryCode: "CO", value: "57" },
  { countryCode: "KM", value: "269" },
  { countryCode: "CG", value: "242" },
  { countryCode: "CK", value: "682" },
  { countryCode: "CR", value: "506" },
  { countryCode: "HR", value: "385" },
  { countryCode: "CU", value: "53" },
  { countryCode: "CY", value: "90392" },
  { countryCode: "CY", value: "357" },
  { countryCode: "CZ", value: "42" },
  { countryCode: "DK", value: "45" },
  { countryCode: "DJ", value: "253" },
  { countryCode: "DM", value: "1809" },
  { countryCode: "DO", value: "1809" },
  { countryCode: "EC", value: "593" },
  { countryCode: "EG", value: "20" },
  { countryCode: "SV", value: "503" },
  { countryCode: "GQ", value: "240" },
  { countryCode: "ER", value: "291" },
  { countryCode: "EE", value: "372" },
  { countryCode: "ET", value: "251" },
  { countryCode: "FK", value: "500" },
  { countryCode: "FO", value: "298" },
  { countryCode: "FJ", value: "679" },
  { countryCode: "FI", value: "358" },
  { countryCode: "FR", value: "33" },
  { countryCode: "GF", value: "594" },
  { countryCode: "PF", value: "689" },
  { countryCode: "GA", value: "241" },
  { countryCode: "GM", value: "220" },
  { countryCode: "GE", value: "7880" },
  { countryCode: "DE", value: "49" },
  { countryCode: "GH", value: "233" },
  { countryCode: "GI", value: "350" },
  { countryCode: "GR", value: "30" },
  { countryCode: "GL", value: "299" },
  { countryCode: "GD", value: "1473" },
  { countryCode: "GP", value: "590" },
  { countryCode: "GU", value: "671" },
  { countryCode: "GT", value: "502" },
  { countryCode: "GN", value: "224" },
  { countryCode: "GW", value: "245" },
  { countryCode: "GY", value: "592" },
  { countryCode: "HT", value: "509" },
  { countryCode: "HN", value: "504" },
  { countryCode: "HK", value: "852" },
  { countryCode: "HU", value: "36" },
  { countryCode: "IS", value: "354" },
  { countryCode: "IN", value: "91" },
  { countryCode: "ID", value: "62" },
  { countryCode: "IR", value: "98" },
  { countryCode: "IQ", value: "964" },
  { countryCode: "IE", value: "353" },
  { countryCode: "IL", value: "972" },
  { countryCode: "IT", value: "39" },
  { countryCode: "JM", value: "1876" },
  { countryCode: "JP", value: "81" },
  { countryCode: "JO", value: "962" },
  { countryCode: "KZ", value: "7" },
  { countryCode: "KE", value: "254" },
  { countryCode: "KI", value: "686" },
  { countryCode: "KP", value: "850" },
  { countryCode: "KR", value: "82" },
  { countryCode: "KW", value: "965" },
  { countryCode: "KG", value: "996" },
  { countryCode: "LA", value: "856" },
  { countryCode: "LV", value: "371" },
  { countryCode: "LB", value: "961" },
  { countryCode: "LS", value: "266" },
  { countryCode: "LR", value: "231" },
  { countryCode: "LY", value: "218" },
  { countryCode: "LI", value: "417" },
  { countryCode: "LT", value: "370" },
  { countryCode: "LU", value: "352" },
  { countryCode: "MO", value: "853" },
  { countryCode: "MK", value: "389" },
  { countryCode: "MG", value: "261" },
  { countryCode: "MW", value: "265" },
  { countryCode: "MY", value: "60" },
  { countryCode: "MV", value: "960" },
  { countryCode: "ML", value: "223" },
  { countryCode: "MT", value: "356" },
  { countryCode: "MH", value: "692" },
  { countryCode: "MQ", value: "596" },
  { countryCode: "MR", value: "222" },
  { countryCode: "YT", value: "269" },
  { countryCode: "MX", value: "52" },
  { countryCode: "FM", value: "691" },
  { countryCode: "MD", value: "373" },
  { countryCode: "MC", value: "377" },
  { countryCode: "MN", value: "976" },
  { countryCode: "MS", value: "1664" },
  { countryCode: "MA", value: "212" },
  { countryCode: "MZ", value: "258" },
  { countryCode: "MN", value: "95" },
  { countryCode: "NA", value: "264" },
  { countryCode: "NR", value: "674" },
  { countryCode: "NP", value: "977" },
  { countryCode: "NL", value: "31" },
  { countryCode: "NC", value: "687" },
  { countryCode: "NZ", value: "64" },
  { countryCode: "NI", value: "505" },
  { countryCode: "NE", value: "227" },
  { countryCode: "NG", value: "234" },
  { countryCode: "NU", value: "683" },
  { countryCode: "NF", value: "672" },
  { countryCode: "NP", value: "670" },
  { countryCode: "NO", value: "47" },
  { countryCode: "OM", value: "968" },
  { countryCode: "PW", value: "680" },
  { countryCode: "PA", value: "507" },
  { countryCode: "PG", value: "675" },
  { countryCode: "PY", value: "595" },
  { countryCode: "PE", value: "51" },
  { countryCode: "PH", value: "63" },
  { countryCode: "PL", value: "48" },
  { countryCode: "PT", value: "351" },
  { countryCode: "PR", value: "1787" },
  { countryCode: "QA", value: "974" },
  { countryCode: "RE", value: "262" },
  { countryCode: "RO", value: "40" },
  { countryCode: "RU", value: "7" },
  { countryCode: "RW", value: "250" },
  { countryCode: "SM", value: "378" },
  { countryCode: "ST", value: "239" },
  { countryCode: "SA", value: "966" },
  { countryCode: "SN", value: "221" },
  { countryCode: "CS", value: "381" },
  { countryCode: "SC", value: "248" },
  { countryCode: "SL", value: "232" },
  { countryCode: "SG", value: "65" },
  { countryCode: "SK", value: "421" },
  { countryCode: "SI", value: "386" },
  { countryCode: "SB", value: "677" },
  { countryCode: "SO", value: "252" },
  { countryCode: "ZA", value: "27" },
  { countryCode: "ES", value: "34" },
  { countryCode: "LK", value: "94" },
  { countryCode: "SH", value: "290" },
  { countryCode: "KN", value: "1869" },
  { countryCode: "SC", value: "1758" },
  { countryCode: "SD", value: "249" },
  { countryCode: "SR", value: "597" },
  { countryCode: "SZ", value: "268" },
  { countryCode: "SE", value: "46" },
  { countryCode: "CH", value: "41" },
  { countryCode: "SI", value: "963" },
  { countryCode: "TW", value: "886" },
  { countryCode: "TJ", value: "7" },
  { countryCode: "TH", value: "66" },
  { countryCode: "TG", value: "228" },
  { countryCode: "TO", value: "676" },
  { countryCode: "TT", value: "1868" },
  { countryCode: "TN", value: "216" },
  { countryCode: "TR", value: "90" },
  { countryCode: "TM", value: "7" },
  { countryCode: "TM", value: "993" },
  { countryCode: "TC", value: "1649" },
  { countryCode: "TV", value: "688" },
  { countryCode: "UG", value: "256" },
  { countryCode: "GB", value: "44" },
  { countryCode: "UA", value: "380" },
  { countryCode: "AE", value: "971" },
  { countryCode: "UY", value: "598" },
  { countryCode: "US", value: "1" },
  { countryCode: "UZ", value: "7" },
  { countryCode: "VU", value: "678" },
  { countryCode: "VA", value: "379" },
  { countryCode: "VE", value: "58" },
  { countryCode: "VN", value: "84" },
  { countryCode: "VG", value: "84" },
  { countryCode: "VI", value: "84" },
  { countryCode: "WF", value: "681" },
  { countryCode: "YE", value: "969" },
  { countryCode: "YE", value: "967" },
  { countryCode: "ZM", value: "260" },
  { countryCode: "ZW", value: "263" },
];
