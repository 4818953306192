import { Environment, firebaseSandbox404 } from "common-module";

// Used to dynamically set the sentry sample rate for routes beginning with the key
const sentryDynamicSampleRate: { [key: string]: number } = {};

export const environment = {
  env: Environment.SANDBOX_404,
  firebase: firebaseSandbox404,
  apiURL: "https://api.404.deskbird.com",
  appURL: "https://404.deskbird.com",
  publicApiUrl: "https://connect.404.deskbird.com",
  backendSlackLoginUrl:
    "https://europe-west3-deskbird-prod.cloudfunctions.net/slack/internal/link",
  enableServiceWorker: false,

  // Sentry configuration
  sentrySampleRate: 0,
  sentryRelease:
    "deskbird-webapp-staging@e15b76776f6494dc8d40aa50f7d400f977d56a67",
  sentryDynamicSampleRate,
  // End Sentry configuration

  // Google Tag Manager
  gtmId: "GTM-MG6ZS9M",
  gtmAuth: "PRVGDohRlSYSa0l2nZdeCA",
  gtmPreview: "env-30",
  // End Google Tag Manager

  // Customer.io configuration
  customerIoSiteId: "5f3f33f261669d602e3f", // deskbird [Staging]
  customerIoUseInAppMessages: true,
  // End Customer.io configuration
};
