<db-progress-bar
  [dbHidden]="!showProgressLoader"
  class="main-loader"
></db-progress-bar>

<div class="header-container">
  <div class="title">
    @if (backAction.observed) {
      <p-button
        (click)="backAction.emit()"
        class="db-back-button"
        icon="pi pi-arrow-left"
        styleClass="p-button-rounded p-button-text"
      >
      </p-button>
    }

    <h2>{{ title }}</h2>
  </div>
  <div
    class="title-template"
    [ngClass]="{ 'right-side': rightSideHeaderTemplate }"
  >
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </div>
</div>
<div class="content-container">
  <ng-content></ng-content>
</div>
