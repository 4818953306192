import { Component, inject, OnDestroy } from "@angular/core";
import { AuthModel } from "auth-module";
import { RouterModel } from "router-module";
import { interval, race, startWith, Subscription, withLatestFrom } from "rxjs";
import { WindowService } from "../window.service";
import { ButtonComponent } from "db-ui";
import { GlobalViewComponent } from "../global-view";

@Component({
  selector: "db-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.scss"],
  standalone: true,
  imports: [ButtonComponent, GlobalViewComponent],
})
export class MaintenanceComponent implements OnDestroy {
  private readonly windowService = inject(WindowService);
  private readonly authModel = inject(AuthModel);
  private readonly routerModel = inject(RouterModel);
  private readonly sub = new Subscription();

  constructor() {
    this.sub.add(
      interval(10000)
        .pipe(startWith(null))
        .subscribe(() => {
          this.authModel.actions.dispatch.refetchAuthData();
        }),
    );

    this.sub.add(
      race(
        this.authModel.actions.listen.refetchAuthDataSuccess$,
        this.authModel.actions.listen.refetchAuthDataFailure$,
      )
        .pipe(withLatestFrom(this.authModel.isLoggedIn$))
        .subscribe(([result, isLogged]) => {
          if ("error" in result && result.error.status === 503) {
            return;
          }
          this.routerModel.actions.dispatch.navigate({
            commands: [isLogged ? "/default" : "/login"],
            extras: { queryParamsHandling: "preserve" },
          });
        }),
    );
  }

  visitWebsiteClick(): void {
    this.windowService?.window?.open("https://www.deskbird.com/", "_blank");
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
