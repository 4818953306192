import { Component, Inject, Input, OnInit, Optional } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { CLIENT_PART_PREFIX, ADMIN_PART_PREFIX } from "../../constants";
import { IRedirectData } from "../../interfaces";

@Component({
  selector: "db-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit {
  @Input() redirectTo: string | undefined;
  @Input() isAbsoluteRedirect = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Optional() @Inject(CLIENT_PART_PREFIX) public clientPartPrefix: string,
    @Optional() @Inject(ADMIN_PART_PREFIX) public adminPartPrefix: string,
  ) {}

  ngOnInit(): void {
    const data = this.activatedRoute.snapshot.data as Partial<IRedirectData>;
    const navigationExtras = data.navigationExtras;
    const redirectTo = data.redirectTo || this.redirectTo || null;
    const isAbsoluteRedirect =
      !!data.absoluteRedirect || this.isAbsoluteRedirect;

    // NOTE:
    // We are using directly the router here because otherwise we will have a cyclic dependency also
    // we when providing relativeTo we are setting activatedRoute as property
    // which is a complex object and ngrx-store-freeze throws an error

    const path = this.router.url;
    const isAdminAppEnv =
      this.adminPartPrefix.length === 1
        ? path.startsWith(this.adminPartPrefix) &&
          !path.startsWith(this.clientPartPrefix)
        : path.startsWith(this.adminPartPrefix);

    if (redirectTo === null) {
      this.router.navigateByUrl(
        isAdminAppEnv ? this.adminPartPrefix : this.clientPartPrefix,
        { replaceUrl: true },
      );
      return;
    }

    if (isAbsoluteRedirect) {
      const routeParamsNames = [];
      let result: any[] | null = [];
      const re = /:([^\/]+)/g;
      while ((result = re.exec(redirectTo))) {
        routeParamsNames.push(result[1]);
      }
      let allQueryParams: Record<string, string> = {};
      let snapshot: ActivatedRouteSnapshot | null =
        this.activatedRoute.snapshot;
      while (snapshot) {
        allQueryParams = { ...allQueryParams, ...snapshot.params };
        snapshot = snapshot.parent;
      }
      const routeParamsValues = routeParamsNames.map(
        (name) => allQueryParams[name],
      );
      const redirectPath = routeParamsNames.reduce(
        (acc: string, name: string, index: number) =>
          acc.replace(`:${name}`, routeParamsValues[index]),
        redirectTo,
      );

      this.router.navigate([redirectPath], {
        ...navigationExtras,
        replaceUrl: true,
      });
      return;
    }

    this.router.navigate([redirectTo], {
      ...navigationExtras,
      replaceUrl: true,
      relativeTo: this.activatedRoute,
    });
  }
}
