<div
  [attr.data-testId]="dataTestId"
  id="notice"
  [ngClass]="{
    info: type === NoticeType.Info,
    error: type === NoticeType.Error,
    'with-margin': !noMargin,
    'd-flex align-items-center align-content-center': alignCenter,
  }"
  [style.text-align]="textLeft ? 'left' : undefined"
  [style.padding.px]="noPadding ? 0 : padding"
>
  @if (showInfoIcon) {
    <img
      class="icon-size-20 mr-16"
      src="/assets/icons/info-message-default.svg"
    />
  }
  <ng-content></ng-content>
</div>
