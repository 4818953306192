@if (!isFullDay) {
  {{
    startTime
      | formatDateTime
        : ({ hourFormat: hourFormat } | getTimeFormatStringForUser)
        : timeZone
  }}
  -
  {{
    endTime
      | formatDateTime
        : ({ hourFormat: hourFormat } | getTimeFormatStringForUser)
        : timeZone
  }}
  {{ startTime | timeZoneAbbreviation: timeZone! }}
} @else {
  <ng-container i18n="@@booking-module|full-day">Full day</ng-container>
}
