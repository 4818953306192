@if (otherOfficesList && otherOfficesList.length > 0) {
  <p-splitButton
    [model]="otherOfficesList"
    [ngClass]="{ 'is-primary-office-closed': isOfficeClosed }"
    styleClass="p-button-outlined p-button-rounded"
    menuStyleClass="office-select-button-menu"
    appendTo="body"
    [disabled]="isBlocked"
    tooltipStyleClass="office-select-button-tooltip"
    [pTooltip]="isMultiOfficeClosedTooltipMessage"
    tooltipPosition="top"
    (onClick)="primaryOfficeSelectHandler()"
    outlined
  >
    <ng-template pTemplate="content">
      <div class="office-label-wrapper">
        <span class="small-text-bold">{{ primaryOfficeButtonLabel }}</span>
        @if (isOfficeClosed) {
          <span i18n="@@common|closed" class="very-small-text">Closed</span>
        }
      </div>
    </ng-template>
  </p-splitButton>
}
@if (!otherOfficesList || otherOfficesList.length === 0) {
  <db-button
    [buttonType]="'outlined'"
    [width]="'fit-content'"
    [disabled]="isBlocked || isOfficeClosed"
    [isLoading]="isBlocked"
    tooltipStyleClass="office-select-button-tooltip"
    tooltipPosition="top"
    [pTooltip]="isSingleOfficeClosedTooltipMessage"
    (click)="primaryOfficeSelectHandler()"
  >
    {{ primaryOfficeButtonLabel }}
  </db-button>
}
