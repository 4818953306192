import { Injectable } from "@angular/core";
import { connectSelectors, connectBundles } from "ngrx-action-bundles";
import { props } from "@ngrx/store";
import { globalLoaderBundles } from "./bundles";
import { globalLoaderSelectors } from "./selectors";

@Injectable({
  providedIn: "root",
})
export class GlobalLoaderModel {
  actions = connectBundles(globalLoaderBundles);
  selectors = connectSelectors(globalLoaderSelectors);
}
