import { IUser } from "types";

const defaultValues = [
  "pending_post_registration_sync",
  "deskbird_firstName",
  "deskbird_lastName",
  "/",
];
export function isUserFirstAndLastNameInputRequired(
  user: IUser | null,
): boolean {
  return (
    !!user &&
    (defaultValues.includes(user.firstName) ||
      defaultValues.includes(user.lastName))
  );
}
