import { DeskAreaType, IBooking } from "types";

export interface IUserSpace {
  isDedicatedResource: boolean;
  isFavoriteResource: boolean;
  isOccupied: boolean;
  zoneItemName: string;
  zoneId: string;
  zoneName: string;
  zoneItemId: number;
  floorId?: string;
  bookings?: IBooking[];
  guestBookings?: IBooking[];
  officeId: string;
  resourceType: DeskAreaType;
}
