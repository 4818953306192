@if (isLoadingCoordinates || errorLoadingApi) {
  <div id="loading-container">
    @if (!errorLoadingApi) {
      <db-progress-spinner></db-progress-spinner>
    }
    @if (errorLoadingApi) {
      <div>Error loading google maps!</div>
    }
  </div>
}
@if (apiMapsApiLoaded$ | async) {
  <div [dbHidden]="isLoadingCoordinates || errorLoadingApi">
    <google-map
      #googleMap
      [height]="height"
      [width]="width"
      [options]="options"
      (mapClick)="mapClickHandler($event)"
    >
      @if (options?.center) {
        <map-marker
          [position]="$any(options!.center)"
          [options]="{ draggable: false, icon: '/assets/icons/red-pin.svg' }"
        >
        </map-marker>
      }
    </google-map>
  </div>
}
