import { Directive, HostListener, Input } from "@angular/core";
import { Router, NavigationExtras, UrlTree } from "@angular/router";

function countRelativeNavigationsRegex(path: string) {
  const regex = /\.\.\/?/g;
  const matches = path.match(regex);
  return matches ? matches.length : 0;
}

@Directive({
  selector: "[dbRelativeRouterLink]",
  standalone: true,
})
export class RelativeRouterLinkDirective {
  @Input() dbRelativeRouterLink!: any[] | string;

  constructor(private router: Router) {}

  @HostListener("click") clickHandler() {
    if (this.dbRelativeRouterLink) {
      const currentUrl: string = this.router.url;
      const baseUrl: string = currentUrl.split("?")[0]; // Extract base URL without query params

      const navigationExtras: NavigationExtras = {
        relativeTo: null, // Set to null for root-level navigation
        queryParamsHandling: "merge",
        preserveFragment: true,
        state: {
          outlets: this.getRouterOutlets(currentUrl),
        },
      };

      const relativeUrlTree: UrlTree = this.router.createUrlTree(
        Array.isArray(this.dbRelativeRouterLink)
          ? this.dbRelativeRouterLink
          : [this.dbRelativeRouterLink],
        navigationExtras,
      );

      let base = baseUrl;
      const relativePathNavigationCount = countRelativeNavigationsRegex(
        typeof this.dbRelativeRouterLink === "string"
          ? this.dbRelativeRouterLink
          : this.dbRelativeRouterLink.join("/"),
      );
      for (let i = 0; i < relativePathNavigationCount; i++) {
        base = base.split("/").slice(0, -1).join("/");
      }
      const relativeUrl: string = `${base}${relativeUrlTree.toString()}`;

      this.router.navigateByUrl(relativeUrl, navigationExtras);
    }
  }

  private getRouterOutlets(currentUrl: string): { [key: string]: any } {
    const outlets: { [key: string]: any } = {};

    const parsedUrl = this.router.parseUrl(currentUrl);
    const outletKeys = Object.keys(parsedUrl.root.children);

    outletKeys.forEach((key: string) => {
      outlets[key] = parsedUrl.root.children[key];
    });

    return outlets;
  }
}
