import { Inject, Injectable, Optional } from "@angular/core";
import { NavigationExtras, Params } from "@angular/router";
import { ADMIN_PART_PREFIX, CLIENT_PART_PREFIX } from "common-module";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";
import { map, Observable } from "rxjs";
import { routerBundles } from "./bundles";
import { routerSelectors } from "./selectors";

@Injectable({
  providedIn: "root",
})
export class RouterModel {
  selectors = connectSelectors(routerSelectors);
  actions = connectBundles(routerBundles);

  isClientAppEnv$!: Observable<boolean>;
  isAdminAppEnv$!: Observable<boolean>;

  currentRouteParams$: Observable<Params>;
  allRouteParams$: Observable<Params>;
  allRouteData$: Observable<{ [key: string | number]: any }>;

  constructor(
    @Optional() @Inject(CLIENT_PART_PREFIX) public clientPartPrefix: string,
    @Optional() @Inject(ADMIN_PART_PREFIX) public adminPartPrefix: string,
  ) {
    if (!clientPartPrefix || !adminPartPrefix) {
      console.warn(
        "DBCommonModule is missing or it has incomplete config! client/admin part prefix are missing. Either add/edit the DBCommonModule or use the appropriate providers!",
      );
    }

    this.isAdminAppEnv$ = this.selectors.path$.pipe(
      map((path) =>
        adminPartPrefix.length === 1
          ? path.startsWith(adminPartPrefix) &&
            !path.startsWith(clientPartPrefix)
          : path.startsWith(adminPartPrefix),
      ),
    );
    this.isClientAppEnv$ = this.selectors.path$.pipe(
      map((path) =>
        clientPartPrefix.length === 1
          ? path.startsWith(clientPartPrefix) &&
            !path.startsWith(adminPartPrefix)
          : path.startsWith(clientPartPrefix),
      ),
    );

    this.currentRouteParams$ = this.selectors.hierarchyRouteParams$.pipe(
      map((rps) => (rps["primary"] && rps["primary"][0]) || {}),
    );
    this.allRouteParams$ = this.selectors.hierarchyRouteParams$.pipe(
      map((rps) =>
        (rps["primary"] || []).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      ),
    );
    this.allRouteData$ = this.selectors.hierarchyData$.pipe(
      map((rd) =>
        (rd["primary"] || []).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      ),
    );
  }

  navigationOutlet(
    outletName: string,
    commands: any[] = [],
    extras?: NavigationExtras,
    primaryRoute = "",
  ) {
    this.actions.dispatch.navigate({
      commands: ["", { outlets: { [outletName]: commands } }],
      extras,
    });

    // TODO - this is a hack to fix the issue with the router not navigating to the primary route when the outlet is empty
    if (primaryRoute) {
      setTimeout(() => {
        this.actions.dispatch.navigate({ commands: [primaryRoute], extras });
      }, 0);
    }
  }

  getAllRouteParamsForAuxiliary$(name: string) {
    return this.selectors.hierarchyRouteParams$.pipe(
      map((rps) =>
        (rps[name] || []).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      ),
    );
  }

  getAllRouteDataForAuxiliary$(name: string) {
    return this.selectors.hierarchyData$.pipe(
      map((rd) =>
        (rd[name] || []).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
      ),
    );
  }
}
