<p-progressSpinner
  class="prime-ng-diameter custom-color-dash"
  [styleClass]="styleClass"
  [style]="style"
  [strokeWidth]="strokeWidth"
  [fill]="fill"
  [animationDuration]="animationDuration"
  [strokeWidth]="strokeWidth"
  [style.--primeng-progress-spinner-diameter]="diameter"
  [style.--primeng-progress-spinner-dash-color]="color"
></p-progressSpinner>
