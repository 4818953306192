import { UserStatus, UserRole, TimeFormat } from "../enums";
import { IUserFollowingStatus } from "./user-following-status";
import { IUserDedicatedResource } from "./user-dedicated-resource";
import { IUserFavoriteResource } from "./user-favorite-resource";
import { IOfficeRole } from "./office-role";

export interface IUser extends IUserFollowingStatus {
  id: string;
  uuid: string;
  firebaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  loginState: number;
  avatarColor: string;
  color: string;
  avatarUrl: string;
  userGroupIds: string[];
  profileImage: string;
  companyId: string;
  expirationDate: number;
  status: UserStatus;
  role: UserRole;
  signup: boolean;
  language?: string;
  initialDeviceLanguage?: string;
  isUsingSystemLanguage: boolean;
  allowNameChange?: boolean;
  allowPasswordReset?: boolean;
  userSettings: {
    enableCalendarInvites: boolean | null;
    enableCheckInReminderPN: boolean | null;
    enableCheckInReminderEmail: boolean | null;
    enableAutoCancellationNoticeEmail: boolean | null;
    enableAutoCancellationNoticePN: boolean | null;
    enableScheduleReminderEmail: boolean | null;
    enableScheduleReminderPN: boolean | null;
    enableFollowRequestNoticeEmail: boolean | null;
    enableFollowRequestNoticePN: boolean | null;
    enableHrisStatusSyncEmail: boolean | null;
    app?: {
      anonymousBookings?: boolean;
      colleaguesStatusSendUpdatesToMe?: boolean;
      colleaguesStatusSendUpdatesToUser?: boolean;
      enableTracking?: {
        necessary?: boolean;
        googleAnalytics?: boolean;
        mixpanel?: boolean;
      };
    };
  };
  hourFormat?: TimeFormat;
  primaryOfficeId: string;
  demoUser: boolean;
  /** Favorite users' ids */
  favourites?: string[];
  createdAt: number;
  /** OfficeIds has value only for administration roles. For normal users it's always empty */
  officeIds: string[];
  managedGroups: string[];
  dedicatedResources?: IUserDedicatedResource[];
  favoriteResources?: IUserFavoriteResource[];
  accessibleOfficeIds?: string[];
  officeRoles: IOfficeRole[];
  hybridWorkPolicyId: string | null;
  providerIds?: string[];
}
