import { AbstractControl, ValidatorFn } from "@angular/forms";

export const timeEqualValidatorFn = (
  firstTimeName: string,
  secondTimeName: string,
): ValidatorFn => {
  return (formGroup: AbstractControl) => {
    const firstTime = formGroup.get(firstTimeName)?.value;
    const secondTime = formGroup.get(secondTimeName)?.value;
    if (firstTime && secondTime && firstTime === secondTime) {
      return { timeEqual: true };
    }
    return null;
  };
};
