import { IUser } from "types";
import { GoogleTagManagerDimensions } from "../enums/google-tag-manager-dimensions";
import { GoogleTagManagerVariableProperty } from "../enums/google-tag-manager-variable-property";

/** Map properties from user object to their matching tracking properties in GTM */
export function convertUserObjectToTrackedProperty(
  changedObject: Partial<IUser>,
): { key: GoogleTagManagerVariableProperty; value: any }[] {
  const objectUpdates: { key: string; value: any }[] = Object.keys(
    changedObject,
  ).reduce(
    (acc, key) => {
      if (
        (changedObject as Partial<IUser>) &&
        (key === "language" ||
          key === "role" ||
          key === "primaryOfficeId" ||
          key === "profileImage")
      ) {
        return [...acc, { key, value: (changedObject as Partial<IUser>)[key] }];
      }
      return acc;
    },
    [] as { key: string; value: any }[],
  );

  const gtmPropertyUpdates = objectUpdates.reduce(
    (acc, update) => {
      const { key } = update;
      switch (key) {
        case "language":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.USER_LANGUAGE,
              value: update.value,
            } as const,
          ];
        case "role":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.USER_ROLE,
              value: update.value,
            } as const,
          ];
        case "primaryOfficeId":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.USER_MAIN_OFFICE_ID,
              value: update.value,
            } as const,
          ];
        case "profileImage":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.USER_AVATAR_ENABLED,
              value: update.value ? true : false,
            } as const,
          ];
        default:
          return acc;
      }
    },
    [] as { key: GoogleTagManagerVariableProperty; value: any }[],
  );

  return gtmPropertyUpdates;
}
