import { Pipe, PipeTransform } from "@angular/core";
import { timeFormatToMomentTimeFormat } from "../utils";
import { IUser } from "types";

@Pipe({ name: "getTimeFormatStringForUser", standalone: true })
export class GetTimeFormatStringForUser implements PipeTransform {
  transform(user: Pick<IUser, "hourFormat">): string {
    if (!user?.hourFormat) {
      return "";
    }
    return timeFormatToMomentTimeFormat(user.hourFormat);
  }
}
