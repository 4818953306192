import { IBookingViewProps } from "./booking-view";
import { IWorkspace } from "../offices/workspace";

export type IBookingCheckinInfo = Pick<
  IBookingViewProps,
  | "checkInStatus"
  | "checkInAvailableFrom"
  | "bookingAutoCancellationTime"
  | "bookingStartTime"
  | "bookingStatus"
> & { workspace: Pick<IWorkspace, "address"> };
