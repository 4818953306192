import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Router, RoutesRecognized } from "@angular/router";
import { diff } from "deep-diff";
import { Keys, pathToRegexp } from "path-to-regexp";
import {
  asyncScheduler,
  BehaviorSubject,
  Observable,
  of,
  Subscription,
} from "rxjs";
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  observeOn,
  pairwise,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";

import {
  SlideOverComponentData,
  SlideOverComponentToken,
} from "../injection-token";
import {
  ISlideOverConfig,
  ISlideOverConfigItem,
  ISlideOverProcessedConfig,
  ISlideOverProcessedConfigItem,
} from "../interfaces-and-types";
import { SlideOverService } from "../slide-over.service";

import { SlideItemComponentPlaceholderDirective } from "./slide-item-component-placeholder.directive";

@Component({
  selector: "db-slide-over",
  templateUrl: "./slide-over.component.html",
  styleUrls: ["./slide-over.component.scss"],
})
export class SlideOverComponent implements OnDestroy {
  private initialized = false;
  private _config: ISlideOverConfig | null = null;
  private isSlideInReady$$ = new BehaviorSubject(false);
  isSlideInReady$ = this.isSlideInReady$$.asObservable().pipe(
    debounceTime(0),
    distinctUntilChanged(),
    switchMap((val) => of(val).pipe(delay(this.visibleCount * 500))),
  );

  instances: any[] = [];

  private slideItemComponentPlaceholderDirectivesSubscription:
    | Subscription
    | undefined;
  private subscription: Subscription | undefined;
  private subs = new Subscription();
  private hasScheduledCD = false;

  @HostBinding("class.has-items") hasItems = false;

  // NOTE: With angular 14 upgrade (the issue comes from scss preprocessor I guess) those styles were ignored when put inside the slide-over.component.css > :host which caused a lot of headaches
  // for me (Iliya) until I can figure out WTF was going on so I'm adding it here and at some point whenever we upgrade the angular version hopefully we can remove it here and only leave it in the scss file.
  @HostBinding("style") hostStyles = "left: 0; right: 0; bottom: 0; top: 0;";

  @ViewChildren(SlideItemComponentPlaceholderDirective)
  set slideItemComponentPlaceholderDirectives(
    slideItemComponentPlaceholderDirectives: QueryList<SlideItemComponentPlaceholderDirective>,
  ) {
    this.slideItemComponentPlaceholderDirectivesSubscription?.unsubscribe();
    this.slideItemComponentPlaceholderDirectivesSubscription = (
      slideItemComponentPlaceholderDirectives.changes as any as Observable<
        QueryList<SlideItemComponentPlaceholderDirective>
      >
    )
      .pipe(
        map((queryList) =>
          queryList.toArray().map((i) => ({
            nativeEl: i.viewContainerRef.element.nativeElement,
            templateRef: i,
          })),
        ),
        startWith([]),
        pairwise(),
        map(([prev, curr]) =>
          diff(
            (prev as any)?.map((i: any) => i.nativeEl),
            (curr as any)?.map((i: any) => i.nativeEl),
          )
            ?.filter((r) => (r as any).item.kind === "N")
            ?.map((_, index) => curr[index].templateRef),
        ),
      )
      .subscribe((newSlideItemTemplateRefs) => {
        if (!newSlideItemTemplateRefs || !newSlideItemTemplateRefs.length) {
          return;
        }
        Promise.resolve().then(() => {
          newSlideItemTemplateRefs.forEach((templateRef, index) => {
            const currentProcessedConfig = this.processedConfig[index];
            const currentConfig = this._config?.find(
              (c) => c.path === currentProcessedConfig.path,
            );
            if (!currentProcessedConfig.isVisible) {
              templateRef.viewContainerRef.clear();
            }
            if (this.instances[index]) {
              return;
            }
            const cmpInjector = Injector.create({
              providers: [
                { provide: SlideOverComponentToken, useValue: this },
                {
                  provide: SlideOverComponentData,
                  useValue: currentConfig?.data || null,
                },
              ],
              parent: this.injector,
            });
            const componentRef = templateRef.viewContainerRef.createComponent(
              currentConfig!.component,
              { injector: cmpInjector },
            );
            const componentSubscription = new Subscription();
            componentRef.onDestroy(() => {
              componentSubscription.unsubscribe();
              delete this.instances[index];
            });
            const componentInstance = componentRef.instance as any;

            this.instances[index] = componentInstance;
            for (const [eventEmitterKey, handler] of Object.entries(
              currentConfig?.on || {},
            )) {
              if (!componentInstance[eventEmitterKey]) {
                continue;
              }
              componentSubscription.add(
                (
                  componentInstance[eventEmitterKey] as EventEmitter<any>
                ).subscribe(handler),
              );
            }

            for (const [inputKey, value] of Object.entries(
              currentConfig?.inputs || {},
            )) {
              componentRef.setInput(inputKey, value);
            }
            for (const [inputKey, value] of Object.entries(
              currentConfig?.asyncInputs || {},
            )) {
              componentSubscription.add(
                (value as Observable<any>)
                  .pipe(distinctUntilChanged())
                  .subscribe((value) => {
                    componentRef.setInput(inputKey, value);
                  }),
              );
            }
          });
        });
      });
  }

  @Output() slideOverClosed = new EventEmitter<any>();
  @Output() slideClosed = new EventEmitter<any>();
  @Output() slideOpen = new EventEmitter<{
    item: ISlideOverConfigItem;
    index: number;
  }>();
  @Output() slideOverOpened = new EventEmitter<any>();
  @Output() overlayClick = new EventEmitter<any>();

  @Input() @HostBinding("class") customZIndex: "" | "z-index-200" = "";
  @Input() set config(config: ISlideOverConfig) {
    this._config = config;
    const configPathRegExps: RegExp[] = this._config.map((item) =>
      typeof item.path === "string"
        ? (
            pathToRegexp(item.path, { end: !!item.exact }) as {
              regexp: RegExp;
              keys: Keys;
            }
          ).regexp
        : item.path,
    );

    this.subscription?.unsubscribe();

    this.subscription = (this.router.events as unknown as Observable<any>)
      .pipe(
        filter((e): e is RoutesRecognized => e instanceof RoutesRecognized),
        startWith({ url: this.router.url }),
        map(({ url }) => ({ url: url.startsWith("/") ? url.slice(1) : url })),
        map(({ url }) => ({ url: url.split("?")[0] })),
        filter(
          ({ url }) =>
            this.hasItems ||
            configPathRegExps.map((re) => re.test(url)).includes(true),
        ),
        observeOn(asyncScheduler),
        tap(({ url }) => {
          this.processedConfig = config.map((item) => {
            const existingItem = this.processedConfig?.find(
              (i) => i.component === item.component && i.path === item.path,
            );
            const regExp: RegExp =
              typeof item.path === "string"
                ? (
                    pathToRegexp(item.path, { end: !!item.exact }) as {
                      regexp: RegExp;
                      keys: Keys;
                    }
                  ).regexp
                : item.path;
            const isVisible = regExp.test(url);
            if (existingItem && existingItem.isVisible && !isVisible) {
              this.isSlideInReady$$.next(false);
              return {
                component: item.component,
                isVisible: true,
                path: item.path,
                isAboutToDisappear: true,
                size: item.size,
              };
            }
            return {
              component: item.component,
              isVisible,
              path: item.path,
              isAboutToDisappear: false,
              size: item.size,
            };
          });
        }),
        switchMap((url) =>
          this.processedConfig.find((item) => item.isAboutToDisappear)
            ? of(url).pipe(delay(300))
            : [url],
        ),
      )
      .subscribe(({ url }) => {
        this.hasItems = false;
        const prevCount = this.visibleCount;
        this.visibleCount = 0;
        this.processedConfig = config.map((item, index) => {
          const currentProcessedConfigValue = this.processedConfig[index];
          const regExp: RegExp =
            typeof item.path === "string"
              ? (
                  pathToRegexp(item.path, { end: !!item.exact }) as {
                    regexp: RegExp;
                    keys: Keys;
                  }
                ).regexp
              : item.path;
          const isVisible = regExp.test(url);
          if (isVisible) {
            this.visibleCount++;
          }
          if (this.visibleCount === 1 && prevCount === 0) {
            this.slideOverOpened.emit();
          }
          this.hasItems = this.hasItems || isVisible;
          if (isVisible && !currentProcessedConfigValue.isVisible) {
            this.slideOpen.emit({ item, index });
          }
          return {
            component: item.component,
            isVisible,
            path: item.path,
            isAboutToDisappear: false,
            size: item.size,
          };
        });

        if (prevCount === this.visibleCount) {
          return;
        }

        if (this.hasItems) {
          // setTimeout(() => {
          //   const visibleComponentConfig = this.processedConfig.reverse().find(c => c.isVisible);
          //   const visibleComponentViewRef = this.cmpInjector.get(ViewContainerRef)
          //   const visibleComponent = visibleComponentViewRef..get(visibleComponentConfig?.component, null, 0);
          //   console.log(visibleComponent);
          // }, 5000);
        }
        this.isSlideInReady$$.next(true);
        if (this.visibleCount === 0 && this.initialized) {
          this.isSlideInReady$$.next(false);
          this.slideOverClosed.emit();
        } else if (prevCount > this.visibleCount && this.initialized) {
          this.slideClosed.emit();
        }

        this.initialized = true;
      });
  }

  processedConfig!: ISlideOverProcessedConfig;
  visibleCount = 0;

  @HostListener("click", ["$event"]) hostClickHandler(event: MouseEvent): void {
    const classList = (event.target as HTMLDivElement)?.classList;
    if (
      !classList ||
      (!classList.contains("overlay") && !classList.contains("has-items"))
    ) {
      return;
    }
    this.overlayClick.emit();
  }

  constructor(
    private router: Router,
    private injector: Injector,
    private slideOverService: SlideOverService,
  ) {
    this.subs.add(
      this.slideOverOpened.subscribe(() =>
        slideOverService._slideOverOpen$$.next(true),
      ),
    );
    this.subs.add(
      this.slideOverClosed.subscribe(() =>
        slideOverService._slideOverOpen$$.next(false),
      ),
    );
  }

  ngOnDestroy(): void {
    asyncScheduler.schedule(() => {
      this.slideOverService._slideOverOpen$$.next(false);
    });
    this.subs.unsubscribe();
    this.subscription?.unsubscribe();
    this.slideItemComponentPlaceholderDirectivesSubscription?.unsubscribe();
  }

  createComponent(config: ISlideOverConfigItem): void {}
  trackByPath(index: number, item: ISlideOverProcessedConfigItem): string {
    return typeof item.component;
  }
  overlayClickHandler(): void {
    this.overlayClick.emit();
  }
}
