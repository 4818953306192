import { inject, Injectable } from "@angular/core";
import { CookieValue, run as cookieConsentRun } from "vanilla-cookieconsent";
import { BehaviorSubject, take } from "rxjs";
import { AuthModel } from "auth-module";
import { IUser } from "types";

@Injectable({
  providedIn: "root",
})
export class CookieConsentService {
  private consentGiven$$ = new BehaviorSubject<boolean>(false);
  public consentGiven$ = this.consentGiven$$.asObservable();

  private readonly authModel = inject(AuthModel);

  initCookieConsent(user: IUser): void {
    if (
      user &&
      (!user.userSettings?.app?.enableTracking ||
        !user.userSettings.app.enableTracking.necessary)
    ) {
      try {
        cookieConsentRun({
          disablePageInteraction: true,
          manageScriptTags: false,
          guiOptions: {
            consentModal: {
              layout: "box wide",
              position: "bottom center",
              equalWeightButtons: true,
              flipButtons: false,
            },
            preferencesModal: {
              layout: "box",
              equalWeightButtons: true,
              flipButtons: false,
            },
          },
          onFirstConsent: ({ cookie }) => this.handleConsent(cookie),
          onConsent: ({ cookie }) => this.handleConsent(cookie),
          onChange: ({ cookie }) => this.handleConsent(cookie),
          categories: {
            necessary: {
              enabled: true,
              readOnly: true,
            },
            analytics: {
              enabled: true,
              readOnly: false,
              autoClear: {
                cookies: [{ name: /^(_ga|_gid)/ }],
              },
              services: {
                googleAnalytics: {
                  label: $localize`:@@cookie-consent|services|googleAnalytics:Google Analytics`,
                },
                mixpanel: {
                  label: $localize`:@@cookie-consent|services|mixpanel:Mixpanel`,
                },
              },
            },
          },
          language: {
            default: "en",
            translations: {
              en: {
                consentModal: {
                  title: $localize`:@@cookie-consent|consent-modal|title:Cookie and Privacy Consent`,
                  description: $localize`:@@cookie-consent|consent-modal|description:We use cookies and similar tracking technologies to improve your experience, analyze site usage, and enhance our services. By clicking 'Accept All', you agree to the use of all cookies. You can manage your preferences in the 'Cookie Settings'. For more info, check our <a href="https://www.deskbird.com/privacy-policy" target="_blank">Privacy Policy</a>.`,
                  acceptAllBtn: $localize`:@@cookie-consent|consent-modal|accept-all-btn:Accept All`,
                  acceptNecessaryBtn: "",
                  showPreferencesBtn: $localize`:@@cookie-consent|consent-modal|show-preferences-btn:Individual preferences`,
                  footer: "",
                },
                preferencesModal: {
                  title: $localize`:@@cookie-consent|preferences-modal|title:Cookie and Privacy Consent`,
                  acceptAllBtn: $localize`:@@cookie-consent|preferences-modal|accept-all-btn:Accept All`,
                  savePreferencesBtn: $localize`:@@cookie-consent|preferences-modal|save-preferences-btn:Apply Individual Preferences`,
                  closeIconLabel: $localize`:@@cookie-consent|preferences-modal|close-icon-label:Close modal`,
                  serviceCounterLabel: $localize`:@@cookie-consent|preferences-modal|service-counter-label:Service|Services`,
                  sections: [
                    {
                      title: "",
                      description: $localize`:@@cookie-consent|preferences-modal|sections[0].description:Tell us your preferences as to how we use cookies and personal data:`,
                    },
                    {
                      title: $localize`:@@cookie-consent|preferences-modal|sections[1].title:Authorizing cookies (can not be deactivated)`,
                      description: $localize`:@@cookie-consent|preferences-modal|sections[1].description:Required for the website to function and cannot be switched off in our systems. This includes log in sessions, in-app notifications and tours, as well as cookies used for configuration of third-party integrations by admin users.`,
                      linkedCategory: "necessary",
                    },
                    {
                      title: $localize`:@@cookie-consent|preferences-modal|sections[2].title:Authorizing tracking`,
                      description: $localize`:@@cookie-consent|preferences-modal|sections[2].description:These tools help us understand the usage of the application and improve our product continuously. Google Analytics works on anonymized data, while Mixpanel processes certain user-specific data such as company association.`,
                      linkedCategory: "analytics",
                    },
                  ],
                },
              },
            },
          },
        });
      } catch (err) {
        console.error("Error run cookies model:", err);
      }
    }
  }

  private handleConsent(cookie: CookieValue): void {
    this.consentGiven$$.next(true);

    const acceptedServices: { [key: string]: boolean } = {
      googleAnalytics: cookie.services["analytics"].includes("googleAnalytics"),
      mixpanel: cookie.services["analytics"].includes("mixpanel"),
    };

    // Update user settings in the backend
    this.updateUserSettings(acceptedServices);
  }

  private updateUserSettings(acceptedServices: {
    [key: string]: boolean;
  }): void {
    this.authModel.user$.pipe(take(1)).subscribe((user) => {
      const enableTracking = {
        necessary: true,
        googleAnalytics: acceptedServices["googleAnalytics"] ?? false,
        mixpanel: acceptedServices["mixpanel"] ?? false,
      };

      const userSettingsUpdate = {
        ...user!.userSettings,
        app: {
          ...user!.userSettings.app,
          enableTracking: enableTracking,
        },
      };
      this.authModel.actions.dispatch.updateUser({
        userId: user!.id,
        updates: { userSettings: userSettingsUpdate },
        noNotification: true,
      });
    });
  }
}
