import { NgModule, Optional, SkipSelf } from "@angular/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { InternalizationEffects } from "./+store/effects";
import { internalizationReducer } from "./+store/reducer";
import { INTERNALIZATION_STORE_NAME } from "./constants";
import { MatPaginatorIntlTranslated } from "./paginator-intl";

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(INTERNALIZATION_STORE_NAME, internalizationReducer),
    EffectsModule.forFeature([InternalizationEffects]),
    MatMomentDateModule,
  ],
  exports: [],
  providers: [
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { strict: true, useUtc: true },
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlTranslated,
    },
  ],
})
export class InternalizationModule {
  constructor(@Optional() @SkipSelf() intlModule: InternalizationModule) {
    if (intlModule) {
      throw new Error("InternalizationModule should only be imported once!");
    }
  }
}
