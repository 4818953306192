import { Component, ViewEncapsulation } from "@angular/core";
import { notificationTypeIconNames } from "../constants";
import { NotificationType } from "../enums";

@Component({
  selector: "db-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {
  getNotificationIconName(severity: NotificationType): string {
    return notificationTypeIconNames[severity];
  }
}
