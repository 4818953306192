<p-checkbox
  [inputId]="inputId || dataTestId"
  [value]="value"
  [name]="name"
  [ngModel]="isChecked"
  [binary]="binary"
  [disabled]="isDisabled"
  [attr.data-testId]="dataTestId"
  (onChange)="valueChangedHandler($event)"
>
</p-checkbox>
<label [for]="inputId">{{ label }}</label>
