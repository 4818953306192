import { AfterViewInit, Directive, ElementRef, OnDestroy } from "@angular/core";
import { NgControl } from "@angular/forms";
import { fromEvent, Subject } from "rxjs";
import { startWith, takeUntil } from "rxjs/operators";

@Directive({
  selector: "[ngModel][dbTrim]",
  standalone: true,
})
export class TrimDirective implements AfterViewInit, OnDestroy {
  private killSubscriptions$: Subject<void> = new Subject<void>();

  constructor(
    private el: ElementRef,
    private control: NgControl,
  ) {}

  ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement, "change")
      .pipe(
        takeUntil(this.killSubscriptions$),
        startWith(this.el.nativeElement.value),
      )
      .subscribe(() => {
        const value: string = this.el.nativeElement.value;
        const needsTrimming = value?.startsWith(" ") || value?.endsWith(" ");
        if (!needsTrimming) {
          return;
        }
        const newValue = value.trim();
        this.control.control?.setValue(newValue);
      });
  }

  ngOnDestroy(): void {
    this.killSubscriptions$.next();
    this.killSubscriptions$.complete();
  }
}
