import { FollowingStatusType, UserStatus } from "../enums";

/**
 * FIXME: (#CU-86bwkjbwj) check which properties are returned and which are not
 * temporary all of them are optional, because there is a lot of IUserInfo usage
 * */

export interface IUserView {
  // FIXME: (#CU-86bwkjbwj) it should be either id or userId, not both
  readonly id?: string | undefined;
  /**
   * @deprecated use id instead
   * */
  readonly userId?: string;
  readonly firstName?: string;
  readonly lastName?: string;
  readonly status?: UserStatus;
  readonly followingStatus?: FollowingStatusType;
  readonly isAnonymous?: boolean;
  // FIXME: (#CU-86bwkjbwj) it should be ONLY profileImage not avatartUrl
  readonly profileImage?: string;
  /**
   * @deprecated use profileImage instead
   * */
  readonly avatarUrl?: string;
  // FIXME: (#CU-86bwkjbwj) it should be ONLY avatarColor not color
  readonly avatarColor?: string;
  /**
   * @deprecated use avatarColor instead
   * */
  readonly color?: string;

  readonly isGuest?: boolean;
}

export type IUserViewAvatar = Pick<
  IUserView,
  | "status"
  | "userId"
  | "id"
  | "isAnonymous"
  | "profileImage"
  | "avatarUrl"
  | "avatarColor"
  | "color"
  | "firstName"
  | "lastName"
  | "followingStatus"
  | "isGuest"
>;

export type IUserViewAvatarProfileOpeningProps = Pick<
  IUserViewAvatar,
  "userId" | "id"
>;
