import { IUser, IOfficePlanningStatusOption, IOfficeRoleOption } from "types";
import { IUserGroup } from "./user-group";
import { CompanyStatus } from "../enums";
import { ICompanyPrivacySettings } from "./company-privacy-settings";
import { IIntegration } from "./integration";

export interface ICorporateInfo extends ICompanyPrivacySettings {
  id: string;
  name: string;
  logo: string;
  allowsCalendarSync: boolean;
  schedulingOptions: IOfficePlanningStatusOption[];
  allowsScheduling: boolean;
  allowsResourceBooking: boolean;
  allowsUsersToManageOfficeRoles: boolean;
  allowsMeetingRoomEquipment: boolean;
  isAnonymousModeDefault: boolean;
  domains: string[];
  users: IUser[];
  userGroups: IUserGroup[];
  trialStartDate: string;
  trialEndDate: string;
  trialExpired: boolean;
  status: CompanyStatus;
  extraInformation: string;
  uuid: string;
  integrations?: IIntegration[];
  officeRoles: IOfficeRoleOption[];
  hrisProviderName?: string;
}
