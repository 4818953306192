import { createReducer, on } from "@ngrx/store";

import { addFavoriteSpaceBundle, removeFavoriteSpaceBundle } from "./bundles";

export interface IFavoriteSpaces {
  loadingResourceIds: number[];
}

export const initialState: IFavoriteSpaces = {
  loadingResourceIds: [],
};

export const favoriteSpacesReducer = createReducer(
  initialState,
  on(
    addFavoriteSpaceBundle.addFavoriteSpace,
    removeFavoriteSpaceBundle.removeFavoriteSpace,
    (state, { resource }) => {
      if (!resource) return state;
      return {
        ...state,
        loadingResourceIds: state.loadingResourceIds.concat(resource.id),
      };
    },
  ),
  on(
    addFavoriteSpaceBundle.addFavoriteSpaceSuccess,
    removeFavoriteSpaceBundle.removeFavoriteSpaceSuccess,
    addFavoriteSpaceBundle.addFavoriteSpaceFailure,
    removeFavoriteSpaceBundle.removeFavoriteSpaceFailure,
    (state, { resource }) => {
      if (!resource) return state;
      return {
        ...state,
        loadingResourceIds: state.loadingResourceIds.filter(
          (id) => id != resource.id,
        ),
      };
    },
  ),
);
