export enum FeatureAccessType {
  PUBLIC_API = "PUBLIC_API",
  RESOURCE_BOOKING = "RESOURCE_BOOKING",
  RESOURCE_BOOKING_GEOFENCING_CHECKIN = "RESOURCE_BOOKING_GEOFENCING_CHECKIN",
  MEETING_ROOMS = "MEETING_ROOMS",
  WORKPLACE_ENGAGEMENT = "WORKPLACE_ENGAGEMENT",
  SCHEDULING = "SCHEDULING",
  OFFICE_EVENTS = "OFFICE_EVENTS",
  HYBRID_WORK_POLICIES = "HYBRID_WORK_POLICIES",
  WORKFORCE_ANALYTICS = "WORKFORCE_ANALYTICS",
  SCHEDULING_CALENDAR_SYNC = "SCHEDULING_CALENDAR_SYNC",
  SCHEDULING_HRIS_SYNC = "SCHEDULING_HRIS_SYNC",
  MANAGER_VALIDATION = "MANAGER_VALIDATION",
}

export enum FeatureQuotaType {
  HYBRID_WORK_POLICIES = "HYBRID_WORK_POLICIES",
  MANAGER_VALIDATION = "MANAGER_VALIDATION",
  OFFICE_ROLES = "OFFICE_ROLES",
  ROOMS_WITH_SERVICES = "ROOMS_WITH_SERVICES",
  ROOMS_WITH_KIOSK = "ROOMS_WITH_KIOSK",
  ACTIVE_OFFICE_EVENTS = "ACTIVE_OFFICE_EVENTS",
  HRIS_SYNC = "HRIS_SYNC",
}
