<div class="ui-modal" [attr.data-testId]="dataTestId">
  <p-card [ngClass]="{ 'no-body': noBody }">
    @if (!noHeader) {
      <ng-template pTemplate="header">
        <div>
          <ng-content select="[uiCardHeader]"></ng-content>
        </div>
      </ng-template>
    }
    <ng-content select="[uiCardBody]"></ng-content>
    @if (!noFooter) {
      <ng-template pTemplate="footer">
        <div class="ui-modal-actions">
          <ng-content select="[uiCardFooter]"></ng-content>
        </div>
      </ng-template>
    }
  </p-card>
</div>
