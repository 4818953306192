import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { UploadEffects } from "./+store/effects";
import { uploadReducer } from "./+store/reducer";
import { UPLOAD_STORE_NAME } from "./constants";
import { UploadButtonComponent } from "./upload-button/upload-button.component";
import { ImageUploaderComponent } from "./image-uploader/image-uploader.component";
import { DropAreaComponent } from "./drop-area/drop-area.component";
import { ButtonModule } from "primeng/button";
import { ExecPipe } from "shared-pipes";

@NgModule({
  declarations: [
    UploadButtonComponent,
    ImageUploaderComponent,
    DropAreaComponent,
  ],
  imports: [CommonModule, ButtonModule, ExecPipe],
  providers: [],
  exports: [UploadButtonComponent, ImageUploaderComponent, DropAreaComponent],
})
export class UploadModule {
  static forRoot(): ModuleWithProviders<UploadModule> {
    const storeProviders =
      StoreModule.forFeature(UPLOAD_STORE_NAME, uploadReducer).providers || [];
    const effectsProviders =
      EffectsModule.forFeature([UploadEffects]).providers || [];
    return {
      ngModule: UploadModule,
      providers: [...storeProviders, ...effectsProviders],
    };
  }
}

export { UploadButtonComponent };
