import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

/**
 * Custom RxJS operator to filter out null and undefined values.
 * @returns A function that filters out null and undefined from the stream.
 */
export function filterNullables<T>(): (
  source$: Observable<T | null | undefined>,
) => Observable<T> {
  return (source$: Observable<T | null | undefined>) =>
    source$.pipe(
      filter((value): value is T => value !== null && value !== undefined),
    );
}
