import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { DropdownComponent } from "../dropdown/dropdown.component";
import { DeskAreaType } from "types";
import { DropdownModule } from "primeng/dropdown";
import { NgClass, NgTemplateOutlet } from "@angular/common";
import { IListItem } from "shared-types";

@Component({
  selector: "db-dropdown-resources",
  standalone: true,
  imports: [DropdownComponent, DropdownModule, NgTemplateOutlet, NgClass],
  templateUrl: "./dropdown-resources.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownResourcesComponent implements OnChanges {
  @Input() selectedValue: DeskAreaType | null = null;
  @Input() class: string = "";
  @Input() withMeetingRoomOption = true;
  @Output() selectedValueChanged = new EventEmitter<DeskAreaType>();

  protected readonly DeskAreaType = DeskAreaType;
  protected dropdownOptions: IListItem[] = [
    {
      value: DeskAreaType.FlexDesk,
      label: $localize`:@@common|resource-filter-desks:Desks`,
    },
    {
      value: DeskAreaType.MeetingRoom,
      label: $localize`:@@common|resource-filter-meeting-rooms:Meeting rooms`,
    },
    {
      value: DeskAreaType.Parking,
      label: $localize`:@@common|resource-filter-parking:Parking`,
    },
    {
      value: DeskAreaType.Other,
      label: $localize`:@@common|resource-filter-other:Other`,
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["withMeetingRoomOption"]) {
      const withMeetingRoomOption = Boolean(
        changes["withMeetingRoomOption"].currentValue,
      );
      this.dropdownOptions = withMeetingRoomOption
        ? this.dropdownOptions
        : this.dropdownOptions.filter(
            (option) =>
              (option.value as DeskAreaType) !== DeskAreaType.MeetingRoom,
          );
    }
  }
}
