import { ErrorHandler, Injectable, Injector, Provider } from "@angular/core";
import { Router } from "@angular/router";
import { Environment, SentryService } from "common-module";
import { WindowService } from "global-module";
import { environment } from "../environments/environment";

@Injectable()
class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error): void {
    if (error.message.includes("FirebaseError:")) {
      return;
    }

    console.error(error);

    const router = this.injector.get(Router);
    const sentry = this.injector.get(SentryService);
    if (error.message.includes("Cannot match any routes")) {
      const isAdminUrl = error.message.includes("admin");
      if (isAdminUrl) {
        return void router.navigateByUrl("/admin/default");
      } else {
        return void router.navigate(["/default"], {
          queryParamsHandling: "preserve",
        });
      }
    }

    // Ignore going to error page when image can't get loaded for some reason
    if (error.message.includes("Error retrieving icon")) {
      return;
    }

    if (environment.env === Environment.DEVELOPMENT) {
      console.warn("Action prevented because of DEVELOPMENT environment...");
      return;
    }

    if (
      typeof (error as any)?.code === "string" &&
      (error as any)?.code?.startsWith("auth/")
    ) {
      return;
    }
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      const windowService = this.injector.get(WindowService);
      return void windowService.reloadWindow();
    }

    if (router.url.startsWith("/error")) {
      return;
    }

    const errorsToIgnoreSentryLogging =
      error.message.includes("No route found");
    if (!errorsToIgnoreSentryLogging) {
      sentry.handleError(error);
    }

    router.navigateByUrl("/error");
  }
}

export const globalErrorHandlerProvider: Provider = {
  provide: ErrorHandler,
  useFactory: (injector: Injector) => new GlobalErrorHandler(injector),
  deps: [Injector],
};
