import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
} from "@angular/core";
import { isElementInViewport, safeRequestAnimationFrame } from "../utils";
import {
  Subscription,
  animationFrameScheduler,
  fromEvent,
  observeOn,
} from "rxjs";

export interface InViewPortItem {
  elementRef: ElementRef<HTMLElement>;
  setIntersectionResult: (
    intersectionResult: ReturnType<typeof isElementInViewport>,
  ) => void;
  scrollContainer: HTMLElement | null | undefined;
  intersectionContainer: HTMLElement | null | undefined;
  ignore: boolean;
}

@Directive({
  selector: "[dbViewportContainer]",
  standalone: true,
})
export class ViewportContainerDirective
  implements AfterViewInit, OnChanges, OnDestroy
{
  // Set ignore to true whenever we want to have the directive but we don't want it to calculate the intersections
  @Input() ignore = false;
  @Input() additionalOffsetTop: number = 0;
  @Input() additionalOffsetLeft: number = 0;
  @Input() additionalOffsetBottom: number = 0;
  @Input() additionalOffsetRight: number = 0;

  // Static - if set to true we will calculate the initial isElementInViewport for the first item
  // inside the viewportMap and apply it to every other item.
  @Input() static = false;

  elementRef = inject(ElementRef);
  scrollSubscription: Subscription | undefined;
  resizeObserver: ResizeObserver;

  private hasRecalculationScheduled = false;
  private recalculationPromise: Promise<void> | undefined;
  private viewportMap = new Map<HTMLElement, InViewPortItem>();
  private _externalScrollContainer: HTMLElement | null | undefined = undefined;
  private _staticIsElementInViewportResult: ReturnType<
    typeof isElementInViewport
  > | null = null;
  private _staticIsElementInViewportResultIntersectionContainer: HTMLElement | null =
    null;

  private set externalScrollContainer(
    externalScrollContainer: HTMLElement | null | undefined,
  ) {
    this.scrollSubscription?.unsubscribe();
    this._externalScrollContainer = externalScrollContainer || null;
    if (!externalScrollContainer) {
      return;
    }
    this.scrollSubscription = fromEvent(externalScrollContainer, "scroll")
      .pipe(observeOn(animationFrameScheduler))
      .subscribe(() => this.recalculateIntersections());
    this.recalculateIntersections();
  }
  private get externalScrollContainer() {
    return this._externalScrollContainer;
  }

  constructor() {
    this.resizeObserver = new ResizeObserver(() => {
      this.recalculateIntersections(true);
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);
    this.scrollSubscription = fromEvent(this.elementRef.nativeElement, "scroll")
      .pipe(observeOn(animationFrameScheduler))
      .subscribe(this.scrollHandler);
  }

  scrollHandler = (): void => {
    if (this._externalScrollContainer) {
      return;
    }
    this.recalculateIntersections();
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["additionalOffsetTop"] ||
      changes["additionalOffsetLeft"] ||
      changes["additionalOffsetBottom"] ||
      changes["additionalOffsetRight"]
    ) {
      this._staticIsElementInViewportResult = null;
    }

    if (changes["ignore"]?.isFirstChange() === false) {
      this.recalculateIntersections();
    }
  }

  ngAfterViewInit(): void {
    this.recalculateIntersections();
  }

  recalculateIntersections(force = false): Promise<void> {
    if (this.ignore) {
      this.viewportMap.forEach((item) => {
        item.ignore = true;
      });
      return Promise.resolve();
    }
    if (this.hasRecalculationScheduled) {
      return this.recalculationPromise!;
    }
    this.hasRecalculationScheduled = true;
    if (force) {
      this._staticIsElementInViewportResult = null;
    }
    this.recalculationPromise = new Promise((res) => {
      safeRequestAnimationFrame(() => {
        this.hasRecalculationScheduled = false;
        this.viewportMap.forEach((item) => {
          const additionalContainerOffsets = {
            top: this.additionalOffsetTop,
            left: this.additionalOffsetLeft,
            bottom: this.additionalOffsetBottom,
            right: this.additionalOffsetRight,
          };

          const intersectionContainer: HTMLElement =
            item.intersectionContainer || this.elementRef.nativeElement;
          if (
            this.static &&
            this._staticIsElementInViewportResultIntersectionContainer !==
              intersectionContainer
          ) {
            this._staticIsElementInViewportResult = null;
          }
          const isElementInViewportResult =
            this._staticIsElementInViewportResult ||
            isElementInViewport(
              item.elementRef.nativeElement,
              intersectionContainer,
              additionalContainerOffsets,
            );
          if (this.static && !this._staticIsElementInViewportResult) {
            this._staticIsElementInViewportResult = isElementInViewportResult;
            this._staticIsElementInViewportResultIntersectionContainer =
              intersectionContainer;
          }
          item.setIntersectionResult(isElementInViewportResult);
        });
        res();
      });
    });
    return this.recalculationPromise;
  }

  registerViewPortItem = (item: InViewPortItem): void => {
    this.viewportMap.set(item.elementRef.nativeElement, item);
    if (
      item.scrollContainer &&
      this.externalScrollContainer !== item.scrollContainer &&
      item.scrollContainer !== this.elementRef.nativeElement
    ) {
      this.externalScrollContainer = item.scrollContainer;
    }
    const container: HTMLElement =
      this.externalScrollContainer || this.elementRef.nativeElement;
    if (
      this.static &&
      this._staticIsElementInViewportResult &&
      this._staticIsElementInViewportResultIntersectionContainer === container
    ) {
      item.setIntersectionResult(this._staticIsElementInViewportResult);
    }
  };

  unregisterViewPortItem(item: InViewPortItem): void {
    this.viewportMap.delete(item.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
    this.resizeObserver.disconnect();
  }
}
