import { createReducer, on } from "@ngrx/store";
import { showLoaderBundle } from "./bundles";

export interface IGlobalLoaderState {
  readonly globalLoaderVisible: boolean;
  readonly counter: number;
  readonly loaderTexts: string[];
}

const initialState: IGlobalLoaderState = {
  globalLoaderVisible: false,
  counter: 0,
  loaderTexts: [],
};

export const globalLoaderReducer = createReducer(
  initialState,
  on(showLoaderBundle.showLoader, (state, payload) => {
    let { globalLoaderVisible, counter, loaderTexts: _loaderTexts } = state;
    let loaderTexts = _loaderTexts.slice();
    const { visibility, reset, textContent } = payload;
    if (reset) {
      counter = visibility ? 1 : 0;
      globalLoaderVisible = visibility;
      loaderTexts = [textContent || ""];
    } else {
      counter += visibility ? 1 : -1;
      if (visibility) {
        loaderTexts.push(textContent || "");
      } else {
        loaderTexts.pop();
      }
      if (counter < 0) {
        console.warn("Global Loader: negative count prevented!");
        counter = 0;
      }
      globalLoaderVisible = counter > 0;
    }
    return { ...state, counter, globalLoaderVisible, loaderTexts };
  }),
);
