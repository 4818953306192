import { Injectable, inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from "@angular/router";
import { RouterModel } from "router-module";
import {
  combineLatest,
  map,
  Observable,
  take,
  withLatestFrom,
  zip,
} from "rxjs";
import { AuthModel } from "../+store/model";

@Injectable({
  providedIn: "root",
})
export class AuthenticateActivate implements CanActivate {
  authModel = inject(AuthModel);
  routerModel = inject(RouterModel);
  router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const { data } = route;
    const { navigated } = this.router;

    const isPublicOnlyRoute = !!data?.["publicOnly"];

    return combineLatest([
      this.authModel.isLoggedIn$,
      this.routerModel.selectors.url$,
      this.authModel.isRequiredToVisitSetupWizard$,
      this.authModel.isUserEmailVerified$,
      this.routerModel.isAdminAppEnv$,
    ]).pipe(
      take(1),
      map(
        ([
          isLogged,
          url,
          isRequiredToVisitSetupWizard,
          isUserEmailVerified,
          isAdminAppEnv,
        ]) => {
          const isLoggedWithVerifiedEmailAndHasNamesSet =
            isLogged &&
            isRequiredToVisitSetupWizard === false &&
            isUserEmailVerified;
          if (isPublicOnlyRoute) {
            if (navigated) {
              return url === "/login/verify-email" && !isUserEmailVerified
                ? true
                : url === "/login/sign-up-wizard" &&
                    isRequiredToVisitSetupWizard
                  ? true
                  : isLoggedWithVerifiedEmailAndHasNamesSet === false;
            }

            const navigationUrl = !isUserEmailVerified
              ? "/login/verify-email"
              : isRequiredToVisitSetupWizard
                ? "/login/sign-up-wizard"
                : `${
                    isAdminAppEnv
                      ? this.routerModel.adminPartPrefix
                      : this.routerModel.clientPartPrefix
                  }default`;

            return !isLogged ? true : this.router.parseUrl(navigationUrl);
          }
          if (navigated)
            return isLoggedWithVerifiedEmailAndHasNamesSet === true;
          return isLoggedWithVerifiedEmailAndHasNamesSet === true
            ? true
            : this.router.parseUrl(`/login?redirectUrl=${url}`);
        },
      ),
    );
  }
}
