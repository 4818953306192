import { Component, Input } from "@angular/core";
import { ProgressBarModule } from "primeng/progressbar";

@Component({
  selector: "db-progress",
  standalone: true,
  imports: [ProgressBarModule],
  templateUrl: "./progress.component.html",
  styleUrls: ["./progress.component.scss"],
})
export class ProgressComponent {
  @Input() value: number | undefined;
  @Input() showValue: boolean = true;
  @Input() styleClass: string | undefined;
  @Input() style: { [klass: string]: any } | null | undefined;
  @Input() unit: string = "%";
  @Input() mode: string = "determinate";
}
