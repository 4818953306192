import { Component } from "@angular/core";
import { Location } from "@angular/common";
import { NgForm } from "@angular/forms";
import { RouterModel } from "router-module";
import { AuthModel } from "../+store/model";
import {
  asyncScheduler,
  combineLatest,
  filter,
  map,
  observeOn,
  race,
  switchMap,
  take,
  tap,
  withLatestFrom,
  zip,
} from "rxjs";
import { UploadModel } from "upload-module";
import { acceptedImageUploadFormats } from "common-module";
import moment from "moment";
import { GlobalLoaderModel } from "loader-module";
import { combineLatestForFrame, LoadingTenseState } from "shared";
import { profile } from "@microsoft/teams-js";

@Component({
  selector: "app-sign-in-wizard",
  templateUrl: "./sign-in-wizard.component.html",
  styleUrls: ["./sign-in-wizard.component.scss"],
})
export class SignInWizardComponent {
  visibility: "text" | "password" = "password";
  acceptedImageFormats = acceptedImageUploadFormats;
  uploadBtnName = "user-avatar";
  userData = { firstName: "", lastName: "" };
  isLoading = false;
  isUploading$ = this.uploadModel.isUploading$("sign-up-avatar-upload");
  LoadingTenseState = LoadingTenseState;
  avatarLocation = "/api/user/uploadAvatar";

  profileImage$ = this.authModel.selectors.profileImage$.pipe(
    map((profileImage) =>
      profileImage ? `${profileImage}?timestamp=${Date.now()}` : null,
    ),
  );
  initials$ = this.authModel.selectors.initials$;
  avatarColor$ = this.authModel.selectors.avatarColor$;

  currentUserData$ = combineLatest([
    this.authModel.selectors.emailCheckResult$,
    this.authModel.user$,
    this.authModel.selectors.corporateInfo$,
    this.authModel.selectors.initials$,
    this.authModel.selectors.avatarColor$,
    this.authModel.selectors.profileImage$,
  ]).pipe(
    map(
      ([
        lastEmailCheckResult,
        currentUser,
        corporateInfo,
        initials,
        avatarColor,
        profileImage,
      ]) => ({
        id: currentUser?.id || "",
        email: currentUser?.email || lastEmailCheckResult?.email,
        userExists:
          currentUser?.email === lastEmailCheckResult?.email
            ? lastEmailCheckResult?.userExists
            : true,
        companyLogoUrl:
          corporateInfo?.logo || lastEmailCheckResult?.companyLogoUrl,
        companyName: corporateInfo?.name || lastEmailCheckResult?.companyName,
        initials: initials,
        userProfileImage: profileImage,
        avatarColor,
        emailVerified: lastEmailCheckResult?.isVerified || false,
        allowsScheduling: corporateInfo?.allowsScheduling || false,
      }),
    ),
  );
  queryParams$ = this.routerModel.selectors.queryParams$;
  page$ = this.queryParams$.pipe(
    map((queryParams) => queryParams["page"] as string),
  );
  isOnSignUpPage$ = this.queryParams$.pipe(
    map(({ page }) => ["page"].includes(page as string)),
  );

  constructor(
    private routerModel: RouterModel,
    private authModel: AuthModel,
    private uploadModel: UploadModel,
    private location: Location,
    private globalLoader: GlobalLoaderModel,
  ) {}

  toggleVisibility(): void {
    this.visibility = this.visibility === "text" ? "password" : "text";
  }

  enterName(value: { firstName: string; lastName: string }): void {
    const { firstName, lastName } = value;
    this.authModel.isLoggedIn$
      .pipe(
        take(1),
        tap((isLoggedIn) => {
          if (isLoggedIn) return;
          this.userData = { firstName, lastName };
          this.routerModel.actions.dispatch.navigate({
            commands: [],
            extras: { queryParams: { page: "set-password" } },
          });
        }),
        filter((isLoggedIn) => !!isLoggedIn),
        tap((): void => {
          this.globalLoader.actions.dispatch.showLoader({ visibility: true });
          this.authModel.actions.dispatch.patchUser({
            updates: { firstName, lastName },
          });
        }),
        filter((val) => !!val),
        switchMap(() =>
          race(
            this.authModel.actions.listen.patchUserSuccess$.pipe(
              take(1),
              map(() => true),
            ),
            this.authModel.actions.listen.patchUserFailure$.pipe(
              take(1),
              map(() => false),
            ),
          ),
        ),
        tap(() =>
          this.globalLoader.actions.dispatch.showLoader({ visibility: false }),
        ),
        filter((val) => !!val),
        withLatestFrom(this.authModel.user$),
      )
      .subscribe(([, user]): void => {
        if (user!.profileImage) {
          return void this.routerModel.actions.dispatch.navigate({
            commands: ["/default"],
          });
        }
        this.routerModel.actions.dispatch.navigate({
          commands: [],
          extras: { queryParams: { page: "upload-avatar" } },
        });
      });
  }

  register(form: NgForm): void {
    if (form.invalid) return;
    const { password } = form.value;
    this.authModel.selectors.emailCheckResult$
      .pipe(take(1))
      .subscribe((emailCheckResult) => {
        if (!emailCheckResult || !emailCheckResult.email) return;
        this.isLoading = true;
        this.authModel.actions.dispatch.register({
          email: emailCheckResult.email,
          password,
          firstName: this.userData.firstName,
          lastName: this.userData.lastName,
        });
        race(
          this.authModel.actions.listen.registerSuccess$.pipe(
            map(({ user }) => ({ user: user, error: null })),
          ),
          this.authModel.actions.listen.registerFailure$.pipe(
            map(({ error }) => ({ user: null, error: error })),
          ),
        )
          .pipe(take(1), observeOn(asyncScheduler))
          .subscribe(({ user, error }): void => {
            this.isLoading = false;
            if (error || !user)
              return void this.routerModel.actions.dispatch.navigate({
                commands: ["/login"],
                extras: { queryParams: { page: undefined } },
              });
            this.routerModel.actions.dispatch.navigate({
              commands: [],
              extras: { queryParams: { page: "upload-avatar" } },
            });
          });
      });
  }

  hasOpacity(obj: any, propertyName: string): boolean {
    if (
      obj === null ||
      Array.isArray(obj) ||
      typeof obj !== "object" ||
      !obj.hasOwnProperty(propertyName)
    ) {
      return false;
    }
    return true;
  }

  handleUpload({ success, body }: { body: any; success: boolean }): void {
    if (!success || !body) {
      return;
    }
    const url: string | null = body?.data?.url || null;
    if (!url) return;
    this.authModel.actions.dispatch.setProfileImage({ profileImage: url });
  }

  removeAvatar(): void {
    this.authModel.actions.dispatch.patchUser({ updates: { avatarUrl: "" } });

    race(
      this.authModel.actions.listen.patchUserSuccess$.pipe(map(() => true)),
      this.authModel.actions.listen.patchUserFailure$.pipe(map(() => false)),
    )
      .pipe(take(1))
      .subscribe((success) => {
        if (success) {
          this.authModel.actions.dispatch.setProfileImage({ profileImage: "" });
        }
        this.isLoading = false;
      });
  }

  confirmProfile(): void {
    zip(
      this.authModel.isUserEmailVerified$,
      this.authModel.selectors.freeTrialStartDate$,
    )
      .pipe(take(1))
      .subscribe(([isUserEmailVerified, freeTrialStartDate]): void => {
        const freeTrialStartDateMoment = freeTrialStartDate
          ? moment(freeTrialStartDate).utc()
          : moment().utc();
        const minDiff = moment().diff(freeTrialStartDateMoment, "minutes");
        const canSkipEmailVerification = minDiff < 120;

        if (!isUserEmailVerified || !canSkipEmailVerification)
          return void this.routerModel.actions.dispatch.navigate({
            commands: ["/login/verify-email"],
            extras: { queryParamsHandling: "preserve" },
          });

        this.authModel.actions.dispatch.checkEmailCleanup();
        this.routerModel.actions.dispatch.navigate({
          commands: ["/default"],
          extras: { queryParamsHandling: "preserve" },
        });
      });
  }

  goBackToLogin(): void {
    this.isUploading$
      .pipe(
        take(1),
        filter((isUploading) => !isUploading),
        switchMap(() =>
          this.queryParams$.pipe(
            map(({ page }) => ["upload-avatar"].includes(page)),
            take(1),
          ),
        ),
        withLatestFrom(this.authModel.user$),
      )
      .subscribe(([isOnUploadAvatar, user]) => {
        if (isOnUploadAvatar || user) {
          this.authModel.actions.dispatch.logout({});
          this.routerModel.actions.dispatch.navigate({
            commands: ["/login"],
            extras: { queryParamsHandling: "preserve" },
          });
          return;
        }
        this.location.back();
      });
  }
}
