<div id="file-progress-container">
  <div id="file-progress-header">
    <div id="file-progress-header-left">
      <div id="file-icon">
        @switch (type) {
          @case ("csv") {
            <i class="csv-file"></i>
          }
        }
      </div>
      <div id="file-info-container">
        <div id="file-name">{{ fileName || " - " }}</div>
        <div id="file-info">
          <div id="file-size">{{ fileSize || " - " }}KB</div>
          @if (status !== FileProgressStatus.UploadFailure) {
            <span id="file-info-separator">•</span>
            <div id="file-progress">
              {{ FileProgressStatus.UploadSuccess ? 100 : progress }}%
            </div>
          }
        </div>
      </div>
    </div>
    <div id="file-progress-header-right">
      @if (status === FileProgressStatus.UploadFailure) {
        <div id="file-progress-error">
          <span i18n="@@common|csv-import|upload-failed"
            >Upload failed. Try uploading again?</span
          >
          <db-button-icon
            icon="pi-replay"
            (click)="retryClickHandler()"
          ></db-button-icon>
          <db-button-icon
            icon="pi-times"
            (click)="cancelClickHandler()"
          ></db-button-icon>
        </div>
      }
      @if (status === FileProgressStatus.UploadSuccess) {
        <div id="file-progress-complete">
          <span i18n="@@common|csv-import|completed">Completed</span>
          <db-button
            icon="green-check-absolute"
            [style]="successButtonStyles"
            (click)="proceedClickHandler()"
          ></db-button>
        </div>
      }
      @if (status === FileProgressStatus.Uploading) {
        <div id="file-progress-ongoing">
          <span (click)="cancelClickHandler()" i18n="@@common|csv-import|cancel"
            >Cancel</span
          >
        </div>
      }
    </div>
  </div>
  <div id="file-progress-progress">
    <db-progress
      [value]="
        status === FileProgressStatus.UploadFailure ||
        FileProgressStatus.UploadSuccess
          ? 100
          : progress
      "
      [showValue]="false"
      [styleClass]="
        status === FileProgressStatus.UploadFailure ? 'progress-failure' : ''
      "
    ></db-progress>
  </div>
</div>
