import { ICalendarDay } from "../types";

/** Prepares string in yyyy-MM-dd format */
export const calendarDayToShortDateString = ({
  year,
  month,
  day,
}: ICalendarDay) => {
  return [
    year,
    `${month < 9 ? "0" : ""}${month + 1}`,
    `${day < 10 ? "0" : ""}${day}`,
  ].join("-");
};
