import { createReducer, on } from "@ngrx/store";
import {
  loadQrStickerTemplateBundle,
  clearQrStickerTemplateBundle,
  updateQrStickerTemplateBundle,
  downloadQrCodesBundle,
  clearErrorsBundle,
  clearDownloadParametersBundle,
  setNameBundle,
  setQrStickerTemplateBundle,
  setCurrentViewBundle,
  setDownloadParametersBundle,
  setQrCodesForResourceCountBundle,
  setQrGenerationLevelBundle,
} from "./bundles";
import {
  QrGenerationLevel,
  QrGeneratorCurrentView,
} from "../../../../../qr-generation-module/src/lib/enums";

export interface IQRGeneratorState {
  withTemplate: boolean;
  companyImage: string;
  backgroundColor: string;
  fontColor: string;
  transparentBackground: boolean;
  totalResources: number;
  qrCodesForResourceCount: number;
  loading: boolean;
  generatingCodes: boolean;
  errors: string[];
  floorId?: string;
  areaId?: string;
  resourceId?: string;
  name: string;
  currentView: QrGeneratorCurrentView;
  qrGenerationLevel: QrGenerationLevel;
}

export const initialState: IQRGeneratorState = {
  withTemplate: false,
  totalResources: 0,
  qrCodesForResourceCount: 0,
  companyImage: "",
  backgroundColor: "#ffffff",
  fontColor: "#000000",
  transparentBackground: false,
  loading: true,
  generatingCodes: false,
  errors: [],
  name: "",
  currentView: QrGeneratorCurrentView.StickerBuilder,
  qrGenerationLevel: QrGenerationLevel.Office,
};

export const qrGeneratorReducer = createReducer(
  initialState,
  on(loadQrStickerTemplateBundle.loadQrOfficeTemplate, (state) => {
    return { ...state, loading: true };
  }),
  on(
    loadQrStickerTemplateBundle.loadQrOfficeTemplateSuccess,
    (state, { template }) => {
      return {
        ...state,
        withTemplate: template.withTemplate,
        totalResources: template.totalResources,
        companyImage: template.companyImage,
        backgroundColor: template.backgroundColor,
        fontColor: template.fontColor,
        transparentBackground: template.transparentBackground,
        loading: false,
      };
    },
  ),
  on(
    loadQrStickerTemplateBundle.loadQrOfficeTemplateFailure,
    loadQrStickerTemplateBundle.loadQrOfficeTemplateCancel,
    (state) => {
      return { ...state, loading: false };
    },
  ),
  on(clearQrStickerTemplateBundle.clearQrOfficeTemplate, () => {
    return { ...initialState };
  }),
  on(updateQrStickerTemplateBundle.updateQrOfficeTemplate, (state) => {
    return { ...state, generatingCodes: true };
  }),
  on(setQrStickerTemplateBundle.setQrOfficeTemplate, (state, payload) => {
    return {
      ...state,
      withTemplate: payload.withTemplate,
      companyImage: payload.companyImage,
      backgroundColor: payload.backgroundColor,
      fontColor: payload.fontColor,
      transparentBackground: payload.transparentBackground,
    };
  }),
  on(updateQrStickerTemplateBundle.updateQrOfficeTemplateCancel, (state) => {
    return {
      ...state,
      generatingCodes: false,
      currentView: QrGeneratorCurrentView.StickerBuilder,
    };
  }),
  on(
    updateQrStickerTemplateBundle.updateQrOfficeTemplateFailure,
    (state, { error }) => {
      return {
        ...state,
        generatingCodes: false,
        errors: [error],
        currentView: QrGeneratorCurrentView.StickerBuilder,
      };
    },
  ),
  on(downloadQrCodesBundle.downloadQrCodes, (state) => {
    return { ...state, generatingCodes: true };
  }),
  on(
    downloadQrCodesBundle.downloadQrCodesSuccess,
    downloadQrCodesBundle.downloadQrCodesCancel,
    downloadQrCodesBundle.downloadQrCodesFailure,
    (state) => {
      return { ...state, generatingCodes: false };
    },
  ),
  on(clearErrorsBundle.clearErrors, (state) => {
    return { ...state, errors: [] };
  }),
  on(
    setDownloadParametersBundle.setDownloadParameters,
    (state, { floorId, areaId, resourceId }) => {
      return {
        ...state,
        floorId: floorId,
        areaId: areaId,
        resourceId: resourceId,
      };
    },
  ),
  on(clearDownloadParametersBundle.clearDownloadParameters, (state) => {
    return {
      ...state,
      floorId: undefined,
      areaId: undefined,
      resourceId: undefined,
    };
  }),
  on(setNameBundle.setName, (state, { name }) => {
    return { ...state, name: name };
  }),
  on(setCurrentViewBundle.setCurrentView, (state, { view }) => {
    return { ...state, currentView: view };
  }),
  on(
    setQrCodesForResourceCountBundle.setQrCodesForResourceCount,
    (state, { qrCodesForResourceCount }) => {
      return { ...state, qrCodesForResourceCount };
    },
  ),
  on(setQrGenerationLevelBundle.setQrGenerationLevel, (state, { level }) => {
    return { ...state, qrGenerationLevel: level };
  }),
);
