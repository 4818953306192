import { GenericConfigFactory, IGenericConfirmation } from "../interfaces";

export const getRemoveFloorPlanConfirmationConfig = (
  cfg: GenericConfigFactory = {},
): IGenericConfirmation => ({
  ...(cfg || {}),
  headerText: $localize`:@@confirmations|remove-floor-plan-header:Remove floor plan`,
  bodyText: $localize`:@@confirmations|remove-floor-plan-body:"${cfg.data[0]}" has areas, are you sure you want to remove "${cfg.data[0]}"?`,
  confirmButtonLabel: cfg?.cancelButtonLabel || $localize`:@@common|save:Save`,
  cancelButtonLabel:
    cfg?.cancelButtonLabel ||
    $localize`:@@common|continue-without-saving:Continue without saving`,
  disableCancel: cfg?.disableCancel || false,
  disableConfirm: cfg?.disableCancel || false,
});
