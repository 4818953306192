import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Inject, Injectable, OnDestroy, Provider } from "@angular/core";
import {
  asyncScheduler,
  EMPTY,
  interval,
  Observable,
  race,
  throwError,
  timer,
} from "rxjs";
import {
  catchError,
  concatMap,
  delay,
  map,
  observeOn,
  retryWhen,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import {
  API_URL,
  APP_URL,
  BACKEND_SLACK_LOGIN_URL,
  WINDOW,
} from "common-module";
import { NotificationModel, NotificationType } from "notification-module";
import { RouterModel } from "router-module";

import { GlobalLoaderModel } from "loader-module";
import { AuthModel } from "./+store/model";
import { ErrorCode } from "./enums";
import {
  FEATURE_ACCESS_STORAGE_KEY,
  FeatureStorage,
} from "./feature-access-control/feature-access.interface";

const apiRegex = /^\/api\//;

const publicUrls = [
  "/api/register/internalWorkspaces",
  "/api/onboarding/checkEmail",
  "/api/auth/microsoft",
  "/api/onboarding/connectUserToMsProvider",
];

const notAuthenticateUrls = ["/api/user", "/api/user/corporateInfo"];
const kioskDeskbirdTokenAuthenticateUrls = [/\/api\/kiosk\/admin/];
const kioskOwnTokenAuthenticateUrls = [/\/api\/kiosk/];

const v1Urls = [
  /\/api\/events/,
  /\/api\/serviceRequests\/categories/,
  /\/api\/serviceRequests\/csv/,
  /\/api\/resources/,
  /\/api\/kiosk/,
  /\/api\/featureAccess/,
];

const v1_2Urls = [
  /\/api\/user\/update/,
  /\/api\/users\/importUsers/,
  /\/api\/users\/exportFailedUsers/,
  /\/api\/users\/importUserCSVStatus/,
  /\/api\/users\/handleImportUsers/,
  /\/api\/internalWorkspaces\/.+\/zones/,
  /\/api\/internalWorkspaces\/.+\/zones\/.+/,
  /\/api\/internalWorkspaces\/createFreeTrialCompany/,
  /\/api\/internalWorkspaces\/createDemoCompany/,
  /\/api\/businessCompany\/users/,
  /\/api\/multipleDayBooking/,
  /\/qrcode\/template$/,
  /\/api\/company\/.+\/officeRoles\/settings/,
  /\/company\/\d+\/calendarProvider\/sync$/,
  /\/company\/\d+\/calendarProvider\/unlink$/,
  /\/api\/user\/syncAzureProfilePicture/,
  /\/api\/businessCompany\/d+\/users\/+d\/officeRoles$/,
  /\/api\/invoices\/company\/(\d+)\/openInvoices/,
];

const v1_4Urls = [/\/api\/user\/corporateInfo/, /\/api\/auth\/microsoft/];

const v2Urls = [
  /\/api\/users\?companyId=/,
  /\/api\/meetingRooms\/equipment/,
  /\/api\/firebaseCustomToken/,
  /\/api\/meetingRooms\/serviceRequests\/categories/,
  /\/api\/v2\/userGroups\?/,
  /\/api\/analytics\/companies\/(\d+)\/offices/,
];

const v3Urls = [/\/api\/v3\/users\?companyId=/];

const fileUploadEndpointRegExps = [
  /\/businesscompany\/[^/]+\/users\/[^/]+\/profileImage/,
  /\/api\/user\/uploadAvatar/,
  /\/api\/users\/importUsers/,
  /\/api\/uploadMedia/,
  /\/zones\/uploadImage/,
  /\/zones\/floorplans\?groupId=/,
];
const textResponseEndpointRegExps = [/\/api\/firebaseCustomToken/];

const blobRequestUrls = [/\/api\/serviceRequests\/csv/];

const nonApiBackendUrls = [/\/bmd\/company/, /\/public-api\/keys/];

const retryCount = 5;
const retryDelayMilliseconds = 1000;

@Injectable()
export class APIHttpInterceptor implements HttpInterceptor, OnDestroy {
  private caughtRequests: Observable<HttpEvent<any>>[] = [];
  private refreshStreams$: Observable<HttpEvent<any>> | null = null;

  private url = "";
  private urlSubscription$$ = this.routerModel.selectors.url$.subscribe(
    (url) => (this.url = url),
  );

  constructor(
    @Inject(API_URL) private APIUrl: string,
    @Inject(APP_URL) private APPUrl: string,
    @Inject(WINDOW) public window: Window,
    @Inject(BACKEND_SLACK_LOGIN_URL) private backendSlackUrl: string,
    private authModel: AuthModel,
    private notificationModel: NotificationModel,
    private routerModel: RouterModel,
    private globalLoaderModel: GlobalLoaderModel,
  ) {}

  ngOnDestroy(): void {
    this.urlSubscription$$.unsubscribe();
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let request = next.handle(req);
    const isTemporarySlackLoginUrl = req.url === this.backendSlackUrl;
    const isNonApiBackedUrl =
      nonApiBackendUrls.find((re) => re.test(req.url)) ||
      isTemporarySlackLoginUrl;

    if (req.url === "/api/featureAccess") {
      const stored = localStorage.getItem(FEATURE_ACCESS_STORAGE_KEY);
      const storage = stored
        ? (JSON.parse(stored) as FeatureStorage)
        : { enabled: false, features: [], quotas: [] };

      if (storage.enabled) {
        return timer(100).pipe(
          map(
            () =>
              new HttpResponse({
                status: 200,
                body: {
                  features: storage.features,
                  quotas: storage.quotas,
                },
              }),
          ),
        );
      }
    }

    if (apiRegex.test(req.url) || isNonApiBackedUrl) {
      request = this.authModel.idToken$.pipe(
        take(1),
        switchMap((idToken) => {
          let url = isNonApiBackedUrl
            ? req.url
            : `${this.APIUrl}/v1.1/${req.url.replace(apiRegex, "")}`;

          // NOTE: currently we have to support web.deskbird.app as well so in order to remove CORS
          // we have to use the old proxy functions (MSTeams uses the old domain / REMOVE THIS after web.deskbird.app is removed)
          // if (this.window.location.host.includes('web.deskbird')) {
          //   url = `${this.window.location.protocol}//${this.window.location.host}/api/v1.1/${req.url.replace(apiRegex, '')}`;
          // }

          // This is only for local development purpose.
          // KEEP IT COMMENTED ON REMOTE ENVIRONMENTS!
          // if (req.url.startsWith("/api/events")) {
          //   url = `http://localhost:3000/api/v1/${req.url.replace(apiRegex, '')}`;
          // }

          if (!isNonApiBackedUrl) {
            if (v1Urls.find((re) => re.test(req.url))) {
              url = url.replace("v1.1", "v1");
            } else if (v1_2Urls.find((re) => re.test(req.url))) {
              url = url.replace("v1.1", "v1.2");
            } else if (v1_4Urls.find((re) => re.test(req.url))) {
              url = url.replace("v1.1", "v1.4");
            } else if (v2Urls.find((re) => re.test(req.url))) {
              url = url.replace("v1.1", "v2");
              url = url.replace("/v2/v2/", "/v2/");
            } else if (v3Urls.find((re) => re.test(req.url))) {
              url = url.replace("v1.1", "v3");
              url = url.replace("/v3/v3/", "/v3/");
            }
          }

          let headers = req.headers;
          const isKioskRequest =
            !kioskDeskbirdTokenAuthenticateUrls.find((re) =>
              re.test(req.url),
            ) && kioskOwnTokenAuthenticateUrls.find((re) => re.test(req.url));
          if (isKioskRequest) {
            const token = localStorage.getItem("kioskToken");
            const bearer = token ? token.split(":")[1] : "";
            headers = headers.set("Authorization", `Bearer ${bearer}`);
          } else if (idToken) {
            headers = headers.set("Authorization", `Bearer ${idToken}`);
          }

          if (
            !fileUploadEndpointRegExps.find((re) => re.test(req.url)) &&
            !textResponseEndpointRegExps.find((re) => re.test(req.url)) &&
            req.body &&
            req.body instanceof Object &&
            ["post", "put"].includes(req.method.toLocaleLowerCase())
          ) {
            headers = headers.set("Content-Type", "application/json");
          }

          if (!isNonApiBackedUrl && req.url.includes("activeDirectory")) {
            const qp = req.url.includes("sync/organization")
              ? "?forceSync=true"
              : "";
            headers = headers.set(
              "done_redirect_uri",
              `${this.APPUrl}${this.url}${qp}`,
            );
          }

          if (!isNonApiBackedUrl && req.url.includes("googleDirectory")) {
            headers = headers.set(
              "done_redirect_uri",
              `${this.APPUrl}${this.url}`,
            );
          }

          if (
            !idToken &&
            !publicUrls.includes(req.url) &&
            !notAuthenticateUrls.includes(req.url) &&
            !isKioskRequest
          ) {
            return throwError(
              () =>
                new HttpResponse({
                  status: 401,
                  body: "<INTERCEPTOR_NO_TOKEN>",
                }),
            );
          }

          const responseType = blobRequestUrls.find((re) => re.test(req.url))
            ? ("blob" as "json")
            : undefined;

          const modifiedRequest = req.clone({ url, headers, responseType });
          return next.handle(modifiedRequest).pipe(
            switchMap((event) => {
              if (event instanceof HttpResponse) {
                if (event.body?.success === false) {
                  if (event.body?.errorCode === "restrictedAccessToOffice") {
                    this.authModel.actions.dispatch.restrictedOfficeAccess();
                    return race(
                      this.authModel.actions.listen
                        .restrictedOfficeAccessSuccess$,
                      this.authModel.actions.listen
                        .restrictedOfficeAccessFailure$,
                    ).pipe(
                      take(1),
                      switchMap(() => [event]),
                    );
                  }
                }
              }
              return [event];
            }),
            retryWhen((error) =>
              error.pipe(
                withLatestFrom(this.routerModel.selectors.url$),
                concatMap(([error, applicationUrl], count) => {
                  if (
                    modifiedRequest.method === "GET" &&
                    count <= retryCount &&
                    !this.skipRetry(req.url, applicationUrl, idToken)
                  ) {
                    return [error];
                  }
                  return throwError(() => error);
                }),
                delay(retryDelayMilliseconds),
              ),
            ),
          );
        }),
      );
    }

    return request.pipe(this.errorHandler);
  }

  errorHandler = (
    source$: Observable<HttpEvent<any>>,
  ): Observable<HttpEvent<any>> =>
    source$.pipe(
      catchError(
        (httpError: HttpErrorResponse, caught: Observable<HttpEvent<any>>) => {
          console.error(httpError);

          if (this.needsTokenRefresh(httpError)) {
            return this.tokenRefreshHandler(caught);
          }

          if (httpError.error?.errorCode === ErrorCode.TOKEN_REVOKED) {
            this.authModel.actions.dispatch.logout({});
          }

          if (httpError.status === 503) {
            return this.routerModel.selectors.url$.pipe(
              tap((url) => {
                if (url.includes("/maintenance")) {
                  return;
                }
                // NOTE: Show the loader so we can wait for any additional navigations to finish and then navigate to the maintenance page
                this.globalLoaderModel.actions.dispatch.showLoader({
                  visibility: true,
                });
                asyncScheduler.schedule(() => {
                  this.globalLoaderModel.actions.dispatch.showLoader({
                    visibility: false,
                  });
                  this.routerModel.actions.dispatch.navigateByUrl({
                    url: "/maintenance",
                  });
                }, 1000);
              }),
              switchMap(() => throwError(() => httpError)),
            );
          }

          if (httpError.status === 401) {
            return this.authModel.isAuthenticating$.pipe(
              take(1),
              tap((isAuthenticating) => {
                const isNoTokenError =
                  (httpError as any).body === "<INTERCEPTOR_NO_TOKEN>";
                if (isAuthenticating || isNoTokenError) {
                  if (isNoTokenError) {
                    console.error(
                      "No token found! If you've just logged out or loaded the app you can discard this error!",
                    );
                  }
                  return;
                }
                this.notificationModel.actions.dispatch.showNotification({
                  data: $localize`:@@auth-module|api-interceptor:You don\'t have permission to execute this operation`,
                  notificationType: NotificationType.ERROR,
                });
              }),
              switchMap((isAuthenticating) => {
                // NOTE if we don't have a token don't return an error so we don't show the error notification
                // here we assume that the resolve directive that made the call will get destroyed so it won't
                // get stuck in the "isResolving" state
                if (
                  (httpError as any).body === "<INTERCEPTOR_NO_TOKEN>" &&
                  !isAuthenticating
                ) {
                  return EMPTY;
                }
                return throwError(() => httpError);
              }),
            );
          }

          if (
            httpError.status === 0 &&
            !httpError.url?.includes("app-info.json")
          ) {
            // NOTE: Wait for one second before navigating to /offline and throwing errors
            // because when switching languages we reload the page and some browsers trigger
            // an ABORT signal on the dispatched (not finished) requests which results in
            // error status - 0 and we end up on the offline page + see errors which is required
            // only when we are really offline
            return interval(1000).pipe(
              take(1),
              tap(() => {
                this.routerModel.actions.dispatch.navigateByUrl({
                  url: "/offline",
                });
              }),
              switchMap(() => throwError(() => httpError)),
            );
          }

          return throwError(() => httpError);
        },
      ),
    );

  tokenRefreshHandler(
    caught: Observable<HttpEvent<any>>,
  ): Observable<HttpEvent<any>> {
    const currentCaughtIndex = this.caughtRequests.length;
    this.caughtRequests = this.caughtRequests.concat(caught);
    if (this.refreshStreams$) {
      return this.refreshStreams$;
    }

    this.authModel.actions.create.refreshToken();

    const tokenRefreshError$ =
      this.authModel.actions.listen.refreshTokenFailure$.pipe(
        take(1),
        switchMap(({ error }) => {
          this.refreshStreams$ = null;
          return throwError(error);
        }),
      );

    const tokenRefreshSuccess$ =
      this.authModel.actions.listen.refreshTokenSuccess$.pipe(
        take(1),
        switchMap(() => {
          this.refreshStreams$ = null;
          const currentCaught = this.caughtRequests[currentCaughtIndex];
          this.caughtRequests = [
            ...this.caughtRequests.slice(0, currentCaughtIndex),
            ...this.caughtRequests.slice(currentCaughtIndex + 1),
          ];
          return currentCaught.pipe(
            observeOn(asyncScheduler),
            switchMap((value) => [value]),
            catchError((httpError: HttpErrorResponse) => throwError(httpError)),
          );
        }),
      );

    this.refreshStreams$ = race(tokenRefreshError$, tokenRefreshSuccess$); // .pipe(tap(clearHandler, clearHandler));

    return this.refreshStreams$;
  }

  needsTokenRefresh(httpError: HttpErrorResponse): boolean {
    return (
      httpError.error?.errorCode === ErrorCode.TOKEN_EXPIRED ||
      httpError.error?.errorCode === ErrorCode.TOKEN_INCOMPATIBLE
    );
  }

  skipRetry(
    requestUrl: string,
    applicationUrl: string,
    firebaseIdToken: string | null,
  ): boolean {
    if (applicationUrl.includes("/maintenance")) {
      return true;
    }

    if (!firebaseIdToken) {
      return (
        requestUrl.includes("/user") || requestUrl.includes("/corporateInfo")
      );
    }

    return false;
  }
}

export const APIInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: APIHttpInterceptor,
};
