import moment, { Moment } from "moment";
import { IAccessRules, TimeFormat } from "types";
import { hourFormatToTimeFormat } from "../hour-format-to-time-format";

/**
 * Determines the allowed booking start time for a restricted area based on access rules and office timezone.
 *
 * @param accessRules - The access rules for the area, including whether it is restricted, the restricted time, and if the user has access.
 * @param officeTimezone - The timezone of the office.
 * @returns The allowed booking start time as a Moment object if the area is time restricted for the authorized user, otherwise null.
 */
export const restrictedAreaAllowedBookingStart = (
  accessRules: IAccessRules,
  officeTimezone: string,
): Moment | null => {
  const areaIsTimeRestrictedForAuthUser =
    accessRules?.isRestricted &&
    accessRules?.restrictedTime &&
    !accessRules?.userHasAccess;
  if (areaIsTimeRestrictedForAuthUser) {
    const { hours, minutes } = getRestrictedTimeInHourAndMinutes(accessRules);

    const now = moment().tz(officeTimezone);
    const bookingForNextDayAfter = moment().tz(officeTimezone).set({
      year: now.year(),
      month: now.month(),
      date: now.date(),
      hour: hours,
      minute: minutes,
    });

    if (now.isAfter(bookingForNextDayAfter)) {
      return moment()
        .tz(officeTimezone)
        .startOf("day")
        .add(1, "day")
        .tz(officeTimezone);
    }
    return moment().tz(officeTimezone).endOf("day");
  }

  return null;
};

export const restrictedAreaAllowedBookingStartString = (
  accessRules: IAccessRules,
  userHourFormat?: TimeFormat,
): string => {
  const { hours, minutes } = getRestrictedTimeInHourAndMinutes(accessRules);
  const localTime = moment()
    .startOf("day")
    .set("hours", hours)
    .set("minutes", minutes)
    .local();
  const hourMinutes = localTime.format(
    hourFormatToTimeFormat(userHourFormat || TimeFormat.TWENTY_FOUR_HOUR),
  );

  return hourMinutes;
};

function getRestrictedTimeInHourAndMinutes(accessRules: IAccessRules) {
  const restrictedTime = accessRules?.restrictedTime || 0;
  const hours = Math.floor(restrictedTime / 60 / 60);
  const minutes = Math.floor(restrictedTime / 60 - hours * 60);
  return { hours, minutes };
}
