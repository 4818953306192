import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonIconComponent } from "../button-icon";

@Component({
  selector: "db-popup-content",
  standalone: true,
  imports: [CommonModule, ButtonIconComponent],
  templateUrl: "./popup-content.component.html",
  styleUrls: ["./popup-content.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PopupContentComponent {
  @Input() hideFooter = false;
  @Input() centerAlignedHeader = false;
  @Input() hideCloseButton = false;
  @Output() closePopup = new EventEmitter<void>();

  closePopupHandler() {
    this.closePopup.emit();
  }
}
