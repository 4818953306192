import { UserRole } from "types";

export const GUEST_BOOKING_DEFAULT_ROLES = [
  UserRole.ADMIN,
  UserRole.OFFICE_ADMIN,
];

export const MAX_AREA_NAME_LENGTH = 25;
export const MAX_AREA_NAME_LENGTH_EXTENDED = 100;

export const MAX_AREA_DESCRIPTION_LENGTH = 45;
export const MAX_AREA_DESCRIPTION_LENGTH_EXTENDED = 150;

export const MAX_RESOURCE_ITEM_NAME_LENGTH = 50;
export const MAX_RESOURCE_ITEM_DESCRIPTION_LENGTH = 100;
