export const skeletonThemes = {
  circleLoaderStyles: {
    height: "40px",
    width: "40px",
  },
  longLineLoaderStyles: {
    width: "200px",
    height: "15px",
    margin: 0,
  },
  middleLineLoaderStyles: {
    width: "150px",
    height: "15px",
    margin: 0,
  },
  shortLineLoaderStyles: {
    width: "100px",
    height: "15px",
    margin: 0,
  },
  squareLoaderStyles: {
    width: "30px",
    "border-radius": "5px",
    height: "30px",
  },
};
