import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { uploadBundles } from "./bundles";
import { selectors } from "./selectors";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";

@Injectable({
  providedIn: "root",
})
export class UploadModel {
  selectors = connectSelectors(selectors);
  actions = connectBundles(uploadBundles);

  isUploading$(uuid: string): Observable<boolean> {
    return this.selectors.progress$.pipe(
      map((progress) => ![undefined, 100].includes(progress[uuid])),
    );
  }
}
