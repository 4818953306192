import { props } from "@ngrx/store";
import { forNamespace } from "ngrx-action-bundles";

const factory = forNamespace("INTERNALIZATION MODULE");

export const loadLocaleBundle = factory.singleAction(
  "loadLocale",
  props<{ locale: string }>(),
);

export const internalizationBundles = [loadLocaleBundle];
