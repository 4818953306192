<input
  #fileInput
  type="file"
  style="display: none"
  (change)="uploadFile()"
  [accept]="accept"
/>
<a
  pButton
  class="d-block p-button-text"
  [ngClass]="{ 'h-100': isFullHeight }"
  href=""
  (click)="selectFile($event)"
>
  <ng-content></ng-content>
</a>
