<div class="popup-header" [ngClass]="{ 'center-aligned': centerAlignedHeader }">
  <ng-content select="[popupContentHeader]"></ng-content>
  @if (!hideCloseButton) {
    <db-button-icon
      icon="pi-times"
      class="close-button"
      (click)="closePopupHandler()"
    >
    </db-button-icon>
  }
</div>

<ng-content select="[popupContentBody]"></ng-content>
@if (!hideFooter) {
  <div class="popup-footer">
    <ng-content select="[popupContentFooter]"></ng-content>
  </div>
}
