@if (
  hasAccessToPageFunction === null || (link | exec: hasAccessToPageFunction)
) {
  <div
    class="menu-item small-text-bold"
    [ngClass]="{
      active: menuItemLinkActive && menuItemLinkActive.isActive && !disabled,
      disabled: disabled,
      loading: loading,
      webapp: style === 'webapp',
      admin: style === 'admin',
      'pointer-events': 'auto',
      'nav-with-border': style === 'nav-with-border',
      'nav-with-border-admin': style === 'nav-with-border-admin',
    }"
  >
    <a
      [pTooltip]="tooltip"
      tooltipPosition="bottom"
      class="small-text"
      [skipLocationChange]="skipLocationChange"
      [ngClass]="linkClassesWithBoldCheck"
      [ngStyle]="{ display: isExternalLink ? 'none' : 'flex' }"
      [queryParams]="queryParams"
      [queryParamsHandling]="queryParamsHandling"
      [attr.data-testid]="dataTestId"
      [routerLink]="link"
      [routerLinkActiveOptions]="linkActiveOptions"
      [routerLinkActive]="link ? linkActive : ''"
      (isActiveChange)="linkActiveChange.emit($event)"
      #menuItemLinkActive="routerLinkActive"
    >
      <ng-container *ngTemplateOutlet="linkContent"></ng-container>
    </a>
    @if (isExternalLink) {
      <a [href]="link" [ngClass]="linkClassesWithBoldCheck" target="blank">
        <ng-container *ngTemplateOutlet="linkContent"></ng-container>
      </a>
    }

    <ng-template #linkContent>
      @if (imgSrc && showIcon) {
        <img [src]="imgSrc" alt="<image>" />
      }
      <div class="info">
        <span class="title">{{ title }} </span>
      </div>
    </ng-template>
  </div>
}
