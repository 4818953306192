import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LOADER_STORE_NAME } from "../constants";
import { IGlobalLoaderState } from "./reducer";

export const getRouterReducerState =
  createFeatureSelector<IGlobalLoaderState>(LOADER_STORE_NAME);

export const getGlobalLoaderVisible = (state: IGlobalLoaderState) =>
  state.globalLoaderVisible;
export const getGlobalLoaderTexts = (state: IGlobalLoaderState) =>
  state.loaderTexts;

export const globalLoaderSelectors = {
  globalLoaderVisible: createSelector(
    getRouterReducerState,
    getGlobalLoaderVisible,
  ),
  globalLoaderTexts: createSelector(
    getRouterReducerState,
    getGlobalLoaderTexts,
  ),
  globalLoaderText: createSelector(getGlobalLoaderTexts, (texts) => {
    if (!texts) {
      return null;
    }
    return texts[0] || "";
  }),
};
