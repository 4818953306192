export const weekDays = [
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
  "sun",
] as const;
export const THIRTY_MINUTES_IN_MILLISECONDS = 1800000;
