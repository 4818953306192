import { IDayOpeningHours, IOfficeWorkingTimesDto, IOpeningHours } from "types";
import moment, { Moment } from "moment";
import { THIRTY_MINUTES_IN_MILLISECONDS, weekDays } from "../../constants";
import { getWorkingHoursForGivenDay } from "shared-utils";

function getNextWorkingDay(
  allWorkingHours: IOpeningHours,
  currentWeekDay: keyof IOpeningHours,
): {
  nextWorkingDay: keyof IOpeningHours;
  daysTillNextWorkDay: number;
} | null {
  const currentDayIndex = weekDays.indexOf(currentWeekDay);

  const hasWorkingDays = Object.values(allWorkingHours).find((d) => d.isOpen);
  if (!hasWorkingDays) {
    return null;
  }

  let nextWorkingDayIndex = currentDayIndex === 6 ? 0 : currentDayIndex + 1;
  let workingHoursData = allWorkingHours[weekDays[nextWorkingDayIndex]];

  while (!workingHoursData.isOpen) {
    nextWorkingDayIndex =
      nextWorkingDayIndex === 6 ? 0 : nextWorkingDayIndex + 1;
    workingHoursData =
      allWorkingHours[weekDays[nextWorkingDayIndex] as keyof IOpeningHours];
  }

  const daysTillNextWorkDay =
    currentDayIndex < nextWorkingDayIndex
      ? nextWorkingDayIndex - currentDayIndex
      : 7 - currentDayIndex + nextWorkingDayIndex;

  return {
    nextWorkingDay: weekDays[nextWorkingDayIndex] as keyof IOpeningHours,
    daysTillNextWorkDay,
  };
}

function getPreviousWorkingDay(
  allWorkingHours: IOpeningHours,
  currentWeekDay: keyof IOpeningHours,
): {
  prevWorkingDay: keyof IOpeningHours;
  daysTillPrevWorkDay: number;
} | null {
  const currentDayIndex = weekDays.indexOf(currentWeekDay);

  const hasWorkingDays = Object.values(allWorkingHours).find((d) => d.isOpen);
  if (!hasWorkingDays) {
    return null;
  }

  let prevWorkingDayIndex = currentDayIndex === 0 ? 6 : currentDayIndex - 1;
  let workingHoursData = allWorkingHours[weekDays[prevWorkingDayIndex]];

  while (!workingHoursData.isOpen) {
    prevWorkingDayIndex =
      prevWorkingDayIndex === 0 ? 6 : prevWorkingDayIndex - 1;
    workingHoursData =
      allWorkingHours[weekDays[prevWorkingDayIndex] as keyof IOpeningHours];
  }

  const daysTillPrevWorkDay =
    currentDayIndex > prevWorkingDayIndex
      ? currentDayIndex - prevWorkingDayIndex
      : 7 - currentDayIndex - prevWorkingDayIndex;

  return {
    prevWorkingDay: weekDays[prevWorkingDayIndex] as keyof IOpeningHours,
    daysTillPrevWorkDay,
  };
}

export function getPreviousCurrentAndNextWorkingDayHours(
  startDay: moment.Moment,
  officeWorkingHours: IOpeningHours,
  officeId: string,
  timezone: string,
): IOfficeWorkingTimesDto {
  const weekDay = startDay
    .startOf("day")
    .locale("en")
    .format("ddd")
    .toLowerCase() as keyof IOpeningHours;
  const workingHoursData = officeWorkingHours[weekDay] as IDayOpeningHours;
  const isOfficeOpenForGivenDay = workingHoursData.isOpen;

  const nextWorkingDayData =
    getNextWorkingDay(officeWorkingHours, weekDay) || null;
  const prevWorkingDayData =
    getPreviousWorkingDay(officeWorkingHours, weekDay) || null;

  const { nextWorkingDay = null, daysTillNextWorkDay = null } =
    nextWorkingDayData || {};
  const { prevWorkingDay = null, daysTillPrevWorkDay = null } =
    prevWorkingDayData || {};

  const currentWorkingDayTimes = isOfficeOpenForGivenDay
    ? getWorkingHoursForGivenDay(
        workingHoursData,
        startDay.toDate().getTime(),
        timezone,
      )
    : null;
  const nextWorkingDayTimes = nextWorkingDay
    ? getWorkingHoursForGivenDay(
        officeWorkingHours[nextWorkingDay],
        startDay.add(daysTillNextWorkDay, "days").toDate().getTime(),
        timezone,
      )
    : null;
  const prevWorkingDayTimes = prevWorkingDay
    ? getWorkingHoursForGivenDay(
        officeWorkingHours[prevWorkingDay],
        startDay.subtract(daysTillPrevWorkDay, "days").toDate().getTime(),
        timezone,
      )
    : null;

  return {
    isOfficeOpenForGivenDay,
    currentWorkingDayTimes,
    nextWorkingDayTimes,
    prevWorkingDayTimes,
    officeId,
  };
}

export function getValidTimes(
  startTime: number | null,
  endTime: number | null,
  defaultTimesForGivenDay: { startTime: number; endTime: number },
  isFullDay: boolean,
): {
  validTimes: { startTime: number; endTime: number };
  navigationRequired: boolean;
} {
  if (isFullDay || !startTime || !endTime) {
    const navigationRequired =
      (startTime &&
        endTime &&
        startTime !== defaultTimesForGivenDay.startTime) ||
      endTime !== defaultTimesForGivenDay.endTime;
    return { validTimes: defaultTimesForGivenDay, navigationRequired };
  }

  // ! Here we need to test the cases where ST > day.ET, ET < day.ST, ST > ET
  const startTimeIsBad =
    startTime < defaultTimesForGivenDay.startTime ||
    startTime > defaultTimesForGivenDay.endTime;
  const endTimeIsBad =
    endTime < defaultTimesForGivenDay.startTime ||
    endTime > defaultTimesForGivenDay.endTime;
  let navigationRequired = startTimeIsBad || endTimeIsBad;

  const res = {
    startTime: startTimeIsBad ? defaultTimesForGivenDay.startTime : startTime,
    endTime: endTimeIsBad ? defaultTimesForGivenDay.endTime : endTime,
  };

  if (res.startTime > res.endTime) {
    navigationRequired = true;
    res.startTime = defaultTimesForGivenDay.startTime;
  }
  if (res.startTime === res.endTime) {
    navigationRequired = true;
    if (res.startTime === defaultTimesForGivenDay.startTime) {
      res.endTime = res.endTime + 30 * 60 * 1000;
    } else if (res.endTime === defaultTimesForGivenDay.endTime) {
      res.startTime = res.startTime - 30 * 60 * 1000;
    } else {
      res.endTime = res.endTime + 30 * 60 * 1000;
    }
  }

  return { validTimes: res, navigationRequired };
}

/** Find first available time slot (and day) based on office hours and office timezone */
export function findFirstAvailableSlotForGivenStartDayOrFirstFollowingWorkingDay(
  officeWorkingHours: IOpeningHours,
  officeId: string,
  officeTimezone: string,
  startDayTimestamp?: number,
): { startTime: number; endTime: number; isFullDay: boolean } {
  let isFullDay = false;

  const currentMoment = moment(startDayTimestamp || undefined).tz(
    officeTimezone,
  );
  let currentAndNextWorkingDayHours = getPreviousCurrentAndNextWorkingDayHours(
    currentMoment.clone(),
    officeWorkingHours,
    officeId,
    officeTimezone,
  );

  let currentFirstAvailableSlot = findFirstAvailableSlotForGivenTime(
    isFullDay,
    currentAndNextWorkingDayHours,
    currentMoment,
  );

  if (!currentFirstAvailableSlot) {
    const daysInWeek = 7;
    let dayForward = 1;
    while (!currentFirstAvailableSlot && dayForward < daysInWeek) {
      const nextDayMoment = currentMoment.clone().add(dayForward, "d");
      isFullDay = true;
      currentAndNextWorkingDayHours = getPreviousCurrentAndNextWorkingDayHours(
        nextDayMoment,
        officeWorkingHours,
        officeId,
        officeTimezone,
      );
      currentFirstAvailableSlot = findFirstAvailableSlotForGivenTime(
        isFullDay,
        currentAndNextWorkingDayHours,
        nextDayMoment,
      );
      dayForward++;
    }
  }

  if (!currentFirstAvailableSlot) {
    throw new Error(
      `Office configuration is not valid. There are no working days in office ${officeId}.`,
    );
  }

  return { ...currentFirstAvailableSlot, isFullDay };
}

/** Find first available time slot based on office hours, full day and CURRENT time in office's timezone. Returns void if the day is non-working one */
export function findFirstAvailableSlotToday(
  startDateInOfficeTimezone: moment.Moment,
  officeWorkingHours: IOpeningHours,
  officeId: string,
  isFullDay: boolean,
  officeTimezone: string,
): { startTime: number; endTime: number } | void {
  const currentAndNextWorkingDayHours =
    getPreviousCurrentAndNextWorkingDayHours(
      startDateInOfficeTimezone,
      officeWorkingHours,
      officeId,
      officeTimezone,
    );

  return findFirstAvailableSlotForGivenTime(
    isFullDay,
    currentAndNextWorkingDayHours,
    startDateInOfficeTimezone,
  );
}

/** Find first available time slot based on office hours, full day and given time in office's timezone. Return void is the day is non-working one. */
export function findFirstAvailableSlotForGivenTime(
  isFullDay: boolean,
  currentAndNextWorkingDayHours: IOfficeWorkingTimesDto,
  currentMoment: Moment,
): { startTime: number; endTime: number } | void {
  const {
    isOfficeOpenForGivenDay: _isOfficeOpenForGivenDay,
    currentWorkingDayTimes: _currentWorkingDayTimes,
  } = currentAndNextWorkingDayHours;

  // OFFICE IS OPEN (!)
  const isOfficeOpenForGivenDay =
    _isOfficeOpenForGivenDay && !!_currentWorkingDayTimes;
  if (!isOfficeOpenForGivenDay) {
    return;
  }
  const currentWorkingDayTimes = _currentWorkingDayTimes as {
    startTime: number;
    endTime: number;
  };

  const currentMomentTimestamp = currentMoment.toDate().getTime();

  const officeIsAboutToClose =
    currentWorkingDayTimes.endTime - currentMomentTimestamp <=
    THIRTY_MINUTES_IN_MILLISECONDS;
  // case 1 - full day is selected
  // ------- case 2.1 - office is not open yet today
  // ------- case 2.3 - office is already closed today
  if (isFullDay) {
    return currentWorkingDayTimes;
  }

  // case 2.2 - office is closing soon
  if (officeIsAboutToClose) {
    return {
      startTime:
        currentWorkingDayTimes.endTime - THIRTY_MINUTES_IN_MILLISECONDS, // is this selectable in the time input?
      endTime: currentWorkingDayTimes.endTime,
    };
  }

  // case 2.3 - office is yet not open for the day
  if (currentWorkingDayTimes.startTime > currentMomentTimestamp) {
    return {
      startTime: currentWorkingDayTimes.startTime,
      endTime: currentWorkingDayTimes.endTime,
    };
  }

  // case 2.4 - office is in the middle of the workday
  let [currentMomentHours, currentMomentMinutes] = currentMoment
    .format("HH:mm")
    .split(":")
    .map((d) => +d);

  if (currentMomentMinutes === 0) {
    return {
      startTime: currentMomentTimestamp,
      endTime: currentWorkingDayTimes.endTime,
    };
  }

  currentMomentMinutes =
    1 < currentMomentMinutes && currentMomentMinutes < 30 ? 30 : 0;
  currentMomentHours =
    currentMomentMinutes === 30 ? currentMomentHours : currentMomentHours + 1;

  const nextIntervalStart = currentMoment
    .set("hours", currentMomentHours)
    .set("minutes", currentMomentMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);
  const nextIntervalStartTimestamp = nextIntervalStart.toDate().getTime();

  return {
    startTime: nextIntervalStartTimestamp,
    endTime: currentWorkingDayTimes.endTime,
  };
}
