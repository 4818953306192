@if (!!label) {
  <label class="d-block small-text-bold">
    {{ label }}
  </label>
}
<db-dropdown
  [ngClass]="{
    'time-setter': true,
    'am-pm': timeFormat === 12,
    'no-border': outlineStyleType === 'dropdown-no-border',
  }"
  #timeInput="ngModel"
  [dataTestId]="dataTestId"
  [options]="options"
  [editable]="editable"
  [required]="required"
  [optionValue]="'value'"
  [optionLabel]="'title'"
  [styleClass]="
    outlineStyleType === 'dropdown'
      ? 'db-time-picker'
      : outlineStyleType === 'input'
        ? 'db-time-picker-as-input'
        : ''
  "
  panelStyleClass="db-p-time-picker-panel"
  appendTo="body"
  [ngModel]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  (onChange)="valueChangedHandler($event)"
>
</db-dropdown>
@if (timeInput.touched && timeInput.errors?.["invalidTime"]) {
  <small
    i18n="@@shared|time-picker-control|invalid-time"
    class="p-error d-block"
  >
    Invalid time.
  </small>
}
@if (timeInput.touched && timeInput.errors?.["required"]) {
  <small i18n="@@shared|field-required-error" class="p-error d-block">
    This field is required.
  </small>
}
