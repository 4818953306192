import { Component, Inject, OnInit } from "@angular/core";
import { WINDOW } from "common-module";
import { RouterModel } from "router-module";
import {
  combineLatest,
  filter,
  map,
  race,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs";
import { AuthModel } from "../+store/model";
import { ISlackQueryParams } from "../interfaces-and-types";

@Component({
  selector: "db-slack",
  templateUrl: "./slack.component.html",
  styleUrls: ["./slack.component.scss"],
})
export class SlackComponent implements OnInit {
  constructor(
    @Inject(WINDOW) private window: Window,
    private routerModel: RouterModel,
    private authModel: AuthModel,
  ) {}

  ngOnInit(): void {
    let slackData: ISlackQueryParams | null = null;
    const slackDataString = localStorage.getItem("slack-login");
    if (slackDataString) {
      try {
        slackData = JSON.parse(slackDataString);
      } catch (e) {
        console.error("Error parsing slack login data", e);
        slackData = null;
      }
    }
    combineLatest([
      this.routerModel.selectors.queryParams$,
      this.authModel.user$,
      [slackData],
    ])
      .pipe(
        take(1),
        map(([queryParams, user, slackData]) => {
          const slackUserUuid = queryParams["slackUserUuid"] as string;
          const slackTeamUuid = queryParams["slackTeamUuid"] as string;
          const slackRedirect = queryParams["slackRedirect"] as string;
          const slackRequestTimestamp = queryParams["timestamp"] as string;
          const slackSignature = queryParams["signature"] as string;
          const queryParamsSlackData: ISlackQueryParams = {
            slackUserUuid,
            slackTeamUuid,
            slackRedirect,
            slackRequestTimestamp,
            slackSignature,
          };
          return [
            queryParams,
            user,
            slackData || queryParamsSlackData,
          ] as const;
        }),
        tap(([, user, slackData]) => {
          localStorage.removeItem("slack-login");
          if (!user && typeof slackData === "object" && slackData !== null) {
            localStorage.setItem("slack-login", JSON.stringify(slackData));
            this.routerModel.actions.dispatch.navigateByUrl({ url: "/login" });
          }
        }),
        filter(([, user]) => !!user),
        withLatestFrom(this.authModel.corporateInfo$),
        switchMap(([[, user, slackData], corporateInfo]) => {
          const deskbirdUserUuid = user!.uuid;
          const deskbirdCompanyUuid = corporateInfo!.uuid;
          const {
            slackUserUuid,
            slackTeamUuid,
            slackRedirect,
            slackRequestTimestamp,
            slackSignature,
          } = slackData;

          this.authModel.actions.dispatch.sendSlackData({
            slackUserUuid,
            slackTeamUuid,
            slackRedirect,
            slackRequestTimestamp,
            slackSignature,
            deskbirdUserUuid,
            deskbirdCompanyUuid,
          });

          return race(
            this.authModel.actions.listen.sendSlackDataSuccess$.pipe(
              map(() => ({ success: true, redirectUrl: slackRedirect })),
            ),
            this.authModel.actions.listen.sendSlackDataFailure$.pipe(
              map(() => ({ success: false, redirectUrl: null })),
            ),
          ).pipe(take(1));
        }),
        filter(({ success }) => success),
      )
      .subscribe(({ redirectUrl }): void => {
        this.window.location.href = redirectUrl!;
      });
  }
}
