import { localesWith12hFormat } from "../constants";
import { TimeFormat } from "types";

export const estimateTimeFormatsFromNavigatorLanguages = (): TimeFormat => {
  const languages = (navigator.languages || [navigator.language]).filter(
    (d) => !!d,
  );

  if (!languages.length) {
    return TimeFormat.NOT_SET;
  }
  const hasLocaleWith12hFormat = languages.find((language) =>
    localesWith12hFormat.includes(language),
  );
  return hasLocaleWith12hFormat
    ? TimeFormat.TWELVE_HOUR
    : TimeFormat.TWENTY_FOUR_HOUR;
};

export const estimatedTimeFormatFromLocaleDate = (): TimeFormat => {
  const localeTimeStringIs12hFormat = new Date()
    .toLocaleTimeString()
    .toLowerCase()
    .includes("m");
  return localeTimeStringIs12hFormat
    ? TimeFormat.TWELVE_HOUR
    : TimeFormat.TWENTY_FOUR_HOUR;
};

export const estimateTimeFormats = (): TimeFormat => {
  return estimatedTimeFormatFromLocaleDate();
};
