import { Injectable } from "@angular/core";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";
import { internalizationBundles } from "./bundles";
import { internalizationSelectors } from "./selectors";

@Injectable({ providedIn: "root" })
export class InternalizationModel {
  selectors = connectSelectors(internalizationSelectors);
  actions = connectBundles(internalizationBundles);
}
