export enum QrGeneratorCurrentView {
  StickerBuilder = "sticker-builder",
  Download = "download",
}

export enum QrGenerationLevel {
  Office = "office",
  Floor = "floor",
  Area = "area",
  Resource = "resource",
}
