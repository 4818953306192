import { InjectionToken } from "@angular/core";

export const SlideOverComponentToken = new InjectionToken(
  "SlideOverComponentToken",
);
export const SlideOverComponentData = new InjectionToken(
  "SlideOverComponentData",
);
export const SlideOverVisibleComponent = new InjectionToken(
  "SlideOverVisibleComponent",
);
