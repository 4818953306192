export enum IntegrationType {
  HRIS_TIME_OFF = "hris time off",
  HRIS_ORGANIZATION = "hris organization",
  CALENDAR = "calendar",
}

export enum CalendarIntegrationFeatureType {
  CALENDAR_INTEGRATION_SCHEDULING_FEATURE = "scheduling",
  CALENDAR_INTEGRATION_OFFICE_EVENTS_FEATURE = "office_events",
  CALENDAR_INTEGRATION_MEETING_ROOMS_FEATURE = "meeting_rooms",
}

export enum CalendarIntegrationType {
  CALENDAR = "calendar",
}
