import { Component, inject } from "@angular/core";
import { RouterModel } from "router-module";
import { combineLatest, filter, map, race, switchMap, take, tap } from "rxjs";
import { TeamsService } from "../services/teams.service";
import { NotificationModel, NotificationType } from "notification-module";
import { LoadingTenseState } from "shared";
import { LoginType } from "types";
import { AuthModel } from "../+store/model";

@Component({
  selector: "db-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent {
  private authModel = inject(AuthModel);
  private routerModel = inject(RouterModel);
  private teamsService = inject(TeamsService);
  private notificationModel = inject(NotificationModel);

  isTeamsAppInitialized$ = this.teamsService.initialized$;

  emailCheckResult$ = combineLatest([
    this.authModel.selectors.emailCheckResult$.pipe(filter((d) => !!d)),
    this.isTeamsAppInitialized$,
  ]).pipe(
    map(([emailCheckResult, isTeamsAppInitialized]) => {
      if (!isTeamsAppInitialized) {
        return emailCheckResult;
      }
      return {
        ...emailCheckResult,
        loginMethods: {
          ...emailCheckResult?.loginMethods,
          google: { enabled: false },
        },
      };
    }),
  );

  LoadingTenseState = LoadingTenseState;
  selectedLoginType:
    | LoginType.Google
    | LoginType.Microsoft
    | LoginType.Saml
    | null = null;
  LoginType = LoginType;

  get isSigningInWithGoogle() {
    return this.selectedLoginType === LoginType.Google;
  }
  get isSigningInWithMicrosoft() {
    return this.selectedLoginType === LoginType.Microsoft;
  }
  get isSigningInWithSAML() {
    return this.selectedLoginType === LoginType.Saml;
  }

  login(loginType: LoginType): void {
    if (loginType === LoginType.Email) return;
    this.selectedLoginType = loginType;
    this.emailCheckResult$
      .pipe(
        take(1),
        map((emailCheckResult) =>
          loginType === LoginType.Saml
            ? {
                providerId:
                  emailCheckResult?.loginMethods.saml?.providerId || "",
                loginType,
              }
            : { loginType },
        ),
        tap((actionPayload): void => {
          if (
            actionPayload.loginType !== LoginType.Saml ||
            actionPayload.providerId !== ""
          )
            return void this.authModel.actions.dispatch.login(
              actionPayload as unknown as any,
            );
          this.notificationModel.actions.create.showNotification({
            data: $localize`:@@auth-module|error-no-saml-provider:No SAML provider.`,
            notificationType: NotificationType.ERROR,
          });
        }),
        filter((val) =>
          loginType === LoginType.Saml ? !!val.providerId : true,
        ),
        switchMap(() =>
          race(
            this.authModel.actions.listen.loginSuccess$.pipe(
              map((payload) => ({ user: payload.user, error: null })),
            ),
            this.authModel.actions.listen.microsoftTeamsLoginSuccess$.pipe(
              map((payload) => ({ user: payload.user, error: null })),
            ),
            this.authModel.actions.listen.loginFailure$.pipe(
              map((payload) => ({ user: null, error: payload.error })),
            ),
          ),
        ),
      )
      .subscribe(({ error }) => {
        this.selectedLoginType = null;
        if (error) return;
        this.authModel.actions.dispatch.checkEmailCleanup();
        this.routerModel.actions.dispatch.navigate({
          commands: ["/default"],
          extras: { queryParamsHandling: "preserve" },
        });
      });
  }

  onEmailSignIn(): void {
    this.routerModel.actions.dispatch.navigate({
      commands: ["login", "sign-in-email"],
      extras: { queryParamsHandling: "preserve" },
    });
  }

  onEmailSignUp(): void {
    this.routerModel.actions.dispatch.navigate({
      commands: ["login", "sign-up-wizard"],
      extras: {
        queryParams: { page: "sign-up" },
        queryParamsHandling: "merge",
      },
    });
  }
}
