import { DeskAreaType } from "types/enums";

export interface IOfficeEquipmentItemBasicInfo {
  id: string;
  name: string;
}

export interface IOfficeEquipmentItem extends IOfficeEquipmentItemBasicInfo {
  resourceType: DeskAreaType;
  associatedResourcesCount?: number;
}

export interface IOfficeEquipmentItemDto extends IOfficeEquipmentItem {
  label: string;
}

export interface IOfficeEquipmentFilterItemDto
  extends IOfficeEquipmentItemBasicInfo {
  resourceType: DeskAreaType;
  officeId: string;
}
