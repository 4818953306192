import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { ColorPickerModule, ColorPicker } from "primeng/colorpicker";
import { EventEmitterValue } from "shared-types";

@Component({
  selector: "db-color-picker",
  standalone: true,
  imports: [FormsModule, ColorPickerModule],
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ColorPickerComponent,
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
  cd = inject(ChangeDetectorRef);
  @ViewChild(ColorPicker, { read: ColorPicker, static: true })
  primeColorPicker!: ColorPicker;

  @Input() style: any;
  @Input() format: string = "hex";
  @Input() disabled: boolean = false;
  @Output() onChange = new EventEmitter<
    EventEmitterValue<ColorPicker["onChange"]>
  >();
  @Output() onShow = new EventEmitter<
    EventEmitterValue<ColorPicker["onShow"]>
  >();
  @Output() onHide = new EventEmitter<
    EventEmitterValue<ColorPicker["onHide"]>
  >();

  show!: ColorPicker["show"];
  hide!: ColorPicker["hide"];

  touched = false;
  value: string = "";

  changeFn!: (value: any) => void;
  touchFn!: () => void;

  // @Input() styleClass: string;
  // @Input() inline: boolean;
  // @Input() appendTo: any;
  // @Input() tabindex: string;
  // @Input() inputId: string;
  // @Input() autoZIndex: boolean = true;
  // @Input() baseZIndex: number = 0;
  // @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';
  // @Input() hideTransitionOptions: string = '.1s linear';
  // @Output() onShow: EventEmitter<any> = new EventEmitter();
  // @Output() onHide: EventEmitter<any> = new EventEmitter();

  constructor() {}

  valueChangedHandler(value: any): void {
    this.value = value;
    if (this.changeFn) {
      this.changeFn(value);
    }

    this.registerOnTouched(value);
    this.onChange.emit(value);
    this.cd.detectChanges();
  }

  writeValue(value: any): void {
    this.value = value;
    this.primeColorPicker.writeValue(value);
    this.cd.detectChanges();
  }
  registerOnChange = (fn: any): void => {
    this.changeFn = fn;
  };

  registerOnTouched = (fn: any): void => {
    this.touchFn = fn;
  };
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.primeColorPicker.onChange.subscribe(this.onChange);

    this.show = this.primeColorPicker.show.bind(this.primeColorPicker);
    this.hide = this.primeColorPicker.hide.bind(this.primeColorPicker);
  }
}
