import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "idList",
  standalone: true,
})
export class IdListPipe implements PipeTransform {
  transform<T extends { id: string }>(values: T[]): string[] {
    return values.map((value) => value.id);
  }
}
