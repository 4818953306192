import { Injectable, inject } from "@angular/core";
import { createEffect } from "@ngrx/effects";
import { QrGenerationModel } from "./model";
import {
  catchError,
  combineLatest,
  map,
  switchMap,
  take,
  takeUntil,
} from "rxjs";
import { QrGenerationService } from "../../../../../qr-generation-module/src/lib/qr-generation.service";
import { NotificationModel } from "notification-module";
import { WindowService } from "global-module";
import { API_URL } from "common-module";
import { HttpErrorResponse } from "@angular/common/http";
import { downloadFile } from "shared-utils";
import { AuthModel } from "auth-module";

@Injectable({
  providedIn: "root",
})
export class QrGenerationEffects {
  authModel = inject(AuthModel);
  qrGenerationModel = inject(QrGenerationModel);
  qrGenerationService = inject(QrGenerationService);
  notificationModel = inject(NotificationModel);
  windowService = inject(WindowService);
  apiUrl = inject(API_URL);

  loadQrStyles = createEffect(() =>
    this.qrGenerationModel.actions.listen.loadQrOfficeTemplate$.pipe(
      switchMap(({ officeId }) =>
        this.qrGenerationService.loadQrOfficeTemplate(officeId).pipe(
          takeUntil(
            this.qrGenerationModel.actions.listen.loadQrOfficeTemplateCancel$,
          ),
          map((template) =>
            this.qrGenerationModel.actions.create.loadQrOfficeTemplateSuccess({
              template,
            }),
          ),
        ),
      ),
    ),
  );

  saveQrStyles = createEffect(() =>
    this.qrGenerationModel.actions.listen.updateQrOfficeTemplate$.pipe(
      switchMap(({ officeId, payload }) =>
        this.qrGenerationService.updateQrOfficeTemplate(officeId, payload).pipe(
          takeUntil(
            this.qrGenerationModel.actions.listen.updateQrOfficeTemplateCancel$,
          ),
          map((template) => {
            this.qrGenerationModel.actions.dispatch.setQrOfficeTemplate(
              template,
            );
            this.qrGenerationModel.actions.dispatch.downloadQrCodes({
              officeId,
            });
            return this.qrGenerationModel.actions.create.updateQrOfficeTemplateSuccess(
              template,
            );
          }),
          catchError((error) => {
            return [
              this.qrGenerationModel.actions.create.updateQrOfficeTemplateFailure(
                { error: error.error.message },
              ),
            ];
          }),
        ),
      ),
    ),
  );

  downloadQrCodes = createEffect(() =>
    this.qrGenerationModel.actions.listen.downloadQrCodes$.pipe(
      switchMap(({ officeId }) =>
        combineLatest(
          this.authModel.idToken$,
          this.qrGenerationModel.selectors.downloadParameters$,
        ).pipe(
          take(1),
          switchMap(([idToken, parameters]) => {
            if (!idToken) {
              return [
                this.qrGenerationModel.actions.create.downloadQrCodesFailure({
                  error: new HttpErrorResponse({ error: "Auth token missing" }),
                }),
              ];
            }

            const { floorId, areaId, resourceId } = parameters;

            let path = `/offices/${officeId}/qrcode/download`;

            if (floorId) {
              path += `?floor=${floorId}`;
            }

            if (areaId) {
              path += `&area=${areaId}`;
            }

            if (resourceId) {
              path += `&resource=${resourceId}`;
            }

            downloadFile(
              this.windowService.window.document,
              this.apiUrl + "/v1.2" + path,
              idToken,
            );

            return [
              this.qrGenerationModel.actions.create.downloadQrCodesSuccess(),
            ];
          }),
        ),
      ),
    ),
  );
}
