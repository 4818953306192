import { Component, HostBinding, Input } from "@angular/core";
import { Lottie } from "lottie-module";
@Component({
  selector: "db-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  rafId: number | null = null;
  @HostBinding("class.loading") _isLoading = false;

  @Input() set isLoading(value) {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }
    if (this._isLoading === value) {
      return;
    }
    this.rafId = requestAnimationFrame(() => {
      this._isLoading = value;
    });
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Input() isFixed = false;
  @Input() noShadow = false;
  @Input() loaderText!: string;

  @Input() size: string | undefined;
  @Input() top: string | undefined;

  loaderLottie = Lottie.LOADING_SPINNER;

  constructor() {}
}
