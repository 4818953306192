import { forNamespace } from "ngrx-action-bundles";
import { NotificationType } from "../enums";
import { props } from "@ngrx/store";

const factory = forNamespace("NOTIFICATION");

export const showNotificationBundle = factory.singleAction(
  "showNotification",
  props<{
    data: string;
    notificationType: NotificationType;
    link?: { route: String; label: string };
  }>(),
);

export const notificationBundles = [showNotificationBundle];
