import { AccessEntityType } from "../enums";

export interface IAccessEntity {
  id: number | string;
  _id?: string;
  icon?: string;
  title: string;
  count?: number;
  color: string;
  type: AccessEntityType;
  isUserGroup: boolean;
  isInactive?: boolean;

  children?: IAccessEntity[];
  extraInfo?: string;

  isRemoved?: boolean;
  imgSrc?: string;
}
