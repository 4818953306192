export interface MyTeamOverview {
  pendingRequests: number;
  policyCompliance: number;
  totalUsers: number;
}

export interface MyTeamValidation {
  total: number;
  limit: number;
  offset: number;
  data: PendingPolicyValidationModel[];
}

export interface PendingPolicyValidationModel {
  userId: string;
  scheduling: SchedulingModel[];
  isCompliant: boolean;
  policyId: string;
}

export interface SchedulingModel {
  id: string;
  day: string;
  userId: string;
  bookingIds: string[];
  companyId: string;
  optionId: string;
  officeId?: string;
  anonymized: boolean;
  lastUpdatedBy?: ILastUpdatedBy | null;
  uuid: string;
  externalStatus: boolean;
  externalStatusId?: string | null;
  externalCalendarEventId: string | null;
  validationStatus: HybridWorkPolicyValidationStatus | null;
}

export interface ILastUpdatedBy {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  profileImage: string;
  updatedAt: number;
}

export enum HybridWorkPolicyValidationStatus {
  APPROVED = "approved",
  REJECTED = "rejected",
}

export interface HybridWorkPolicyValidationResultModel {
  id: string;
  policyId: string;
  companyId: string;
  userId: string;
  approvedBy: string;
  approvedAt: Date;
  status: HybridWorkPolicyValidationStatus;
  isoWeekYear: string;
  note: string | null;
  days: HybridWorkPolicyValidationDay[];
}

export interface HybridWorkPolicyValidationDay {
  day: string;
  optionId?: string;
  officeId?: string;
}
