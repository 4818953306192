import moment from "moment";
import { IDeskArea } from "types";
import {
  allMeetingRoomsSectionPadding,
  allMeetingRoomsSectionWidth,
} from "../constants";

export const availabilityColors = {
  available: "#31CCA1",
  available_short: "#E59A07",
  busy: "#FC7373",
};

export const meetingRoomIsAvailable = (deskArea: IDeskArea): boolean => {
  const { availability } = deskArea;
  const hasUsers = availability?.users?.length;
  if (!availability || !hasUsers) {
    return true;
  }
  const allBookingData = availability.zoneItems.reduce(
    (acc, curr) => acc.concat(curr.users),
    [] as any[],
  );
  const now = new Date().getTime();
  const currentMeeting = allBookingData.find(
    (u) => u.startTime <= now && now <= u.endTime,
  );
  return !currentMeeting;
};

export const meetingRoomAvailabilityColor = (deskArea: IDeskArea): string => {
  const isAvailable = meetingRoomIsAvailable(deskArea);
  return isAvailable ? availabilityColors.available : availabilityColors.busy;
};

export const meetingRoomAvailabilityTitle = (deskArea: IDeskArea): string => {
  const isAvailable = meetingRoomIsAvailable(deskArea);
  return isAvailable
    ? $localize`:@@desk-area-module|meeting-room-is-available:Available`
    : $localize`:@@desk-area-module|meeting-room-is-not-available:Busy`;
};

export const meetingRoomNextMeeting = (
  deskArea: IDeskArea,
  timezone: string,
  timeFormat: string,
): string => {
  const { availability } = deskArea;

  if (!availability) {
    return "";
    // return $localize`:@@desk-area-module|meeting-room-available-for-rest-of-day:For the rest of the day`;
  }
  const allBookingData = availability.zoneItems.reduce(
    (acc, curr) => acc.concat(curr.users),
    [] as any[],
  );
  if (!allBookingData || allBookingData.length === 0) {
    return "";
    // return $localize`:@@desk-area-module|meeting-room-available-for-rest-of-day:For the rest of the day`;
  }

  const now = new Date().getTime();

  const currentMeetingIdx = allBookingData.findIndex(
    (u) => u.startTime <= now && now <= u.endTime,
  );
  const currentMeeting = allBookingData[currentMeetingIdx];

  const nextMeeting = allBookingData.find((u) => now <= u.startTime);
  if (!nextMeeting && !currentMeeting) {
    return "";
    // return $localize`:@@desk-area-module|meeting-room-available-for-rest-of-day:For the rest of the day`;
  }

  if (!currentMeeting) {
    const nextMeetingTime = moment(nextMeeting.startTime)
      .tz(timezone)
      .format(timeFormat);
    return $localize`:@@desk-area-module|meeting-room-next-meeting-available-now:Next meeting at ${nextMeetingTime}`;
  }

  if (!nextMeeting) {
    const currentMeetingEndTime = currentMeeting
      ? findNextFreeTime(
          allBookingData.slice(currentMeetingIdx),
          timezone,
          timeFormat,
        ) // moment(currentMeeting.endTime).tz(timezone).format('HH:mm')
      : "";
    return $localize`:@@desk-area-module|meeting-room-next-meeting-busy-now:Available from ${currentMeetingEndTime}`;
  }

  return "";
};

const findNextFreeTime = (
  bookings: any[],
  timezone: string,
  timeFormat: string,
): string => {
  const bookingTimes = bookings.map(({ startTime, endTime }) => ({
    startTime,
    endTime,
  }));
  let idx = 0;
  let foundValidTime = false;
  let time = null;
  while (!foundValidTime && idx < bookingTimes.length) {
    const b = bookingTimes[idx];
    if (!time) {
      time = b.endTime;
      idx++;
      continue;
    }
    if (b.startTime > time) {
      foundValidTime = true;
      continue;
    }
    time = b.endTime;
    idx++;
  }

  return moment(time).tz(timezone).format(timeFormat);
};

const borderWidth = 1;
export const calculateMeetingRoomCardOffsets = (
  startMoment: moment.Moment,
  endMoment: moment.Moment,
): { width: number; offset: number } => {
  let durationInMinutes = endMoment.diff(startMoment, "minutes");
  if (durationInMinutes > 1440) {
    durationInMinutes = durationInMinutes - 1440;
  }

  const sectionWidths = durationInMinutes / 60;
  const sectionOffsets =
    startMoment.get("hours") + startMoment.get("minutes") / 60;

  const width = Math.round(
    sectionWidths * allMeetingRoomsSectionWidth +
      (sectionWidths - 1) -
      allMeetingRoomsSectionPadding * 2 -
      borderWidth * 2 -
      1,
  );
  const offset = Math.round(
    sectionOffsets * allMeetingRoomsSectionWidth +
      1 +
      allMeetingRoomsSectionPadding,
  );

  return { width, offset };
};

export const calculateMeetingRoomCardBufferTimesOffsets = (
  startMoment: moment.Moment,
  endMoment: moment.Moment,
  bufferBefore: number,
  bufferAfter: number,
): {
  widthBefore: number;
  offsetBefore: number;
  widthAfter: number;
  offsetAfter: number;
} => {
  let durationInMinutes = endMoment.diff(startMoment, "minutes");
  if (durationInMinutes > 1440) {
    durationInMinutes = durationInMinutes - 1440;
  }

  const sectionWidthsBefore = bufferBefore / 60;
  const sectionOffsetsBefore =
    startMoment.get("hours") +
    startMoment.get("minutes") / 60 -
    bufferBefore / 60;

  const sectionWidthsAfter = bufferAfter / 60;
  const sectionOffsetsAfter =
    startMoment.get("hours") +
    startMoment.get("minutes") / 60 +
    durationInMinutes / 60;

  const widthBefore = Math.round(
    sectionWidthsBefore * allMeetingRoomsSectionWidth +
      (sectionWidthsBefore - 1) -
      allMeetingRoomsSectionPadding * 2 -
      borderWidth * 2 -
      1,
  );
  const offsetBefore = Math.round(
    sectionOffsetsBefore * allMeetingRoomsSectionWidth +
      1 +
      allMeetingRoomsSectionPadding,
  );

  const widthAfter = Math.round(
    sectionWidthsAfter * allMeetingRoomsSectionWidth +
      (sectionWidthsAfter - 1) -
      allMeetingRoomsSectionPadding * 2 -
      borderWidth * 2 -
      1,
  );
  const offsetAfter = Math.round(
    sectionOffsetsAfter * allMeetingRoomsSectionWidth +
      1 +
      allMeetingRoomsSectionPadding,
  );

  return { widthBefore, offsetBefore, widthAfter, offsetAfter };
};
