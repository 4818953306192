import { IDeskAreaItemForAvailabilitySummary } from "./desk-area-item";

interface IAvailabilityUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  color: string;
  isAnonymous: boolean;
  avatarUrl: string;
  isGuest: boolean;
}
export interface IAvailability {
  users: IAvailabilityUser[];
  used: number;
  total: number;
  available: number;
  zoneItems: IDeskAreaItemForAvailabilitySummary[];
}
