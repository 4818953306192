<div
  (click)="canOpenProfile ? openProfileHandler($event) : null"
  [class]="avatarSize"
  [ngClass]="{ 'hover-style': canOpenProfile }"
  [pTooltip]="showTooltips ? avatarTooltip : ''"
  tooltipPosition="top"
>
  @if (imageUrl) {
    <div class="avatar" [ngStyle]="{ background: 'url(' + imageUrl + ')' }">
      <div
        class="hover-overlay"
        [ngClass]="{ 'hover-disabled': !canOpenProfile }"
        data-testId="db-ui--user-avatar"
      ></div>
    </div>
  } @else {
    <div
      class="initials"
      [ngStyle]="{
        background: userInfo?.avatarColor || userInfo?.color || '#A5A5A5',
      }"
    >
      {{
        name || userInfo?.firstName + " " + userInfo?.lastName
          | exec: getAbbreviation
      }}
      <div
        class="hover-overlay"
        [ngClass]="{ 'hover-disabled': !canOpenProfile }"
        data-testId="db-ui--user-avatar"
      ></div>
    </div>
  }

  <ng-template #initials>
    <div
      class="initials"
      [ngStyle]="{
        background: userInfo?.avatarColor || userInfo?.color || '#A5A5A5',
      }"
    >
      {{
        name || userInfo?.firstName + " " + userInfo?.lastName
          | exec: getAbbreviation
      }}
      <div
        class="hover-overlay"
        [ngClass]="{ 'hover-disabled': !canOpenProfile }"
        data-testId="db-ui--user-avatar"
      ></div>
    </div>
  </ng-template>

  @if (
    showFavUserIcon &&
    userInfo?.followingStatus === FollowingStatusType.FOLLOWING
  ) {
    <div class="favorite-star">
      <img src="/assets/icons/purple-star.svg" />
    </div>
  }
</div>
