import { NgModule, Provider, Type } from "@angular/core";

export function attachLazyLoadedModuleProviders<T extends NgModule>(
  module: Type<T>,
  providers: Provider[],
): Type<T> {
  (module as any)["ɵinj"].providers = (
    (module as any)["ɵinj"].providers || []
  ).concat(providers);
  return module;
}
