import { Pipe, PipeTransform } from "@angular/core";
import { IUserInfo } from "types";
import { userName } from "shared-utils";

@Pipe({ name: "userName", standalone: true })
/** Prepare user name string taking into account anonymous and guest labeling */
export class UserNamePipe implements PipeTransform {
  transform(userInfo?: IUserInfo): string {
    return userName(userInfo);
  }
}
