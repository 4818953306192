import { Component, inject } from "@angular/core";
import { AuthModel } from "../+store/model";

@Component({
  selector: "db-no-access",
  templateUrl: "./no-access.component.html",
  styleUrls: ["./no-access.component.css"],
})
export class NoAccessComponent {
  authModel = inject(AuthModel);

  logout(): void {
    this.authModel.actions.dispatch.logout({});
  }
}
