<div id="tree-node-container">
  <div
    id="tree-node-info"
    [ngStyle]="{
      opacity: hoveredItem !== this && hoveredItem !== null ? 0.6 : 1,
    }"
  >
    <div
      id="tree-node-name-action-holder"
      (click)="toggleExpandHandler()"
      [style.width.px]="
        treeNodeNameColumnWidthPx -
        (skipInitialIndent ? level - 1 : level) * indentSpacePx +
        (skipInitialIndent ? level * indentSpacePx : 0)
      "
      [style.paddingLeft.px]="level * indentSpacePx"
    >
      <div id="tree-node-name-action-holder-container">
        @if (hasExpandButton) {
          <div id="tree-node-action">
            <i
              [ngClass]="{
                'toggle-minus': isExpanded,
                'toggle-plus': !isExpanded,
              }"
            ></i>
          </div>
        }

        <div
          id="tree-node-name"
          (mouseenter)="mouseenterListenerHandler()"
          (mouseleave)="mouseleaveListenerHandler()"
          [style.paddingLeft.px]="!hasExpandButton ? 20 : 0"
        >
          <span
            [ngClass]="{
              bold: node | exec: boldNamePredFn,
              hovered: isHovered,
            }"
            [pTooltip]="node.name"
            [showDelay]="1000"
            tooltipPosition="top"
            >{{ node.name }}</span
          >
          @if (node.details) {
            <span class="details">
              {{ node.details }}
            </span>
          }
        </div>
      </div>
    </div>

    <div
      id="tree-node-content"
      [style.width]="
        'calc(100% - ' +
        (treeNodeNameColumnWidthPx -
          (skipInitialIndent ? level - 1 : level) * indentSpacePx +
          (skipInitialIndent ? level * indentSpacePx : 0)) +
        'px)'
      "
    >
      <div id="tree-node-content-container">
        @if (nodeTemplateRef) {
          <ng-container
            *ngTemplateOutlet="
              nodeTemplateRef;
              context: {
                $implicit: node,
                level: level,
                index: index,
                parentNode: parentNode,
                parentNodeComponent: parentNodeComponent,
                toggleHoveredHandler: toggleHoveredHandler,
                isHovered: isHovered,
                toggleExpandHandler: toggleExpandHandler,
              }
            "
          ></ng-container>
        }
      </div>
    </div>
  </div>

  <div id="tree-node-children">
    @if (isExpanded && hasMoreChildLevels) {
      <div id="line" [style.left.px]="(level + 1) * indentSpacePx + 5.5"></div>
    }
    @if (isExpanded) {
      <div id="tree-node-children-holder">
        @for (node of node.children; track node; let idx = $index) {
          <db-tree-node
            [node]="node"
            [index]="idx"
            [level]="level + 1"
            [parentNode]="node"
            [parentNodeComponent]="this"
            [nodeTemplateRef]="nodeTemplateRef"
            [skipInitialIndent]="skipInitialIndent"
            [indentSpacePx]="indentSpacePx"
            [boldNamePredFn]="boldNamePredFn"
            [treeNodeNameColumnWidthPx]="treeNodeNameColumnWidthPx"
            [hoveredItem]="hoveredItem"
            (hoverItem)="hoverItemHandler($event)"
          ></db-tree-node>
        }
      </div>
    }
  </div>
</div>
