import { AccessEntityType } from "../enums";
import { IAccessEntity, IUserGroup } from "../interfaces";

export function generateRecursiveUserGroupAccessEntities(
  userGroups?: IUserGroup[],
  result: IAccessEntity[] = [],
): IAccessEntity[] {
  userGroups?.forEach((u) => {
    result.push({
      id: AccessEntityType.TEAM + "-" + u.id,
      _id: u.id,
      title: u.name,
      color: "#ff1658",
      type: AccessEntityType.TEAM,
      count: u.userIds.length,
      isUserGroup: true,
    });

    if (Array.isArray(u.children) && u.children.length > 0) {
      generateRecursiveUserGroupAccessEntities(u.children, result);
    }
  }) || [];

  return result;
}

export function generateUserGroupAccessEntities(
  userGroups?: IUserGroup[],
  result: IAccessEntity[] = [],
): IAccessEntity[] {
  userGroups?.forEach((u) => {
    result.push({
      id: AccessEntityType.TEAM + "-" + u.id,
      _id: u.id,
      title: u.name,
      color: "#ff1658",
      type: AccessEntityType.TEAM,
      count: u.userIds.length,
      isUserGroup: true,
    });
  }) || [];

  return result;
}
