import { CommonModule } from "@angular/common";
import { Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { CardModule } from "primeng/card";

@Component({
  selector: "db-card",
  templateUrl: "./card.component.html",
  standalone: true,
  imports: [CardModule, CommonModule],
})
export class CardComponent {
  @Input() isUiCard = false;
  @Input() titleAboveCard: string | undefined = undefined;
  @ContentChild("headerTemplate")
  headerTemplate?: TemplateRef<any>;
}
