export interface IDailyOfficeViewStatistics {
  label: string;
  labelLong: string;
  id: string;
  assignedUsersCount: number;
  iconPath?: string;
}

export interface IFloorPlanOfficeRoleStatistics {
  label: string;
  id: string;
  assignedUsersCount: number;
}
