import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  OfficePlanningStatusOptionType,
  IOfficePlanningStatusOption,
} from "types";

@Component({
  selector: "db-daily-status-option-label",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./daily-status-option-label.component.html",
  styleUrls: ["./daily-status-option-label.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyStatusOptionLabelComponent {
  protected readonly optionType = OfficePlanningStatusOptionType;
  @Input() status: IOfficePlanningStatusOption | null = null;
}
