import moment from "moment";

//** Default sorting function when table has customSort enabled based on prime documentation https://primeng.org/table#custom-sort */
export const regularSort = (value1: any, value2: any) => {
  let result = null;
  if (value1 == null && value2 != null) result = -1;
  else if (value1 != null && value2 == null) result = 1;
  else if (value1 == null && value2 == null) result = 0;
  else if ((typeof value1 as string) && (typeof value2 as string)) {
    result = String(value1).localeCompare(String(value2));
  } else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

  return result;
};

export const sortByFullName = (
  firstName1?: string,
  firstName2?: string,
  lastName1?: string,
  lastName2?: string,
) => {
  const fullName1 = `${firstName1} ${lastName1}`;
  const fullName2 = `${firstName2} ${lastName2}`;

  return fullName1.localeCompare(fullName2);
};

export const sortByDateTime = (
  dateTime1: string,
  dateTime2: string,
  sortDirection: "asc" | "desc",
) => {
  const value1 = moment.utc(dateTime1).valueOf();
  const value2 = moment.utc(dateTime2).valueOf();

  let result = null;
  if (value1 == null && value2 != null) result = -1;
  else if (value1 != null && value2 == null) result = 1;
  else if (value1 == null && value2 == null) result = 0;
  else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

  return sortDirection === "asc" ? result : -result;
};
