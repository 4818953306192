<db-card>
  <div class="wrapper">
    @if (showLottie$ | async) {
      <db-lottie-player
        width="250px"
        height="250px"
        margin="auto"
        [lottie]="Lottie.CHECK_IN"
      >
      </db-lottie-player>
    }
    <p i18n="@@auth-module|not-active-company|message-inactive">
      Your company does not seem to be using deskbird yet.
    </p>
    <h1 i18n="@@auth-module|not-active-company|message-start">
      Get started now!
    </h1>
    <div>
      <db-button
        (click)="requestDemoClickHandler()"
        i18n="@@auth-module|not-active-company|request-demo"
      >
        Request a demo
      </db-button>
      <a
        [routerLink]="['/login']"
        queryParamsHandling="preserve"
        class="big-link"
        i18n="@@auth-module|go-back"
        >Go back</a
      >
    </div>
  </div>
</db-card>
