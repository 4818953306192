import { ICompany, ICorporateInfo, IIntegration } from "../interfaces";

export const filterEnabledIntegrations = (
  integrations: IIntegration[],
): IIntegration[] => {
  return integrations.filter((i) => i.isEnabled);
};

export const companyHasIntegrations = (
  company: ICompany | ICorporateInfo,
): boolean => {
  const { integrations } = company;
  return !!integrations && filterEnabledIntegrations(integrations).length > 0;
};
