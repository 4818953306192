import { TimeBlocker } from "types/interfaces";

export const isStartInRange = (time: string, blockers: TimeBlocker[]) =>
  blockers.some(
    ({ startTime, endTime }) => startTime <= time && time < endTime,
  );

export const isEndInRange = (time: string, blockers: TimeBlocker[]) =>
  blockers.some(
    ({ startTime, endTime }) => startTime < time && time <= endTime,
  );

export const isOverlapping = (
  sideValues: [string, string],
  timeBlockers: TimeBlocker[],
) => {
  const [startingValue, endingValue] = sideValues;
  return timeBlockers.some(
    ({ startTime, endTime }) =>
      startingValue < startTime && endTime < endingValue,
  );
};
