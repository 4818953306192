import { IMultiDayBooking } from "../../interfaces";

function selectedBookingStartTimeIsInBetweenStartAndEndTimeOfMultiDayBooking(
  selectedBookingStartTime: number,
  multiDayBooking: IMultiDayBooking,
): boolean {
  return (
    multiDayBooking.timeInfo!.startTime! - 1000 < selectedBookingStartTime &&
    selectedBookingStartTime < multiDayBooking.timeInfo!.endTime! - 1000
  );
}

function selectedBookingEndTimeIsInBetweenStartAndEndTimeOfMultiDayBooking(
  selectedBookingEndTime: number,
  multiDayBooking: IMultiDayBooking,
): boolean {
  return (
    multiDayBooking.timeInfo!.startTime! < selectedBookingEndTime &&
    selectedBookingEndTime - 1000 < multiDayBooking.timeInfo!.endTime!
  );
}

function selectedBookingContainsStartAndEndTimeOfMultiDayBooking(
  selectedBookingStartTime: number,
  selectedBookingEndTime: number,
  multiDayBooking: IMultiDayBooking,
): boolean {
  return (
    selectedBookingStartTime - 1000 < multiDayBooking.timeInfo!.startTime! &&
    multiDayBooking.timeInfo!.endTime! - 1000 < selectedBookingEndTime
  );
}

export function findCollidingTimeframeBookingsForBooking(
  selectedMultiDayBooking: IMultiDayBooking,
  otherMultiDayBookings: IMultiDayBooking[],
): IMultiDayBooking[] {
  return otherMultiDayBookings.filter((mdb) => {
    return (
      mdb.id !== selectedMultiDayBooking.id &&
      (selectedBookingContainsStartAndEndTimeOfMultiDayBooking(
        selectedMultiDayBooking.timeInfo!.startTime!,
        selectedMultiDayBooking.timeInfo!.endTime!,
        mdb,
      ) ||
        selectedBookingStartTimeIsInBetweenStartAndEndTimeOfMultiDayBooking(
          selectedMultiDayBooking.timeInfo!.startTime!,
          mdb,
        ) ||
        selectedBookingEndTimeIsInBetweenStartAndEndTimeOfMultiDayBooking(
          selectedMultiDayBooking.timeInfo!.endTime!,
          mdb,
        ))
    );
  });
}
