export interface IDeskAreaAccessConfig {
  isRestricted?: boolean;
  isHidden?: boolean;
  isTimeRestricted?: boolean;
  restrictedTime?: number;

  usersWithAccess?: {
    id: string;
    canDoBooking: boolean;
    isUserGroup: boolean;
  }[];
}
