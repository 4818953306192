<div class="autocomplete-users">
  <p-autoComplete
    [delay]="0"
    [(ngModel)]="currentSelection"
    [dataKey]="'key'"
    [optionLabel]="'key'"
    [suggestions]="suggestions"
    (completeMethod)="onSearch($event)"
    [multiple]="true"
    [placeholder]="placeholder"
    [size]="'small'"
    [forceSelection]="true"
    [autoOptionFocus]="true"
    [showClear]="true"
    [emptyMessage]="emptyMessage"
    [minLength]="1"
    [scrollHeight]="'320px'"
    [lazy]="true"
    [style]="{ width: '462px', 'max-width': '462px' }"
    [panelStyle]="{ 'max-width': '462px' }"
    (onSelect)="onSelect()"
    (onUnselect)="onUnselect()"
    (onClear)="onClearAll()"
    (onBlur)="onBlur()"
    [attr.data-testid]="dataTestId"
    [inputStyleClass]="'autocomplete-users-input'"
  >
    <ng-template let-suggestion let-i="index" pTemplate="item">
      <div
        class="d-flex align-items-center"
        [ngStyle]="{ 'max-width': '462px' }"
      >
        @switch (suggestion.type) {
          @case ("user") {
            <db-user-info
              [style]="{ width: '100%' }"
              [userInfo]="suggestion.user"
              [showUserEmail]="true"
              [fullWidth]="true"
              containerPadding="10px 16px"
              containerDataTestId="autocomplete--search-result"
              avatarDataTestId="autocomplete--search-result-avatar"
              [showEmailIfNameNotAvailable]="true"
              [ignoreEmailMaxWidth]="true"
              [attr.data-testid]="dataTestId + '--search-result-user-' + i"
            >
            </db-user-info>
          }
          @case ("group") {
            <div
              class="custom-list-item"
              [attr.data-testid]="dataTestId + '--search-result-group-' + i"
            >
              <div class="icon group-icon">
                <i class="pi pi-users"></i>
              </div>
              <span class="title">
                {{ suggestion.group.name }} ({{ suggestion.group.userCount }})
              </span>
            </div>
          }
          @case ("externalEmail") {
            <div
              class="custom-list-item"
              [attr.data-testid]="dataTestId + '--search-result-email-' + i"
            >
              <div class="icon email-icon">
                <i class="svg-email"></i>
              </div>
              <span class="title">
                {{ suggestion.email }}
              </span>
            </div>
          }
        }
      </div>
    </ng-template>
    <ng-template let-selectedItem let-i="index" pTemplate="selectedItem">
      @switch (selectedItem.type) {
        @case ("user") {
          <db-avatar-chip
            class="d-inline-flex"
            [label]="
              selectedItem.user.firstName
                ? selectedItem.user.firstName + ' ' + selectedItem.user.lastName
                : selectedItem.user.email
            "
            [avatarColor]="selectedItem.user.avatarColor"
            [avatarImgSrc]="selectedItem.user.profileImage"
            [size]="'normal'"
            [attr.data-testid]="dataTestId + '--selected-item-user-' + i"
          ></db-avatar-chip>
        }
        @case ("group") {
          <div
            class="custom-pill"
            [attr.data-testid]="dataTestId + '--selected-item-group-' + i"
          >
            <div class="icon group-icon">
              <i class="pi pi-users"></i>
            </div>
            <span class="title">
              {{ selectedItem.group.name }} ({{ selectedItem.group.userCount }})
            </span>
            @if (
              enableUserSelection &&
              selectedItem.group.userCount > 0 &&
              selectedItem.group.userCount < groupTooltipExpandUserLimit
            ) {
              <i
                class="pi pi-window-maximize expand-icon"
                [pTooltip]="groupTooltipContent[selectedItem.group.id] || ''"
                tooltipPosition="top"
                (mouseenter)="onGroupTooltipHover(selectedItem.group)"
                (click)="onGroupTooltipClick(selectedItem.group)"
              ></i>
            }
          </div>
        }
        @case ("externalEmail") {
          <div
            class="custom-pill"
            [attr.data-testid]="dataTestId + '--selected-item-external-' + i"
          >
            <div class="icon email-icon">
              <i class="svg-email"></i>
            </div>
            <span class="title">
              {{ selectedItem.email }}
            </span>
          </div>
        }
      }
    </ng-template>
    <ng-template pTemplate="clearicon">
      <i class="pi pi-times"></i>
    </ng-template>
    <ng-template pTemplate="removetokenicon">
      <i class="svg-delete-sign remove-pill-icon"></i>
    </ng-template>
  </p-autoComplete>
</div>
