import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { AuthModel } from "auth-module";
import { IUser } from "types";
import { ICustomerIoConfig } from "projects/common-module/src/lib/interfaces/customer-io-config";
import { CUSTOMER_IO_CONFIG } from "common-module";
import { filter, take } from "rxjs/operators";

@Component({
  selector: "db-customer-io",
  template: ``,
})
export class CustomerIoComponent implements OnInit {
  constructor(
    private authModel: AuthModel,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(CUSTOMER_IO_CONFIG) private customerIoConfig: ICustomerIoConfig,
  ) {}

  public ngOnInit() {
    this.authModel.user$
      .pipe(
        filter((user): user is IUser => !!user),
        take(1),
      )
      .subscribe((user) => {
        if (user) {
          this.initCustomerIo(user, this.customerIoConfig);
        }
      });
  }

  private initCustomerIo(user: IUser, customerIoConfig: ICustomerIoConfig) {
    if (!customerIoConfig.siteId) {
      return;
    }

    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.text = `
    var _cio = _cio || [];
    (function () {
      var a, b, c; a = function (f) {
        return function () {
          _cio.push([f].
            concat(Array.prototype.slice.call(arguments, 0)))
        }
      }; b = ["load", "identify",
        "sidentify", "track", "page", "on", "off"]; for (c = 0; c < b.length; c++) { _cio[b[c]] = a(b[c]) };
      var t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.id = 'cio-tracker';
      t.setAttribute('data-site-id', '${customerIoConfig.siteId}');
      t.setAttribute('data-use-array-params', 'true');
      t.setAttribute('data-use-in-app', '${customerIoConfig.useInAppMessages}');
      t.src = 'https://assets.customer.io/assets/track-eu.js'
      s.parentNode.insertBefore(t, s);
  
  
      _cio.identify({
        // Required attributes — you must include email or ID
        id: '${user.uuid}',
  
        // Strongly recommended when you first identify someone
        //created_at: 1339438758,   // Timestamp in representing when the user
        // first signed up in Unix format.
      });
    })();
    `;

    this._renderer2.appendChild(this._document.body, script);
  }
}
