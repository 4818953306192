import { IUserViewAvatar } from "types";

export const userIsDeleted = (userInfo: IUserViewAvatar): boolean => {
  // FIXME: no deleted status in IUserInfo
  return !!userInfo?.status && userInfo.status === ("deleted" as any);
  // TODO: Clarify this check --- https://app.clickup.com/t/8676yr3h6
  // option 1: status 'deleted'
  // option 2: check name ('Deleted User') and email ('deleted@user.com')
  // the above are from a talk with Milan
};
