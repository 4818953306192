import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "db-drop-area",
  templateUrl: "./drop-area.component.html",
  styleUrls: ["./drop-area.component.scss"],
})
export class DropAreaComponent {
  private acceptReExprs: RegExp[] = [];

  @Input() preventBodyDrop = true;
  @Input() set acceptMimeTypes(accept: string[]) {
    this.acceptReExprs = accept.map((ext) => new RegExp(ext));
  }
  @Output() swDrop = new EventEmitter();

  @HostBinding("class.active") active = true;
  @HostBinding("class.invalid") invalid = false;

  @HostListener("drop", ["$event"]) onDrop(event: DragEvent): void {
    this.active = false;
    this.swDrop.emit(this.getEventFileArray(event));
  }

  @HostListener("dragover", ["$event"]) onDragOver(event: DragEvent): void {
    const files = this.getEventItemArray(event);
    if (this.acceptReExprs.length > 0) {
      for (const file of files) {
        if (this.acceptReExprs.find((re) => re.test(file.type))) {
          continue;
        }
        this.invalid = true;
        break;
      }
    }
    if (!this.invalid && event.dataTransfer && event.dataTransfer.dropEffect) {
      event.dataTransfer.dropEffect = "copy";
    }
    event.stopPropagation();
    event.preventDefault();
    this.active = true;
  }

  @HostListener("dragleave", ["$event"]) onDragLeave(event: DragEvent): void {
    if (event.dataTransfer && event.dataTransfer.dropEffect) {
      event.dataTransfer.dropEffect = "none";
    }
    this.active = false;
    this.invalid = false;
  }

  @HostListener("body:dragover", ["$event"]) onBodyDragOver(
    event: DragEvent,
  ): void {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener("body:drop", ["$event"]) onBodyDrop(event: DragEvent): void {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  getEventFileArray(event: DragEvent): File[] {
    return event.dataTransfer?.files
      ? Array.from(event.dataTransfer.files)
      : [];
  }

  getEventItemArray(event: DragEvent): DataTransferItem[] {
    return event.dataTransfer?.items
      ? Array.from(event.dataTransfer.items)
      : [];
  }
}
