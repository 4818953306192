@if (accessRules) {
  @if (accessRules | exec: isTimeRestricted) {
    <div class="d-flex" [ngClass]="{ 'text-gray-icon': isTextGrayIcon }">
      <img
        src="/assets/icons/access-clock.svg"
        [style.width.px]="iconSize"
        [style.height.px]="iconSize"
      />
    </div>
  }
  @if (accessRules | exec: isRestrictedButHasAccess) {
    <div class="d-flex" [ngClass]="{ 'text-gray-icon': isTextGrayIcon }">
      <img
        src="/assets/icons/access-lock-check.svg"
        [style.width.px]="iconSize"
        [style.height.px]="iconSize"
      />
    </div>
  }
  @if (accessRules | exec: isRestrictedButDoesNotHaveAccess) {
    <div class="d-flex" [ngClass]="{ 'text-gray-icon': isTextGrayIcon }">
      <img
        src="/assets/icons/access-lock.svg"
        [style.width.px]="iconSize"
        [style.height.px]="iconSize"
      />
    </div>
  }
}
