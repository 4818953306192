import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  Output,
} from "@angular/core";
import { filter, Observable, race, take } from "rxjs";
import { UploadModel } from "../+store/model";
import { IUploadEvent } from "../upload-button/upload-button.component";
import { RequestMethod } from "../enums/request-method";
import { NotificationModel, NotificationType } from "notification-module";

@Component({
  selector: "db-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"],
})
export class ImageUploaderComponent {
  static counter = 0;
  isDragOver = false;
  isUploading = false;

  @Input() imageUrls: string[] = [];
  @Input() hasImage = false;
  @Input() acceptedImageFormats!: string[];
  @Input() uploadBtnName!: string;
  @Input() maxFileSizeMB = 5;
  @Input() uuid!: string;
  @Input() location!: string;
  @Input() height = "260px";
  @Input() width = "fit-content";
  @Input() formDataName!: string;
  @Input() csrf: { token: string; headerName: string } | undefined;
  @Input() usePUTRequest = false;
  @Input() requestMethod:
    | RequestMethod.PUT
    | RequestMethod.POST
    | RequestMethod.PATCH = RequestMethod.POST;
  @Input() showAllowedFormatsAndSizes = false;
  @Input() lazy = false;
  @Input() maxImageDimensions?: { w: number; h: number };
  @Input() background: "purple" | "white" = "purple";
  @HostBinding("style.--background-color") get backgroundColor(): string {
    return this.background === "purple" ? "#eaecff" : "#ffffff";
  }

  @Input() simpleDesign = false;

  @Output() uploadResult: EventEmitter<{ success: boolean; body: any }> =
    new EventEmitter<{ success: boolean; body: any }>();
  @Output() upload: EventEmitter<IUploadEvent> =
    new EventEmitter<IUploadEvent>();

  get parsedAcceptedImageFormats(): string {
    return this.acceptedImageFormats.join(", ");
  }

  @HostListener("dragenter") onDragOver(): void {
    this.isDragOver = true;
  }

  @HostListener("dragleave") onDragLeave(): void {
    this.isDragOver = false;
  }

  private readonly uploadModel = inject(UploadModel);
  private readonly notificationModel = inject(NotificationModel);

  ngOnInit(): void {
    this.uuid =
      this.uuid || `image-uploader-${ImageUploaderComponent.counter++}`;
  }

  uploadImage({ files, uuid }: { files: File[]; uuid: string }): void {
    if (this.lazy) {
      this.imageUrls = [];
      for (const file of files) {
        const fileURL = URL.createObjectURL(file);
        this.imageUrls.push(fileURL);
      }
    }

    if (files.length === 0) {
      return;
    }

    const file = files[0];

    const fileSize = file.size / 1024 / 1024;
    if (fileSize > this.maxFileSizeMB) {
      this.notificationModel.actions.dispatch.showNotification({
        data: $localize`:@@upload-module|image-upload|error-max-size:File size is too big. Max size: ${this.maxFileSizeMB}MB`,
        notificationType: NotificationType.ERROR,
      });

      return;
    }

    if (this.acceptedImageFormats) {
      const acceptedFormats = this.acceptedImageFormats.map((format) =>
        format.split(".").pop()?.toLowerCase(),
      );
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (!acceptedFormats.includes(fileExtension)) {
        this.notificationModel.actions.dispatch.showNotification({
          data: $localize`:@@upload-module|image-upload|error-format:File format is not supported. Supported formats: ${this.parsedAcceptedImageFormats}`,
          notificationType: NotificationType.ERROR,
        });

        return;
      }
    }

    if (this.maxImageDimensions) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      this.checkImageDimensions$(img.src).subscribe(({ width, height }) => {
        if (
          width > this.maxImageDimensions!.w ||
          height > this.maxImageDimensions!.h
        ) {
          this.notificationModel.actions.dispatch.showNotification({
            data: $localize`:@@upload-module|image-upload|error-max-dimensions:File dimensions are too big. Max width: ${
              this.maxImageDimensions!.w
            }px, max height: ${this.maxImageDimensions!.h}px`,
            notificationType: NotificationType.ERROR,
          });
          return;
        } else {
          this.initiateFileUpload(files, uuid);
        }
      });
    } else {
      this.initiateFileUpload(files, uuid);
    }
  }

  handleDragAndDrop(files: File[]): void {
    this.uploadImage({ files, uuid: this.uuid });
  }

  hasImages(imageUrls: string[] | undefined | null): boolean {
    return (imageUrls?.length || 0) > 0 && !!imageUrls?.every((a) => !!a);
  }

  private initiateFileUpload(files: File[], uuid: string): void {
    if (this.upload.observed) {
      this.upload.emit({ files, uuid });
      return;
    }

    this.uploadModel.actions.dispatch.upload({
      uuid,
      files: [...files],
      location: this.location,
      requestMethod: this.requestMethod,
      formDataName: this.formDataName,
      csrf: this.csrf,
    });

    race(
      this.uploadModel.actions.listen.uploadSuccess$.pipe(
        filter((u) => u.uuid === uuid),
        take(1),
      ),
      this.uploadModel.actions.listen.uploadFailure$.pipe(
        filter((u) => u.uuid === uuid),
        take(1),
      ),
    )
      .pipe(take(1))
      .subscribe((payload) => {
        this.isUploading = false;
        this.uploadResult.emit({
          success: !(payload as any).error,
          body: payload.body,
        });
      });
  }

  private checkImageDimensions$(
    imageSrc: string,
  ): Observable<{ width: number; height: number }> {
    return new Observable((observer) => {
      const img = new Image();
      img.onload = () => {
        observer.next({ width: img.width, height: img.height });
        observer.complete();
      };
      img.onerror = (err) => {
        observer.error(err);
      };
      img.src = imageSrc;
    });
  }
}
