import { Injectable } from "@angular/core";
import {
  GoogleTagManagerDimensions,
  GoogleTagManagerVariableProperty,
} from "analytics-tracking";
import { GoogleTagManagerService as AngularGoogleTagManagerService } from "angular-google-tag-manager";
import { ICorporateInfo } from "../interfaces";
import { IUser } from "types";
import { IntegrationType } from "../types/integration";

@Injectable({
  providedIn: "root",
})
export class GoogleTagManagerService {
  constructor(private gtmService: AngularGoogleTagManagerService) {
    if (gtmService.googleTagManagerId) {
      // catch is needed to silently ignore if GTM is blocked by Adblock
      gtmService.addGtmToDom().catch(() => null);
    }
  }

  pushTag(tag: any) {
    // catch is needed to silently ignore if GTM is blocked by Adblock
    this.gtmService.pushTag(tag).catch(() => null);
  }

  initAuthAndCompanyData(user: IUser, corporateInfo: ICorporateInfo): void {
    this.setUserData(user);
    this.setCompanyData(corporateInfo);
    this.setEnabledCoreModules(
      corporateInfo.allowsResourceBooking,
      corporateInfo.allowsScheduling,
      corporateInfo.allowsCalendarSync,
    );
    this.pushTag({ event: "user_login" });
  }

  resetAuthAndCompanyData(): void {
    this.setUserData(null);
    this.setCompanyData(null);
    this.setEnabledCoreModules(false, false, false);
    this.pushTag({ event: "user_logout" });
  }

  updateProperties(
    properties: { key: GoogleTagManagerVariableProperty; value: any }[],
  ): void {
    properties.forEach((trackedProperty) =>
      this.pushTag({ [trackedProperty.key]: trackedProperty.value }),
    );
    this.pushTag({ event: "mixpanel_property_change" });
  }

  private setUserData(user: IUser | null): void {
    this.pushTag({ [GoogleTagManagerDimensions.USER_UUID]: user?.uuid || "" });
    this.pushTag({ [GoogleTagManagerDimensions.USER_ROLE]: user?.role || "" });
    this.pushTag({
      [GoogleTagManagerDimensions.USER_MAIN_OFFICE_ID]:
        user?.primaryOfficeId || "",
    });
    this.pushTag({
      [GoogleTagManagerDimensions.USER_LANGUAGE]: user?.language || "",
    });
    this.pushTag({
      [GoogleTagManagerDimensions.USER_AVATAR_ENABLED]: user?.profileImage
        ? true
        : false,
    });
    this.pushTag({
      [GoogleTagManagerDimensions.USER_ID]: user?.firebaseId || "",
    });
  }

  private setEnabledCoreModules(
    allowsResourceBooking: boolean,
    allowsScheduling: boolean,
    allowsCalendarSync: boolean,
  ): void {
    this.pushTag({
      [GoogleTagManagerDimensions.ALLOWS_RESOURCE_BOOKING]:
        allowsResourceBooking,
    });
    this.pushTag({
      [GoogleTagManagerDimensions.ALLOWS_OFFICE_PLANNING]: allowsScheduling,
    });
    this.pushTag({
      [GoogleTagManagerDimensions.MEETING_ROOM_SYNC_ENABLED]:
        allowsCalendarSync,
    });
  }

  private setCompanyData(corporateInfo: ICorporateInfo | null): void {
    this.pushTag({
      [GoogleTagManagerDimensions.COMPANY_UUID]: corporateInfo?.uuid || "",
    });
    this.pushTag({
      [GoogleTagManagerDimensions.COMPANY_NAME]: corporateInfo?.name || "",
    });
    this.pushTag({
      [GoogleTagManagerDimensions.COMPANY_USER_VISIBILITY_RESTRICTION]:
        corporateInfo?.userVisibilityRestriction || "",
    });
    this.pushTag({
      [GoogleTagManagerDimensions.COMPANY_USE_PRIVATE_PROFILE]:
        corporateInfo?.allowsPrivateProfilesToggle || "",
    });

    const hrisIntegrationActivated =
      (corporateInfo?.integrations?.length &&
        corporateInfo?.integrations.some(
          (x) => x.type === IntegrationType.HRIS_TIME_OFF && x.isConnected,
        )) ||
      false;
    this.pushTag({
      [GoogleTagManagerDimensions.COMPANY_HAS_HRIS_ACTIVATED]: corporateInfo
        ? hrisIntegrationActivated
        : "",
    });
    // TODO: add a calendarIntegrationActivated google tag
  }
}
