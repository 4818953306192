import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from "@angular/core";
import { Menu, MenuModule } from "primeng/menu";
import { MenuItem, PrimeTemplate } from "primeng/api";

import { EventEmitterValue } from "shared";

@Component({
  selector: "db-menu",
  standalone: true,
  imports: [MenuModule],
  templateUrl: "./menu.component.html",
  styleUrls: [],
})
export class MenuComponent implements OnInit {
  @Input() dataTestId?: string;
  @Input({ required: true }) items: MenuItem[] = [];
  @Input() popup: boolean = false; // Defines if menu would displayed as a popup

  // @Input() style: { [klass: string]: any } | null | undefined; // Inline style of the element.
  // @Input() styleClass: string | null = null; // Style class of the component
  // @Input() appendTo: any = null; // Target element to attach the overlay, valid values are "body" or a local ng-template variable of another element (note: use binding with brackets for template variables, e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name)
  // @Input() autoZIndex: boolean = true; // Whether to automatically manage layering
  // @Input() baseZIndex: number = 0; // Base zIndex value to use in layering
  // @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)'; // Transition options of the show animation
  // @Input() hideTransitionOptions: string = '.1s linear'; // Transition options of the hide animation
  // @Input() ariaLabel: string | null = null; // Defines a string value that labels an interactive element
  // @Input() ariaLabelledBy: string | null = null; // Identifier of the underlying input element
  // @Input() id: string | null = null; // Current id state as a string
  // @Input() tabindex: number = 0; // Index of the element in tabbing order

  @Output() onShow = new EventEmitter<EventEmitterValue<Menu>["onShow"]>();

  @ContentChildren(PrimeTemplate) templates = new QueryList<PrimeTemplate>();
  @ViewChild(Menu, { read: Menu, static: true }) primeNgMenu!: Menu;

  ngOnInit() {
    this.primeNgMenu.onShow.subscribe(this.onShow);
  }

  toggle(event: MouseEvent): void {
    this.primeNgMenu.toggle(event);
  }
}
