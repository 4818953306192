export interface IDemoCompany {
  domain: string;
  companyName: string;
  officeName1: string;
  officeName2: string;

  companySize?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  sendToHubSpot: boolean;
  languageLocale?: string;
  language?: string;
  logo?: string;
  days?: number;
  limit?: number;
}
