import { IDayView, IDayViewMySelectedOption } from "./day-view";

export type SetDayStatusProps = Pick<IDayView, "day"> & {
  statusOptionId: string;
  // Name of the status option or name of the office if other office status is selected
  statusOptionName: string;
  officeId: string;
};

export type RemoveDayStatusProps = Pick<
  IDayView,
  "day" | "officeId" | "officeName"
> &
  Pick<IDayViewMySelectedOption, "schedulingEntryId" | "bookingIds"> & {
    primaryOfficeId: string | null;
  };

export type RedirectToAddBookingProps = Pick<
  IDayView,
  "day" | "isOutsideOfficeLeadTime"
> &
  Pick<IDayViewMySelectedOption, "officeId">;

export type GetTooltipProps = Pick<IDayView, "day" | "isOutsideOfficeLeadTime">;
