<div
  class="picker-container"
  dbClickOutside
  (clickOutside)="isPickerVisible = false"
>
  <div class="trigger" (click)="isPickerVisible = !isPickerVisible">
    @if (value) {
      <db-emoji [emojiHtml]="value" [dataTestId]="ctaDataTestId"> </db-emoji>
    } @else {
      <img
        [attr.dataTestId]="ctaDataTestId"
        class="emoji"
        src="assets/icons/blushing.svg"
      />
    }
  </div>

  @if (isPickerVisible) {
    <emoji-mart
      [i18n]="i18n"
      [emoji]="selectedEmoji?.colons || ''"
      [darkMode]="false"
      [emojiTooltip]="false"
      [backgroundImageFn]="backgroundImageFn"
      (emojiSelect)="emojiSelectHandler($event)"
    ></emoji-mart>
  }
</div>
