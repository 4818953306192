import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  inject,
} from "@angular/core";
import {
  QueryParamsHandling,
  RouterLink,
  RouterLinkActive,
} from "@angular/router";
import { MenuItemComponent } from "../menu-item/menu-item.component";
import { TeamsService } from "auth-module";
import { map, merge, Observable, startWith, switchMap, take } from "rxjs";
import { AsyncPipe, NgClass } from "@angular/common";

@Component({
  selector: "db-menu-item-with-children",
  templateUrl: "./menu-item-with-children.component.html",
  styleUrls: ["./menu-item-with-children.component.scss"],
  standalone: true,
  imports: [NgClass, RouterLink, RouterLinkActive, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemWithChildrenComponent
  implements OnChanges, AfterContentInit
{
  // NOTE: prevent default behavior for html title attribute
  @HostBinding("attr.title") get getTitle(): null {
    return null;
  }

  @ContentChildren(MenuItemComponent)
  childMenuItems!: QueryList<MenuItemComponent>;

  @Input() link: string | string[] = "";
  @Input() linkActiveOptions: { exact: boolean } = { exact: false };
  @Input() queryParamsHandling: QueryParamsHandling = "";
  @Input() skipLocationChange = false;
  @Input() loading = false;
  @Input() badge = "";
  @Input() linkClass: string[] = [];
  @Input() imgSrc: string | null = null;
  @Input() title = "";
  @Input() hideIcons = false;
  @Input() disabled = false;

  private readonly teamsService = inject(TeamsService);
  private readonly teamsAppInitialized$ = this.teamsService.initialized$;
  protected hasActiveChild$: Observable<boolean> | undefined;
  protected showIcon = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["hideIcons"]) {
      this.teamsAppInitialized$
        .pipe(take(1))
        .subscribe((teamsAppInitialized) => {
          if (this.hideIcons && teamsAppInitialized) {
            this.showIcon = false;
          }
        });
    }
  }

  ngAfterContentInit() {
    this.hasActiveChild$ = this.childMenuItems.changes.pipe(
      startWith<Array<MenuItemComponent>>(this.childMenuItems.toArray() || []),
      switchMap((items: MenuItemComponent[]) =>
        merge(...items.map((menu: MenuItemComponent) => menu.linkActiveChange)),
      ),
      map(() =>
        this.childMenuItems
          .toArray()
          .some((menu) => menu.menuItemLinkActive?.isActive || false),
      ),
    );
  }
}
