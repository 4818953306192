<form [formGroup]="form" (ngSubmit)="formSubmittedHandler()">
  <div class="field">
    <input
      #inputElement
      type="text"
      pInputText
      formControlName="item"
      [placeholder]="placeholder"
      [readOnly]="!editModeEnabled"
    />
    @if (isFieldInvalid && itemControl.hasError("required")) {
      <small class="p-error" i18n="@@shared|validator|required">
        Value is required
      </small>
    }
  </div>

  @if (editModeEnabled) {
    <db-button-icon [isSubmit]="true" [icon]="'pi pi-save'"> </db-button-icon>
    <db-button-icon [icon]="'pi pi-times'" (click)="cancelEditHandler()">
    </db-button-icon>
  }
  @if (!editModeEnabled && editButtonVisible) {
    <db-button-icon
      [icon]="'pi pi-pencil'"
      (click)="enterEditModeHandler()"
    ></db-button-icon>
  }
</form>
