import { DayShortAbbreviation } from "types/day-short-abbreviation";

/** Map of days (abbreviation used in office's IWorkingHours) to number of week day used in Angular, Prime, etc. */
export const workingDaysToNumberMap: Record<DayShortAbbreviation, number> = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};
