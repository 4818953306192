import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { AvatarSize, GuestAvatarSize, GuestAvatarSizes } from "shared-types";

@Component({
  selector: "db-guest-avatar",
  templateUrl: "./guest-avatar.component.html",
  styleUrls: ["./guest-avatar.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class GuestAvatarComponent {
  guestAvatarSize: GuestAvatarSize = "standard";

  @Input() set avatarSize(value: AvatarSize) {
    if (GuestAvatarSizes.includes(value as GuestAvatarSize)) {
      this.guestAvatarSize = value as GuestAvatarSize;
    } else {
      throw new Error(
        "Provided avatar size is not implemented for guest avatar.",
      );
    }
  }
}
