import { DayShortAbbreviation } from "types";

export interface IDayOpeningHours {
  openingTime: string;
  closingTime: string;
  isOpen: boolean;
  isOpen24Hours: boolean;
}

export type IOpeningHours = Record<DayShortAbbreviation, IDayOpeningHours>;
