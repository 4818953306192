export interface IChipItem {
  id: number | string;
  title?: string;
  icon?: string;
  color?: string;
  avatarUrl?: string;
  count?: number;
  extraInfo?: string;
  isRemoved?: boolean;
  isInactive?: boolean;
}
