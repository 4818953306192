export interface IAddressInfo {
  street: string;
  streetNumber: string;
  city: string;
  country: string;
  postCode: string;
  coordinates: {
    lng: number;
    lat: number;
  };
  timeZone?: string;
  formattedAddress?: string;
}
