import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({ name: "isPastDay", standalone: true })
export class IsPastDayPipe implements PipeTransform {
  transform(day: string, currentDay: moment.Moment): boolean {
    const momentDay = moment(day);
    const diff = momentDay.diff(currentDay, "days");
    return diff < 0;
  }
}
