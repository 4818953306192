import { NgModule, Optional, SkipSelf } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { ROUTER_STORE_NAME } from "./constants";
import { RouterEffects } from "./effects";
import { AppRouterStateSerializer } from "./state-serializer";

@NgModule({
  imports: [
    StoreModule.forFeature(ROUTER_STORE_NAME, routerReducer),
    StoreRouterConnectingModule.forRoot({
      serializer: AppRouterStateSerializer,
    }),
    EffectsModule.forFeature([RouterEffects]),
  ],
})
export class AppRouterModule {
  constructor(@SkipSelf() @Optional() module: AppRouterModule) {
    if (module) {
      throw new Error("AppRouterModule should be imported only once!");
    }
  }
}
