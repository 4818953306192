import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { Directive, forwardRef, Input } from "@angular/core";
import { DynamicSizeVirtualScrollStrategy } from "../utils/dynamic-size-virtual-scroll-strategy";
import { ScrollableItemHeight } from "../types";

@Directive({
  selector: "[dbDynamicSizeVirtualScroll]",
  standalone: true,
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: (d: DynamicSizeVirtualScrollDirective) => d._scrollStrategy,
      deps: [forwardRef(() => DynamicSizeVirtualScrollDirective)],
    },
  ],
})
export class DynamicSizeVirtualScrollDirective {
  _scrollStrategy = new DynamicSizeVirtualScrollStrategy();

  private _scrollableItems: ScrollableItemHeight[] = [];

  @Input()
  set scrollableItems(value: ScrollableItemHeight[] | null) {
    if (value) {
      this._scrollStrategy.updateContent(value);
      this._scrollableItems = value;
    }
  }
}
