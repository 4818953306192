import { GoogleTagManagerConfig } from "angular-google-tag-manager";
import { Environment } from "../enums";
import { ICustomerIoConfig } from "../interfaces/customer-io-config";
import { adminPartPrefixProviderFactory } from "./admin-part-prefix";
import { apiUrlProviderFactory } from "./api-url";
import { appUrlProviderFactory } from "./app-url";
import { backendSlackLoginURL } from "./backend-slack-login-url";
import { clientPartPrefixProviderFactory } from "./client-part-prefix";
import { customerIoProviderFactory } from "./3rd-party-integrations";
import { environmentProviderFactory } from "./environment";
import { googleTagManagerProvidersFactory } from "./google-tag-manager";
import { sentryTransactionProviders } from "./sentry";
import { windowProvider } from "./window";
import { publicApiUrlProviderFactory } from "./public-api-url";

export * from "./window";
export * from "./environment";

export interface IApplicationProviderData {
  environment: Environment;
  apiURL: string;
  publicApiUrl: string;
  appURL: string;
  backendSlackLoginUrl: string;
  clientPartPrefix: string;
  adminPartPrefix: string;
  gtm: GoogleTagManagerConfig;
  customerIoConfig: ICustomerIoConfig;
}

export function appProviders(data: IApplicationProviderData) {
  return [
    apiUrlProviderFactory(data.apiURL),
    environmentProviderFactory(data.environment),
    appUrlProviderFactory(data.appURL),
    publicApiUrlProviderFactory(data.publicApiUrl),
    adminPartPrefixProviderFactory(data.adminPartPrefix),
    clientPartPrefixProviderFactory(data.clientPartPrefix),
    customerIoProviderFactory(data.customerIoConfig),
    backendSlackLoginURL(data.backendSlackLoginUrl),
    windowProvider,
    ...sentryTransactionProviders,
    ...googleTagManagerProvidersFactory(data.gtm),
  ];
}
