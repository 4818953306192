export interface IServiceRequest {
  requestedCategories: IRequestedCategory[];
}

export interface IRequestedCategory {
  categoryId: string;
  title?: string;
  requestedItems: IRequestedItem[];
  notes: string | undefined;
  bufferBeforeMinutes?: number;
  bufferAfterMinutes?: number;
}

export interface IRequestedItem {
  itemId: string;
  quantity: number;
  title?: string;
}
