<div #root>
  <cdk-virtual-scroll-viewport dbFullVh autosize #viScroll dbViewportContainer>
    <div
      *cdkVirtualFor="
        let day of scrollableItems;
        let i = index;
        trackBy: trackByFn;
        templateCacheSize: 0
      "
      [attr.vc-data-index]="i"
      dbInViewport
      [intersectionContainer]="root"
      [style.height.px]="day.height"
    >
      <ng-container
        [ngTemplateOutlet]="scrollableItemTemplate"
        [ngTemplateOutletContext]="{ day: day, index: i }"
      >
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
