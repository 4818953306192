<div class="global-view-container">
  <div class="global-view-container-col-left">
    <ng-content select="[globalViewImage]"></ng-content>
  </div>
  <div class="global-view-container-col-right">
    <div>
      <img
        class="deskbird-logo"
        src="/assets/icons/deskbird_logo_text.svg"
        alt="logo text"
      />
      <h1>
        <ng-content select="[globalViewHeader]"></ng-content>
      </h1>
      <h3>
        <ng-content select="[globalViewSubHeader]"></ng-content>
      </h3>
      <h4>
        <ng-content select="[globalViewMessage]"></ng-content>
      </h4>
      <ng-content select="[globalViewContent]"></ng-content>
    </div>
  </div>
</div>
