export interface IOfficeWorkingTimesDto {
  currentWorkingDayTimes: {
    startTime: number;
    endTime: number;
  } | null;
  nextWorkingDayTimes: {
    startTime: number;
    endTime: number;
  } | null;
  prevWorkingDayTimes: {
    startTime: number;
    endTime: number;
  } | null;
  isOfficeOpenForGivenDay: boolean;
  officeId: string;
}
