<div class="user-image-list">
  @for (user of visibleUsers; track trackByFn(idx, user); let idx = $index) {
    <div
      class="user"
      [ngStyle]="
        avatarSpacingStyle === 'overlap'
          ? { left: idx * -13 + 'px' }
          : { marginRight: '8px' }
      "
    >
      @if (itemBadgeTemplateRef) {
        <div class="badge">
          <ng-container
            *ngTemplateOutlet="itemBadgeTemplateRef!"
          ></ng-container>
        </div>
      }
      <db-user-avatar
        [userInfo]="user"
        [authUser]="authUser"
        [openProfileOnClick]="openProfileOnClick"
        (profileOpening)="openProfileHandler($event)"
      ></db-user-avatar>
    </div>
  }
  @if (!visibleUsers.length) {
    <div class="user none">
      <span class="empty"></span>
      <span class="info" i18n="@@common|no-one-here-yet"
        >No one is here yet.</span
      >
    </div>
  }
  @if (hiddenUserCount) {
    <div
      class="rest"
      [ngStyle]="
        avatarSpacingStyle === 'overlap'
          ? { left: visibleUsers.length * -13 + 'px' }
          : { left: 0 }
      "
    >
      +{{ hiddenUserCount }}
    </div>
  }
</div>
