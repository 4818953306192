import { Injectable } from "@angular/core";
import { createEffect } from "@ngrx/effects";
import { catchError, mergeMap, switchMap, takeUntil, throwError } from "rxjs";

import { NotificationModel, NotificationType } from "notification-module";

import { FavoriteSpacesService } from "../favorite-spaces.service";
import { FavoriteSpacesModel } from "./model";
import { AuthModel, setFavoriteResourceBundle } from "auth-module";
import { IUserFavoriteResource } from "types";

@Injectable({ providedIn: "root" })
export class FavoriteSpacesEffects {
  addFavoriteSpaceBundle = createEffect(() =>
    this.favoriteSpacesModel.actions.listen.addFavoriteSpace$.pipe(
      takeUntil(
        this.favoriteSpacesModel.actions.listen.addFavoriteSpaceCancel$,
      ),
      mergeMap(({ resource }) => {
        if (!resource) {
          return [];
        }

        return this.favoriteSpacesService.addFavoriteSpace(resource.id).pipe(
          switchMap((response) =>
            response.success
              ? [
                  this.favoriteSpacesModel.actions.create.addFavoriteSpaceSuccess(
                    {
                      resource: response.data as IUserFavoriteResource,
                    },
                  ),
                  setFavoriteResourceBundle.setFavoriteResource({
                    resource: response.data as IUserFavoriteResource,
                  }),
                  this.notificationModel.actions.create.showNotification({
                    data: $localize`:@@common|success-add-favorite-space:Added ${resource.name} to favorite spaces`,
                    notificationType: NotificationType.SUCCESS,
                  }),
                ]
              : throwError(() => response),
          ),
          catchError((error) => {
            return [
              this.favoriteSpacesModel.actions.create.addFavoriteSpaceFailure({
                error,
                resource,
              }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@common|error-add-favorite-space:Error adding favorite space`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        );
      }),
    ),
  );

  removeFavoriteSpace = createEffect(() =>
    this.favoriteSpacesModel.actions.listen.removeFavoriteSpace$.pipe(
      takeUntil(
        this.favoriteSpacesModel.actions.listen.removeFavoriteSpaceCancel$,
      ),
      mergeMap(({ resource }) => {
        if (!resource) {
          return [];
        }
        return this.favoriteSpacesService.removeFavoriteSpace(resource.id).pipe(
          switchMap((response) =>
            response.success
              ? [
                  this.favoriteSpacesModel.actions.create.removeFavoriteSpaceSuccess(
                    { resource },
                  ),
                  this.authModel.actions.dispatch.removeFavoriteResource({
                    resourceId: resource.id,
                  }),
                  this.notificationModel.actions.create.showNotification({
                    data: $localize`:@@common|success-remove-favorite-space:Removed ${resource.name} from your favorite spaces`,
                    notificationType: NotificationType.DEFAULT,
                  }),
                ]
              : throwError(() => response),
          ),
          catchError((error) => {
            return [
              this.favoriteSpacesModel.actions.create.removeFavoriteSpaceFailure(
                {
                  error,
                  resource,
                },
              ),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@common|error-remove-favorite-space:Error removing favorite space`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        );
      }),
    ),
  );

  constructor(
    private favoriteSpacesService: FavoriteSpacesService,
    private favoriteSpacesModel: FavoriteSpacesModel,
    private notificationModel: NotificationModel,
    private authModel: AuthModel,
  ) {}
}
