export interface IApiErrorResponse {
  /**Standard status codes */
  statusCode: number;
  /**Custom error codes summarizing the error e.g. unauthorized, notAllowedToUpdateEvent. etc. */
  errorCode: string;
  /**Error message */
  message: string;
}

export class ApiErrorResponse extends Error implements IApiErrorResponse {
  statusCode: number;
  errorCode: string;

  constructor(statusCode: number, errorCode: string, message: string) {
    super(message);
    this.statusCode = statusCode;
    this.errorCode = errorCode;
  }
}
