<div
  [scrollTop]="1"
  #textElement
  [ngClass]="textStyleClass"
  (mouseenter)="showTooltip ? tooltip.show($event) : undefined"
  (mouseleave)="showTooltip ? tooltip.hide() : undefined"
>
  {{ text }}
</div>

<p-overlayPanel #tooltip styleClass="db-ui-overlay-tooltip">
  <ng-template pTemplate="content">
    <div class="db-ui-overlay-tooltip-line">
      {{ text }}
    </div>
  </ng-template>
</p-overlayPanel>
