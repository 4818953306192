export interface ListResponse<T> {
  next: string;
  previous: string;
  currentCount: number;
  maximumCountPerPage: number;
  results: T[];
  totalCount?: number;
}

export interface ListPaginatedResponse<T> {
  data: T[];
  totalCount: number;
  skip: number;
  take: number;
}
