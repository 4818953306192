import { IUserSpaceView } from "./user-space-view";

export type CreateBookingForSpaceProps = Pick<
  IUserSpaceView,
  | "zoneId"
  | "zoneItemId"
  | "floorId"
  | "isDedicatedResource"
  | "isFavoriteResource"
  | "resourceType"
  | "officeId"
>;
