import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProgressBarComponent } from "../progress-bar";
import { HiddenDirective } from "../../directives";
import { ButtonModule } from "primeng/button";

@Component({
  selector: "db-page-frame",
  templateUrl: "./page-frame.component.html",
  styleUrls: ["./page-frame.component.scss"],
  standalone: true,
  imports: [CommonModule, ProgressBarComponent, HiddenDirective, ButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFrameComponent {
  @HostBinding("attr.title") get getTitle(): null {
    return null;
  }

  @Input() title = "";
  @Input() showProgressLoader = false;
  @Input() headerTemplate: TemplateRef<any> | null = null;
  @Input() hasBackButton: boolean = false;
  @Input() rightSideHeaderTemplate = true;
  @Output() backAction = new EventEmitter<void>();
}
