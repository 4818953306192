<div class="container">
  <a
    href="https://deskbird.com"
    (click)="goToDeskbird($event)"
    [ngClass]="{ 'cursor-default': (teamsInitialized$ | async) }"
  >
    <img class="logo" src="/assets/icons/deskbird-logo-text.svg" />
  </a>
  <div class="card">
    <ng-content></ng-content>
  </div>
</div>
