import moment from "moment";

export const formatTime = (timezone: string, time: number | string): string => {
  return moment(time).tz(timezone).format("HH:mm");
};

export const formatHourAndMinutes = (
  is12HourFormat: boolean,
  hour: number,
  minutes = 0,
): string => {
  const isInPm = hour > 11;

  let formattedHour = is12HourFormat && isInPm ? hour - 12 : hour;
  if (is12HourFormat && formattedHour === 0) {
    formattedHour = 12;
  }

  const hourString = String(formattedHour).padStart(2, "0");
  const timeFormatSuffix = is12HourFormat ? (isInPm ? " PM" : " AM") : "";
  const minuteString = String(minutes).padStart(2, "0");

  return `${hourString}:${minuteString}${timeFormatSuffix}`;
};
