import { Component } from "@angular/core";

import { ButtonComponent } from "db-ui";

@Component({
  selector: "db-global-view",
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: "./global-view.component.html",
  styleUrls: ["./global-view.component.scss"],
})
export class GlobalViewComponent {}
