export function hasAccessToPageFactory(
  userRoleCompanyPageRestrictions: string[] | null,
) {
  return (pageUrl: string | string[] | null | undefined) => {
    if (pageUrl === null || pageUrl === undefined) {
      return false;
    }
    const pageUrlString = Array.isArray(pageUrl) ? pageUrl.join("/") : pageUrl;
    return userRoleCompanyPageRestrictions === null
      ? true
      : !userRoleCompanyPageRestrictions.find((page) =>
          pageUrlString.includes(page),
        );
  };
}
