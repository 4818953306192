import { IDeskAreaItemUser } from "./desk-area-item-user";
import { DeskAreaType } from "../enums";
import { IUserInfo, IUserViewInfo } from "./user-info";
import { IAccessRules } from "./access-rules";
import { IAssignedSpaceAccessInfo } from "./assigned-space-access-info";
import { IOfficeEquipmentItemBasicInfo } from "./offices/office-equipment";

export interface IDeskAreaItemBasicInfo {
  id: number;
  name: string;
  status: "active" | "inactive";
  resourceType?: DeskAreaType;
  accessInfo?: IAssignedSpaceAccessInfo;
}

export interface IDeskAreaItem extends IDeskAreaItemBasicInfo {
  description: string;
  users: IDeskAreaItemUser[];
  order: number;
  userId?: string;

  dedicatedResourceOwner?: IUserInfo; // exists in availability

  accessRules?: IAccessRules;

  equipment: IOfficeEquipmentItemBasicInfo[];
}

/** All properties of the desk area item when used in floors&spaces -> the area configuration screen */
export interface IDeskAreaItemConfig
  extends Omit<IDeskAreaItemBasicInfo, "id"> {
  id: string;
  description: string;
  order: number;
  accessInfo?: IAssignedSpaceAccessInfo & {
    user?: IUserViewInfo; // exists only in get resources endpoint and only when the user is assigned to the space
    userId?: string; // used only for update endpoints
  };
  zoneId: string;
  officeId: string;
  equipment?: IOfficeEquipmentItemBasicInfo[];
}

export type IDeskAreaItemCreateDto = Pick<
  IDeskAreaItemConfig,
  "name" | "order" | "status" | "accessInfo"
>;

/** All properties of the desk area item when used as part of IAvailability */
export interface IDeskAreaItemForAvailabilitySummary
  extends IDeskAreaItemBasicInfo {
  description: string;
  isAvailable: boolean;
  equipment: IOfficeEquipmentItemBasicInfo[];

  isFavoriteResource?: boolean;
  dedicatedResourceOwner?: IUserInfo;
  users: IDeskAreaItemUser[];
}

/** All properties of the desk area item when used as part of IBooking */
export interface IDeskAreaItemForBookingSummary extends IDeskAreaItemBasicInfo {
  accessRules?: IAccessRules;
  userId?: string;
}

export type IDeskAreaItemAssignedSpaceProps = Pick<
  IDeskAreaItemBasicInfo,
  "accessInfo"
> &
  Pick<IDeskAreaItemForAvailabilitySummary, "dedicatedResourceOwner"> &
  Pick<IDeskAreaItemForBookingSummary, "userId">;
