import {
  Component,
  ElementRef,
  OnDestroy,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { RouterModel } from "router-module";
import {
  first,
  withLatestFrom,
  startWith,
  takeUntil,
  debounceTime,
  take,
} from "rxjs";
import { Lottie } from "lottie-module";
import { AuthModel } from "auth-module";
import { interval, Subscription, filter } from "rxjs";
import { GlobalModel } from "../+store/global/model";
import { NavigationCancel, Params, Router } from "@angular/router";
import { WindowService } from "../window.service";

@Component({
  selector: "db-offline",
  templateUrl: "./offline.component.html",
  styleUrls: ["./offline.component.scss"],
})
export class OfflineComponent implements OnDestroy {
  previousOutlets!: {
    [outletName: string]: string[];
    primary: string[];
  };

  previousQueryParams!: Params;

  sub = new Subscription();

  constructor(
    private routerModel: RouterModel,
    private authModel: AuthModel,
    private globalModel: GlobalModel,
    private router: Router,
    private windowService: WindowService,
  ) {
    if (!router.navigated && windowService.window.navigator.onLine) {
      this.routerModel.actions.dispatch.navigate({
        commands: ["/default"],
        extras: { queryParamsHandling: "preserve" },
      });
    }

    this.routerModel.selectors.previousOutlets$
      .pipe(take(1))
      .subscribe((previousOutlets): void => {
        this.previousOutlets = previousOutlets;
      });

    this.routerModel.selectors.previousQueryParams$
      .pipe(take(1))
      .subscribe((previousQueryParams): void => {
        this.previousQueryParams = previousQueryParams;
      });

    this.sub.add(
      this.globalModel.actions.listen.loadAppInfoSuccess$
        .pipe(debounceTime(1000))
        .subscribe(() => {
          this.refresh();
        }),
    );

    this.sub.add(
      interval(2000)
        .pipe(
          startWith(-1),
          takeUntil(this.globalModel.actions.listen.loadAppInfoSuccess$),
        )
        .subscribe(() => {
          this.globalModel.actions.dispatch.loadAppInfo({});
        }),
    );
  }

  refresh(): void {
    if (
      this.previousOutlets.primary.includes("offline") ||
      this.previousOutlets.primary.includes("error")
    ) {
      this.previousOutlets = { primary: ["default"] };
      this.previousQueryParams = {};
    }

    this.routerModel.actions.dispatch.navigate({
      commands: [{ outlets: this.previousOutlets }],
      extras: { queryParams: this.previousQueryParams },
    });

    this.sub.add(
      this.router.events
        .pipe(
          filter((e) => e instanceof NavigationCancel),
          withLatestFrom(
            this.authModel.isLoggedIn$,
            this.authModel.selectors.emailCheckResult$,
          ),
        )
        .subscribe(([navigationCancel, isLogged, emailCheckResult]): void => {
          const canceledUrl = (navigationCancel as NavigationCancel).url;
          if (
            (!isLogged && canceledUrl.includes("login")) ||
            (isLogged && !canceledUrl.includes("login"))
          ) {
            return void this.windowService.reloadWindow();
          }

          if ((isLogged && emailCheckResult) || !isLogged) {
            return void this.routerModel.actions.dispatch.navigate({
              commands: ["/login"],
              extras: { queryParamsHandling: "preserve" },
            });
          }

          this.routerModel.actions.dispatch.navigate({
            commands: ["/default"],
            extras: { queryParamsHandling: "preserve" },
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
