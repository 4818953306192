import { Injectable } from "@angular/core";
import { connectBundles, connectSelectors } from "ngrx-action-bundles";
import { confirmationBundles } from "./bundles";
import { selectors } from "./selectors";

@Injectable({
  providedIn: "root",
})
export class ConfirmationModel {
  selectors = connectSelectors(selectors);
  actions = connectBundles(confirmationBundles);
}
