import { IUserFollowingStatus } from "./user-following-status";
import { OfficeRoleCode, UserStatus } from "../enums";

export interface IUserNameInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
}

/** Based on BE's BasicUserInfo https://github.com/deskbird/deskbird-api/blob/f491b8d1b55efc5b9555a3fc7e66ff5e7dd7a96c/functions/src/apis/shared/interfaces/entities/user.interface.ts#L352 */
export interface IUserInfo extends IUserNameInfo, IUserFollowingStatus {
  id?: string; // FIXME: need to sync with backend to use only one naming (#86bvycxnv)
  userId?: string;

  avatarColor?: string;
  profileImage?: string;
  status?: UserStatus;
  isAnonymous?: boolean;
  isGuest?: boolean;

  /** Those two are just different naming for avatarColor and profileImage but only one endpoint returns those */

  /**
   * @deprecated use avatarColor instead
   * */
  color?: string; // FIXME: need to sync with backend to use only one naming (#86bvycxnv)

  /**
   * @deprecated use profileImage instead
   * */
  avatarUrl?: string; // FIXME: need to sync with backend to use only one naming (#86bvycxnv)

  extra?: any;
}

export type IUserViewInfo = Pick<
  IUserInfo,
  "id" | "firstName" | "lastName" | "email" | "avatarColor" | "profileImage"
>;

export type IOfficeUserViewInfo = Pick<
  IUserInfo,
  | "id"
  | "firstName"
  | "lastName"
  | "email"
  | "avatarColor"
  | "profileImage"
  | "followingStatus"
  | "profileType"
  | "isGuest"
> & { officeRoleCodes?: OfficeRoleCode[] };
