import { NavigationExtras, Route } from "@angular/router";
import { RedirectComponent } from "../components/redirect/redirect.component";

export function redirectRouteFactory(
  path: string,
  redirectTo: string,
  {
    pathMatchFull,
    canActivate,
    ...extras
  }: {
    [key: string]: any;
    absoluteRedirect?: boolean;
    navigationExtras?: NavigationExtras;
    pathMatchFull?: boolean;
  } = {
    absoluteRedirect: false,
  },
): Route {
  const pathMatch = pathMatchFull || path === "" ? "full" : undefined;
  return {
    path,
    pathMatch,
    component: RedirectComponent,
    data: {
      redirectTo,
      ...extras,
    },
    canActivate,
  };
}
