export const emojiPickerI18n = () => {
  return {
    search: $localize`:@@emoji-picker|search:Search`,
    emojilist: $localize`:@@emoji-picker|list:List of emoji`,
    notfound: $localize`:@@emoji-picker|not-found:No Emoji Found`,
    clear: $localize`:@@emoji-picker|clear:Clear`,
    categories: {
      search: $localize`:@@emoji-picker|search-results:Search Results`,
      recent: $localize`:@@emoji-picker|frequently-used:Frequently Used`,
      people: $localize`:@@emoji-picker|smileys-and-people:Smileys & People`,
      nature: $localize`:@@emoji-picker|animals-and-nature:Animals & Nature`,
      foods: $localize`:@@emoji-picker|food-and-drinks:Food & Drink`,
      activity: $localize`:@@emoji-picker|activity:Activity`,
      places: $localize`:@@emoji-picker|travel-and-places:Travel & Places`,
      objects: $localize`:@@emoji-picker|objects:Objects`,
      symbols: $localize`:@@emoji-picker|symbols:Symbols`,
      flags: $localize`:@@emoji-picker|flags:Flags`,
      custom: $localize`:@@emoji-picker|custom:Custom`,
    },
    skintones: {
      1: $localize`:@@emoji-picker|skin-tone-1:Default Skin Tone`,
      2: $localize`:@@emoji-picker|skin-tone-2:Light Skin Tone`,
      3: $localize`:@@emoji-picker|skin-tone-3:Medium-Light Skin Tone`,
      4: $localize`:@@emoji-picker|skin-tone-4:Medium Skin Tone`,
      5: $localize`:@@emoji-picker|skin-tone-5:Medium-Dark Skin Tone`,
      6: $localize`:@@emoji-picker|skin-tone-6:Dark Skin Tone`,
    },
  };
};
