import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import moment, { Moment } from "moment";
import {
  Subscription,
  merge,
  filter,
  observeOn,
  asyncScheduler,
  map,
  pairwise,
  throttleTime,
  BehaviorSubject,
  Observable,
  Subject,
  takeUntil,
  withLatestFrom,
  distinctUntilChanged,
} from "rxjs";
import {
  CdkAutoSizeVirtualScroll,
  FullVhDirective,
  InViewportDirective,
} from "../../directives";
import { DATE_SHORT_YEAR_FORMAT } from "../../constants";
import { ViewportContainerDirective } from "../../directives/viewport-container.directive";
import { ScrollableItem } from "../../types";

@Component({
  selector: "db-virtual-scroll",
  templateUrl: "./virtual-scroll.component.html",
  styleUrls: ["./virtual-scroll.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ScrollingModule,
    CdkAutoSizeVirtualScroll,
    InViewportDirective,
    ViewportContainerDirective,
    FullVhDirective,
  ],
})
export class VirtualScrollComponent implements OnDestroy {
  @Input() scrollableItems!: Observable<Array<any & ScrollableItem>>;
  @Input() itemsCountPerFetch!: number;

  @ContentChild("scrollableItemTemplate")
  scrollableItemTemplate!: TemplateRef<any>;
  @ViewChildren(CdkAutoSizeVirtualScroll)
  autosizeScrollList!: QueryList<CdkAutoSizeVirtualScroll>;

  // Event emitter for when the user scrolls to a point where fetching next data is needed
  @Output() onFetchDataInitiated = new EventEmitter<{
    fetchFromDate: string;
  }>();

  // Event emitter for every scroll event
  @Output() onScrolledElementChange = new EventEmitter<{
    scrollToDate: Moment;
  }>();

  private viScrollSubscriptions = new Subscription();
  private killSubscriptions$$: Subject<void> = new Subject<void>();

  // Current start date holds the date that we want to fetch data from. On each change, event is emitted to the parent component
  private currentStartDate$$ = new BehaviorSubject(
    moment().format(DATE_SHORT_YEAR_FORMAT),
  );

  // Date that is on top of viewport. On each change, event is emitted to the parent component
  private selectedDate$$ = new BehaviorSubject<Moment | null>(null);
  private selectedDate$ = this.selectedDate$$
    .asObservable()
    .pipe(distinctUntilChanged((a, b) => !!a?.isSame(b)));

  @ViewChild("viScroll") set viScroll(viScroll: CdkVirtualScrollViewport) {
    this.viScrollSubscriptions?.unsubscribe();
    if (!viScroll) {
      return;
    }
    this.viScrollSubscriptions = new Subscription();

    this.viScrollSubscriptions.add(
      merge(
        viScroll.elementScrolled().pipe(
          observeOn(asyncScheduler),
          map(() => viScroll.measureScrollOffset("top")),
          pairwise(),
          filter(([y1, y2]: [number, number]) => y2 < y1),
          throttleTime(300, undefined, { trailing: true }),
          map(() => true),
        ),
        viScroll.elementScrolled().pipe(
          observeOn(asyncScheduler),
          map(() => viScroll.measureScrollOffset("bottom")),
          pairwise(),
          filter(([y1, y2]: [number, number]) => y2 < y1),
          throttleTime(300, undefined, { trailing: true }),
          map(() => false),
        ),
      )
        .pipe(
          withLatestFrom(this.scrollableItems),
          map(([scrollToValue, days]) => {
            const { start, end } = viScroll?.getRenderedRange();
            const shouldScrollToOffset = false;

            if (scrollToValue) {
              const currentDay = days[start];
              if (!currentDay.isDummyValue) {
                return false;
              }
              const newCurrentDate = moment(currentDay.day)
                .subtract(this.itemsCountPerFetch, "day")
                .format(DATE_SHORT_YEAR_FORMAT);
              this.currentStartDate$$.next(newCurrentDate);
              return shouldScrollToOffset;
            }

            const entityWithoutData = days
              .slice(start, end)
              .find((d: ScrollableItem) => d.isDummyValue);
            if (
              entityWithoutData &&
              days.length - end > this.itemsCountPerFetch
            ) {
              this.currentStartDate$$.next(entityWithoutData.day);
              return shouldScrollToOffset;
            }

            if (days.length - end < 5) {
              const currentDate = moment(days[days.length - 1].day)
                .add(1, "day")
                .format(DATE_SHORT_YEAR_FORMAT);
              this.currentStartDate$$.next(currentDate);
              return shouldScrollToOffset;
            }
            return shouldScrollToOffset;
          }),
        )
        .subscribe(),
    );
  }

  trackByFn = (index: number, item: any) => item.day;

  ngAfterViewInit(): void {
    this.selectedDate$
      .pipe(
        takeUntil(this.killSubscriptions$$),
        filter((date) => !!date),
      )
      .subscribe((scrollToDate) => {
        this.onScrolledElementChange.emit({ scrollToDate: scrollToDate! });
      });

    this.currentStartDate$$
      .pipe(
        takeUntil(this.killSubscriptions$$),
        withLatestFrom(this.scrollableItems),
      )
      .subscribe(([currentStartDate, scrollableItems]) => {
        if (!scrollableItems?.length) {
          return;
        }

        const existingDateIndex = scrollableItems.findIndex(
          (d: ScrollableItem) => d.day === currentStartDate,
        );
        const dataAlreadyLoaded =
          existingDateIndex >= 0 &&
          !scrollableItems[existingDateIndex].isDummyValue;

        if (dataAlreadyLoaded) {
          return;
        }

        this.onFetchDataInitiated.emit({ fetchFromDate: currentStartDate });
      });
  }

  ngOnDestroy(): void {
    this.viScrollSubscriptions?.unsubscribe();
    this.killSubscriptions$$.next();
    this.killSubscriptions$$.complete();
  }
}
