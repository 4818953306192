<div
  [ngClass]="officeRoleDto.ngClass"
  [ngStyle]="officeRoleDto.ngStyle"
  [pTooltip]="officeNames.length ? officeNames.join('\n') : ''"
  tooltipPosition="bottom"
>
  <div class="mr-4">
    <img class="icon-size-16" [src]="officeRoleDto.icon" />
  </div>
  <label>{{ officeRoleDto.label }}</label>
</div>
