import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExecPipe } from "shared-pipes";
import { StepsModule } from "primeng/steps";
import { StepMenuItem } from "./typings";

@Component({
  selector: "db-steps",
  standalone: true,
  imports: [CommonModule, ExecPipe, StepsModule],
  templateUrl: "./steps.component.html",
  styleUrls: ["./steps.component.scss"],
})
export class StepsComponent implements OnChanges {
  @Input({ required: true }) steps!: StepMenuItem[];
  @Input() activeIndex: number = 0;

  orderedSteps: StepMenuItem[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (!changes["steps"]) {
      return;
    }
    this.orderedSteps = this.steps
      .slice()
      .sort((step1, step2) => (step1.position < step2.position ? -1 : 1));
  }
}
