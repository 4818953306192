import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { filter, map, Observable, take } from "rxjs";

import {
  getClientDefaultLoggedNavigationUrl,
  ICorporateInfo,
} from "common-module";
import { AuthModel } from "auth-module";

@Injectable({ providedIn: "root" })
export class DefaultProfileActivate {
  constructor(
    private authModel: AuthModel,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> {
    return this.authModel.corporateInfo$
      .pipe(filter((val): val is ICorporateInfo => !!val))
      .pipe(
        map((corporateInfo) => {
          const url = getClientDefaultLoggedNavigationUrl(corporateInfo);
          const profileId = route.params?.["profileId"];

          return this.router.parseUrl(
            profileId ? `${url}(user:profile/${profileId})` : url,
          );
        }),
        take(1),
      );
  }
}
