@if (!!label) {
  <label class="d-block small-text-bold mb-4">
    {{ label }}
    @if (showAsterisk) {
      *
    }
    @if (optional) {
      <i i18n="@@shared|optional-label">(optional)</i>
    }
  </label>
}
@if (_multiselectVisibility) {
  <p-multiSelect
    [attr.data-testId]="dataTestId"
    styleClass="w-100"
    [options]="options"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [optionDisabled]="optionDisabled"
    [display]="chip ? 'chip' : 'comma'"
    [showToggleAll]="showToggleAll"
    [placeholder]="placeholder"
    [filter]="filter"
    [filterBy]="filterBy || optionLabel"
    [showHeader]="showHeader"
    [selectedItemsLabel]="selectedItemsLabel"
    [maxSelectedLabels]="maxSelectedLabels"
    [panelStyleClass]="panelStyleClass || ''"
    [group]="group"
    [virtualScroll]="virtualScroll"
    [virtualScrollItemSize]="virtualScrollItemSize"
    [showClear]="showClear"
    [resetFilterOnHide]="resetFilterOnHide"
    [ngModel]="selectedOptions ?? undefined"
    appendTo="body"
  >
    @if (itemTemplate) {
      <ng-template pTemplate="item" let-item>
        <ng-container
          *ngTemplateOutlet="itemTemplate!; context: { $implicit: item }"
        >
        </ng-container>
      </ng-template>
    }
    @if (selectedItemsTemplate) {
      <ng-template pTemplate="selectedItems" let-values>
        <ng-container
          *ngTemplateOutlet="
            selectedItemsTemplate;
            context: { $implicit: values }
          "
        >
        </ng-container>
      </ng-template>
    }
    @if (groupTemplate) {
      <ng-template pTemplate="group" let-group>
        <ng-container
          *ngTemplateOutlet="groupTemplate; context: { $implicit: group }"
        >
        </ng-container>
      </ng-template>
    }
  </p-multiSelect>
}
