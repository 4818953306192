import { ICompany } from "common-module";
import { GoogleTagManagerDimensions } from "../enums/google-tag-manager-dimensions";
import { GoogleTagManagerVariableProperty } from "../enums/google-tag-manager-variable-property";

/** Map properties from corporateInfo object to their matching tracking properties in GTM */
export function convertCompanyObjectToTrackedProperty(
  changedObject: Partial<ICompany>,
): { key: GoogleTagManagerVariableProperty; value: any }[] {
  const objectUpdates: { key: string; value: any }[] = Object.keys(
    changedObject,
  ).reduce(
    (acc, key) => {
      if (
        (changedObject as Partial<ICompany>) &&
        (key === "allowsResourceBooking" ||
          key === "allowsScheduling" ||
          key === "allowsCalendarSync" ||
          key === "allowsPrivateProfilesToggle" ||
          key === "userVisibilityRestriction")
      ) {
        return [
          ...acc,
          { key, value: (changedObject as Partial<ICompany>)[key] },
        ];
      }
      return acc;
    },
    [] as { key: string; value: any }[],
  );

  const gtmPropertyUpdates = objectUpdates.reduce(
    (acc, update) => {
      const { key } = update;
      switch (key) {
        // Company properties
        case "allowsResourceBooking":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.ALLOWS_RESOURCE_BOOKING,
              value: update.value,
            } as const,
          ];
        case "allowsScheduling":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.ALLOWS_OFFICE_PLANNING,
              value: update.value,
            } as const,
          ];
        case "allowsCalendarSync":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.MEETING_ROOM_SYNC_ENABLED,
              value: update.value,
            } as const,
          ];
        case "allowsPrivateProfilesToggle":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.COMPANY_USE_PRIVATE_PROFILE,
              value: update.value,
            } as const,
          ];
        case "userVisibilityRestriction":
          return [
            ...acc,
            {
              key: GoogleTagManagerDimensions.COMPANY_USER_VISIBILITY_RESTRICTION,
              value: update.value,
            } as const,
          ];

        default:
          return acc;
      }
    },
    [] as { key: GoogleTagManagerVariableProperty; value: any }[],
  );

  return gtmPropertyUpdates;
}
