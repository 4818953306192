import { IUserInfo } from "types";
import { IOfficeEventAttendance } from "./office-event-attendance";

export interface IOfficeEvent {
  id: string;
  companyId: string;
  companyWideEvent: boolean;
  invitedOfficeIds: string[];
  invitedUserIds: string[];
  invitedUserGroupIds: string[];
  /** HTML encoded utf8 chars e.g. &#1111;&#2222;  */
  icon: string | null;
  title: string;
  description?: string | null;
  location?: string | null;
  /** Attendance is populated in BE response only for getOne event. It's null in the getAll one. */
  attendance?: IOfficeEventAttendance;
  timeZone?: string;
  startTime: string;
  endTime: string;
  organiser?: IUserInfo;
  notifyInvitees: boolean;
  allowUsersToRespond: boolean;

  canRSVP?: boolean;
  canUpdate?: boolean;
  canDelete?: boolean;
  // Needed here to later remove it with destruction
  officeId?: string;
}
