<div class="p-fluid">
  @if (!!label) {
    <label [for]="inputId" class="d-block small-text-bold mb-4">
      {{ label }}
      @if (showAsterisk) {
        *
      }
      @if (optional) {
        <i i18n="@@shared|optional-label">(optional)</i>
      }
    </label>
  }
  <p-chips
    #input="ngModel"
    styleClass="w-100"
    [allowDuplicate]="false"
    [addOnBlur]="true"
    [addOnTab]="true"
    [disabled]="disabled"
    [max]="max"
    [field]="field"
    [placeholder]="placeholder"
    [styleClass]="styleClass"
    [attr.data-test-id]="dataTestId"
    [ngModel]="values"
    separator=","
    (onAdd)="onAdd($event)"
    (onRemove)="onRemove($event)"
  >
    @if (itemTemplateRef) {
      <ng-template let-item pTemplate="item">
        <ng-container
          *ngTemplateOutlet="itemTemplateRef; context: { item: item }"
        ></ng-container>
      </ng-template>
    }
  </p-chips>
</div>
