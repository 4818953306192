import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TreeSelect, TreeSelectModule } from "primeng/treeselect";
import { TreeNode } from "primeng/api";
import { EventEmitterValue } from "projects/shared/src/lib/types/event-emitter-value";
@Component({
  selector: "db-primeng-tree-select",
  templateUrl: "./primeng-tree-select.component.html",
  styleUrls: ["./primeng-tree-select.component.scss"],
  imports: [TreeSelectModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PrimengTreeSelectComponent,
      multi: true,
    },
  ],
})
export class PrimengTreeSelectComponent<T = any>
  implements OnInit, ControlValueAccessor
{
  private changeDetectorRef = inject(ChangeDetectorRef);

  selectedNodes!: TreeNode[];

  @ViewChild(TreeSelect, { read: TreeSelect, static: true })
  primeTreeSelect!: TreeSelect;

  @Input() nodes: TreeNode[] = [];

  @Input() selectionMode: "single" | "multiple" | "checkbox" = "single";

  @Input() propagateSelectionDown: boolean = true;

  @Input() propagateSelectionUp: boolean = true;

  @Input() filter: boolean = false;

  // @Input() options(): any[]

  // @Input() type: string = "button";

  // @Input() inputId: string;

  // @Input() scrollHeight: string = "400px";

  // @Input() disabled: boolean;

  // @Input() metaKeySelection: boolean = true;

  // @Input() display: string = "comma";

  // @Input() tabindex: string;

  // @Input() ariaLabelledBy: string;

  // @Input() placeholder: string;

  // @Input() panelClass: string;

  // @Input() emptyMessage: string = '';

  // @Input() appendTo: any;

  // @Input() filterBy: string = 'label';

  // @Input() filterMode: string = 'lenient';

  // @Input() filterPlaceholder: string;

  // @Input() filterLocale: string;

  // @Input() filterInputAutoFocus: boolean = true;

  // @Input() showClear: boolean = false;

  // @Input() resetFilterOnHide: boolean = true;

  // @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

  // @Input() hideTransitionOptions: string = '.1s linear';

  @Output() onNodeExpand = new EventEmitter<
    EventEmitterValue<TreeSelect["onNodeExpand"]>
  >();

  @Output() onNodeCollapse = new EventEmitter<
    EventEmitterValue<TreeSelect["onNodeCollapse"]>
  >();

  @Output() onShow = new EventEmitter<
    EventEmitterValue<TreeSelect["onShow"]>
  >();

  @Output() onHide = new EventEmitter<
    EventEmitterValue<TreeSelect["onHide"]>
  >();

  @Output() onClear = new EventEmitter<
    EventEmitterValue<TreeSelect["onClear"]>
  >();

  @Output() onFilter = new EventEmitter<
    EventEmitterValue<TreeSelect["onFilter"]>
  >();

  @Output() onNodeUnselect = new EventEmitter<
    EventEmitterValue<TreeSelect["onNodeUnselect"]>
  >();

  @Output() onNodeSelect = new EventEmitter<
    EventEmitterValue<TreeSelect["onNodeSelect"]>
  >();

  constructor() {}
  writeValue(obj: any): void {
    this.primeTreeSelect.writeValue(obj);
    this.changeDetectorRef.detectChanges();
  }
  registerOnChange(fn: any): void {
    this.primeTreeSelect.registerOnChange(fn);
  }
  registerOnTouched(fn: any): void {
    this.primeTreeSelect.registerOnTouched(fn);
  }
  setDisabledState?(isDisabled: boolean): void {
    this.primeTreeSelect.setDisabledState(isDisabled);
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.primeTreeSelect.onNodeExpand.subscribe(this.onNodeExpand);
    this.primeTreeSelect.onNodeSelect.subscribe(this.onNodeSelect);
    this.primeTreeSelect.onFilter.subscribe(this.onFilter);
    this.primeTreeSelect.onNodeCollapse.subscribe(this.onNodeCollapse);
    this.primeTreeSelect.onNodeUnselect.subscribe(this.onNodeUnselect);
    this.primeTreeSelect.onShow.subscribe(this.onShow);
    this.primeTreeSelect.onHide.subscribe(this.onHide);
    this.primeTreeSelect.onClear.subscribe(this.onClear);
  }
}
