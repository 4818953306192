@if (!isLoading) {
  <db-dropdown-with-search
    [virtualScrollItemSize]="56"
    [options]="userOptions"
    [placeholder]="placeholder || ''"
    [optionValue]="'id'"
    [filterBy]="'firstName,lastName,email,fullName'"
    [selectedOption]="selectedUserId"
    [dataTestId]="dataTestId"
    [emptyFilterMessage]="emptyFilterMessage"
    [isDisabled]="isDisabled"
    [styleClass]="styleClass"
    [showClear]="showClear"
    [panelStyleClass]="panelStyleClass"
    (selectedValueChanged)="selectedUserIdChangeHandler($event)"
  >
    <ng-template pTemplate="selectedItem" let-option>
      @if (!hideSelectedValue) {
        <div [ngClass]="{ small: smallSelectedValue }">
          <db-user-info
            [userInfo]="option"
            [showUserEmail]="showUserEmail"
            [isUserEmailOnSameLine]="true"
            [avatarSize]="selectedUserAvatarSize"
            [ignoreEmailMaxWidth]="true"
          >
          </db-user-info>
        </div>
      } @else {
        <div>{{ placeholder || defaultPlaceholder }}</div>
      }
    </ng-template>
    <ng-template pTemplate="item" let-itemOption>
      <db-user-info
        [userInfo]="itemOption"
        [showUserEmail]="showUserEmail"
        avatarSize="mini"
        [ignoreEmailMaxWidth]="true"
      >
      </db-user-info>
    </ng-template>
  </db-dropdown-with-search>
} @else {
  <db-skeleton-loader skeletonHeight="54px"></db-skeleton-loader>
}
