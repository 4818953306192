import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "isSpaceBeingBooked" })
export class IsSpaceBeingBookedPipe implements PipeTransform {
  transform(
    zoneItemId: string | number,
    spaceIdentifier: string | number | undefined,
    resourcesBeingBooked: number[] | string[] | null,
  ): boolean {
    const identifier = spaceIdentifier || zoneItemId;
    if (!resourcesBeingBooked) {
      return false;
    }
    return (resourcesBeingBooked as (string | number)[]).includes(identifier);
  }
}
