@if ((!isResolving || bookings?.length) && authUser) {
  @if (bookings?.length) {
    <div
      data-testid="booking--bookings-cards-container"
      class="bookings-container"
    >
      @for (booking of bookings; track trackByFn($index, booking)) {
        <db-booking-card-item
          [isClickable]="bookingClick.observed"
          [authUserId]="authUser.id"
          [authUserTimeFormat]="authUser.hourFormat"
          [authUserDedicatedResources]="authUser.dedicatedResources"
          [authUserFavoriteResources]="authUser.favoriteResources"
          [favoriteSpaceGtmEntryPoint]="gtmEntryPoint"
          [booking]="booking"
          [fitDetailsOnOneLine]="fitDetailsOnOneLine"
          (click)="bookingClick.observed && bookingItemClickHandler()"
          (bookingCheckIn)="checkInHandler(booking)"
        ></db-booking-card-item>
      }
    </div>
  } @else {
    @if (showIfEmptyMessage) {
      <div class="bookings-empty">
        <div
          class="small-text"
          i18n="@@booking-module|your-bookings|no-bookings"
        >
          No bookings on this day
        </div>
      </div>
    }
  }
} @else {
  <div class="bookings-container">
    <db-booking-card-item [isLoading]="true"></db-booking-card-item>
    <db-booking-card-item [isLoading]="true"></db-booking-card-item>
    <db-booking-card-item [isLoading]="true"></db-booking-card-item>
  </div>
}
