<db-global-view>
  <ng-container globalViewImage>
    <img [src]="iconPath" alt="Deskbird error" />
  </ng-container>
  <ng-container globalViewHeader>{{ title }}</ng-container>
  <ng-container globalViewSubHeader>{{ subTitle }}</ng-container>
  <ng-container globalViewMessage>{{ text }}</ng-container>
  <ng-container globalViewContent>
    <db-button [width]="'full-width'" (click)="onButtonClick()">
      {{ buttonText }}
    </db-button>
  </ng-container>
</db-global-view>
