import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from "@angular/core";
import { DropdownWithSearchComponent } from "../dropdown-with-search/dropdown-with-search.component";
import { IUserViewInfo } from "types";
import { NgClass } from "@angular/common";
import { DropdownModule } from "primeng/dropdown";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UserInfoComponent } from "../user-info";
import { SkeletonLoaderComponent } from "../skeleton-loader";

const primeImports = [
  DropdownModule, // required for declaring templates
];

@Component({
  selector: "db-dropdown-with-search-users",
  templateUrl: "./dropdown-with-search-users.component.html",
  styleUrls: ["./dropdown-with-search-users.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    DropdownWithSearchComponent,
    SkeletonLoaderComponent,
    UserInfoComponent,
    ...primeImports,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownWithSearchUsersComponent,
      multi: true,
    },
  ],
})
export class DropdownWithSearchUsersComponent
  implements OnChanges, ControlValueAccessor
{
  readonly defaultPlaceholder = $localize`:@@common|select-one:Select one`;

  @ViewChild(DropdownWithSearchComponent<IUserViewInfo>)
  dropDownWithSearch!: DropdownWithSearchComponent<IUserViewInfo>;

  @Input() users!: IUserViewInfo[];
  @Input() selectedUserId: string | null = null;
  @Input() selectedUserAvatarSize: "mini" | "x-mini" = "mini";
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() showUserEmail = false;
  @Input() dataTestId?: string;
  @Input() smallSelectedValue = false;
  @Input() hideSelectedValue = false;
  @Input() placeholder?: string;
  @Input()
  emptyFilterMessage = $localize`:@@common|no-results-found:No results found`;
  @Input() styleClass?: string;
  @Input() showClear?: boolean; // When enabled, a clear icon is displayed to clear the value.
  @Input() panelStyleClass?: string; // Style class of the overlay panel element.

  @Output() selectedUserIdChange = new EventEmitter<string>();

  userOptions: IUserViewInfo & { fullName: string }[] = [];

  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  changeFn!: (value: string) => void;
  touchFn!: () => void;

  writeValue = (obj: string): void => {
    this.selectedUserId = obj;
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  };

  registerOnChange = (fn: () => void): void => {
    this.dropDownWithSearch.registerOnChange(fn);
  };

  registerOnTouched = (fn: () => void): void => {
    this.dropDownWithSearch.registerOnTouched(fn);
  };

  setDisabledState = (isDisabled: boolean): void => {
    this.isDisabled = isDisabled;
    this.dropDownWithSearch.setDisabledState(isDisabled);
    this.changeDetectorRef.detectChanges();
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["users"] && changes["users"].currentValue) {
      this.userOptions = this.users.map((user) => ({
        ...user,
        fullName: `${user.firstName} ${user.lastName}`,
      }));
    }
  }

  selectedUserIdChangeHandler(userId: string): void {
    this.selectedUserId = userId;
    this.selectedUserIdChange.emit(userId);
  }
}
