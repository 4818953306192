import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";
import moment from "moment/moment";

export function dateRangeValidator(
  startControlName: string,
  endControlName: string,
  maxAllowedPeriodInMonths?: number,
): ValidatorFn {
  return (control: AbstractControl) => {
    const formGroup = control as FormGroup;
    const startControl = formGroup.controls[startControlName];
    const endControl = formGroup.controls[endControlName];

    if (
      !startControl ||
      !endControl ||
      (startControl.disabled && endControl.disabled)
    ) {
      return null;
    }

    const startDate = moment(startControl.value);
    const endDate = moment(endControl.value);

    if (startDate <= endDate) {
      if (maxAllowedPeriodInMonths) {
        const endDateLimit = moment(startDate).add(
          maxAllowedPeriodInMonths,
          "months",
        );
        if (endDateLimit.isBefore(endDate)) {
          if (maxAllowedPeriodInMonths === 3) {
            endControl.setErrors({ dateRangeLength3Months: true });
            return { dateRangeLength3Months: true };
          }
          endControl.setErrors(null);
          return null;
        }
      }

      endControl.setErrors(null);
      return null;
    } else {
      endControl.setErrors({ dateRange: true });
      return { dateRange: true };
    }
  };
}
