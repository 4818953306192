import { FeatureAccess, FeatureAccessType } from "types";

export const hasFeatureAccess = (
  access: FeatureAccess | null,
  condition: "every" | "some",
  features: FeatureAccessType | FeatureAccessType[],
): boolean => {
  if (!access) {
    return false;
  }

  return Array.isArray(features)
    ? condition === "every"
      ? features.every((feature) => access.features.includes(feature))
      : features.some((feature) => access.features.includes(feature))
    : access.features.includes(features);
};
