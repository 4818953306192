import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { DEFAULT_TIMEZONE } from "shared-constants";
import { StartEndTimeComponent } from "../start-end-time";
import { IUser } from "types";

@Component({
  selector: "db-entry-element",
  standalone: true,
  imports: [StartEndTimeComponent],
  templateUrl: "./entry-element.component.html",
  styleUrls: ["./entry-element.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntryElementComponent {
  @Input() itemTitle: string | null = null;
  @Input() itemSubTitle: string | null | undefined = null;
  @Input() timezone: string | undefined = DEFAULT_TIMEZONE;
  @Input() isFullDay = false;
  @Input() startTime: number | string | null = null;
  @Input() endTime: number | string | null = null;
  @Input() authUser!: IUser;
}
