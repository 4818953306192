import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from "@angular/core";
import {
  QueryParamsHandling,
  RouterLink,
  RouterLinkActive,
} from "@angular/router";
import { TeamsService } from "auth-module";
import { RouterModel } from "router-module";
import { take } from "rxjs";
import { TooltipModule } from "primeng/tooltip";
import { NgClass, NgStyle, NgTemplateOutlet } from "@angular/common";
import { ExecPipe } from "shared-pipes";

@Component({
  selector: "db-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    TooltipModule,
    RouterLink,
    NgStyle,
    RouterLinkActive,
    NgTemplateOutlet,
    ExecPipe,
  ],
})
export class MenuItemComponent implements OnChanges {
  @ViewChild("menuItemLinkActive") menuItemLinkActive?: RouterLinkActive;

  // NOTE: prevent default behavior for html title attribute
  @HostBinding("attr.title") get getTitle(): null {
    return null;
  }

  @Input({ required: true }) link!: string[];
  @Input({ required: true }) title = "";
  @Input() linkActive = "active";
  @Input() linkActiveOptions: { exact: boolean } = { exact: false };
  @Input() isExternalLink = false;
  @Input() skipLocationChange = false;
  @Input() hideIcons = false;
  @Input() tooltip: string | undefined = undefined;

  @Input() queryParamsHandling: QueryParamsHandling = "preserve";
  @Input() queryParams: { [key: string]: string } = {};

  @Input() linkClasses: string[] = [];

  @Input() imgSrc: string | null = null;
  @Input() titleImgSrc: string | null = null;
  @Input() subtitle: string | null = null;

  @Input() disabled = false;
  @Input() loading = false;

  @Input({ required: true }) style:
    | "webapp"
    | "admin"
    | "nav-with-border"
    | "nav-with-border-admin" = "webapp";
  @Input() dataTestId: string = "";
  @Input() hasAccessToPageFunction:
    | ((args: string[] | null) => boolean)
    | null = null;
  @Output() linkActiveChange = new EventEmitter<boolean>();

  get isNavWithBorder(): boolean {
    return (
      this.style === "nav-with-border" || this.style === "nav-with-border-admin"
    );
  }

  get linkClassesWithBoldCheck(): string[] {
    return this.linkClasses.concat(
      this.isNavWithBorder ? ["small-text-bold"] : [],
    );
  }

  private readonly teamsService = inject(TeamsService);
  private readonly routerModel = inject(RouterModel);

  protected teamsAppInitialized$ = this.teamsService.initialized$;
  protected showIcon = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["hideIcons"]) {
      this.teamsAppInitialized$
        .pipe(take(1))
        .subscribe((teamsAppInitialized) => {
          if (this.hideIcons && teamsAppInitialized) {
            this.showIcon = false;
          }
        });
    }
  }
}
