import { Component, inject } from "@angular/core";
import { RouterModel } from "router-module";
import { AuthModel } from "../+store/model";

@Component({
  selector: "db-not-active-user",
  templateUrl: "./not-active-user.component.html",
  styleUrls: ["./not-active-user.component.scss"],
})
export class NotActiveUserComponent {
  authModel = inject(AuthModel);
  routerModel = inject(RouterModel);

  emailCheckResult$ = this.authModel.selectors.emailCheckResult$;

  visitDeskbirdWebsiteClickHandler(): void {
    this.routerModel.actions.dispatch.navigateByUrl({
      url: "http://deskbird.com",
    });
  }
}
