import { getOfficeRoleTranslation } from "shared-utils";
import { IUserInfo, IUserOfficeRoleDto, OfficeRoleCode } from "types";
import {
  IDeskAreaItemForAvailabilitySummary,
  IDeskAreaItemUser,
  IFloorPlanOfficeRoleStatistics,
  IOfficeRoleOption,
} from "types/interfaces";

export const mapOfficeRolesInFloorData = (
  officeId: string,
  floorZones: any,
  officeRolesByUser: Map<string, IUserOfficeRoleDto[]> | null,
  allUsersWithOfficeRoles: IUserInfo[] | undefined,
) => {
  return floorZones?.map((zone: any) => {
    const zoneItems = zone.items?.map((item: any) => {
      if (item.users?.length > 0) {
        const usersWithOfficeRoles = mapOfficeRoles(
          officeId,
          officeRolesByUser,
          item.users as IUserInfo[],
        ) as IUserInfo[];
        allUsersWithOfficeRoles?.push(...usersWithOfficeRoles);
        return { ...item, users: usersWithOfficeRoles };
      } else {
        return item;
      }
    });

    return {
      ...zone,
      items: zoneItems,
    };
  });
};

export const mapOfficeRolesInDeskAreaData = (
  officeId: string,
  zoneItems: IDeskAreaItemForAvailabilitySummary[] | null,
  officeRolesByUser: Map<string, IUserOfficeRoleDto[]> | null,
  allUsersWithOfficeRoles: IUserInfo[] | undefined,
): IDeskAreaItemForAvailabilitySummary[] | null => {
  if (!zoneItems) return null;

  const zoneItemsWithOfficeFunctions = zoneItems.map(
    (item: IDeskAreaItemForAvailabilitySummary) => {
      if (item.users?.length > 0) {
        const usersWithOfficeRoles = mapOfficeRoles(
          officeId,
          officeRolesByUser,
          item.users,
        ) as IDeskAreaItemUser[];
        allUsersWithOfficeRoles?.push(...usersWithOfficeRoles);
        return { ...item, users: usersWithOfficeRoles };
      } else {
        return item;
      }
    },
  );

  return zoneItemsWithOfficeFunctions;
};

export const prepareOfficeRoleFilterOptions = (
  officeRolesForCompany: IOfficeRoleOption[],
  dataSource: IUserInfo[],
): IFloorPlanOfficeRoleStatistics[] => {
  const statistics = new Array<IFloorPlanOfficeRoleStatistics>();

  officeRolesForCompany.forEach((officeRole) => {
    switch (officeRole.code) {
      case OfficeRoleCode.FIRST_AIDER:
        const firstAiders = dataSource.filter((user) =>
          (user as any)?.officeRoleCodes?.includes(OfficeRoleCode.FIRST_AIDER),
        );
        statistics.push({
          label: `${getOfficeRoleTranslation(OfficeRoleCode.FIRST_AIDER)} (${
            firstAiders.length
          })`,
          id: OfficeRoleCode.FIRST_AIDER,
          assignedUsersCount: firstAiders.length,
        });
        break;
      case OfficeRoleCode.FIRE_RESPONDER:
        const fireResponders = dataSource.filter((user) =>
          (user as any)?.officeRoleCodes?.includes(
            OfficeRoleCode.FIRE_RESPONDER,
          ),
        );
        statistics.push({
          label: `${getOfficeRoleTranslation(OfficeRoleCode.FIRE_RESPONDER)} (${
            fireResponders.length
          })`,
          id: OfficeRoleCode.FIRE_RESPONDER,
          assignedUsersCount: fireResponders.length,
        });
        break;
      case OfficeRoleCode.EVACUATION_ASSISTANT:
        const evacuationAssistants = dataSource.filter((user) =>
          (user as any)?.officeRoleCodes?.includes(
            OfficeRoleCode.EVACUATION_ASSISTANT,
          ),
        );
        statistics.push({
          label: `${getOfficeRoleTranslation(
            OfficeRoleCode.EVACUATION_ASSISTANT,
          )} (${evacuationAssistants.length})`,
          id: OfficeRoleCode.EVACUATION_ASSISTANT,
          assignedUsersCount: evacuationAssistants.length,
        });
        break;
      case OfficeRoleCode.KEY_HOLDER:
        const keyHolders = dataSource.filter((user) =>
          (user as any)?.officeRoleCodes?.includes(OfficeRoleCode.KEY_HOLDER),
        );
        statistics.push({
          label: `${getOfficeRoleTranslation(OfficeRoleCode.KEY_HOLDER)} (${
            keyHolders.length
          })`,
          id: OfficeRoleCode.KEY_HOLDER,
          assignedUsersCount: keyHolders.length,
        });
    }
  });

  return statistics;
};

const mapOfficeRoles = (
  officeId: string,
  officeRolesByUser: Map<string, IUserOfficeRoleDto[]> | null,
  users: (IUserInfo | IDeskAreaItemUser)[],
): (IUserInfo | IDeskAreaItemUser)[] => {
  return users.map((user) => {
    if (user.id && officeRolesByUser && officeRolesByUser.get(user.id)) {
      return {
        ...user,
        officeRoleCodes: (officeRolesByUser.get(user.id) || [])
          .filter((role) => role.officeId === officeId)
          .sort((roleA, roleB) => {
            return roleA?.order && roleB?.order ? roleA.order - roleB.order : 0;
          })
          .map((role) => role.code),
      };
    } else {
      return {
        ...user,
        officeRoleCodes: [],
      };
    }
  });
};
