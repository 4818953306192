@for (chip of options; track chip) {
  <db-chip
    [label]="chip.label"
    [size]="'normal'"
    [styleClass]="
      (selectedValues | includes: chip.value) ? 'chip-db-purple' : ''
    "
    (click)="buttonClickHandler(chip.value)"
  >
  </db-chip>
}
