<db-error
  [iconPath]="'/assets/images/errors/error-404.svg'"
  title="Error 404"
  subTitle="This is a little embarrassing."
  text="The page was likely moved or does not exist. Our team is looking into it, but in the meantime we can gladly point you towards the nearest exit."
  buttonText="Back to main page"
  i18n-title="@@global-module|error-not-found|title"
  i18n-subTitle="@@global-module|error-not-found|subTitle"
  i18n-text="@@global-module|error-not-found|text"
  i18n-buttonText="@@global-module|error-not-found|buttonText"
>
</db-error>
