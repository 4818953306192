import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { DATE_FORMAT } from "../constants";

@Pipe({ name: "isToday", standalone: true })
export class IsTodayPipe implements PipeTransform {
  transform(
    _day: number | string | moment.Moment,
    currentDay: moment.Moment,
  ): boolean {
    const day: string =
      typeof _day === "string" ? _day : moment(_day).format(DATE_FORMAT);
    return day === currentDay.format(DATE_FORMAT);
  }
}
