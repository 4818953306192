import { Component, Input, inject } from "@angular/core";
import {
  createResolveBundle,
  ResolveBundle,
  ResolveModule,
} from "resolve-module";
import { RouterModel } from "router-module";
import { combineLatest, filter, map, switchMap, take } from "rxjs";
import { CommonModule } from "@angular/common";
import { DropdownWithSearchComponent } from "db-ui";
import { GlobalModel } from "global-module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { GoogleTagManagerService } from "common-module";

@Component({
  selector: "db-office-switcher",
  templateUrl: "./office-switcher.component.html",
  styleUrls: ["./office-switcher.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    ResolveModule,
    DropdownWithSearchComponent,
    NgxSkeletonLoaderModule,
  ],
})
export class OfficeSwitcherComponent {
  @Input() officeChangeGtmEventName: null | string = null;
  @Input() preserveQueryParams = false;

  private readonly routerModel = inject(RouterModel);
  private readonly globalModel = inject(GlobalModel);
  private readonly googleTagManagerService = inject(GoogleTagManagerService);

  readonly officeId$ = this.globalModel.selectors.adminAppOfficeId$;
  readonly offices$ = this.routerModel.isAdminAppEnv$.pipe(
    switchMap((isAdminAppEnv) =>
      isAdminAppEnv
        ? this.globalModel.adminAppOffices$
        : this.globalModel.clientAppOffices$,
    ),
    filter(Boolean),
  );
  readonly showOfficeSwitcher$ = this.routerModel.selectors.path$.pipe(
    map((path) => /office/.test(path)),
  );
  readonly officeOptions$ = this.offices$.pipe(
    map((offices) =>
      (offices || []).map((office) => ({
        value: office.id,
        title: office.name,
      })),
    ),
  );
  readonly selectedOfficeOption$ = combineLatest([
    this.officeId$,
    this.officeOptions$,
  ]).pipe(
    map(([officeId, officeOptions]) =>
      officeOptions?.find(({ value }) => value === officeId),
    ),
  );
  readonly officeResolveBundle: ResolveBundle[] = [
    createResolveBundle(
      this.globalModel.selectors.adminAppCompanyId$.pipe(
        filter((val): val is string => typeof val === "string"),
      ),
    )({
      dispatchRequest: (adminAppCompanyId) => {
        this.globalModel.actions.dispatch.loadOffices({
          companyId: adminAppCompanyId,
        });
      },
      dispatchRequestCancel: () => {
        this.globalModel.actions.dispatch.loadOfficesCancel();
      },
      requestSuccess$: this.globalModel.actions.listen.loadOfficeSuccess$,
      requestFailure$: this.globalModel.actions.listen.loadOfficeFailure$,
    }),
  ];

  handleOfficeSelectionChange(selectedOfficeId: string): void {
    combineLatest([
      this.routerModel.selectors.path$,
      this.officeOptions$,
      this.officeId$,
    ])
      .pipe(take(1))
      .subscribe(([path, officeOptions, currentOfficeId]) => {
        const newSelectedOption = officeOptions.find(
          ({ value }) => value === selectedOfficeId,
        );
        const newSelectedOfficeId = newSelectedOption?.value;
        if (newSelectedOfficeId === currentOfficeId) {
          return;
        }

        if (this.officeChangeGtmEventName) {
          this.googleTagManagerService.pushTag({
            event: this.officeChangeGtmEventName,
          });
        }
        const url = path.replace(
          `/office/${currentOfficeId}`,
          `/office/${newSelectedOfficeId}`,
        );

        if (this.preserveQueryParams) {
          this.routerModel.actions.dispatch.navigate({
            commands: [url],
            extras: {
              queryParamsHandling: "preserve",
            },
          });
        } else {
          this.routerModel.actions.dispatch.navigateByUrl({ url });
        }
      });
  }
}
