<db-dropdown-with-search
  [virtualScrollItemSize]="virtualScrollItemSize"
  [options]="options"
  [filterBy]="filterBy"
  [selectedOption]="selectedOptionValue"
  [optionValue]="optionValue"
  [optionLabel]="optionLabel"
  [isDisabled]="isLoading"
  [placeholder]="placeholder"
  (selectedValueChanged)="selectedOptionChangedHandler($event)"
>
  <ng-template let-itemValue pTemplate="item">
    {{ itemValue && itemValue[optionLabel] }}
  </ng-template>
</db-dropdown-with-search>
