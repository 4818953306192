import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "db-emoji",
  templateUrl: "./emoji.component.html",
  styleUrls: ["./emoji.component.scss"],
  standalone: true,
  imports: [NgClass],
})
export class EmojiComponent {
  @Input() emojiHtml!: string;
  @Input() asFont = false;
  @Input() dataTestId?: string;
}
