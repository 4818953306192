import { HttpErrorResponse } from "@angular/common/http";
import { props } from "@ngrx/store";
import {
  ICompany,
  ICorporateInfo,
  IDemoCompany,
  IEmailCheck,
} from "common-module";
import { forNamespace } from "ngrx-action-bundles";
import { IUser, IUserFavoriteResource, LoginType, FeatureAccess } from "types";
import { ISlackDataPayload } from "../interfaces-and-types";

export const factory = forNamespace("[AUTH]");

type AuthDataPayload = {
  user: IUser | null;
  isDeskbirdAdmin: boolean;
  corporateInfo: ICorporateInfo;
};

export const samlMobileNavigationBundle = factory.singleAction(
  "samlMobileNavigation",
);
export const authenticateBundle = factory.singleAction(
  "authenticate",
  props<{ initialPath?: string }>(),
);
export const setUserBundle = factory.singleAction(
  "setUser",
  props<{ user: IUser | null; isDeskbirdAdmin?: boolean } | { user: null }>(),
);
export const setProfileImageBundle = factory.singleActionWithCleanup(
  "setProfileImage",
  props<{ profileImage: string }>(),
);

export const setAuthResultBundle = factory.singleActionWithCleanup(
  "setAuthResult",
  props<{
    email: string | null;
    firstName: string | null;
    lastName: string | null;
  }>(),
);

type EmailLoginPayload = {
  email: string;
  password: string;
  loginType: LoginType.Email;
};
type SSOLoginPayload = {
  loginType: LoginType.Google | LoginType.Microsoft;
};
type SAMLLoginPayload = {
  loginType: LoginType.Saml;
  providerId: string;
};

export type LoginActionPayload =
  | EmailLoginPayload
  | SSOLoginPayload
  | SAMLLoginPayload;

export const loginBundle = factory.asyncAction(
  "login",
  props<LoginActionPayload>(),
  props<AuthDataPayload>(),
  props<{ error: Error }>(),
);

export const fetchUserAndCorporateInformationBundle = factory.asyncAction(
  "fetchUserAndCorporateInformation",
  props<{ dispatchLoginActions: boolean }>(),
  props<AuthDataPayload>(),
  props<{ error: Error }>(),
);

export const checkEmailBundle = factory.asyncActionWithCleanup(
  "checkEmail",
  props<{ email: string }>(),
  props<{ emailCheckResult: IEmailCheck }>(),
  props<{ error: any; email: string }>(),
);

export const logoutBundle = factory.asyncAction(
  "logout",
  props<{ skipNavigationToLoginScreen?: boolean }>(),
  undefined,
  props<{ error: any }>(),
);

export const samlSignInBundle = factory.asyncAction(
  "samlSignIn",
  props<{ provider: string }>(),
  undefined,
  props<{ error: any }>(),
);

export const patchUserBundle = factory.asyncAction(
  "patchUser",
  props<{
    updates: Partial<{
      avatarUrl: string;
      firstName: string;
      lastName: string;
    }>;
  }>(),
  props<{
    updates: Partial<{
      avatarUrl: string;
      firstName: string;
      lastName: string;
    }>;
  }>(),
  props<{ error: Error }>(),
);

export const updateUserBundle = factory.asyncAction(
  "updateUser",
  props<{
    updates: Partial<IUser>;
    userId: string;
    noNotification?: boolean;
  }>(),
  props<{ updates: Partial<IUser>; userId: string }>(),
  props<{ error: any; updates: Partial<IUser> }>(),
);

export const microsoftTeamsLoginBundle = factory.asyncAction(
  "microsoftTeamsLogin",
  props<{ email?: string }>(),
  props<AuthDataPayload>(),
  props<{ error: Error; user: IUser | null }>(),
);

export const passwordResetBundle = factory.asyncAction(
  "passwordReset",
  props<{ email: string }>(),
  undefined,
  props<{ error: HttpErrorResponse }>(),
);

export const resendVerificationEmailBundle = factory.asyncAction(
  "resendVerificationEmail",
  undefined,
  undefined,
  props<{ error: any }>(),
);

export const loadPublicDomainsBundle = factory.asyncAction(
  "loadPublicDomains",
  undefined,
  props<{ publicDomains: string[] }>(),
  props<{ error: any }>(),
);

export const refreshTokenBundle = factory.asyncAction(
  "refreshToken",
  undefined,
  undefined,
  props<{ error: any }>(),
);

export const registerBundle = factory.asyncAction(
  "register",
  props<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>(),
  props<AuthDataPayload>(),
  props<{ error: HttpErrorResponse }>(),
);

export const freeTrialRegisterBundle = factory.asyncAction(
  "freeTrialRegister",
  props<{
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }>(),
  props<{
    user: IUser | null;
    isEmailVerified?: boolean;
    isDeskbirdAdmin?: boolean;
  }>(),
  props<{ error: HttpErrorResponse }>(),
);

export const addUserOfficeIdBundle = factory.singleAction(
  "addUserOfficeId",
  props<{ officeId: string }>(),
);

export const createFreeTrialCompany = factory.asyncAction(
  "createFreeTrialCompany",
  props<{ demoCompany: IDemoCompany }>(),
  props<{ company: ICompany }>(),
  props<{ error: any }>(),
);

export const sendSlackDataBundle = factory.asyncAction(
  "sendSlackData",
  props<ISlackDataPayload>(),
);

export const setFavoriteResourceBundle = factory.singleAction(
  "setFavoriteResource",
  props<{ resource: IUserFavoriteResource }>(),
);

export const removeFavoriteResourceBundle = factory.singleAction(
  "removeFavoriteResource",
  props<{ resourceId: number }>(),
);

export const restrictedOfficeAccess = factory.asyncAction(
  "restrictedOfficeAccess",
);
export const refetchAuthDataBundle = factory.asyncAction(
  "refetchAuthData",
  undefined,
  props<AuthDataPayload>(),
  props<{ error: any }>(),
);

export const setFreeTrialDataBundle = factory.singleAction(
  "freeTrialData",
  props<{
    freeTrialStartDate: string | null;
    freeTrialEndDate: string | null;
  }>(),
);

export const syncMicrosoftProfilePictureBundle = factory.asyncAction(
  "syncMicrosoftProfilePicture",
  props<{ email: string; firebaseId: string; accessToken: string }>(),
  undefined,
  props<{ error: any }>(),
);

export const fetchUserFeatureAccessBundle = factory.asyncAction(
  "fetchUserFeatureAccess",
  undefined,
  props<FeatureAccess>(),
  props<{ error: any }>(),
);

export const addMicrosoftProviderForUserBundle = factory.asyncAction(
  "addMicrosoftProviderForUser",
  props<{ msUid: string; firebaseId: string; email: string }>(),
  undefined,
  props<{ error: any }>(),
);

export const authBundles = [
  fetchUserAndCorporateInformationBundle,
  authenticateBundle,
  setUserBundle,
  loginBundle,
  samlMobileNavigationBundle,
  setProfileImageBundle,
  setAuthResultBundle,
  checkEmailBundle,
  logoutBundle,
  samlSignInBundle,
  updateUserBundle,
  patchUserBundle,
  microsoftTeamsLoginBundle,
  passwordResetBundle,
  refreshTokenBundle,
  registerBundle,
  freeTrialRegisterBundle,
  addUserOfficeIdBundle,
  createFreeTrialCompany,
  sendSlackDataBundle,
  setFavoriteResourceBundle,
  removeFavoriteResourceBundle,
  restrictedOfficeAccess,
  refetchAuthDataBundle,
  loadPublicDomainsBundle,
  setFreeTrialDataBundle,
  resendVerificationEmailBundle,
  syncMicrosoftProfilePictureBundle,
  fetchUserFeatureAccessBundle,
  addMicrosoftProviderForUserBundle,
];
