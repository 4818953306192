@if ((showCompanySwitcher$ | async) && (isDeskbirdAdmin$ | async)) {
  @if (!(showSkeletonLoader$ | async)) {
    <db-dropdown-with-search
      id="company-select"
      [dataTestId]="'admin-portal--company-select'"
      [panelStyleClass]="'dropdown-panel-width-350'"
      [options]="(companyOptions$ | async)!"
      [optionValue]="'value'"
      [optionLabel]="'title'"
      [filterBy]="'title'"
      [selectedOption]="selectedCompanyOption$ | async"
      [isDisabled]="
        (companies$ | async) === null || (companies$ | async)?.length === 0
      "
      (selectedValueChanged)="handleCompanySelectionChange($event)"
    >
    </db-dropdown-with-search>
  } @else {
    <div id="company-select">
      <ngx-skeleton-loader
        [theme]="{ margin: 0, height: '40px', 'border-radius': '6px' }"
      >
      </ngx-skeleton-loader>
    </div>
  }
}
