<p-radioButton
  [attr.data-testId]="dataTestId"
  [name]="name!"
  [value]="value"
  [inputId]="inputId!"
  [label]="label || ''"
  [disabled]="disabled"
  [ngModel]="ngModelValue"
  (ngModelChange)="valueChangedHandler($event)"
></p-radioButton>
