import { IUserAccess } from "./user-access";

export interface IUserGroup {
  id: string;
  companyId: string;
  name: string;
  userIds: string[];
  parentId: string;
  subChildrenIds: string[];
  groupManagers: IUserAccess[];

  provider?: string;
  type?: string;
  children?: IUserGroup[];
  lastSynced?: number;
  // dev
  isNew?: boolean;
  _parent?: IUserGroup;
}

export interface IUserGroupSearchResult {
  id: string;
  uuid: string;
  parentId: string | null;
  name: string;
  users?: { id: string; uuid: string }[];
  userCount?: number;
}
