import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectButtonModule, SelectButton } from "primeng/selectbutton";

type EventEmitterValue<T> = T extends EventEmitter<infer U> ? U : never;

export interface SelectButtonOption<T = unknown> {
  label: string;
  value: T;
  disabled?: boolean;
}

@Component({
  selector: "db-select-button",
  standalone: true,
  imports: [CommonModule, SelectButtonModule],
  templateUrl: "./select-button.component.html",
  styleUrls: ["./select-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectButtonComponent,
      multi: true,
    },
  ],
})
export class SelectButtonComponent<T = unknown>
  implements OnInit, ControlValueAccessor
{
  /** Custom template for rendering option content */
  @ContentChild(TemplateRef) template?: TemplateRef<{ $implicit: T }>;
  /** Reference to the underlying PrimeNG SelectButton component */
  @ViewChild(SelectButton, { read: SelectButton, static: true })
  primeNgSelectButton!: SelectButton;

  /** Emits when an option is clicked */
  @Output() optionClick = new EventEmitter<
    EventEmitterValue<SelectButton["onOptionClick"]>
  >();
  /** Emits when the selected value changes */
  @Output() valueChange = new EventEmitter<
    EventEmitterValue<SelectButton["onChange"]>
  >();

  /** Array of options to display in the select button */
  @Input() options: SelectButtonOption<T>[] | undefined;
  /** Property name to use as the option label */
  @Input() optionLabel = "label";
  /** Property name to use as the option value */
  @Input() optionValue = "value";
  /** Whether multiple options can be selected */
  @Input() multiple?: boolean;

  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.primeNgSelectButton.onOptionClick.subscribe(this.optionClick);
    this.primeNgSelectButton.onChange.subscribe(this.valueChange);
  }

  writeValue(value: T | T[] | null): void {
    this.primeNgSelectButton.writeValue(value);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: (value: T | T[]) => void): void {
    this.primeNgSelectButton.registerOnChange(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.primeNgSelectButton.registerOnTouched(fn);
  }
}
