<div class="popup-container">
  @if (data?.header) {
    <div class="popup-header">{{ data?.header }}</div>
  }
  @if (data?.description) {
    <div class="popup-description">
      {{ data?.description }}
    </div>
  }
  @if (data?.image || dynamicDialogConfig?.data?.image) {
    <img
      [src]="data?.image ?? dynamicDialogConfig?.data?.image"
      alt="popup image"
      class="popup-image"
    />
  }

  @if (dynamicDialogConfig?.data?.description) {
    <div class="popup-description">
      {{ dynamicDialogConfig?.data?.description }}
    </div>
  }

  <div
    class="popup-buttons"
    [ngClass]="{
      'single-button': !(
        data?.buttonCancel || dynamicDialogConfig?.data?.buttonCancel
      ),
    }"
  >
    @if (data?.buttonCancel || dynamicDialogConfig?.data?.buttonCancel) {
      <db-button
        (click)="forceClose()"
        [buttonType]="'outlined'"
        [width]="'fit-content'"
        [severity]="'primary'"
      >
        {{
          data?.buttonCancel?.label ??
            dynamicDialogConfig?.data?.buttonCancel?.label
        }}
      </db-button>
    }

    @if (data?.buttonPrimary || dynamicDialogConfig?.data?.buttonPrimary) {
      <db-button
        (click)="
          (
            data?.buttonPrimary?.action ??
            dynamicDialogConfig?.data?.buttonPrimary?.action
          )?.()
        "
        [buttonType]="'normal'"
        [width]="
          !(data?.buttonCancel || dynamicDialogConfig?.data?.buttonCancel)
            ? 'full-width'
            : 'min-width-160'
        "
        [severity]="'primary'"
        [icon]="'pi pi-envelope'"
      >
        {{
          data?.buttonPrimary?.label ??
            dynamicDialogConfig?.data?.buttonPrimary?.label
        }}
      </db-button>
    }
  </div>
</div>
