@if (
  noticeMessageService._noticeMessageTemplateRef &&
  !(isOnFreeTrialPage$ | async)
) {
  <div id="notice-message" [ngStyle]="noticeMessageService._noticeMessageStyle">
    <ng-container
      *ngTemplateOutlet="noticeMessageService._noticeMessageTemplateRef"
    ></ng-container>
  </div>
}
