@if (status) {
  @if (!status.isNameUserDefined) {
    @switch (status.type) {
      @case (optionType.Office) {
        <span i18n="@@common|office">Office</span>
      }
      @case (optionType.OtherOffice) {
        <span i18n="@@common|office">Office</span>
      }
      @case (optionType.HomeOffice) {
        <span i18n="@@planning-module|home-office">Home office</span>
      }
      @case (optionType.Travel) {
        <span i18n="@@planning-module|business-travel">Business travel</span>
      }
      @case (optionType.Absent) {
        <span i18n="@@planning-module|absent">Absent</span>
      }
      @case (optionType.MobileWork) {
        <span i18n="@@planning-module|mobile-work">Mobile work</span>
      }
      @default {
        <span>{{ status.type | titlecase }}</span>
      }
    }
  } @else {
    <span>{{ status.name }}</span>
  }
}
