import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { ComponentType, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Subscription } from "rxjs";
import { AttachPopupContainerDirective } from "./attach-popup-container.directive";
import { getAttachedPopupPositionStrategy } from "./utils";
import { safeRequestAnimationFrame } from "shared-utils";

@Directive({
  selector: "[dbAttachPopup]",
  standalone: true,
})
export class AttachPopupDirective<
  T,
  C extends {
    closePopup?: EventEmitter<void>;
    repositionPopup?: EventEmitter<void>;
  },
> implements OnDestroy
{
  private subscription = new Subscription();
  private readonly detachSubscription = new Subscription();
  private readonly overlay = inject(Overlay);
  private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  private readonly attachPopupContainer = inject(AttachPopupContainerDirective);
  private overlayRef: OverlayRef | null = null;
  private isAttached = false;
  private timeout: number | null = null;
  @HostBinding("class.item-active") isActive = false;
  @Input() dbAttachPopupInputs!: Partial<T>;
  @Input() dbAttachPopup!: ComponentType<C>;
  @Input() dbDisableOnMobile: boolean | null = false;
  @Input() dbAttachPopupTriggerEvent: "click" | "hover" = "click";
  @Input() dbAttachPopupOutputs: {
    outputName: keyof C;
    closeDialog: boolean;
  }[] = [];
  @Input() dbAttachPopupDetachAfterTime = 1000;
  @Input() dbAttachPopupPosition:
    | "right"
    | "center-right"
    | "center-top"
    | undefined = undefined;
  @Input() dbAttachPopupOffsets: { offsetX?: number; offsetY?: number } = {};
  @Input() dbAttachPopupDisabled = false;
  @Output() dbAttachPopupActionEmitted = new EventEmitter<{
    action: keyof C;
    data?: unknown;
  }>();
  @Output() dbAttachPopupClosed = new EventEmitter<void>();

  constructor() {
    this.detachSubscription.add(
      this.attachPopupContainer.detachAll$.subscribe(() =>
        this.detachPortal(true),
      ),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.detachSubscription.unsubscribe();
    this.detachPortal(true);
  }

  @HostListener("click", ["$event"])
  mouseClick(scrollTo = false): void {
    if (this.dbDisableOnMobile) return;
    if (this.dbAttachPopupTriggerEvent === "click") {
      this.attachPortal();
    }
    if (scrollTo) {
      safeRequestAnimationFrame(() =>
        this.elementRef.nativeElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        }),
      );
    }
  }

  @HostListener("mouseenter", ["$event"])
  mouseEnter(): void {
    if (this.dbDisableOnMobile) return;
    if (this.dbAttachPopupTriggerEvent === "hover") {
      this.attachPortal();
    }
  }

  @HostListener("mouseleave", ["$event"])
  mouseLeave(): void {
    if (this.dbDisableOnMobile) return;
    if (this.dbAttachPopupTriggerEvent === "hover") {
      this.detachPortal();
    }
  }

  private attachPortal(): void {
    if (this.dbAttachPopupDisabled) {
      return;
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this.isAttached) {
      return;
    }
    this.subscription = new Subscription();
    this.attachPopupContainer.detachAll$.next();
    const portal = new ComponentPortal(this.dbAttachPopup);
    this.createOverLay();
    if (!this.overlayRef) {
      return;
    }
    const componentRef = this.overlayRef.attach(portal);
    if (this.dbAttachPopupInputs) {
      Object.assign(componentRef.instance as object, this.dbAttachPopupInputs);
    }
    if (componentRef.instance["closePopup"]) {
      this.subscription.add(
        componentRef.instance["closePopup"].subscribe(() =>
          this.detachPortal(true),
        ),
      );
    }
    if (componentRef.instance["repositionPopup"]) {
      this.subscription.add(
        componentRef.instance["repositionPopup"].subscribe(() =>
          safeRequestAnimationFrame(() => this.overlayRef!.updatePosition()),
        ),
      );
    }
    const outputSubscriptions = this.dbAttachPopupOutputs.map(
      ({ outputName, closeDialog }) => {
        const emitter = componentRef.instance[
          outputName
        ] as EventEmitter<unknown>;
        return emitter.subscribe((data) => {
          this.dbAttachPopupActionEmitted.emit({ action: outputName, data });
          if (closeDialog) {
            this.detachPortal();
          }
        });
      },
    );

    this.subscription.add(...outputSubscriptions);
    this.isAttached = true;
    this.isActive = true;
  }

  private detachPortal(immediately = false): void {
    if (!this.isAttached) {
      return;
    }
    if (immediately) {
      this.clearDetach();
    } else {
      this.timeout = setTimeout(() => {
        if (!this.isAttached) {
          return;
        }
        this.clearDetach();
      }, this.dbAttachPopupDetachAfterTime) as unknown as number;
    }
    if (this.overlayRef) {
      this.isActive = false;
    }
  }

  private clearDetach(): void {
    this.subscription.unsubscribe();
    this.overlayRef!.detach();
    this.isAttached = false;
    this.dbAttachPopupClosed.emit();
  }

  private createOverLay(): void {
    this.overlayRef = this.overlay.create({
      positionStrategy: getAttachedPopupPositionStrategy(
        this.overlay,
        this.elementRef.nativeElement,
        this.dbAttachPopupPosition,
        this.dbAttachPopupOffsets,
      ),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
    this.subscription.add(
      this.overlayRef
        .outsidePointerEvents()
        .subscribe(() => this.detachPortal(true)),
    );
    if (this.dbAttachPopupTriggerEvent === "hover") {
      this.overlayRef.overlayElement.addEventListener("mouseenter", () =>
        this.attachPortal(),
      );
      this.overlayRef.overlayElement.addEventListener("mouseleave", () =>
        this.detachPortal(),
      );
    }
  }
}
