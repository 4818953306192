@if (showFeatureControl$ | async) {
  <db-button-icon
    [icon]="(isPanelVisible$ | async) ? 'pi-times' : 'pi-cog'"
    (click)="togglePanel()"
    [tooltip]="
      (isPanelVisible$ | async)
        ? 'Close Feature Controls'
        : 'Open Feature Controls'
    "
    [size]="'x-large'"
    [severity]="'primary'"
  />

  @if (isPanelVisible$ | async) {
    <div class="control-panel">
      <h4>Feature Access Control</h4>
      <div class="feature-list">
        <div class="feature-item">
          <db-switch
            inputId="enable-mocking"
            [value]="isMockingEnabled()"
            (valueChanged)="toggleMocking($event)"
            label="Enable Feature Mocking"
          />
        </div>
        <hr />
        @for (feature of features; track feature) {
          <div class="feature-item">
            <db-switch
              [inputId]="feature"
              [value]="isFeatureEnabled(feature)"
              (valueChanged)="toggleFeature(feature)"
              [label]="feature"
              [disabled]="!isMockingEnabled()"
            />
          </div>
        }
        <hr />
        <h4>Quotas</h4>
        @for (quotaType of quotaTypes; track quotaType) {
          <div class="quota-item">
            <span class="quota-name small-text">{{ quotaType }}</span>
            <div class="quota-controls">
              <db-text-input
                [ngModel]="getQuota(quotaType).quantity"
                (ngModelChange)="
                  updateQuotaField(quotaType, 'quantity', $event)
                "
                [disabled]="!isMockingEnabled()"
                type="number"
              />
              <db-dropdown
                [ngModel]="getQuota(quotaType).limit"
                (ngModelChange)="updateQuotaField(quotaType, 'limit', $event)"
                [options]="quotaLimits"
                [disabled]="!isMockingEnabled()"
                [optionLabel]="'label'"
                [optionValue]="'value'"
              />
            </div>
          </div>
        }
      </div>
    </div>
  }
}
