<p-treeSelect
  class="w-full"
  containerStyleClass="w-full"
  [options]="nodes"
  [selectionMode]="selectionMode"
  [propagateSelectionUp]="propagateSelectionUp"
  [propagateSelectionDown]="propagateSelectionDown"
  [filter]="filter"
  appendTo="body"
></p-treeSelect>
