import { IGuestUser } from "./booking-guest";
import { ICreateBookingAttributes } from "./booking-request-response";

export interface IBookingForGuest {
  guest: IGuestUser;
  bookingStartTime: number;
  bookingEndTime: number;
  isAnonymous: boolean;
  zoneItemId: string;
}

export interface ICreateBookingForGuestDto extends ICreateBookingAttributes {
  guest: IGuestUser;
}
