import { Injectable, inject } from "@angular/core";
import { createEffect } from "@ngrx/effects";
import { NotificationModel, NotificationType } from "notification-module";
import { catchError, map, mergeMap, switchMap, takeUntil } from "rxjs";
import {
  ApiErrorResponse,
  IApiErrorResponse,
  IBaseResponse,
  IBaseResponseError,
  IOfficeEquipmentItem,
} from "types";
import { OfficeEquipmentModel } from "./model";
import { OfficeEquipmentService } from "../../services/office-equipment.service";

@Injectable({
  providedIn: "root",
})
export class OfficeEquipmentEffects {
  private readonly officeEquipmentModel = inject(OfficeEquipmentModel);
  private readonly notificationModel = inject(NotificationModel);
  private readonly officeEquipmentService = inject(OfficeEquipmentService);

  loadOfficeEquipment = createEffect(() =>
    this.officeEquipmentModel.actions.listen.loadOfficeEquipment$.pipe(
      switchMap(({ officeId }) =>
        this.officeEquipmentService.loadOfficeEquipment(officeId).pipe(
          takeUntil(
            this.officeEquipmentModel.actions.listen.loadOfficeEquipmentCancel$,
          ),
          map((response) => {
            const errorResponse = response as IApiErrorResponse;
            if (errorResponse.errorCode) {
              throw new ApiErrorResponse(
                errorResponse.statusCode,
                errorResponse.errorCode,
                errorResponse.message,
              );
            }
            return this.officeEquipmentModel.actions.create.loadOfficeEquipmentSuccess(
              {
                equipment: (response as IBaseResponse<IOfficeEquipmentItem[]>)
                  .data,
              },
            );
          }),
          catchError((error) => {
            return [
              this.officeEquipmentModel.actions.create.loadOfficeEquipmentFailure(
                { error },
              ),
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@office-module|error-load-equipment:Error loading equipment`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          }),
        ),
      ),
    ),
  );

  createOfficeEquipmentItem = createEffect(() =>
    this.officeEquipmentModel.actions.listen.createOfficeEquipmentItem$.pipe(
      mergeMap(({ officeId, item }) =>
        this.officeEquipmentService
          .createOfficeEquipmentItem(officeId, item)
          .pipe(
            takeUntil(
              this.officeEquipmentModel.actions.listen
                .createOfficeEquipmentItemCancel$,
            ),
            map((response) =>
              this.officeEquipmentModel.actions.create.createOfficeEquipmentItemSuccess(
                { item: response },
              ),
            ),
            catchError((error) => {
              const isExistingEquipmentError =
                (error.error as IBaseResponseError).errorCode === "conflict";
              const notificationMessage = isExistingEquipmentError
                ? $localize`:@@office-module|error-create-equipment|item-already-exists:Equipment already exists`
                : $localize`:@@office-module|error-create-equipment:Error creating equipment`;
              return [
                this.officeEquipmentModel.actions.create.createOfficeEquipmentItemFailure(
                  { error },
                ),
                this.notificationModel.actions.create.showNotification({
                  data: notificationMessage,
                  notificationType: NotificationType.ERROR,
                }),
              ];
            }),
          ),
      ),
    ),
  );

  removeOfficeEquipmentItem = createEffect(() =>
    this.officeEquipmentModel.actions.listen.removeOfficeEquipmentItem$.pipe(
      mergeMap(({ officeId, id, resourceType }) =>
        this.officeEquipmentService
          .deleteOfficeEquipmentItem(officeId, id)
          .pipe(
            takeUntil(
              this.officeEquipmentModel.actions.listen
                .removeOfficeEquipmentItemCancel$,
            ),
            map(() =>
              this.officeEquipmentModel.actions.create.removeOfficeEquipmentItemSuccess(
                { id, resourceType },
              ),
            ),
            catchError((error) => {
              return [
                this.officeEquipmentModel.actions.create.removeOfficeEquipmentItemFailure(
                  { error },
                ),
                this.notificationModel.actions.create.showNotification({
                  data: $localize`:@@office-module|error-remove-equipment:Error removing equipment`,
                  notificationType: NotificationType.ERROR,
                }),
              ];
            }),
          ),
      ),
    ),
  );
}
