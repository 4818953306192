import { DeskAreaType } from "../enums";

export interface IUserFavoriteResourceEssentials {
  id: number;
  name: string;
}

export interface IUserFavoriteResource extends IUserFavoriteResourceEssentials {
  zoneId: string;
  zoneName: string;
  groupId: string;
  groupName: string;
  officeId: string;
  officeName: string;
  companyId: string;
  resourceType: DeskAreaType;
  autoBook: boolean;
  floorConfigReady: boolean;
  timeZone: string;
}
