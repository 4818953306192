<div
  [ngClass]="[
    'info-box',
    size,
    isClickable ? 'clickable' : '',
    isSelected ? 'selected' : '',
  ]"
  (click)="isClickable ? infoBoxClick.emit(id) : undefined"
>
  <div class="content">
    <img class="icon" [src]="iconPath" />
    <div class="label">{{ label }}</div>
  </div>
</div>
