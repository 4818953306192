<div
  class="db-image"
  [ngClass]="{
    'has-image': imageUrls | exec: hasImages,
    'drag-over': isDragOver,
    'simple-design': simpleDesign,
  }"
  [ngStyle]="{ height, width }"
>
  <div id="image-container">
    @for (imageUrl of imageUrls; track imageUrl) {
      <div
        class="image"
        [ngStyle]="{
          background:
            'no-repeat center/contain url(' + imageUrl + '), transparent',
        }"
      ></div>
    }
  </div>
  <db-drop-area (swDrop)="handleDragAndDrop($event)">
    <div class="db-upload-photo-message">
      <div class="db-subheader-message">
        @if (simpleDesign) {
          <div class="small-text text-gray">
            +
            <ng-container i18n="@@upload-module|image-uploader|add-photo"
              >Add photo</ng-container
            >
          </div>
        } @else {
          <div class="d-flex-column align-items-center">
            <div i18n="@@upload-module|image-uploader|drag-drop-message">
              Drag & drop your picture or<span class="highlight"
                >&nbsp;click here</span
              >
            </div>
            @if (showAllowedFormatsAndSizes) {
              <div
                class="mt-16"
                i18n="@@upload-module|image-uploader|supported-formats"
              >
                Supported formats: {{ parsedAcceptedImageFormats }}
              </div>
              <div i18n="@@upload-module|image-uploader|max-dimensions">
                Max width {{ maxImageDimensions?.w }}, max height
                {{ maxImageDimensions?.h }}
              </div>
              <div i18n="@@upload-module|image-uploader|max-size">
                Max size: {{ maxFileSizeMB }}MB
              </div>
            }
            <div class="d-flex justify-content-center mt-16">
              <ng-content select="[uploadAdditionalInfo]"></ng-content>
            </div>
          </div>
        }

        <db-upload-button
          [uuid]="uuid"
          (upload)="uploadImage($event)"
          [accept]="acceptedImageFormats"
          [isFullHeight]="true"
        >
          <div class="placeholder"></div>
        </db-upload-button>
      </div>
    </div>
  </db-drop-area>
</div>
