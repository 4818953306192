import { Pipe, PipeTransform } from "@angular/core";
import { IOfficeEquipmentItemBasicInfo } from "types";

@Pipe({
  name: "equipmentNamesList",
  standalone: true,
})
export class EquipmentNamesListPipe implements PipeTransform {
  transform(equipmentItems: IOfficeEquipmentItemBasicInfo[]): string[] {
    return equipmentItems.map((equipmentItem) => equipmentItem.name);
  }
}
