import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  inject,
} from "@angular/core";
import { ChartOptions } from "chart.js";
import { PrimengChartTooltipComponent } from "./primeng-chart-tooltip.component";
import { WINDOW } from "common-module";

@Component({
  selector: "db-primeng-chart-with-tooltip",
  template: `
    <db-primeng-chart-tooltip
      [x]="tooltipPosition?.x"
      [y]="tooltipPosition?.y"
      [whiteTheme]="useWhiteTheme"
    >
      <ng-container
        *ngTemplateOutlet="
          tooltipTemplateRef;
          context: {
            data: tooltipData!,
            dataIndex: tooltipDataIndex,
            datasetIndex: datasetIndex,
            chart: chart,
          }
        "
      ></ng-container>
    </db-primeng-chart-tooltip>
    <ng-container
      *ngTemplateOutlet="
        chartTemplateRef;
        context: { data: data, options: extendedOptions }
      "
    ></ng-container>
  `,
  styles: [
    `
      :host {
        position: relative;
      }
    `,
  ],
  imports: [CommonModule, PrimengChartTooltipComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PrimengChartWithTooltipComponent<TooltipData = any>
  implements OnChanges
{
  @HostListener("mouseleave") mouseLeaveHandler() {
    this.tooltipPosition = null;
    this.chartHover.emit(false);
    this.changeDetectorRef.markForCheck();
  }

  @ContentChild("chart") chartTemplateRef!: TemplateRef<{
    data: any;
    options: ChartOptions;
  }>;
  @ContentChild("tooltip") tooltipTemplateRef!: TemplateRef<{
    data: TooltipData;
    dataIndex: number | null;
    datasetIndex: number | null;
    chart: any | null;
  }>;

  @Input() data!: any;
  @Input() options!: ChartOptions;
  @Input() whiteTheme: undefined | string | boolean;
  @Input() disableTooltipVisibilityFn: (
    tooltipData: TooltipData | null,
  ) => boolean = () => false;

  @Output() chartHover = new EventEmitter<boolean>();

  changeDetectorRef = inject(ChangeDetectorRef);
  window = inject(WINDOW) as Window;
  extendedOptions!: ChartOptions;

  tooltipData: TooltipData | null = null;
  tooltipPosition: { x: number | null; y: number | null } | null = null;
  tooltipDataIndex: number | null = null;
  datasetIndex: number | null = null;
  chart: any | null = null;

  get useWhiteTheme() {
    return this.whiteTheme === undefined ? false : true;
  }

  ngOnChanges(): void {
    this.extendedOptions = {
      ...this.options,
      onHover: (event, elements) => {
        const tooltipData =
          PrimengChartTooltipComponent.getTooltipData<TooltipData>(
            this.window,
            event,
            elements,
          );
        if (this.disableTooltipVisibilityFn(tooltipData.tooltipData)) {
          this.tooltipData = null;
          this.tooltipDataIndex = null;
          this.tooltipPosition = null;
          this.datasetIndex = null;
          this.chart = null;
          this.chartHover.emit(false);
        } else {
          this.datasetIndex = tooltipData.datasetIndex;
          this.tooltipData = tooltipData.tooltipData;
          this.tooltipDataIndex = tooltipData.tooltipDataIndex;
          this.tooltipPosition = tooltipData.tooltipPosition;
          this.chart = tooltipData.chart;
          this.chartHover.emit(true);
        }
        this.changeDetectorRef.markForCheck();
      },
    };
  }
}
