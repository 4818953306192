import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IApiErrorResponse, IUserOfficeRole } from "types";

@Injectable({
  providedIn: "root",
})
export class OfficeRolesService {
  constructor(private http: HttpClient) {}

  getForOffice(
    officeId: string,
  ): Observable<IUserOfficeRole[] | IApiErrorResponse> {
    return this.http.get<IUserOfficeRole[] | IApiErrorResponse>(
      `/api/offices/${officeId}/officeRoles`,
    );
  }

  getForUsers(
    userIds: string[],
  ): Observable<IUserOfficeRole[] | IApiErrorResponse> {
    return this.http.get<IUserOfficeRole[] | IApiErrorResponse>(
      `/api/users/officeRoles?userIds=${userIds.join(",")}`,
    );
  }
}
