import { commonExecPipeFunctions, hasAccessToPageFactory } from "shared";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FeatureAccessType, IUserInfo } from "types";
import { ALL_OFFICES } from "office-module/constants";
import { MenuItem } from "primeng/api";
import { ExecPipe } from "shared-pipes";
import { MenuComponent, UserInfoComponent } from "db-ui";
import { MenuItemComponent } from "../menu-item/menu-item.component";
import { map } from "rxjs/operators";
import { FeatureAccessDirective, FeatureAccessService } from "auth-module";
import { Observable } from "rxjs";
import { AsyncPipe } from "@angular/common";

@Component({
  selector: "db-client-navigation",
  templateUrl: "./client-navigation.component.html",
  styleUrls: ["./client-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MenuItemComponent,
    UserInfoComponent,
    MenuComponent,
    ExecPipe,
    FeatureAccessDirective,
    AsyncPipe,
  ],
})
export class ClientNavigationComponent implements OnInit, OnChanges {
  @Input() hasPlanningAccess = false;
  @Input() hasBookingAccess = false;

  @Input() isUserAdmin = false;
  @Input() isUserManager = false;
  @Input() isUserGroupManager = false;
  @Input() isUserGuest = false;
  @Input() isUserOfficeAdmin = false;
  @Input() isUserRegularUser = false;
  @Input() isUserDeskbirdAdmin = false;
  @Input() hideIcons = false;

  @Input() isExpanded = false;
  @Input() officeId!: string | null;
  @Input() companyId!: string;
  @Input() userProfileImageUrl: string | null | undefined;
  @Input() userInfo!: IUserInfo;
  @Input() isLoadingOfficesInitially = false;

  @Input() adminPartPrefix!: string;
  @Input() clientPartPrefix!: string;

  @Input() userRoleCompanyPageRestrictions: string[] | null = null;

  @Output() navigateToProfile = new EventEmitter<void>();
  @Output() navigateToYourSpaces = new EventEmitter<void>();
  @Output() signOut = new EventEmitter<void>();

  readonly FeatureAccessType = FeatureAccessType;
  ALL_OFFICES = ALL_OFFICES;

  private readonly featureAccessService: FeatureAccessService =
    inject(FeatureAccessService);

  private readonly menuItems: {
    menuConfig: MenuItem;
    featureAccess?: FeatureAccessType;
  }[] = [
    {
      menuConfig: {
        icon: "user-icon",
        label: $localize`:@@navigation-module|client-navigation|profile:Profile settings`,
        command: () => this.navigateToProfile.emit(),
      },
    },
    {
      menuConfig: {
        icon: "spaces-icon",
        label: $localize`:@@navigation-module|client-navigation|your-spaces:Your spaces`,
        command: () => this.navigateToYourSpaces.emit(),
      },
      featureAccess: FeatureAccessType.RESOURCE_BOOKING,
    },
    {
      menuConfig: {
        icon: "sign-out-icon",
        label: $localize`:@@navigation-module|client-navigation|sign out:Sign out`,
        command: () => this.signOut.emit(),
      },
    },
  ];

  userMenuItems$!: Observable<MenuItem[]>;

  ngOnInit(): void {
    this.userMenuItems$ = this.featureAccessService
      .hasFeatureAccess(FeatureAccessType.RESOURCE_BOOKING)
      .pipe(
        map((hasAccess: boolean | null) => {
          return this.menuItems
            .filter((item) => {
              if (item.featureAccess === FeatureAccessType.RESOURCE_BOOKING) {
                return hasAccess;
              }

              return true;
            })
            .map((item) => item.menuConfig);
        }),
      );
  }

  hasAccessToPage: (args: string[] | null) => boolean = () => true;

  getAbbreviation = commonExecPipeFunctions.getAbbreviation;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      typeof changes["userProfileImageUrl"]?.currentValue === "string" &&
      this.userInfo
    ) {
      this.userInfo = {
        ...this.userInfo,
        profileImage: this.userProfileImageUrl as string,
      };
    }

    if (changes["userRoleCompanyPageRestrictions"]) {
      this.hasAccessToPage = hasAccessToPageFactory(
        this.userRoleCompanyPageRestrictions,
      );
    }
  }
}
