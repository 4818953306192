<db-global-view>
  <ng-container globalViewImage>
    <img
      id="toolbox"
      src="/assets/images/toolbox.svg"
      alt="deskbird-maintenance-toolbox"
    />
  </ng-container>
  <ng-container globalViewHeader>Be back soon.</ng-container>
  <ng-container globalViewSubHeader>We're down for maintenance</ng-container>
  <ng-container globalViewMessage
    >This may take a while. In the meantime, check out:</ng-container
  >
  <ng-container globalViewContent>
    <div id="links">
      <a
        href="https://www.deskbird.com/resources/success-stories"
        target="_blank"
      >
        <span>Success stories of deskbird users</span>
        <img src="/assets/icons/new-window.svg" alt="new window" />
      </a>
      <a href="https://www.deskbird.com/blog" target="_blank">
        <span>Our informative blog</span>
        <img src="/assets/icons/new-window.svg" alt="new window" />
      </a>
      <a
        href="https://www.deskbird.com/resources/hybrid-work-ebook"
        target="_blank"
      >
        <span>Free e-book on successful hybrid work</span>
        <img src="/assets/icons/new-window.svg" alt="new window" />
      </a>
    </div>
    <db-button width="full-width" (click)="visitWebsiteClick()"
      >Visit website</db-button
    >
  </ng-container>
</db-global-view>
