import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { RouterModel } from "router-module";
import { Observable } from "rxjs";
import { map, take, tap } from "rxjs/operators";
import { AuthModel } from "../+store/model";

@Injectable({
  providedIn: "root",
})
export class HasEmailSetGuard implements CanActivate {
  router = inject(Router);
  authModel = inject(AuthModel);
  routerModel = inject(RouterModel);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const isInitialNavigation = !this.router.navigated;
    return this.authModel.selectors.emailCheckResult$.pipe(
      take(1),
      map((emailCheckResult) => !!emailCheckResult?.email),
      tap((canAccess) => {
        if (canAccess || !isInitialNavigation) return;
        this.routerModel.actions.dispatch.navigate({ commands: ["login"] });
      }),
    );
  }
}
