<db-card>
  <div class="wrapper">
    <h3>
      @if (!(emailCheckResult$ | async)?.userExists) {
        <ng-container i18n="@@auth-module|welcome">
          Welcome to {{ (emailCheckResult$ | async)?.companyName }}
        </ng-container>
      } @else {
        <ng-container i18n="@@auth-module|welcome-back"
          >Welcome back to {{ (emailCheckResult$ | async)?.companyName }}
        </ng-container>
      }
    </h3>
    <div
      class="company-logo"
      [ngStyle]="{
        'background-image':
          'url(' + (emailCheckResult$ | async)?.companyLogoUrl + ')',
        'background-position': 'center',
      }"
    ></div>
    <div class="email">
      <img src="/assets/icons/purple-check-mark.svg" alt="Check" />
      <p>{{ (emailCheckResult$ | async)?.email }}</p>
    </div>
    <form #form="ngForm" autocomplete="off" class="sign-up-wizard">
      <div class="row">
        <div class="form-group">
          <div class="small-text-bold" i18n="@@auth-module|password">
            Password
          </div>
          <input
            type="hidden"
            name="email"
            [ngModel]="(emailCheckResult$ | async)?.email"
          />

          <span
            class="p-input-icon-right input-with-toggle-visibility d-block w-100"
          >
            <i
              [ngClass]="{
                'pi-eye': visibility === 'text',
                'pi-eye-slash': visibility === 'password',
              }"
              class="pi"
              (click)="toggleVisibility()"
            ></i>
            <input
              class="w-100"
              type="text"
              pInputText
              name="password"
              [type]="visibility"
              placeholder="Password"
              ngModel
              i18n-placeholder="@@auth-module|password"
            />
          </span>
          <a
            (click)="forgotPassword()"
            i18n="@@auth-module|sign-in-email|forgot-password"
            >Forgot password?</a
          >
        </div>
      </div>
      <db-button
        [isSubmit]="true"
        [disabled]="form.invalid || isLoading"
        (click)="onEmailSignIn(form)"
        width="full-width"
      >
        <db-loading-tense
          presentText="Signing in"
          infinitiveText="Confirm"
          [state]="
            isLoading ? LoadingTenseState.Present : LoadingTenseState.Infinitive
          "
          i18n-presentText="@@auth-module|sign-in-loader-present-text"
          i18n-infinitiveText="@@common|confirm"
        >
        </db-loading-tense>
      </db-button>
    </form>
    <a
      class="big-link"
      [routerLink]="['/login/sign-in']"
      queryParamsHandling="preserve"
      i18n="@@auth-module|go-back"
      >Go back</a
    >
  </div>
</db-card>
