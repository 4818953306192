<nav class="p-steps p-component p-readonly">
  <ul class="p-0 m-0 d-flex list-style-none">
    @for (step of orderedSteps; track step.position) {
      <li
        class="p-element p-steps-item"
        [ngClass]="{
          'p-disabled': step.position > activeIndex,
          'p-highlight': step.position === activeIndex,
        }"
      >
        <a
          role="link"
          class="p-menuitem-link"
          [routerLink]="step.routerLink"
          [attr.aria-disabled]="step.position !== activeIndex"
        >
          @if (step.position < activeIndex) {
            <span class="p-steps-number pi pi-check"></span>
          } @else {
            <span class="p-steps-number">{{ step.position + 1 }}</span>
          }
          <span class="p-steps-title">{{ step.label }}</span>
        </a>
      </li>
    }
  </ul>
</nav>
