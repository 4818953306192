import { Provider } from "@angular/core";
import { CUSTOMER_IO_CONFIG } from "../constants";
import { ICustomerIoConfig } from "../interfaces/customer-io-config";

export function customerIoProviderFactory(
  customerIoConfig: ICustomerIoConfig,
): Provider {
  return {
    provide: CUSTOMER_IO_CONFIG,
    useValue: {
      siteId: customerIoConfig.siteId,
      useInAppMessages: customerIoConfig.useInAppMessages,
    },
  };
}
