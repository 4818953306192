<db-error
  [iconPath]="'/assets/images/errors/error-unexpected.svg'"
  title="Oops."
  subTitle="An unexpected error occurred."
  text="This was most likely caused by a server error. Our team is investigating and apologize for the hiccup. Feel free to refresh the page or try again."
  buttonText="Refresh Page"
  i18n-title="@@global-module|error-unexpected|title"
  i18n-subTitle="@@global-module|error-unexpected|subTitle"
  i18n-text="@@global-module|error-unexpected|text"
  i18n-buttonText="@@global-module|error-unexpected|buttonText"
  (buttonClick)="refresh()"
>
</db-error>
