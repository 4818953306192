export interface IExternalGoogleMeetingRoom {
  kind: string;
  etags: string;
  resourceId: string;
  resourceName: string;
  generatedResourceName: string;
  resourceType: string;
  resourceDescription: string;
  resourceEmail: string;
  capacity: number;
  buildingId: string;
  floorName: string;
  resourceCategory: string;
  userVisibleDescription: string;
  featureInstances: {
    feature: {
      kind: string;
      etags: string;
      name: string;
    };
  }[];
}
