import {
  Component,
  ContentChild,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { HoverContainerDirective } from "shared";
import {
  IUser,
  IDeskArea,
  IBookingCheckInParams,
  IUserSpaceView,
  IAddAnotherBookingProps,
  IUserSpaceViewBooking,
  CreateBookingForSpaceProps,
} from "types";
import { bookingStatusDataTestIdForCheckInCta } from "db-ui";
import { MenuItem } from "primeng/api";
import { FeatureAccessType } from "types";

@Component({
  selector: "db-user-spaces-cards",
  templateUrl: "./user-spaces-cards.component.html",
  styleUrls: ["./user-spaces-cards.component.scss"],
})
export class UserSpacesCardsComponent {
  readonly bookingStatusDataTestIdForCheckInCta = inject(
    bookingStatusDataTestIdForCheckInCta,
    {
      optional: true,
    },
  );

  @Output() quickBook = new EventEmitter<CreateBookingForSpaceProps>();
  @Output() bookingCheckIn = new EventEmitter<IBookingCheckInParams>();
  @Output() addAnotherBooking = new EventEmitter<IAddAnotherBookingProps>();
  @Output() viewBooking = new EventEmitter<void>();
  @Output() viewLocation = new EventEmitter<string>();

  @Input() userSpaces!: IUserSpaceView[] | null;
  @Input() userSpaceIdentifiers: string[] | undefined;
  @Input() resourcesBeingBooked!: number[] | string[] | null;
  @Input() authUser!: IUser;
  @Input() isResolving!: boolean;

  @Input() showBorder = false;
  @Input() takeFullWidth = false;
  @Input() disableQuickBook = false;

  @Input() showMoreActionsMenu = false;
  @Input() deskAreas!: IDeskArea[]; // required for more actions menu only
  /** Control if the resource name, area (and guest name) are displayed on one line. If false, each detail is displayed on separated line */
  @Input() fitDetailsOnOneLine = false;

  @ContentChild("favoriteIconTemplate") favoriteIconTemplate!: TemplateRef<{
    userSpace: IUserSpaceView;
    hoverContainer: HoverContainerDirective;
    authUser: IUser;
  }>;

  occupiedToolTipMessage = $localize`:@@book-module|your-spaces|occupied-space-tooltip:Already booked for this day. Select a different date to book this space.`;
  noViewLocationToolTipMessage = $localize`:@@book-module|your-spaces|no-view-location-tooltip:No floor plan available.`;

  extraActionsMenuItems: MenuItem[] = [];
  protected readonly FeatureAccessType = FeatureAccessType;

  regenerateExtraActionsMenuItems(userSpace: IUserSpaceView) {
    const newItems: MenuItem[] = [
      {
        label: $localize`:@@booking-module|your-spaces|action-add-another-booking:Add another booking`,
        command: () => this.addAnotherBookingHandler(userSpace),
      },
    ];
    if (userSpace.bookings?.length && !userSpace.isOccupied) {
      newItems.push({
        label: $localize`:@@booking-module|your-spaces|action-view-bookings:View booking(s)`,
        command: () => this.viewBookingHandler(userSpace),
      });
    }
    const spaceHasFloor = this.spaceHasFloorPlan(userSpace, this.deskAreas);
    newItems.push({
      label: $localize`:@@booking-module|your-spaces|view-location:View location`,
      disabled: !spaceHasFloor,
      tooltip: !spaceHasFloor ? this.noViewLocationToolTipMessage : "",
      tooltipPosition: "right",
      command: () => this.viewLocationHandler(userSpace),
    });
    this.extraActionsMenuItems = newItems;
  }

  trackByFn = (_: number, item: IUserSpaceView) =>
    `${item.zoneId}-${item.zoneItemId}`;

  quickBookHandler(userSpace: IUserSpaceView): void {
    this.quickBook.emit({
      zoneItemId: userSpace.zoneItemId,
      zoneId: userSpace.zoneId,
      floorId: userSpace.floorId,
      isDedicatedResource: userSpace.isDedicatedResource,
      isFavoriteResource: userSpace.isFavoriteResource,
      resourceType: userSpace.resourceType,
      officeId: userSpace.officeId,
    });
  }

  addAnotherBookingHandler(userSpace: IUserSpaceView): void {
    this.addAnotherBooking.emit(userSpace);
  }

  viewBookingHandler(userSpace: IUserSpaceView): void {
    const [booking] = userSpace?.bookings || [];
    if (!booking || userSpace.isOccupied) {
      return;
    }
    this.viewBooking.emit();
  }

  viewLocationHandler(userSpace: IUserSpaceView): void {
    this.viewLocation.emit(userSpace.zoneId);
  }

  spaceHasFloorPlan(
    userSpace: IUserSpaceView,
    deskAreas: IDeskArea[],
  ): boolean {
    if (!deskAreas || !deskAreas?.length) {
      return false;
    }
    const areaId = userSpace.zoneId;
    const area = deskAreas.find((a) => a.id === areaId);
    return !!area?.group.floorConfigReady;
  }

  bookingCheckInHandler(booking: IUserSpaceViewBooking): void {
    this.bookingCheckIn.emit({
      zoneItemId: booking.zoneItemId,
      bookingId: booking.id,
      workspaceId: booking.workspaceId,
      zoneId: booking.zoneId,
    });
  }
}
