export function getBookingUpsertErrorCodeMessageMap(): {
  [key: string]: string;
} {
  const bookingUpsertErrorCodeMessageMap: { [key: string]: string } = {
    deskAlreadyOccupied: $localize`:@@booking-module|error-desk-occupied:The selected resource is already occupied`,
    restrictedAccessToOffice: $localize`:@@booking-module|error-restricted-access:The selected resource has restricted access`,
    bookingsLimitReached: $localize`:@@booking-module|multi-day-booking-error|error-booking-limit-reached:You cannot make more than 1 booking for the same resource type at the same date and time.`,
  };

  return bookingUpsertErrorCodeMessageMap;
}
