const timeoutIds: number[] = [];
const rafIds: number[] = [];
let lastIndex = -1;

function cleanUp(index: number): void {
  delete timeoutIds[index];
  delete rafIds[index];
}

export function safeRequestAnimationFrame(cb: (...args: any[]) => any) {
  const currentIndex = lastIndex + 1;
  lastIndex++;
  timeoutIds[currentIndex] = setTimeout(() => {
    cb();
    cancelAnimationFrame(rafIds[currentIndex]);
    cleanUp(currentIndex);
  }, 2000) as unknown as number;
  rafIds[currentIndex] = requestAnimationFrame(() => {
    cb();
    clearTimeout(timeoutIds[currentIndex]);
    cleanUp(currentIndex);
  });

  return () => {
    cancelAnimationFrame(rafIds[currentIndex]);
    clearTimeout(timeoutIds[currentIndex]);
    cleanUp(currentIndex);
  };
}
