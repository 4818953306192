import { Injectable, OnDestroy } from "@angular/core";
import {
  Observable,
  Subject,
  Subscription,
  from,
  isObservable,
  of,
} from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";

type EffectCallback<T> = (value: T) => void;
type EffectOptions<T> = {
  next?: EffectCallback<T>;
  error?: EffectCallback<any>;
  complete?: () => void;
};

@Injectable()
export class RxEffects implements OnDestroy {
  private destroy$ = new Subject<void>();
  private subscriptions = new Map<number, Subscription>();
  private effectCounter = 0;

  /**
   * Register an observable effect with lifecycle and error management.
   * @param input An observable, promise, or scheduler.
   * @param handler Optional handlers for next, error, and complete callbacks.
   * @returns A function to unregister the effect manually.
   */
  register<T>(
    input: Observable<T> | Promise<T> | (() => void),
    handler?: EffectCallback<T> | EffectOptions<T>,
  ): () => void {
    const id = this.effectCounter++;

    // Convert input to an observable if necessary
    const source$ = this.convertToObservable(input);

    // Standardize the handler
    const options =
      typeof handler === "function" ? { next: handler } : handler || {};

    // Create a subscription
    const subscription = source$
      .pipe(
        tap((value) => options.next?.(value as T)), // Safely cast value to T
        catchError((err) => {
          if (options.error) options.error(err);
          return [];
        }),
        finalize(() => options.complete?.()),
      )
      .subscribe();

    // Track the subscription
    this.subscriptions.set(id, subscription);

    // Return a function to unregister the effect
    return () => this.unregister(id);
  }

  /**
   * Unregister an effect manually.
   * @param id The ID of the effect to unregister.
   */
  unregister(id: number): void {
    const subscription = this.subscriptions.get(id);
    if (subscription) {
      subscription.unsubscribe();
      this.subscriptions.delete(id);
    }
  }

  /**
   * Automatically cleans up all effects when the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions.clear();
  }

  /**
   * Converts input to an observable.
   * @param input An observable, promise, or function.
   * @returns An observable.
   */
  private convertToObservable<T>(
    input: Observable<T> | Promise<T> | (() => void),
  ): Observable<T> {
    if (isObservable(input)) {
      return input;
    } else if (typeof input === "function") {
      return new Observable<T>((subscriber) => {
        try {
          input();
          subscriber.complete();
        } catch (err) {
          subscriber.error(err);
        }
      });
    } else {
      return from(input);
    }
  }
}

/**
 * Utility to check if an input is a promise.
 * @param input Any input to check.
 * @returns True if the input is a promise, false otherwise.
 */
function isPromise(input: any): input is Promise<any> {
  return input && typeof input.then === "function";
}
