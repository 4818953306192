import { Injectable, inject } from "@angular/core";
import { FeatureFlagService } from "../../services/feature-flag.service";
import { createEffect } from "@ngrx/effects";
import {
  catchError,
  combineLatest,
  filter,
  mergeMap,
  switchMap,
  take,
  takeUntil,
} from "rxjs";
import { FeatureFlagModel } from "./model";
import { HttpErrorResponse } from "@angular/common/http";
import { distinctUntilChangedDiff } from "common-module";
import { AuthModel } from "../model";
import { GlobalModel } from "global-module";

@Injectable({
  providedIn: "root",
})
export class FeatureFlagEffects {
  private readonly featureFlagModel = inject(FeatureFlagModel);
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly authModel = inject(AuthModel);
  private readonly globalModel = inject(GlobalModel);

  constructor() {
    this.featureFlagService.initialize();
  }

  loadFlag = createEffect(() =>
    combineLatest([
      this.featureFlagModel.actions.listen.loadFlag$,
      this.authModel.authenticatedUser$.pipe(
        filter(Boolean),
        distinctUntilChangedDiff((prev, curr) => [prev?.uuid, curr?.uuid]),
      ),
      this.authModel.corporateInfo$.pipe(
        filter(Boolean),
        distinctUntilChangedDiff((prev, curr) => [prev?.uuid, curr?.uuid]),
      ),
    ]).pipe(
      takeUntil(this.featureFlagModel.actions.listen.loadFlagCancel$),
      mergeMap(([payload, user, authUserCompany]) => {
        return this.featureFlagService
          .getFeatureFlagValue(payload.flag, user.uuid, authUserCompany?.uuid)
          .pipe(
            switchMap((value) => [
              this.featureFlagModel.actions.create.loadFlagSuccess({
                flag: payload.flag,
                value,
              }),
            ]),
            catchError((error: HttpErrorResponse) => {
              return [
                this.featureFlagModel.actions.create.loadFlagFailure(error),
              ];
            }),
          );
      }),
    ),
  );

  loadFlagForAdmin = createEffect(() =>
    combineLatest([
      this.featureFlagModel.actions.listen.loadFlagForAdmin$,
      this.authModel.authenticatedUser$.pipe(
        filter(Boolean),
        distinctUntilChangedDiff((prev, curr) => [prev?.uuid, curr?.uuid]),
      ),
      this.globalModel.selectors.adminAppCompany$.pipe(
        filter(Boolean),
        distinctUntilChangedDiff((prev, curr) => [prev?.uuid, curr?.uuid]),
      ),
    ]).pipe(
      takeUntil(this.featureFlagModel.actions.listen.loadFlagCancel$),
      mergeMap(([payload, user, adminAppCompany]) => {
        return this.featureFlagService
          .getFeatureFlagValue(payload.flag, user.uuid, adminAppCompany?.uuid)
          .pipe(
            switchMap((value) => [
              this.featureFlagModel.actions.create.loadFlagSuccess({
                flag: payload.flag,
                value,
              }),
            ]),
            catchError((error: HttpErrorResponse) => {
              return [
                this.featureFlagModel.actions.create.loadFlagFailure(error),
              ];
            }),
          );
      }),
    ),
  );
}
