import { IUserInfo } from "./user-info";

export interface IDeskAreaItemUser extends IUserInfo {
  bookingId: string;
  bookingTitle: string;
  startTime: number;
  endTime: number;
  isFullDay: boolean;
  seriesId?: string;
  virtualId?: string;

  // dev only - used for office roles visualization in floor plan
  officeRoleCodes?: string[];
}
