<div class="d-flex-column editor-container">
  @if (label) {
    <label class="small-text-bold mb-4">{{ label }}</label>
  }
  <p-editor
    pRipple
    [ngModel]="htmlValue"
    #input="ngModel"
    [style]="{ height: height }"
    [placeholder]="placeholder"
    (onTextChange)="valueChangedHandler($event)"
    [attr.data-testId]="dataTestId"
    bounds=".editor-container"
  >
    <ng-template pTemplate="header">
      <span class="ql-formats">
        <button type="button" class="ql-bold" aria-label="Bold"></button>
        <button type="button" class="ql-italic" aria-label="Italic"></button>
        <button
          type="button"
          class="ql-underline"
          aria-label="Underline"
        ></button>
        <button type="button" class="ql-link" aria-label="Link"></button>
      </span>
    </ng-template>
  </p-editor>

  @for (error of errorDictionaryList; track error) {
    @if (
      (input.dirty || input.touched || !!control?.touched) &&
      (input.errors?.[error.name] || control?.getError(error.name))
    ) {
      <small class="p-error d-block">
        {{
          error.getLabel(
            input.errors?.[error.name] || control?.getError(error.name)
          )
        }}
      </small>
    }
  }
</div>
