import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SelectButtonModule, SelectButton } from "primeng/selectbutton";
import { EventEmitterValue } from "shared";

@Component({
  selector: "db-button-selector",
  standalone: true,
  imports: [SelectButtonModule],
  templateUrl: "./button-selector.component.html",
  styleUrls: ["./button-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ButtonSelectorComponent,
      multi: true,
    },
  ],
})
export class ButtonSelectorComponent<T = any>
  implements OnInit, ControlValueAccessor
{
  @ContentChild(TemplateRef) template!: TemplateRef<{ $implicit: T }>;
  @ViewChild(SelectButton, { read: SelectButton, static: true })
  primeNgSelectButton!: SelectButton;

  @Output() onOptionClick = new EventEmitter<
    EventEmitterValue<SelectButton["onOptionClick"]>
  >();
  @Output() onChange = new EventEmitter<
    EventEmitterValue<SelectButton["onChange"]>
  >();

  // Used primeng inputs:

  @Input() options: any[] | undefined; // An array of selectitems to display as the available options.
  @Input() optionLabel: string | undefined; // Name of the label field of an option.
  @Input() optionValue: string | undefined; // Name of the value field of an option.
  @Input() multiple: boolean | undefined; // When specified, allows selecting multiple values.

  // Unused primeng inputs:
  // @Input() optionDisabled: string | undefined; // Name of the disabled field of an option.
  // @Input() tabindex: number = 0; // Index of the element in tabbing order.
  // @Input() style: any; // Inline style of the component.
  // @Input() styleClass: string | undefined; // Style class of the component.
  // @Input() ariaLabelledBy: string | undefined; // Establishes relationships between the component and label(s) where its value should be one or more element IDs.
  // @Input() disabled: boolean | undefined; // When present, it specifies that the element should be disabled.
  // @Input() dataKey: string | undefined; // A property to uniquely identify a value in options.

  disabled = false;
  changeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.primeNgSelectButton.onOptionClick.subscribe(this.onOptionClick);
    this.primeNgSelectButton.onChange.subscribe(this.onChange);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  writeValue(obj: any): void {
    this.primeNgSelectButton.writeValue(obj);
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.primeNgSelectButton.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.primeNgSelectButton.registerOnTouched(fn);
  }
}
