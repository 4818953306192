<button
  class="status-button"
  [ngClass]="styleClasses"
  [ngStyle]="styles"
  [disabled]="isDisabled"
  (click)="statusClickHandler()"
  [attr.data-testId]="dataTestId"
>
  <div class="label-wrapper">
    @if (buttonLabel) {
      <span>{{ buttonLabel }}</span>
    } @else {
      <span>
        <db-daily-status-option-label
          [status]="status"
        ></db-daily-status-option-label>
      </span>
    }
    @if (officeName) {
      <span data-testid="schedule--office-name" class="very-small-text">{{
        officeName
      }}</span>
    }
  </div>
</button>
