import { SimpleChange } from "@angular/core";

/**
 * Represents a strongly-typed version of Angular's SimpleChange.
 * This type maintains all properties of SimpleChange but enforces type safety for previousValue and currentValue.
 *
 * @template T The type of the value being tracked for changes (typically a type of a single component input property)
 */
export type SimpleChangeTyped<T> = Omit<
  SimpleChange,
  "previousValue" | "currentValue"
> & {
  previousValue: T;
  currentValue: T;
};

/**
 * A strongly-typed version of Angular's SimpleChanges object.
 * Maps each property of an object to its corresponding SimpleChangeTyped instance.
 * Useful for type-safe ngOnChanges implementations.
 *
 * @template T The interface/type containing the properties being tracked for changes (typically the component class itself)
 */
export type SimpleChangesTyped<T> = {
  [K in keyof T]: SimpleChangeTyped<T[K]>;
};
