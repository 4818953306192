export interface IExternalMicrosoftMeetingRoom {
  id: string;
  displayName: string;
  address: null | string;
  geoCoordinates: null;
  phone: string;
  nickname: string;
  emailAddress: string;
  building: null;
  floorNumber: null;
  floorLabel: null;
  label: null;
  capacity: number;
  bookingType: string;
  audioDeviceName: null;
  videoDeviceName: null;
  displayDeviceName: null;
  isWheelChairAccessible: false;
  tags: string[];
}
